import type { JSXElementConstructor } from "react";

export enum ChatMode {
    Duo,
    Group,
}

export enum ChatRoomType {
    Match = 0,
}

export type ChatUser = {
    userId: string | undefined;
    hasWriteAccess: boolean;
    hasReadAccess: boolean;
};

export type ChatUserInformation = {
    displayName?: string;
    teamName?: string;
    avatarUrl?: string;
};

export type ChatUserLookup = (userId: string | undefined) => ChatUserInformation | undefined;

export interface ReadHighWaterMark {
    userId: string;
    roomId: string;
    readAt: string;
}

export interface ChatServerMessage {
    id: string;
    userId: string;
    roomId: string;
    message: string;
    sentAt: string;
    reactions: ChatMessageReaction[];
}

export interface ChatMessageReaction {
    userId: string;
    emoji: string;
}

export interface ChatMessageBaseProps {
    message: ChatServerMessage;
    chatUser: ChatUserInformation | undefined;
    messageAction?: JSXElementConstructor<{ message: ChatServerMessage }>;
    reactionsComponent?: JSXElementConstructor<{
        reactions: ChatMessageReaction[];
    }>;
}
