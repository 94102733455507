import { styled } from "@mui/material";
import type { ReactNode } from "react";
import type { TextSize } from "./utils";
import { getFontSize } from "./utils";

interface DetailsTextProps {
    size?: TextSize;
    bold?: boolean;
    uppercase?: boolean;
    children?: ReactNode;
}

export const DetailsText = styled(({ size, bold, uppercase, ...rest }: DetailsTextProps) => (
    <span {...rest} />
))<DetailsTextProps>(({ theme, size, bold, uppercase }) => ({
    color: theme.colors.secondary_text,
    fontSize: getFontSize(size),
    fontWeight: bold ? "bold" : "normal",
    textTransform: uppercase ? "uppercase" : "unset",
}));
