import { DrawerContext, DrawerSection, MbNumberField, SpinnerButton, BlockContainer } from "@masterblaster/basics";
import { Box, Divider } from "@mui/material";
import { sendCommand } from "@masterblaster/api";
import type { CSSProperties } from "react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import type { MatchSeries } from "../api/MatchSeries";
import { useHead2HeadTeams } from "../api/MatchApi";

export const ScoreInputs = ({ matchSeries }: { matchSeries: MatchSeries }) => {
    const { teamA, teamB } = useHead2HeadTeams(matchSeries);
    const { t } = useTranslation(["common", "translation"]);

    const [teamAScores, setTeamAScores] = useState(
        matchSeries.teamScores.find((x) => x.teamId === teamA?.team?.id)?.score?.value
    );
    const [teamBScores, setTeamBScores] = useState(
        matchSeries.teamScores.find((x) => x.teamId === teamB?.team?.id)?.score?.value
    );

    const { hasChanges, setKeyChanged, clearKeyChanged } = useContext(DrawerContext);
    const changeKey = "MatchScores";

    const onScoresUpdated = async () => {
        const result = await sendCommand("UpdateMatchSeriesScores", {
            matchSeriesId: matchSeries.id,
            teamScores: {
                [teamA?.team?.id ?? ""]: teamAScores,
                [teamB?.team?.id ?? ""]: teamBScores,
            },
        });
        if (result.success) {
            clearKeyChanged(changeKey);
        }
    };

    return (
        <BlockContainer>
            <DrawerSection>{t("translation:components.tournament.match.drawer.score_inputs.scores")}</DrawerSection>
            <Box display="flex">
                <ScoreInput
                    name={teamA?.team?.name}
                    score={teamAScores}
                    onChanged={(e) => {
                        setTeamAScores(e);
                        setKeyChanged(changeKey);
                    }}
                />
                <Divider orientation="vertical" style={{ margin: "0 0.5rem" }} />
                <ScoreInput
                    name={teamB?.team?.name}
                    score={teamBScores}
                    onChanged={(e) => {
                        setTeamBScores(e);
                        setKeyChanged(changeKey);
                    }}
                />
            </Box>
            {hasChanges(changeKey) && (
                <SpinnerButton variant="contained" color="primary" size="small" onClick={onScoresUpdated}>
                    {t("common:button_actions.save")}
                </SpinnerButton>
            )}
        </BlockContainer>
    );
};

export const ScoreInput = (props: {
    name: string | undefined;
    score: number | undefined;
    onChanged?(score: number | undefined): void;
    style?: CSSProperties;
}) => {
    const [error, setError] = useState<string>();

    if (!props.name) {
        return null;
    }

    return (
        <MbNumberField
            fullWidth
            label={props.name}
            style={props.style}
            value={props.score ?? ""}
            error={!!error}
            helpText={error}
            onChange={(e) => {
                const v = parseInt(e.currentTarget.value);
                const result = isNaN(v) ? undefined : v;

                setError(undefined);
                props.onChanged?.(result);
            }}
        />
    );
};
