import type { VetoConfiguration } from "@masterblaster/api";
import { formatDateTime, sendCommand } from "@masterblaster/api";
import { DrawerContext, DrawerSection, GfLabel, PropertyList, SpinnerButton, Switch } from "@masterblaster/basics";
import { Box } from "@mui/material";
import { MapSide, VetoMapStatus, useVeto } from "@services/VetoApi";
import _ from "lodash";
import { useContext, useState } from "react";

import { VetoCountDown } from "@components/matches/veto/VetoCountDown";
import { useGame } from "@src/config";
import { useTranslation } from "react-i18next";
import type { MatchSeries } from "../api/MatchSeries";
import { MatchStatus } from "../api/MatchSeries";

export const VetoSettings = ({
    matchSeries,
    vetoConfiguration,
}: {
    matchSeries: MatchSeries;
    vetoConfiguration: VetoConfiguration | undefined;
}) => {
    const { veto } = useVeto(matchSeries?.settings.mapVetoId, matchSeries?.gameId);
    const [useVetoSetting, setUseVetoSetting] = useState(matchSeries.settings.useVeto);
    const [useVetoSide, setUseVetoSide] = useState(matchSeries.settings.useVetoSide);
    const { settings } = useGame(matchSeries.gameId);

    const { t } = useTranslation(["common", "translation"]);

    const { hasChanges, setKeyChanged, clearKeyChanged } = useContext(DrawerContext);
    const changeKey = "MatchVeto";

    const onSave = async () => {
        const result = await sendCommand("UpdateMatchSeriesVetoSettings", {
            matchSeriesId: matchSeries.id,
            useVeto: useVetoSetting,
            useVetoSide: useVetoSide,
        });
        if (result.success) {
            clearKeyChanged(changeKey);
        }
    };

    const onChanged = () => {
        setKeyChanged(changeKey);
    };

    if (!settings.useVeto) {
        return null;
    }

    const getTeamName = (teamId: string | undefined) => {
        return matchSeries.teams.find((x) => x.team?.id === teamId)?.team?.name ?? "";
    };

    const getMapString = (status: VetoMapStatus) =>
        _.chain(veto?.maps ?? [])
            .filter((x) => x.status === status)
            .orderBy((x) => x.index)
            .map((x) => {
                const teamName = getTeamName(x.chosenByTeamId);
                const autoPostFix = x.autoChosen
                    ? t("translation:components.tournament.match.drawer.veto_settings.auto_postfix")
                    : "";
                const side = x.side && status === VetoMapStatus.Pick ? ` - ${MapSide[x.side]}` : "";
                return teamName ? `${x.index}. ${x.name} (${teamName}${autoPostFix}${side})` : x.name;
            })
            .value();

    const data = [
        { label: t("common:shared.started"), value: formatDateTime(veto?.createdAt) },
        {
            label: t("translation:components.tournament.match.drawer.veto_settings.time_to_veto"),
            value: matchSeries.status < MatchStatus.Postgame && veto && vetoConfiguration && (
                <VetoCountDown veto={veto} vetoConfiguration={vetoConfiguration} />
            ),
        },
        {
            label: t("translation:components.tournament.match.drawer.veto_settings.team_to_veto"),
            value: getTeamName(veto?.nextTeamToChoose),
        },
        {
            label: t("translation:components.tournament.match.drawer.veto_settings.last_map_selected_at"),
            value: formatDateTime(veto?.lastMapSelectedAt),
        },
        { label: t("common:shared.number_of_maps"), value: veto?.numberOfMaps },
        {
            label: t("translation:components.tournament.match.drawer.veto_settings.picked"),
            value: getMapString(VetoMapStatus.Pick),
        },
        { label: t("common:shared.banned"), value: getMapString(VetoMapStatus.Ban) },
        { label: t("common:shared.not_selected"), value: getMapString(VetoMapStatus.NotSelected) },
    ];

    return (
        <Box>
            <DrawerSection>{t("translation:components.tournament.match.drawer.veto_settings.veto")}</DrawerSection>
            <Box display="flex" flexDirection="column" marginBottom="1rem">
                <GfLabel>{t("translation:components.tournament.match.drawer.veto_settings.help_text")}</GfLabel>
                <Switch
                    label={t("translation:components.tournament.match.drawer.veto_settings.use_gf_veto")}
                    checked={useVetoSetting}
                    onChanged={(c) => {
                        setUseVetoSetting(c);
                        onChanged();
                    }}
                />
                <Switch
                    label={t("translation:components.tournament.match.drawer.veto_settings.use_veto_side")}
                    checked={useVetoSide}
                    onChanged={(c) => {
                        setUseVetoSide(c);
                        onChanged();
                    }}
                />
            </Box>

            {useVetoSetting && veto && (
                <Box marginBottom="1rem">
                    <GfLabel>{t("translation:components.tournament.match.drawer.veto_settings.veto_status")}</GfLabel>

                    <PropertyList properties={data} style={{ margin: 0 }} />
                </Box>
            )}
            {hasChanges(changeKey) && (
                <SpinnerButton
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                        onSave();
                    }}
                >
                    {t("common:button_actions.save")}
                </SpinnerButton>
            )}
        </Box>
    );
};
