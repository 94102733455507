import Typography from "@mui/material/Typography";
import { formatDateTime } from "@masterblaster/api";
import { showModal } from "@store/core";
import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { MatchRescheduleDateInfo } from "./MatchRescheduleDateInfo";
import { MatchRescheduleDialogTitle } from "./MatchRescheduleDialogTitle";
import { MatchRescheduleTeamBox } from "./MatchRescheduleTeamBox";
import { StyledDialog, StyledDialogContent } from "./shared";
import type { MatchSeries } from "../api/MatchSeries";

interface MatchRescheduleConfirmedDialogProps {
    matchSeries: MatchSeries;
    scheduledAt: string;
    onClose: () => void;
}

export const showMatchRescheduleConfirmedDialog = async (matchSeries: MatchSeries, scheduledAt: string) => {
    await showModal((onClose) => (
        <MatchRescheduleConfirmedDialog matchSeries={matchSeries} scheduledAt={scheduledAt} onClose={onClose} />
    ));
};

export const MatchRescheduleConfirmedDialog: FC<MatchRescheduleConfirmedDialogProps> = ({
    matchSeries,
    scheduledAt,
    onClose,
}) => {
    const { t } = useTranslation("translation", {
        keyPrefix: "components.tournament.match.reschedule.confirmed_dialog",
    });

    return (
        <StyledDialog open={true} fullWidth={true} onClose={onClose}>
            <MatchRescheduleDialogTitle onClose={onClose}>
                {t("title", {
                    matchName: matchSeries.name,
                })}
            </MatchRescheduleDialogTitle>
            <StyledDialogContent>
                <MatchRescheduleTeamBox teams={matchSeries.teams} mb="1.375rem" />
                <Typography variant="h6" lineHeight="1.3" textAlign="center" mb="0.625rem">
                    {t("help_text")}
                </Typography>
                <MatchRescheduleDateInfo isActive mb="0.625rem">
                    {t("new_date", {
                        date: formatDateTime(scheduledAt),
                    })}
                </MatchRescheduleDateInfo>
            </StyledDialogContent>
        </StyledDialog>
    );
};
