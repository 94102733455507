import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export interface BannerState {
    isReloadNoticeOpen: boolean;
    hasWebSocketBeenOpen: boolean;
    isWebSocketNoticeSuspended: boolean;
    isWebSocketOpen: boolean;
    retryInMs: number | undefined;
}

const initialState: BannerState = {
    isReloadNoticeOpen: false,
    isWebSocketOpen: false,
    hasWebSocketBeenOpen: false,
    isWebSocketNoticeSuspended: false,
    retryInMs: undefined,
};

export const bannerSlice = createSlice({
    name: "banners",
    initialState,
    reducers: {
        openReloadNotice(state) {
            state.isReloadNoticeOpen = true;
        },
        closeReloadNotice(state) {
            state.isReloadNoticeOpen = false;
        },
        openWebSocketNotice(state) {
            if (!state.isWebSocketNoticeSuspended) {
                state.isWebSocketOpen = true;
            }
        },
        reconnectionWebScoket(state, action: PayloadAction<number>) {
            if (!state.isWebSocketNoticeSuspended) {
                state.isWebSocketOpen = true;
                state.retryInMs = action.payload;
            }
        },
        closeWebSocketNotice(state) {
            state.isWebSocketOpen = false;
            state.retryInMs = undefined;
        },
        suspendWebSocketNotice(state) {
            state.isWebSocketNoticeSuspended = true;
        },
        resumeWebSocketNotice(state) {
            state.isWebSocketNoticeSuspended = false;
        },
    },
});
