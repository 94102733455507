import type { Duration, Locale } from "date-fns";
import {
    format as fnsFormat,
    formatRelative as fnsFormatRelative,
    intervalToDuration,
    parseISO,
    formatDistance,
} from "date-fns";
import * as locales from "date-fns/locale";

export const dateFormat = "YYYY-MM-DD";

export const shortDateTime = "d LLL yy p";

type LocalesType = Record<string, Locale>;

export const getBrowserLocale = () => {
    let chosenLocale: Locale | undefined;
    const browserLocales = navigator.languages;

    browserLocales.some((locale) => {
        if (locale === "no" || locale === "no-NO") {
            chosenLocale = locales.nb;
            return true;
        }

        const camelCaseLocale = locale.replace("-", "");
        const [language] = locale.split("-");

        if (camelCaseLocale in locales) {
            chosenLocale = (locales as unknown as LocalesType)[camelCaseLocale];
            return true;
        }

        if (Object.prototype.hasOwnProperty.call(locales, language)) {
            chosenLocale = (locales as unknown as LocalesType)[language];
            return true;
        }

        return Boolean(chosenLocale);
    });

    if (!chosenLocale) {
        return locales.enUS;
    }

    return chosenLocale;
};

export const formatDateTime = (date: string | Date | undefined | null, format?: string) => {
    const d = typeof date === "string" ? parseISO(date) : date;
    if (!d) {
        return "";
    }

    const l = getBrowserLocale();
    return fnsFormat(d, format ?? "Pp", { locale: l });
};

export const formatAgo = (date: string | Date | undefined | null) => {
    const d = typeof date === "string" ? parseISO(date) : date;
    if (!d) {
        return "";
    }

    const l = getBrowserLocale();
    return formatDistance(d, new Date(), { locale: l, addSuffix: false });
};

export const formatRelative = (date: string | Date | undefined | null, baseDate?: string | Date) => {
    const parsedDate = typeof date === "string" ? parseISO(date) : date;
    const parsedBaseDate = typeof baseDate === "string" ? parseISO(baseDate) : baseDate;

    if (!parsedDate) {
        return "";
    }

    const l = getBrowserLocale();
    return fnsFormatRelative(parsedDate, parsedBaseDate ?? new Date(), {
        locale: l,
    });
};

interface TimeSpan {
    ticks: number;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    totalDays: number;
    totalHours: number;
    totalMilliseconds: number;
    totalMinutes: number;
    totalSeconds: number;
}

export const isTimeSpan = (timeSpan: any) => {
    return (
        timeSpan["ticks"] !== undefined &&
        timeSpan["days"] !== undefined &&
        timeSpan["hours"] !== undefined &&
        timeSpan["minutes"] !== undefined &&
        timeSpan["seconds"] !== undefined &&
        timeSpan["totalDays"] !== undefined &&
        timeSpan["totalHours"] !== undefined &&
        timeSpan["totalMilliseconds"] !== undefined &&
        timeSpan["totalMinutes"] !== undefined &&
        timeSpan["totalSeconds"] !== undefined
    );
};

export const parseTimeSpan = (timeSpan: any) => {
    if (isTimeSpan(timeSpan)) {
        return timeSpan as TimeSpan;
    }
};
export const formatTimeSpan = (timeSpan: TimeSpan | undefined) => {
    if (!timeSpan) {
        return "";
    }

    const pad = (value: number) => value.toString().padStart(2, "0");
    return `${pad(timeSpan.hours)}:${pad(timeSpan.minutes)}:${pad(timeSpan.seconds)}`;
};

export const getMillisecondsFromTimespan = (timespan: string | TimeSpan | undefined) => {
    if (!timespan) {
        return 0;
    }

    if (typeof timespan === "string") {
        const parts = timespan.split(":");
        const date = new Date(0);

        date.setHours(parseInt(parts[0]));
        date.setMinutes(parseInt(parts[1]));

        const subParts = parts[2]?.split(".");
        const seconds = parseInt(subParts[0]);
        date.setSeconds(seconds);

        const msHours = parseInt(parts[0]) * 60 * 60 * 1000;
        const msMinutes = parseInt(parts[1]) * 60 * 1000;
        const msSeconds = seconds * 1000;

        if (subParts[1]) {
            const ms = subParts[1].replace("0", "");
            date.setMilliseconds(parseInt(ms));
        }

        const ms = subParts[1] ? parseInt(subParts[1].replace("0", "")) : 0;

        // const ms = date.getTime();
        // return Math.abs(ms);
        return msHours + msMinutes + msSeconds + ms;
    }

    if (isTimeSpan(timespan)) {
        return timespan.totalMilliseconds;
    }

    return 0;
};

export const millisecondsToDuration = (milliseconds: number) =>
    intervalToDuration({
        start: new Date(0),
        end: new Date((milliseconds ?? 0) < 0 ? 0 : milliseconds),
    });

export const customFormatDuration = (duration: Duration) => {
    const days = duration.days ?? 0;
    const hrs = duration.hours ?? 0;
    const mins = duration.minutes ?? 0;
    const seconds = (duration.seconds ?? 0).toString().padStart(2, "0");
    if (days > 0) {
        return `${days} days ${hrs} hrs`;
    }

    if (hrs > 0) {
        return `${hrs} hrs ${mins} min`;
    }

    if (mins > 0) {
        return `${mins} min ${seconds} sec`;
    }

    return `${seconds} sec`;
};
