import { GameIcon } from "@components/gameAccounts";
import { styled } from "@mui/material/styles";
import { useGame } from "@src/config";
import type { FC } from "react";

export interface GameTagProps {
    gameId: string;
}
export const GameTag: FC<GameTagProps> = ({ gameId }) => {
    const { settings } = useGame(gameId);

    return (
        <GameTagContainer>
            <GameIcon gameSettings={settings} size="medium" />
        </GameTagContainer>
    );
};

const GameTagContainer = styled("div")(({ theme }) => ({
    flexShrink: "0",
    position: "relative",
    width: "32px",
    height: "32px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.colors.greys[12],
    border: `1px solid ${theme.colors.white}`,
    borderRadius: "5px",
    overflow: "hidden",
}));
