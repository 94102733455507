import type { SxProps, Theme } from "@mui/material";
import { Tooltip, useTheme } from "@mui/material";
import { Help as HelpIcon } from "@mui/icons-material";
import type { CSSProperties } from "react";

export const TooltipIcon = ({ title, iconSx }: { title: string | undefined; iconSx?: SxProps<Theme> }) => {
    const { colors } = useTheme<Theme>();

    if (!title) {
        return null;
    }

    return (
        <Tooltip sx={{ fontSize: "1em" }} title={title} arrow={true}>
            <HelpIcon sx={{ fontSize: "1.25em", color: colors.main_color, ...iconSx }} />
        </Tooltip>
    );
};
