import { ThemeSwitcher } from "@masterblaster/theme";
import type { ApplicationState } from "@store/index";
import { useSelector } from "react-redux";
import type { FC, PropsWithChildren, ReactNode } from "react";

export const ReduxThemeProvider: FC<PropsWithChildren<unknown>> = (props) => {
    const themeColors = useSelector((state: ApplicationState) => state.theme.themeColors);

    return <ThemeSwitcher themeColors={themeColors}>{props.children}</ThemeSwitcher>;
};
