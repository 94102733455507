import type { MouseEvent } from "react";
import { Trans } from "react-i18next";
import { getTeamGravatarUrl, GfLink } from "@masterblaster/basics";
import { generateLink } from "@utils/generateLink";
import { Notification } from "../Notification";
import { NotificationTitle } from "../shared";
import type { NotificationPayload } from "../NotificationService";
import type { NotificationElementProps } from "../NotificationElementProps";
import { getImageUrl } from "@masterblaster/api";

interface TeamJoinRequestRejected extends NotificationPayload {
    teamId: string;
    teamName: string;
    bannerImageId: string | undefined;
}

export const NotificationTeamJoinRequestRejected = ({ notification, navigateTo }: NotificationElementProps) => {
    const { teamId, bannerImageId, teamName } = notification.payload as TeamJoinRequestRejected;
    const teamLink = generateLink("TEAM_ROUTES.OVERVIEW", {
        id: teamId,
    });

    const handleNotificationClick = () => navigateTo(teamLink);
    const handleLinkClick = (link: string) => (event: MouseEvent<HTMLAnchorElement>) => {
        event.stopPropagation();
        navigateTo(link);
    };

    return (
        <Notification
            createdAt={notification.createdAt}
            isRead={!!notification.readAt}
            image={bannerImageId ? getImageUrl(bannerImageId) : getTeamGravatarUrl(teamName)}
            title={
                <NotificationTitle>
                    <span>
                        <Trans
                            ns="translation"
                            i18nKey="components.notifications.notification_type.team_join_request.your_player_team_join_request_rejected"
                            values={{ teamName }}
                            components={[
                                <GfLink underline key="0" to={teamLink} onClick={handleLinkClick(teamLink)} />,
                            ]}
                        />
                    </span>
                </NotificationTitle>
            }
            onClick={handleNotificationClick}
        />
    );
};
