import type { SelectProps } from "@mui/material";
import { MenuItem, Select } from "@mui/material";
import type { ReactNode } from "react";
import { MbTextField } from "./MbTextField";

export interface MbSelectProps<T extends string | string[] | number | number[]>
    extends Omit<SelectProps<T>, "variant" | "placeholder"> {
    label?: ReactNode;
    helpText?: string;
    subLabel?: string;
    subLabelIcon?: ReactNode;
    tooltip?: string;
    success?: boolean;
    placeholder?: {
        label: string;
        value: T extends string | string[] ? string | undefined : number;
    };
}

export function MbSelect<T extends string | string[] | number | number[]>({
    placeholder,
    label,
    fullWidth,
    helpText,
    subLabel,
    subLabelIcon,
    tooltip,
    success,
    children,
    displayEmpty,
    size,
    ...selectProps
}: MbSelectProps<T>) {
    const { value } = selectProps;

    return (
        <Select
            MenuProps={{
                MenuListProps: {
                    sx: { padding: 0 },
                },
                // disableScrollLock: true,
                // allow the menu to go outside the window
                // marginThreshold: null,
            }}
            input={
                <MbTextField
                    label={label}
                    fullWidth={fullWidth}
                    helpText={helpText}
                    subLabel={subLabel}
                    subLabelIcon={subLabelIcon}
                    tooltip={tooltip}
                    success={success}
                />
            }
            fullWidth={fullWidth}
            value={value}
            size={size}
            {...selectProps}
            displayEmpty={placeholder && displayEmpty === undefined ? true : displayEmpty}
            sx={{
                minWidth: "4rem",
                "& .MuiSelect-select": {
                    padding: calculatePadding(size),
                },
                "& .MuiInputBase-root": { padding: "0" },
                ...selectProps.sx,
            }}
        >
            {placeholder && <MenuItem value={placeholder.value}>{placeholder.label}</MenuItem>}
            {children}
        </Select>
    );
}

const calculatePadding = (size: SelectProps["size"]) => {
    switch (size) {
        case "small":
            return "0.5rem 2rem 0.5rem 1rem !important";
        case "medium":
        default:
            return "0.75rem 2rem 0.75rem 1rem !important";
    }
};
