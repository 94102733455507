import { type FC } from "react";
import { Notification } from "../Notification";
import { NotificationTitle } from "../shared";
import { type NotificationElementProps } from "../NotificationElementProps";
import type { NotificationPayload } from "../NotificationService";

interface ChallengeOpponentInvited extends NotificationPayload {
    competitionId: string;
}

export const NotificationChallengeOpponentInvite: FC<NotificationElementProps> = ({
    notification,
    context,
    navigateTo,
    t,
}) => {
    const Invite = notification.payload as ChallengeOpponentInvited;

    const handleClick = async () => {
        await context.markAsRead(notification.id);
        const url = `/challenge/${Invite.competitionId}`;
        navigateTo(url);
    };

    return (
        <Notification
            createdAt={notification.createdAt}
            isRead={!!notification.readAt}
            title={
                <NotificationTitle>
                    <span>
                        {t(
                            "translation:components.notifications.notification_type.challenge_opponent_invited.you_invited"
                        )}
                    </span>
                </NotificationTitle>
            }
            onClick={handleClick}
        />
    );
};
