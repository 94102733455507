import type { FormControlLabelProps } from "@mui/material/FormControlLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import type { SwitchProps } from "@mui/material/Switch";
import Switch from "@mui/material/Switch";
import type { FC } from "react";
import { styled } from "@mui/material/styles";

export interface MbSwitchProps extends SwitchProps {
    label: string;
    subLabel?: string;
    labelPlacement?: FormControlLabelProps["labelPlacement"];
}

export const MbSwitch: FC<MbSwitchProps> = ({
    label,
    subLabel,
    labelPlacement,
    className,
    style,
    sx,
    ...switchProps
}) => {
    return (
        <StyledFormControlLabel
            control={<Switch {...switchProps} />}
            label={
                <>
                    <MbSwitchLabel>{label}</MbSwitchLabel>
                    {subLabel && <MbSwitchSubLabel>{subLabel}</MbSwitchSubLabel>}
                </>
            }
            labelPlacement={labelPlacement}
            className={className}
            style={style}
            sx={sx}
        />
    );
};

const MbSwitchLabel = styled("p")(({ theme }) => ({
    margin: 0,
    fontWeight: "bold",
    fontSize: "1rem",
    lineHeight: "1.625rem",
    color: theme.colors.white,
}));

const MbSwitchSubLabel = styled("p")(({ theme }) => ({
    margin: "0.25rem 0 0",
    fontSize: "0.75rem",
    lineHeight: "1rem",
    color: theme.colors.white,
}));

const StyledFormControlLabel = styled(FormControlLabel)({
    alignItems: "start",

    "& .MuiSwitch-root": {
        marginRight: "1rem",
    },
});
