import mixpanel from "mixpanel-browser";
import { isProduction } from "@src/config/AppConfigKeeper";
import type { IUser } from "@masterblaster/api";

export const initMixPanel = (token: string) => {
    if (!isProduction()) return;

    mixpanel.init(token, {
        api_host: "https://api-eu.mixpanel.com",
        debug: false,
        secure_cookie: isProduction(),
    });
};

export const configureMixPanel = (user?: IUser) => {
    if (!isProduction()) return;

    if (user) {
        try {
            mixpanel.identify(user.id);
            mixpanel.people.set({
                $distinct_id: user.id,
                $email: user.eMail,
                $name: user.name,
                Nick: user.nickName,
            });
        } catch (err) {
            console.log("Failed to initialize Mixpanel: ", err);
        }
    }
};

const isMixPanelEnabled = () => isProduction();

const actions = {
    trackPage: () => {
        if (!isMixPanelEnabled()) return;

        try {
            mixpanel.track_pageview();
        } catch (error) {}
    },
    track: (name: string, props?: { [key: string]: unknown }) => {
        if (isMixPanelEnabled()) {
            try {
                mixpanel.track(name, props);
            } catch (error) {}
        }
    },
};

export const Mixpanel = actions;
