import type { Theme } from "@mui/material";
import { Box, Divider, useTheme } from "@mui/material";

import { getFontSize } from "@masterblaster/basics";
import { type FC, type PropsWithChildren } from "react";

export const DrawerSection: FC<PropsWithChildren<unknown>> = (props) => {
    const { colors } = useTheme<Theme>();

    return (
        <Box style={{ margin: "1rem 0" }}>
            <label
                style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: getFontSize("sm"),
                    color: colors.secondary_text,
                }}
            >
                {props.children}
            </label>
            <Divider style={{ height: 2 }} />
        </Box>
    );
};
