export const TRIALS_ROUTES = {
    TRIALS: "/cs/trials",
    TRIAL: "/cs/trials/:id",
    CREATE_TRIAL: "/cs/trials/create",
} as const;

const TRIAL_TABS_BASE_URL = TRIALS_ROUTES.TRIAL;

export const TRIAL_TAB_ROUTES = {
    BASE: TRIAL_TABS_BASE_URL,
    PLAYER_SESSION: `${TRIAL_TABS_BASE_URL}/sessions/:playerId`,
    STAND_PLAYERS: `${TRIAL_TABS_BASE_URL}/stand_players`,
    SERVERS: `${TRIAL_TABS_BASE_URL}/servers`,
    TAB: `${TRIAL_TABS_BASE_URL}/:tab?`,
    EDIT: `${TRIAL_TABS_BASE_URL}/edit`,
    PRIZES: `${TRIAL_TABS_BASE_URL}/edit/prizes`,
    SESSIONS: `${TRIAL_TABS_BASE_URL}/edit/sessions`,
} as const;
