import type { RefObject } from "react";
import { useEffect, useLayoutEffect, useRef, useState } from "react";

interface ContainerQueryHookProps<T extends number | number[]> {
    query: T;
    elementRef: RefObject<HTMLElement>;
}

export const useContainerQuery = <T extends number | number[]>({
    elementRef,
    query,
}: ContainerQueryHookProps<T>): T extends number ? boolean : boolean[] => {
    const [isMobile, setIsMobile] = useState(() => (typeof query === "number" ? false : query.map(() => false)));
    const queryRef = useRef(query);

    useEffect(() => {
        queryRef.current = query;
    }, [query]);

    useLayoutEffect(() => {
        if (!elementRef.current) {
            return;
        }

        let observer: ResizeObserver | null;

        observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
            const entryWidth = entries[0].contentRect.width;

            setIsMobile((prevState) => {
                if (typeof queryRef.current === "number") {
                    return entryWidth < queryRef.current;
                }
                const newState = queryRef.current?.map((elem) => entryWidth < elem);

                return JSON.stringify(prevState) === JSON.stringify(newState) ? prevState : newState;
            });
        });

        observer.observe(elementRef.current);

        return () => {
            observer && observer.disconnect();
            observer = null;
        };
    }, [elementRef]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    return isMobile;
};
