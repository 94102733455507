import { updateMatchConnectionInfoFields } from "@components/matches/api/MatchApi";
import type { MatchSeries } from "@components/matches/api/MatchSeries";
import { isPlayerInMatch } from "@components/matches/api/MatchSeries";
import type { CustomField, CustomFieldValues } from "@masterblaster/api";
import { CustomFieldIntent, isCustomFieldVisible } from "@masterblaster/api";
import { SpinnerButton } from "@masterblaster/basics";
import type { SxProps, Theme } from "@mui/material";
import { Box, styled, useMediaQuery } from "@mui/material";
import { useGame } from "@src/config";
import { CustomFieldValue, EditableCustomField } from "@src/config/games";
import type { ApplicationState } from "@store/index";
import camelcase from "camelcase";
import _ from "lodash";
import type { FC, PropsWithChildren, ReactNode } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ConnectionGuideButton } from "../shared/ConnectionGuide";
import { LobbyHeaderSubtext } from "./LobbyHeaderSubtext";
import { useAuthorization } from "@services/AuthSerivce";
import { AccessLevels } from "@mb/auth";

export const useConnectionFields = (fieldValues: CustomFieldValues, fields: CustomField[], showInputs?: boolean) => {
    const connectionFields = useMemo(
        () =>
            _.chain(fields)
                .orderBy((x) => x.sortOrder)
                .map((x) => {
                    const visible = showInputs ? x.intent === CustomFieldIntent.Value : true;
                    const value = (fieldValues?.[camelcase(x.field)] ?? "") as string;
                    return {
                        ...x,
                        visible,
                        value,
                    };
                })
                .filter((x) => x.visible)
                .value(),
        [fields, fieldValues, showInputs]
    );

    const missingFields = connectionFields.filter((x) => x.intent === CustomFieldIntent.Value && !x.value);
    const hasMissingFieldValues = missingFields.length > 0;

    return {
        connectionFields,
        missingFields,
        hasMissingFieldValues,
    };
};

interface ConnectionInfoComponentProps {
    matchSeries: MatchSeries;
    fields: CustomField[];
    homeTeamName?: string;
    showInputs?: boolean;
    showButtons?: boolean;
    stacked?: boolean;
    slot?: number;
}
export const ConnectionInfoComponent: FC<ConnectionInfoComponentProps> = (props) => {
    const { matchSeries, fields, homeTeamName, showInputs, slot, showButtons = true } = props;
    const md = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
    const stacked = props.stacked || md;
    const { t } = useTranslation("translation", { keyPrefix: "match_lobby" });
    const isAdmin = useAuthorization("Tournament", matchSeries.competitionId, AccessLevels.GameAdmin);
    const { settings } = useGame(matchSeries.gameId);
    const [matchConnectionFields, setMatchConnectionFields] = useState(matchSeries.connectionInfo?.fields);
    const [changed, setChanged] = useState(false);
    const user = useSelector((state: ApplicationState) => state.session.user);
    const isPlayer = isPlayerInMatch(matchSeries, user?.id);
    const { connectionFields, hasMissingFieldValues } = useConnectionFields(matchConnectionFields, fields, showInputs);

    const { hasMissingFieldValues: initialHasMissingFieldValues } = useConnectionFields(
        matchSeries.connectionInfo?.fields,
        fields,
        showInputs
    );

    useEffect(() => {
        setMatchConnectionFields(matchSeries.connectionInfo?.fields);
        setChanged(false);
    }, [matchSeries.connectionInfo?.fields]);

    const handleSaveFields = useCallback(async () => {
        const updated = connectionFields.reduce((p, c) => ({ ...p, [c.field]: c.value }), {});
        const result = await updateMatchConnectionInfoFields(matchSeries.id, updated);
        return result.success;
    }, [connectionFields, matchSeries]);

    if (connectionFields.length === 0) {
        return null;
    }

    if (!showInputs && hasMissingFieldValues) {
        if (homeTeamName && settings.allowHomeTeamToEnterConnectionInfo && hasMissingFieldValues) {
            return (
                <ConnectionInfoHeader
                    teamName={homeTeamName}
                    subtitle={t("away_team_connection_guide_sub", { teamName: homeTeamName })}
                />
            );
        }

        return (
            <LobbyHeaderSubtext sx={{}}>
                <span>{t("waiting_for_connection_info")}</span>
                <span className="blink-1">.</span>
            </LobbyHeaderSubtext>
        );
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: 1 }} data-testid="connection-fields">
            {homeTeamName && settings.allowHomeTeamToEnterConnectionInfo && initialHasMissingFieldValues && (
                <ConnectionInfoHeader
                    teamName={homeTeamName}
                    subtitle={t("home_team_connection_guide")}
                    sx={{ marginBottom: "1rem" }}
                />
            )}
            <Box
                sx={{
                    display: "flex",
                    alignItems: stacked ? "flex-start" : "center",
                    flexDirection: stacked ? "column" : "row",
                    width: 1,
                    gap: "1rem",
                }}
            >
                <Box
                    sx={{ display: "flex", gap: "1rem", width: 1, flex: 1, flexDirection: stacked ? "column" : "row" }}
                    data-testid="connection-fields-container"
                >
                    {connectionFields.map((field, idx) => {
                        const visible = isCustomFieldVisible(field, user, isPlayer) || isAdmin;
                        if (!visible) {
                            return null;
                        }

                        const showLabel = Boolean(field?.intent !== CustomFieldIntent.LinkButton && field?.displayName);
                        const value = field.value;
                        return (
                            <LabelFieldContainer
                                key={idx}
                                label={showLabel ? field.displayName : stacked ? "" : undefined}
                            >
                                {showInputs && (
                                    <EditableCustomField
                                        key={idx}
                                        field={field}
                                        value={value}
                                        fullWidth
                                        onChanged={(val) => {
                                            setMatchConnectionFields((prev) => ({
                                                ...prev,
                                                [field.field]: val,
                                                [camelcase(field.field)]: val,
                                            }));
                                            setChanged(true);
                                        }}
                                    />
                                )}
                                {!showInputs && (
                                    <CustomFieldValue
                                        field={field}
                                        value={value}
                                        options={{ showCopyToClipboard: true }}
                                        sx={{ fontSize: "1.25em", lineHeight: "1.25em" }}
                                    />
                                )}
                            </LabelFieldContainer>
                        );
                    })}
                    {settings.useSlotInConnectionInfo && !showInputs && (
                        <LabelFieldContainer label="Slot">
                            <CustomFieldValue
                                field={{
                                    field: "slot",
                                    displayName: "Slot",
                                    intent: CustomFieldIntent.Value,
                                    defaultValue: undefined,
                                }}
                                value={slot}
                                options={{ showCopyToClipboard: true }}
                                sx={{ fontSize: "1.25em", lineHeight: "1.25em" }}
                            />
                        </LabelFieldContainer>
                    )}
                </Box>
                {(showInputs || showButtons) && (
                    <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                        {showInputs && (
                            <LabelFieldContainer label={stacked ? "" : undefined}>
                                <SpinnerButton
                                    disabled={!changed}
                                    fullWidth
                                    variant="contained"
                                    onClick={handleSaveFields}
                                >
                                    Save
                                </SpinnerButton>
                            </LabelFieldContainer>
                        )}

                        {showButtons && (
                            <LabelFieldContainer label={stacked ? "" : undefined}>
                                <ConnectionGuideButton fullWidth={stacked} gameSettings={settings} />
                            </LabelFieldContainer>
                        )}
                    </Box>
                )}
            </Box>
        </Box>
    );
};

const LabelFieldContainer: FC<PropsWithChildren<{ label?: ReactNode; sx?: SxProps<Theme> }>> = ({
    label,
    sx,
    children,
}) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0.25rem",
                justifyContent: label ? "flex-end" : "center",
                width: 1,
                ...sx,
            }}
        >
            <Label>{label ?? <>&nbsp;</>}</Label>
            {children}
        </Box>
    );
};

const ConnectionInfoHeader = ({
    teamName,
    subtitle,
    sx,
}: {
    teamName: string;
    subtitle: string;
    sx?: SxProps<Theme>;
}) => {
    const { t } = useTranslation("translation", { keyPrefix: "match_lobby" });
    return (
        <LobbyHeaderSubtext
            sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", ...sx }}
        >
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", textAlign: "center" }}>
                <StyledSpan sx={{ color: (theme) => theme.colors.yellow_base }}>{teamName}</StyledSpan>
                <StyledSpan>&nbsp;{t("away_team_connection_guide")}</StyledSpan>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    fontSize: "0.75rem",
                    color: (theme) => theme.colors.greys[3],
                }}
            >
                {subtitle}
            </Box>
        </LobbyHeaderSubtext>
    );
};

const StyledSpan = styled("span")({});

const Label = styled("div")(({ theme }) => ({
    fontWeight: "bold",
    fontSize: "1em",
}));
