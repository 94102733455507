import type { Theme } from "@mui/material";
import { Snackbar, SnackbarContent, IconButton, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { amber, green } from "@mui/material/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import { useTranslation } from "react-i18next";
import type { ReactNode } from "react";
import { forwardRef } from "react";

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const getSnackContentStyle = (theme: Theme, variant: keyof typeof variantIcon) => {
    switch (variant) {
        case "success":
            return {
                backgroundColor: green[600],
                color: theme.colors.white,
            };
        case "info":
            return {
                backgroundColor: theme.palette.primary.main,
                color: theme.colors.white,
            };
        case "error":
            return {
                backgroundColor: theme.palette.error.dark,
                color: theme.colors.white,
            };
        case "warning":
            return {
                backgroundColor: amber[700],
                color: theme.colors.white,
            };
    }
};

interface SnackbarContentWrapperProps {
    className?: string;
    message: string | ReactNode;
    onClose?: () => void;
    variant: keyof typeof variantIcon;
}

export const SnackbarContentWrapper = forwardRef<HTMLDivElement, SnackbarContentWrapperProps>(
    function SnackbarContentWrapper(props: SnackbarContentWrapperProps, ref) {
        const { className, message, onClose, variant, ...other } = props;
        const { t } = useTranslation("common", {
            keyPrefix: "button_actions",
        });
        const Icon = variantIcon[variant];

        return (
            <SnackbarContent
                ref={ref}
                aria-describedby="client-snackbar"
                sx={(theme) => getSnackContentStyle(theme, variant)}
                message={
                    <Box display="flex" alignItems="center">
                        <Icon
                            sx={{
                                fontSize: 20,
                                opacity: 0.9,
                                marginRight: (theme) => theme.spacing(1),
                            }}
                        />
                        <Pre id="client-snackbar">{message}</Pre>
                    </Box>
                }
                action={[
                    <IconButton key="close" aria-label={t("close")} onClick={() => onClose?.()} size="large">
                        <CloseIcon sx={{ fontSize: 20 }} />
                    </IconButton>,
                ]}
                {...other}
            />
        );
    }
);

const Pre = styled("pre")(() => ({
    display: "block",
    alignItems: "center",
    whiteSpace: "pre-wrap",
    unicodeBidi: "embed",
    marginLeft: "0.5rem",
    fontFamily: "Inter Variable",
}));

export interface SnackbarWrapperProps extends SnackbarContentWrapperProps {
    autoHideDuration?: number;
}
export const SnackbarWrapper = (props: SnackbarWrapperProps) => {
    const { autoHideDuration, onClose, ...rest } = props;
    return (
        <Snackbar
            open={true}
            autoHideDuration={autoHideDuration}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            onClose={onClose}
        >
            <SnackbarContentWrapper onClose={onClose} {...rest} />
        </Snackbar>
    );
};
