import type { DependencyList } from "react";
import { useEffect, useState } from "react";
import type { SubscriptionEvents, BroadcastEvents, UserEvents } from "./events";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { subscribeToBroadcastEvent, subscribeToTopic, subscribeToUserEvent } from "./signalRSubscriptions";
import { useSelector } from "react-redux";
import type { ApplicationState } from "@store/core";

export const useTopicSubscription = <T>(
    events: SubscriptionEvents | SubscriptionEvents[],
    callback: (args: T, event?: SubscriptionEvents) => void,
    deps?: DependencyList,
    debounceWait?: number
) => {
    return useTopicIdSubscription(undefined, events, callback, deps, debounceWait);
};

export const useTopicIdSubscription = <T>(
    topicId: string | string[] | undefined,
    events: SubscriptionEvents | SubscriptionEvents[],
    callback: (args: T, event?: SubscriptionEvents) => void,
    deps?: DependencyList,
    debounceWait?: number
) => {
    const [subscribed, setSubscribed] = useState(true);

    useEffect(() => {
        setSubscribed(true);
        const internalCallback = debounceWait ? AwesomeDebouncePromise(callback, debounceWait) : callback;
        const topics = Array.isArray(topicId) ? topicId : topicId ? [topicId] : undefined;
        const unsubscribe = subscribeToTopic(topics, events, (payload: T, event?: SubscriptionEvents) => {
            if (subscribed) {
                internalCallback(payload, event);
            }
        });

        return () => {
            setSubscribed(false);
            unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topicId, events, ...(deps ?? [])]);
};

export const useUserSubscription = <T>(
    events: UserEvents | UserEvents[],
    callback: (payload: T, event?: UserEvents) => void,
    deps?: DependencyList,
    debounceWait?: number
) => {
    const [subscribed, setSubscribed] = useState(true);
    const user = useSelector((state: ApplicationState) => state.session.user);

    useEffect(() => {
        setSubscribed(true);
        const internalCallback = debounceWait ? AwesomeDebouncePromise(callback, debounceWait) : callback;
        const unsubscribe = subscribeToUserEvent(events, (payload: T, event?: UserEvents) => {
            if (subscribed) {
                internalCallback(payload, event);
            }
        });

        return () => {
            setSubscribed(false);
            unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, events, ...(deps ?? [])]);
};

export const useBroadcastSubscription = <T>(
    events: BroadcastEvents | BroadcastEvents[],
    callback: (payload: T, event?: BroadcastEvents) => void,
    deps?: DependencyList,
    debounceWait?: number
) => {
    const [subscribed, setSubscribed] = useState(true);

    useEffect(() => {
        setSubscribed(true);
        const internalCallback = debounceWait ? AwesomeDebouncePromise(callback, debounceWait) : callback;
        const unsubscribe = subscribeToBroadcastEvent(events, (payload: T, event?: BroadcastEvents) => {
            if (subscribed) {
                internalCallback(payload, event);
            }
        });

        return () => {
            setSubscribed(false);
            unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [events, ...(deps || [])]);
};
