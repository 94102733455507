import type { FC } from "react";
import type { SvgIconProps } from "@mui/material/SvgIcon";
import SvgIcon from "@mui/material/SvgIcon";

export const GradientIcon: FC<SvgIconProps> = ({ children, ...svgProps }) => (
    <SvgIcon {...svgProps} sx={{ fill: "url(#icon-gradient)" }}>
        {children}
        <defs>
            <linearGradient id="icon-gradient">
                <stop offset="3.45%" stopColor="#FFC700" />
                <stop offset="97.24%" stopColor="#EC008E" />
            </linearGradient>
        </defs>
    </SvgIcon>
);

export const CheckBoxRoundedIcon = () => (
    <GradientIcon>
        <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-8.29 13.29c-.39.39-1.02.39-1.41 0L5.71 12.7a.9959.9959 0 0 1 0-1.41c.39-.39 1.02-.39 1.41 0L10 14.17l6.88-6.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-7.58 7.59z"></path>
    </GradientIcon>
);

export const RadioButtonCheckedRoundedIcon = () => (
    <GradientIcon>
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path>
        <circle cx="12" cy="12" r="5"></circle>
    </GradientIcon>
);
