import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { MatchStatus } from "@components/matches/api/MatchSeries";
import type { FC } from "react";

export const MatchStatusText: FC<{ status: MatchStatus }> = ({ status }) => {
    const { t } = useTranslation(["translation", "common"]);

    switch (status) {
        case MatchStatus.Starting:
            return <StatusText>{t("common:shared.starting")}</StatusText>;
        case MatchStatus.Started:
            return (
                <StatusText sx={{ color: (theme) => theme.colors.greens[4] }}>{t("common:shared.started")}</StatusText>
            );
        case MatchStatus.Paused:
            return (
                <StatusText sx={{ color: (theme) => theme.colors.warnings[4] }}>{t("common:shared.paused")}</StatusText>
            );
        case MatchStatus.Failed:
            return <StatusText sx={{ color: (theme) => theme.colors.reds[4] }}>{t("common:shared.failed")}</StatusText>;
        case MatchStatus.Postgame:
            return (
                <StatusText sx={{ color: (theme) => theme.colors.warnings[4] }}>
                    {t("common:shared.postgame")}
                </StatusText>
            );
        case MatchStatus.Finished:
            return <StatusText>{t("common:shared.finished")}</StatusText>;
        default:
            return <StatusText>{MatchStatus[status]}</StatusText>;
    }
};
const StatusText = styled("div")(({ theme }) => ({
    fontSize: "0.875rem",
    fontWeight: "normal",
}));
