import type { FC } from "react";
import MenuItem from "@mui/material/MenuItem";

import { MbSelect } from "@masterblaster/basics";

import type { ToolbapItemBaseProps } from ".";
import { headingOptions } from "./editorOptions";
import type { HeadingLevels } from "../utils";

export const HeadingSelector: FC<ToolbapItemBaseProps> = ({ editor, editorCommands }) => {
    const handleMenuItemClick = (key: HeadingLevels) => () => {
        editorCommands.setHeading(key);
    };

    const headingLevel = editor.getAttributes("heading").level;

    return (
        <MbSelect value={headingLevel ?? 0} placeholder={{ label: "Select heading level", value: 0 }}>
            {headingOptions.map(({ key, label }) => (
                <MenuItem key={key} value={key} onClick={handleMenuItemClick(key)}>
                    {label}
                </MenuItem>
            ))}
        </MbSelect>
    );
};
