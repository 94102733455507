import type { MatchTeamPlayerScore } from "@components/matches/api/MatchSeries";

export * from "./matchDrawer";
export * from "./CsApi";
export * from "./CsGameServerLocations";

export enum CsGameServerStatus {
    NotSet = 0,
    Starting = 10,
    Started = 30,
    Stopping = 35,
    Stopped = 40,
    Failed = 60,
}

interface UsedGameServer {
    serverId: string;
    host: string;
    port: string;
    usedAt: string;
}

export interface CsMatchConnectionInfo {
    host: string | undefined;
    port: string | undefined;
    password: string | undefined;
    location: string | undefined;
    serverId: string | undefined;
    shutdownDelay: number | undefined;
    status: CsGameServerStatus;
    failedReason: string | undefined;
    readyAt: string | undefined;
    connectionString: string | undefined;
    gotvConnectionString: string | undefined;
    usedGameServers: UsedGameServer[] | undefined;
    paused: boolean;
}

export interface CsMatchTeamPlayerScore extends MatchTeamPlayerScore {
    kills: number;
    deaths: number;
    assists: number;
    suicides: number;
}
