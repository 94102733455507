import GlobalStyles from "@mui/material/GlobalStyles";
import type { Theme } from "@mui/material";
import type { GlobalStylesProps } from "@mui/system";
import { keyframes } from "@mui/system";

const blink = keyframes`
    0%,
    50%,
    100% {
        opacity: 1;
    }
    25%,
    75% {
        opacity: 0;
    }
`;

const pulsateFWD = keyframes`
    0% {
        transform: scale(0.7);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.7);
    }
`;

const styles: GlobalStylesProps<Theme>["styles"] = {
    // main.scss

    "html, body, #root": {
        height: "100%",
    },

    "#root": {
        overflow: "hidden",
    },

    "html, body": {
        paddingRight: "0 !important", // prevents popups from adding padding to body
        fontFamily: '"Inter Variable", Inter, sans-serif',
    },

    'input[type="date"]::-webkit-calendar-picker-indicator, input[type="date"]::-webkit-calendar-picker-indicator:hover':
        {
            filter: "invert(1)",
        },

    'input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration':
        {
            WebkitAppearance: "none",
        },

    'input[type="number"]': {
        WebkitAppearance: "textfield",
        MozAppearance: "textfield",
        appearance: "textfield",
    },

    'input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button': {
        WebkitAppearance: "none",
    },

    ".pulsate-fwd": {
        animation: `${pulsateFWD} 1s ease-in-out infinite both`,
    },

    ".blink-1": {
        animation: `${blink} 0.8s infinite both`,
    },

    // site.scss

    ".ripple_root": {},

    ".ripple": {
        top: "0 !important",
        left: "0 !important",
        width: "100% !important",
        height: "100% !important",
    },

    ".ripple_visible": {
        backgroundColor: "#858898",
        animationName: "none !important",
    },

    ".ripple_pulsate": {
        animationName: "none !important",
    },

    ".ripple_child": {
        display: "none !important",
        borderRadius: "0 !important",
    },

    ".ripple_child_leaving": {
        animationDelay: "0ms !important",
    },

    ".ripple_child_pulsate": {
        animationName: "none !important",
        animationDelay: "0ms !important",
    },
};

export const CustomGlobalStyles = () => <GlobalStyles styles={styles} />;
