import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";

export const GradientBorder = styled("div", {
    shouldForwardProp: (prop) => prop !== "active",
})<{
    active: boolean;
}>(({ active }) => ({
    padding: "2px",
    width: "fit-content",
    borderRadius: "5px",
    background: active ? "linear-gradient(266.21deg, #FFC700 2.76%, #FF6B00 50.14%, #EC008E 96.55%)" : "transparent",
    boxShadow: active
        ? "4px 4px 15px rgba(236, 0, 142, 0.35), -2px -2px 15px rgba(255, 199, 0, 0.35), 0px 0px 5px rgba(255, 107, 0, 0.5)"
        : "none",
}));

export const StyledDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
        width: "clamp(550px, 40vw, 820px)",
        maxWidth: "none",
        background: `linear-gradient(180deg, #282A36 0%, ${theme.colors.contrast_background} 100%)`,
        boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.4)",
        borderRadius: "3px",

        [theme.breakpoints.down("sm")]: {
            width: "100%",
            height: "100%",
            maxHeight: "none",
            margin: "0",
        },
    },
}));

export const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "1.25rem !important",

    [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
    },
}));

export const StyledDialogActions = styled(DialogActions)({
    marginTop: "1.25rem",
    padding: 0,
    justifyContent: "center",
});
