import type { PaletteColorOptions, PaletteMode, Theme } from "@mui/material";
import { createTheme, stepConnectorClasses, useMediaQuery } from "@mui/material";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import type { ColorsType } from "./designFeatures";
import { borderRadius, darkModeColors } from "./designFeatures";
import { MuiTypography, typography } from "./typography";
import { dark } from "@mui/material/styles/createPalette";

export const useIsMobile = () => {
    return useMediaQuery<Theme>((theme) => theme.breakpoints.between("xs", "sm"));
};

export const useIsSmall = () => {
    return useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
};

export const useIsMedium = () => {
    return useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));
};

export const useIsSidebarCollapsed = () => useMediaQuery<Theme>((theme) => theme.breakpoints.down("sidebar"));

export const fullPageMaxWidth = 1440;
export const sidebarMaxWidth = 320;
export const desktopAppBarHeight = 60;
export const mobileAppBarHeight = 60;
export const mobileMaxWidth = 320;
export const alertHeight = 48;
export const desktopAppBarWithAlert = desktopAppBarHeight + alertHeight;
export const mobileAppBarWithAlert = mobileAppBarHeight + alertHeight;

export const contentPadding = 32;
export const contentMaxWidth = fullPageMaxWidth;
export const communityImageAspectRatio = { width: 1154, height: 270 };
export const communityAvatarAspectRatio = { width: 96, height: 96 };

export const teamBannerImageAspectRatio = { width: 1154, height: 280 };
export const teamAvatarAspectRatio = { width: 512, height: 512 };

export const organizationImageAspectRatio = { width: 1154, height: 270 };
export const organizationAvatarAspectRatio = { width: 96, height: 96 };

export const tournamentImageDimensions = { width: 1400, height: 586 }; // 16:7
export const tournamentSponsorImageDimensions = { width: 1376, height: 1000 }; // 16:7

export const tournamentPrizeImageDimensions = { width: 250, height: 100 };

export const gameIconDimensions = { width: 100, height: 100 };
export const mapThumbnailDimensions = { width: 300, height: 300 };
export const teamImageDimensions = { width: 1080, height: 280 };
export const matchBoxBackgroundDimensions = { width: 1920, height: 1080 };

declare module "@mui/material" {
    interface ChipPropsVariantOverrides {
        outlined: false;
        filled: false;
        ["low-emphasis"]: true;
        ["high-emphasis"]: true;
    }
    interface IconButtonPropsColorOverrides {
        ghost: true;
        tertiary: true;
        accent: true;
    }

    interface SvgIconPropsColorOverrides {
        accent: true;
        ghost: true;
    }

    interface ButtonPropsColorOverrides {
        accent: true;
        ghost: true;
    }
}
declare module "@mui/material/styles" {
    export interface Theme {
        colors: ColorsType;
    }
    interface ThemeOptions {
        colors: ColorsType;
    }

    interface BreakpointOverrides {
        xs: true;
        sm: true;
        md: true;
        sidebar: true;
        lg: true;
        laptop: true;
        xl: true;
    }

    interface Palette {
        ghost: string;
        tertiary: string;
        accent: string;
    }
    interface PaletteOptions {
        ghost: PaletteColorOptions;
        tertiary: PaletteColorOptions;
        accent: PaletteColorOptions;
    }
}

const createGFTheme = (colors: ColorsType, mode: PaletteMode): Theme =>
    createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                sidebar: 1024,
                lg: 1280,
                laptop: 1400,
                xl: 1920,
            },
        },
        colors: colors,
        shape: {
            borderRadius: borderRadius,
        },
        palette: {
            mode: mode,
            primary: {
                main: colors.main_color,
            },
            secondary: {
                main: colors.black,
            },
            error: {
                main: colors.red_base,
            },
            warning: {
                main: colors.important_color,
            },
            info: {
                main: colors.main_color,
            },
            success: {
                main: colors.success,
            },
            background: {
                default: colors.background,
                paper: colors.contrast_background,
            },
            divider: colors.greys[10],
            text: {
                primary: colors.primary_text,
                secondary: colors.secondary_text,
                disabled: colors.disabled_text,
            },
            action: {},
            accent: {
                main: colors.accent_base,
            },
            ghost: {
                main: colors.white,
            },
            tertiary: {
                main: "transparent",
            },
        },
        typography,
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    "input[type='date']::-webkit-calendar-picker-indicator": {
                        filter: `invert(${mode === "dark" ? "1" : "0"})`,
                    },
                    body: {
                        fontFamily: "'Inter Variable', Inter, sans-serif",
                    },
                    textTransform: "none",
                },
            },
            MuiTypography: MuiTypography,
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundColor: colors.greys[14],
                        backgroundImage: "none",

                        "& .PrivatePickersToolbar-root": {
                            backgroundColor: colors.greys[13],
                        },
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        color: colors.link_foreground_color,
                    },
                },
            },
            MuiInputBase: {
                defaultProps: {},
                styleOverrides: {
                    root: {
                        padding: "0.75rem 1rem",
                        borderRadius: "2px",
                        color: colors.greys[2],
                        background: colors.greys[13],
                        caretColor: colors.greys[2],

                        "&::before, &::after": {
                            display: "none",
                        },

                        "& .MuiInputBase-input": {
                            padding: 0,
                            fontSize: "1rem",
                            lineHeight: "1.5",

                            "&:-webkit-autofill": {
                                fontSize: "1rem",
                                boxShadow: `inset 0 0 0 50px ${colors.greys[13]} !important`,
                                WebkitTextFillColor: colors.greys[2],
                            },
                        },

                        "& .MuiInputBase-input::placeholder": {
                            color: colors.greys[6],
                        },

                        "&.Mui-disabled": {
                            color: colors.greys[9],

                            "& .MuiInputBase-input::placeholder": {
                                color: colors.greys[9],
                            },

                            "& .MuiInputAdornment-positionEnd, & .MuiInputAdornment-positionStart": {
                                color: colors.greys[10],
                            },
                        },
                    },
                    sizeSmall: {
                        padding: "4px 10px",
                    },
                    adornedEnd: {
                        "& .MuiInputAdornment-positionEnd": {
                            color: colors.greys[4],
                        },
                    },

                    adornedStart: {
                        "& .MuiInputAdornment-positionStart": {
                            color: colors.greys[4],
                        },
                    },

                    input: {
                        "&.Mui-disabled": {
                            "& + .MuiOutlinedInput-notchedOutline": {
                                borderColor: colors.greys[11],
                            },
                        },
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    root: {
                        "&.MuiAutocomplete-hasClearIcon > .MuiAutocomplete-inputRoot": {
                            backgroundColor: "red",
                        },
                    },
                    listbox: {
                        padding: 0,

                        "& .MuiAutocomplete-option": {
                            backgroundColor: colors.greys[13],
                            paddingTop: "0.625rem",
                            paddingBottom: "0.625rem",
                            paddingLeft: "1rem",
                            paddingRight: "1rem",
                            transition: "all 250ms ease-in-out",

                            "&:hover, &.Mui-focused": {
                                backgroundColor: colors.greys[14],
                                padding: "0.625rem 2rem",
                            },
                        },
                    },

                    popupIndicator: {
                        width: "2rem",
                        height: "2rem",
                    },

                    endAdornment: {
                        overflow: "hidden",
                        right: "0.25rem",
                    },

                    inputRoot: {
                        "& .MuiInputAdornment-positionStart": {
                            height: "auto",
                            maxHeight: "unset",
                        },
                    },
                },
            },
            MuiSwitch: {
                defaultProps: {
                    disableRipple: true,
                },
                styleOverrides: {
                    root: {
                        overflow: "visible",
                    },

                    switchBase: {
                        "&.Mui-checked:not(.Mui-disabled)": {
                            filter: "drop-shadow(4px 4px 15px rgba(236, 0, 142, 0.35)) drop-shadow(-2px -2px 15px rgba(255, 199, 0, 0.35)) drop-shadow(0px 0px 5px rgba(255, 107, 0, 0.5))",

                            "& + .MuiSwitch-track": {
                                background: "linear-gradient(266.21deg, #FFC700 2.76%, #FF6B00 50.14%, #EC008E 96.55%)",
                                filter: "drop-shadow(4px 4px 15px rgba(236, 0, 142, 0.35)) drop-shadow(-2px -2px 15px rgba(255, 199, 0, 0.35)) drop-shadow(0px 0px 5px rgba(255, 107, 0, 0.5))",
                            },
                        },

                        "&.Mui-disabled .MuiSwitch-thumb": {
                            backgroundColor: colors.greys[11],
                        },

                        "&.Mui-checked.Mui-disabled .MuiSwitch-thumb": {
                            backgroundColor: colors.greys[8],
                        },
                    },

                    sizeSmall: {
                        width: "2.625rem",
                        height: "1.75rem",

                        "& .MuiSwitch-switchBase": {
                            padding: "0.375rem",
                        },
                    },

                    sizeMedium: {
                        width: "3.875rem",
                        height: "2.625rem",

                        "& .MuiSwitch-switchBase": {
                            padding: "0.6875rem",
                        },

                        "& .MuiSwitch-track": {
                            borderRadius: "0.5625rem",
                        },
                    },

                    track: {
                        backgroundColor: colors.greys[13],
                    },

                    thumb: {
                        backgroundColor: colors.white,
                    },
                },
            },
            MuiSelect: {
                styleOverrides: {
                    select: {},
                },
            },
            MuiMenu: {
                styleOverrides: {
                    paper: {
                        boxSizing: "border-box",
                        border: `1px solid ${colors.greys[14]}`,
                        borderRadius: "3px",
                    },
                },
            },
            MuiTooltip: {
                defaultProps: {
                    enterTouchDelay: 0,
                },
                styleOverrides: {
                    tooltip: {
                        padding: "0.5rem 1rem",
                        backgroundColor: colors.greys[14],
                        borderRadius: "3px",
                        fontSize: "0.85rem",
                        // lineHeight: "1rem",
                        // letterSpacing: "0.4px",
                        color: colors.white,
                    },
                    arrow: {
                        color: colors.greys[14],
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        "&.Mui-selected": {
                            background: "linear-gradient(86.21deg, #534A2C 3.11%, #502248 96.9%)",
                        },
                    },
                },
            },
            MuiButton: {
                defaultProps: {
                    TouchRippleProps: {
                        classes: {
                            root: "ripple_root",
                            ripple: "ripple",
                            rippleVisible: "ripple_visible",
                            ripplePulsate: "ripple_pulsate",
                            child: "ripple_child",
                            childLeaving: "ripple_child_leaving",
                            childPulsate: "ripple_child_pulsate",
                        },
                    },
                },
                variants: [
                    {
                        props: { variant: "contained", color: "success" },
                        style: {
                            color: colors.greys[15],
                            background: colors.greens[4],
                            ":hover": {
                                color: colors.greys[10],
                                background: colors.greens[3],
                            },
                            ":focus-visible": {
                                background: colors.greens[3],
                            },
                            ":disabled": {
                                color: colors.greys[5],
                                background: colors.greens[2],
                            },
                        },
                    },
                    {
                        props: { variant: "contained", color: "error" },
                        style: {
                            color: colors.greys[15],
                            background: colors.reds[4],
                            ":hover": {
                                color: colors.greys[10],
                                background: colors.reds[3],
                            },
                            ":focus-visible": {
                                background: colors.reds[3],
                            },
                            ":disabled": {
                                color: colors.greys[5],
                                background: colors.reds[1],
                            },
                        },
                    },
                    {
                        props: { variant: "contained", color: "accent" },
                        style: {
                            border: "solid 1px transparent",
                            borderRadius: "5px",
                            backgroundImage:
                                "linear-gradient(270deg, #B8006E 0%, #A86815 100%), linear-gradient(270deg, #EC008E 0%, #FFC700 100%)",
                            backgroundOrigin: "padding-box, border-box",
                            backgroundClip: "padding-box, border-box",
                            "&:hover": {
                                backgroundImage:
                                    "linear-gradient(270deg, #B8006E 0%, #A86815 100%), linear-gradient(270deg, #EC008E 0%, #FFC700 100%)",
                                boxShadow:
                                    "4px 4px 15px rgba(236, 0, 142, 0.35), -2px -2px 15px rgba(255, 199, 0, 0.35), 0px 0px 5px rgba(255, 107, 0, 0.5)",
                                backgroundOrigin: "padding-box, border-box",
                                backgroundClip: "padding-box, border-box",
                            },
                        },
                    },
                ],

                styleOverrides: {
                    root: {
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        textTransform: "none",
                        ":focus-visible": {
                            boxShadow: colors.button_focused_shadow,
                        },
                        ":hover": {
                            boxShadow: colors.button_hover_shadow,
                        },
                    },
                    sizeSmall: {
                        padding: "8px 12px",
                        lineHeight: "16px",
                        fontSize: 14,
                    },
                    sizeMedium: {
                        padding: "12px 16px",
                        fontSize: 16,
                        lineHeight: "20px",
                    },
                    sizeLarge: {
                        padding: "16px 20px",
                        fontSize: 18,
                        lineHeight: "24px",
                    },
                    containedPrimary: {
                        color: colors.greys[15],
                        background: colors.greys[1],
                        ":hover": {
                            background: colors.greys[0],
                        },
                        ":disabled": {
                            color: colors.greys[5],
                            background: colors.greys[2],
                        },
                    },
                    containedSecondary: {
                        color: colors.greys[0],
                        background: colors.greys[10],
                        ":hover": {
                            background: colors.greys[7],
                        },
                        ":focus-visible": {
                            background: colors.greys[7],
                        },
                        ":disabled": {
                            color: colors.greys[7],
                        },
                    },
                    outlinedPrimary: {
                        color: colors.greys[0],
                        border: `1px solid ${colors.greys[1]}`,
                        background: "transparent",
                        ":hover": {
                            border: `1px solid ${colors.greys[1]}`,
                            background: colors.greys[13],
                        },
                        ":focus-visible": {
                            background: colors.greys[14],
                        },
                        ":disabled": {
                            color: colors.greys[14],
                            background: "transparent",
                        },
                    },
                    textPrimary: {
                        color: colors.greys[0],
                        ":hover": {
                            background: colors.greys[13],
                        },
                        ":focus-visible": {
                            background: colors.greys[14],
                        },
                        ":disabled": {
                            color: colors.greys[6],
                        },
                    },
                },
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        "&.Mui-focusVisible": {
                            outlineColor: "#fe52c1",
                            outlineWidth: "0.125px",
                        },
                    },
                },
            },
            MuiIconButton: {
                defaultProps: {
                    // disableRipple: true,
                    // color: "tertiary",
                    size: "medium",
                },
                styleOverrides: {
                    root: {
                        padding: 0,
                        borderRadius: "25%",
                        fontSize: "1.5rem",

                        "& > svg": {
                            width: "1em",
                            height: "1em",
                            fontSize: "inherit",
                        },

                        "&.Mui-focusVisible": {
                            boxShadow: "0px 0px 0px 1px #181920, 0px 0px 0px 3px #FF52C2",
                        },
                    },
                    sizeSmall: {
                        width: "2rem",
                        height: "2rem",
                    },
                    sizeMedium: {
                        width: "2.5rem",
                        height: "2.5rem",
                    },
                    sizeLarge: {
                        width: "2.75rem",
                        height: "2.75rem",
                    },
                },
                variants: [
                    {
                        props: { color: "primary" },
                        style: {
                            backgroundColor: colors.greys[1],
                            color: colors.greys[17],
                            transition: "all 150ms ease",

                            "&&:hover": {
                                backgroundColor: colors.white,
                                boxShadow: "0px 0px 2px rgba(255, 255, 255, 0.5)",
                            },

                            "&.Mui-disabled": {
                                backgroundColor: colors.greys[2],
                                color: colors.greys[5],
                            },
                        },
                    },
                    {
                        props: { color: "secondary" },
                        style: {
                            backgroundColor: colors.greys[10],
                            color: colors.white,
                            transition: "all 150ms ease",

                            "&&:hover": {
                                backgroundColor: colors.greys[7],
                                boxShadow: "0px 0px 2px rgba(255, 255, 255, 0.5)",
                            },

                            "&.Mui-focusVisible": {
                                backgroundColor: colors.greys[13],
                            },

                            "&.Mui-disabled": {
                                backgroundColor: colors.greys[10],
                                color: colors.greys[7],
                            },
                        },
                    },
                    {
                        props: { color: "ghost" },
                        style: {
                            border: `1px solid ${colors.greys[1]}`,
                            backgroundColor: "transparent",
                            color: colors.white,
                            transition: "all 150ms ease",

                            "&&:hover": {
                                backgroundColor: colors.greys[13],
                                boxShadow: "0px 0px 2px rgba(255, 255, 255, 0.5)",
                            },

                            "&.Mui-focusVisible": {
                                backgroundColor: colors.greys[13],
                            },

                            "&.Mui-disabled": {
                                backgroundColor: "transparent",
                                border: `1px solid ${colors.greys[6]}`,
                                color: colors.greys[7],
                            },
                        },
                    },
                    {
                        props: { color: "tertiary" },
                        style: {
                            backgroundColor: "transparent",
                            color: colors.white,
                            transition: "all 150ms ease",

                            "&&:hover": {
                                textShadow: "0px 0px 10px #FFFFFF",

                                "> svg": {
                                    filter: "drop-shadow(0px 0px 10px #FFFFFF)",
                                },
                            },

                            "&.Mui-focusVisible": {
                                backgroundColor: colors.greys[13],
                            },

                            "&.Mui-disabled": {
                                backgroundColor: "transparent",
                                color: colors.greys[7],
                            },
                        },
                    },
                ],
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        padding: "0.5rem",

                        "&.Mui-checked svg": {
                            filter: "drop-shadow(4px 4px 15px rgba(236, 0, 142, 0.35)) drop-shadow(-2px -2px 15px rgba(255, 199, 0, 0.35)) drop-shadow(0px 0px 5px rgba(255, 107, 0, 0.5))",
                        },

                        "&.Mui-disabled": {
                            color: colors.greys[9],

                            "& svg": {
                                fill: "currentColor",
                                filter: "none",
                            },
                        },
                    },
                },
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        background: colors.background,
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    root: {
                        marginLeft: "0",
                        marginRight: "0",
                    },
                    label: {
                        fontSize: "0.875rem",
                        lineHeight: "1.375rem",
                    },
                },
            },
            MuiRadio: {
                styleOverrides: {
                    root: {
                        "&.Mui-checked svg": {
                            filter: "drop-shadow(4px 4px 15px rgba(236, 0, 142, 0.35)) drop-shadow(-2px -2px 15px rgba(255, 199, 0, 0.35)) drop-shadow(0px 0px 5px rgba(255, 107, 0, 0.5))",
                        },

                        "&.Mui-disabled": {
                            color: colors.greys[9],

                            "& svg": {
                                fill: colors.greys[9],
                                filter: "none",
                            },
                        },
                    },
                },
            },
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        backgroundColor: "unset",
                        lineHeight: "1",

                        "&::before": {
                            content: "''",
                            position: "absolute",
                            inset: "-1px",
                            zIndex: -1,
                            boxSizing: "border-box",
                            padding: "1px",
                            borderRadius: "50%",
                            display: "none",
                        },

                        "&.Mui-selected": {
                            zIndex: 2,

                            "&::before": {
                                display: "block",
                                background:
                                    "linear-gradient(266.21deg, #FFF 0%, #FFF 100%), linear-gradient(266.21deg, #EC008E 2.76%, #FFC700 96.55%)",
                                boxShadow:
                                    "4px 4px 15px rgba(236, 0, 142, 0.35), -2px -2px 15px rgba(255, 199, 0, 0.35), 0px 0px 5px rgba(255, 107, 0, 0.5)",
                                backgroundClip: "content-box, padding-box",
                            },
                        },
                    },
                    today: {
                        zIndex: 2,
                        border: "none",

                        "&:not(.Mui-selected):hover&::before": {
                            display: "block",
                            background: `linear-gradient(rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), linear-gradient(${colors.greys[15]} 0%, ${colors.greys[15]} 100%), linear-gradient(266.21deg, #EC008E 2.76%, #FFC700 96.55%)`,
                            backgroundClip: "content-box, content-box, padding-box",
                        },

                        "&::before": {
                            display: "block",
                            background: `linear-gradient( ${colors.greys[15]} 0%, ${colors.greys[15]} 100%), linear-gradient(266.21deg, #EC008E 2.76%, #FFC700 96.55%)`,
                            backgroundClip: "content-box, padding-box",
                        },
                    },
                },
            },
            MuiYearCalendar: {
                styleOverrides: {
                    root: {
                        "& .PrivatePickersYear-yearButton.Mui-selected": {
                            position: "relative",
                            background: "none",
                            zIndex: 1,

                            "&::before": {
                                content: "''",
                                position: "absolute",
                                inset: "-1px",
                                zIndex: -1,
                                boxSizing: "border-box",
                                padding: "1px",
                                borderRadius: "30px",
                                background:
                                    "linear-gradient(266.21deg, #FFF 0%, #FFF 100%), linear-gradient(266.21deg, #EC008E 2.76%, #FFC700 96.55%)",
                                boxShadow:
                                    "4px 4px 15px rgba(236, 0, 142, 0.35), -2px -2px 15px rgba(255, 199, 0, 0.35), 0px 0px 5px rgba(255, 107, 0, 0.5)",
                                backgroundClip: "content-box, padding-box",
                            },
                        },
                    },
                },
            },
            MuiChip: {
                defaultProps: {
                    variant: "low-emphasis",
                },
                styleOverrides: {
                    root: {
                        height: "auto",
                        color: colors.white,

                        "&.Mui-disabled": {
                            opacity: 1,
                            color: colors.greys[6],
                        },

                        "& .MuiTouchRipple-root": {
                            display: "none !important",
                        },
                    },
                    icon: {
                        marginLeft: 0,
                        marginRight: "0.375rem",
                        fontSize: "1rem",
                    },
                    avatar: {
                        marginLeft: 0,
                        marginRight: "0.375rem",
                    },
                    deleteIcon: {
                        marginRight: 0,
                        marginLeft: "0.375rem",
                        fontSize: "1rem",
                    },
                    label: {
                        padding: 0,
                        fontSize: "0.625rem",
                        lineHeight: "0.9375rem",
                    },
                },
                variants: [
                    {
                        props: { variant: "low-emphasis" },
                        style: {
                            backgroundColor: colors.grey_base,
                            padding: "0.25rem 0.5rem",

                            "&:hover, &.Mui-focusVisible": {
                                backgroundColor: colors.secondary_text,
                                boxShadow: "0px 0px 2px rgba(255, 255, 255, 0.5)",
                            },
                        },
                    },
                    {
                        props: { variant: "high-emphasis" },
                        style: {
                            border: "1px solid transparent",
                            padding: "0.1875rem 0.5rem",
                            backgroundImage: `linear-gradient(86.21deg, ${colors.accent_base} 3.11%, #6C1048 96.9%), linear-gradient(266.21deg, #EC008E 2.76%, #FFC700 96.55%)`,
                            backgroundClip: "padding-box, border-box",
                            backgroundOrigin: "padding-box, border-box",

                            "&:hover, &.Mui-focusVisible": {
                                boxShadow:
                                    "4px 4px 15px rgba(236, 0, 142, 0.35), -2px -2px 15px rgba(255, 199, 0, 0.35), 0px 0px 5px rgba(255, 107, 0, 0.5)",
                            },
                        },
                    },
                    {
                        props: { variant: "high-emphasis", disabled: true },
                        style: {
                            background: `linear-gradient(86.21deg, ${colors.accent_base} 3.11%, #6C1048 96.9%) border-box`,
                        },
                    },
                ],
            },
            MuiStepper: {
                styleOverrides: {
                    root: {
                        [`&.${stepConnectorClasses.active}`]: {
                            [`& .${stepConnectorClasses.line}`]: {
                                borderColor: colors.pink_base,
                            },
                        },
                        [`&.${stepConnectorClasses.completed}`]: {
                            [`& .${stepConnectorClasses.line}`]: {
                                borderColor: colors.pink_base,
                            },
                        },
                        [`& .${stepConnectorClasses.line}`]: {
                            borderColor: colors.greys[8],
                        },
                    },
                },
            },
            MuiStep: {
                styleOverrides: {
                    root: {
                        "& .MuiStepLabel-iconContainer": {
                            "& .Mui-completed": {
                                color: colors.accent_text,
                            },
                            "& .Mui-disabled": {
                                color: colors.greys[8],
                            },
                            "& .Mui-active": {
                                color: colors.pink_base,
                            },
                        },
                    },
                },
            },
        },
    });

export const darkTheme: Theme = createGFTheme(darkModeColors, "dark");
// export const tenantDefinedTheme = createGFTheme(tenantDefinedColors, "dark");
export const createCustomTheme = (colors: ColorsType, mode: PaletteMode) =>
    createGFTheme({ ...darkModeColors, ...colors }, mode); // This will be used to create a custom theme for tenants based on their tenantUiConfig.colorStyle
