import { LinkBehaviour, TeamAvatar } from "@masterblaster/basics";
import type { SxProps, Theme } from "@mui/material";
import { alpha, Box, Button, Skeleton, styled, Tooltip, useMediaQuery } from "@mui/material";
import { generateLink } from "@utils/generateLink";
import type { ReactElement } from "react";
import { useHistory } from "react-router";

export interface TeamWidgetProps {
    team: { id: string; name: string; avatarImageId: string | undefined } | undefined;
    reverse?: boolean;
    disqualified?: boolean;
    alwaysShowName?: boolean;
    placeholderComponent?: ReactElement;
    onClick?(target: any): void;
    sx?: SxProps<Theme>;
}

export const TeamWidget = (props: TeamWidgetProps) => {
    const { team, reverse, alwaysShowName, placeholderComponent, onClick } = props;
    const history = useHistory();
    const hideName = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));

    if (!team) {
        if (placeholderComponent) {
            return placeholderComponent;
        } else {
            return <PlaceholderTeam />;
        }
    }

    const nameContent = (
        <>
            <Box width="0.5rem"></Box>
            <Tooltip title={team?.name}>
                <span
                    style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontStyle: props.disqualified ? "italic" : "normal",
                        textDecoration: props.disqualified ? "line-through" : "normal",
                    }}
                >
                    {team?.name}
                </span>
            </Tooltip>
        </>
    );

    const flexDirection = reverse ? "row-reverse" : "row";
    const url = generateLink("TEAM_ROUTES.TEAM", { id: team.id });
    return (
        <>
            <Button
                component={LinkBehaviour}
                href={url}
                onClick={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    if (onClick) {
                        onClick(e?.currentTarget);
                    } else {
                        history.push(url);
                    }
                }}
                style={{ minWidth: 32 }}
            >
                <TeamContainerBox flexDirection={flexDirection} sx={props.sx}>
                    <TeamAvatar team={team} />
                    {alwaysShowName && nameContent}
                    {!alwaysShowName && !hideName && nameContent}
                </TeamContainerBox>
            </Button>
        </>
    );
};

const PlaceholderTeam = styled((props) => <Skeleton variant="rectangular" {...props} />)(({ theme }) => ({
    background: alpha(theme.colors.black, theme.palette.mode === "light" ? 0.11 : 0.13),
    width: 125,
    height: 18,
    borderRadius: 4,
}));

const TeamContainerBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    whiteSpace: "nowrap",
    color: theme.colors.primary_text,
}));
