import { createContext, useContext } from "react";
import type { Organization } from "./OrganizationApi";
import type { Subscription } from "@components/subscriptions";
import type { TeamDto } from "@components/team";
import type { PlayerDto } from "@masterblaster/api";

interface OrganizationContextProps {
    organization: Organization;
    teams: TeamDto[];
    players: PlayerDto[];
    subscriptions: Subscription[] | undefined;
    isSubscriptionsLoading: boolean;
    isOrgAdmin: boolean;
}

export const OrganizationContext = createContext<OrganizationContextProps>(undefined!);

export const useOrganizationContext = () => {
    return useContext(OrganizationContext);
};
