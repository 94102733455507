import { styled } from "@mui/material/styles";
import type { EditorOptions } from "@tiptap/react";
import { EditorContent, useEditor } from "@tiptap/react";
import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";

import type { TipTapEditorProps } from "./TipTapEditorProps";
import { EditorToolbar } from "./components";
import { extensions } from "./extensions";
import { markdownToJSON } from "./utils";

const parseContent = async (content: string | undefined) => {
    const value = content ?? "";
    try {
        const promise = new Promise<Record<string, any>>((resolve) => resolve(JSON.parse(value)));
        return await promise;
    } catch {
        return await markdownToJSON(value);
    }
};

export const TipTapEditor: FC<TipTapEditorProps> = ({ content, isEditable = false, sx, onChanged }) => {
    const [loaded, setLoaded] = useState(false);

    const handleUpdate = useCallback<EditorOptions["onUpdate"]>(
        ({ editor }) => onChanged?.(JSON.stringify(editor.getJSON())),
        [onChanged]
    );

    const editor = useEditor({
        extensions,
        editorProps: {
            attributes: {
                class: "editor",
            },
        },
        onUpdate: handleUpdate,
        editable: isEditable,
    });

    useEffect(() => {
        if (!editor || loaded) return;

        parseContent(content).then((result) => {
            editor.commands.setContent(result);
            setLoaded(true);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor, content]);

    if (!editor) return null;

    return (
        <EditorContainer isEditable={isEditable} sx={sx}>
            {isEditable && <EditorToolbar editor={editor} />}
            <EditorContent editor={editor} />
        </EditorContainer>
    );
};

const EditorContainer = styled("div", {
    shouldForwardProp: (prop) => prop !== "isEditable" && prop !== "height",
})<{ isEditable?: boolean }>(({ theme, isEditable }) => ({
    padding: isEditable ? 12 : 0,
    borderRadius: 4,
    color: theme.colors.primary_text,
    background: isEditable ? theme.colors.secondary_background : "none",
    width: "100%",

    "& .editor": {
        padding: isEditable ? "0 0.5rem" : 0,
        border: isEditable ? `1px solid ${theme.colors.border}` : "none",
        outline: "none",
        height: "auto",

        "& img": {
            height: "auto",
            maxWidth: "100%",

            "&.ProseMirror-selectednode": {
                outline: isEditable ? "3px solid #68cef8" : "none",
            },
        },

        "& blockquote": {
            borderLeft: "3px solid rgba(13, 13, 13, 0.4)",
            paddingLeft: "1rem",
        },

        "& code": {
            backgroundColor: "rgba(97, 97, 97, 0.8)",
            borderRadius: "0.25em",
            color: theme.colors.white,
            padding: "0.25rem",
        },

        "& a": {
            color: theme.colors.link_foreground_color,
        },
    },
}));

export default TipTapEditor;
