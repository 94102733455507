import type { MatchSeries } from "@components/matches/api/MatchSeries";
import { MatchStatus } from "@components/matches/api/MatchSeries";
import { TwitchTagState, formatDateTime } from "@masterblaster/api";
import type { TwitchTagProps } from "@masterblaster/basics";
import { Mixpanel } from "@utils/mixpanel";
import { supportRegExp } from "@utils/regexp";
import { BASE_URL, COMMUNITY_ROUTES, COMMUNITY_TAB_ROUTES, LOBBY_TAB_ROUTES, TOURNAMENT_ROUTES } from "@utils/routes";
import { isToday } from "date-fns";
import type { TFunction } from "i18next";
import type { match } from "react-router";
import { matchPath } from "react-router";
import type { PlayerOptions } from "twitch-embed";

const TWITCH_BASE = "https://www.twitch.tv";

const OPEN_TWITCH_STREAM_MY_SCHEDULE_EVENT = "Open Twitch Stream From My Schedule";
const OPEN_TWITCH_STREAM_COMPETITION_OVERVIEW_EVENT = "Open Twitch Stream From Competition Overview Tab";
const OPEN_TWITCH_STREAM_COMPETITION_STREAMS_PAGE_EVENT = "Open Twitch Stream From Competition Streams Overview Page";
const OPEN_TWITCH_STREAM_COMPETITION_MATCHES_OVERVIEW_EVENT = "Open Twitch Stream From Competition Matches Overview";
const OPEN_TWITCH_STREAM_COMPETITION_BRACKETS_OVERVIEW_EVENT = "Open Twitch Stream From Competition Brackets Overview";
const OPEN_TWITCH_STREAM_COMMUNITY_OVERVIEW_EVENT = "Open Twitch Stream From Community Overview";
const OPEN_TWITCH_STREAM_COMMUNITY_STREAMS_PAGE_EVENT = "Open Twitch Stream From Community Streams Overview Page";
const OPEN_TWITCH_PAGE_EVENT = "Open Twitch Webpage From Embedded Player";

const TWITCH_ROUTES = {
    CHANNEL: `${TWITCH_BASE}/:channel`,
    VIDEO: `${TWITCH_BASE}/videos/:video`,
} as const;

const TWITCH_LIBRARY_LINK = "https://player.twitch.tv/js/embed/v1.js";

const getTwitchTagState = (matchSeries: MatchSeries): TwitchTagState | null => {
    const { twitchStreamingUrl } = matchSeries.settings;

    if (!twitchStreamingUrl) return null;

    const [matchStream] = matchTwitchPath(twitchStreamingUrl, "CHANNEL");

    if (matchSeries.status < MatchStatus.Starting && matchStream !== null) return TwitchTagState.SCHEDULED;

    if (matchSeries.status >= MatchStatus.Starting && matchSeries.status < MatchStatus.Failed && matchStream !== null)
        return TwitchTagState.LIVE;

    const [matchVideo] = matchTwitchPath(twitchStreamingUrl, "VIDEO");

    if (matchVideo !== null) return TwitchTagState.RECORD;

    return null;
};

const getPlayerId = (id: string) => `player_${id}`;

const getURLObject = (url: string) => {
    try {
        return new URL(url);
    } catch {
        return null;
    }
};

type TwitchRoutesType = typeof TWITCH_ROUTES;
type TwitchRoutesKeys = keyof TwitchRoutesType;

const matchTwitchPath = <T extends { [K in keyof T]?: string } = Record<string, never>>(
    url: string,
    pathKey: TwitchRoutesKeys
): [match<T> | null, URL | null] => {
    const URLObject = getURLObject(url);

    if (!URLObject) return [null, null];

    const twithcPath = URLObject.origin + URLObject.pathname;

    return [
        matchPath<T>(twithcPath, {
            path: TWITCH_ROUTES[pathKey],
            exact: true,
        }),
        URLObject,
    ];
};

const validateTwitchStreamingUrl = (
    twitchStreamingUrl: string,
    t: TFunction<("validation" | "translation" | "common")[]>
) => {
    const isValidUrl = supportRegExp.test(twitchStreamingUrl);

    if (!isValidUrl) return t("validation:basics.inputs.url_field.helper_text");

    const isStartWithTwitchDomainName = twitchStreamingUrl.startsWith(TWITCH_BASE);

    if (!isStartWithTwitchDomainName) return t("validation:components.streams.link_must_contain_twitch_domain");

    return null;
};

const getVideoCredentials = (url: string) => {
    const [match, URLObject] = matchTwitchPath<{ video: string }>(url, "VIDEO");

    if (!match || !URLObject) return null;

    return {
        video: match.params.video,
        collection: URLObject.searchParams.get("collection") ?? undefined,
        time: URLObject.searchParams.get("t") ?? undefined,
    };
};

const getStreamCredentials = (url: string) => {
    const [match, URLObject] = matchTwitchPath<{ channel: string }>(url, "CHANNEL");

    if (!match || !URLObject) return null;

    return {
        channel: match.params.channel,
    };
};

const getPlayerOptions = (
    url: string
): { playerOptions: Omit<PlayerOptions, "width" | "height">; isVideo: boolean; isStream: boolean } => {
    const streamCredentials = getStreamCredentials(url);
    const videoCredentials = getVideoCredentials(url);

    const playerOptions = {
        ...(streamCredentials ?? {}),
        ...(videoCredentials ?? {}),
    };

    return {
        playerOptions,
        isVideo: !!videoCredentials,
        isStream: !!streamCredentials,
    };
};

const getTwitchTagProps = ({
    matchSeries,
    pathname,
    customTagProps,
}: {
    matchSeries: MatchSeries;
    pathname: string;
    customTagProps?: Partial<TwitchTagProps>;
}): TwitchTagProps | null => {
    const twitchTagState = getTwitchTagState(matchSeries);

    if (twitchTagState === null) return null;

    return {
        ...customTagProps,
        LinkComponent: "a",
        state: twitchTagState,
        href: matchSeries.settings.twitchStreamingUrl,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        target: "_blank",
        onClick: (event) => {
            event.stopPropagation();
            trackTwitchEvent(pathname, matchSeries.id, matchSeries.competitionId);
        },
    };
};

const trackTwitchEvent = (pathname: string, matchSeriesId: string, competitionId: string) => {
    const match = matchPath(pathname, {
        exact: true,
        path: [
            BASE_URL,
            TOURNAMENT_ROUTES.LOBBY,
            LOBBY_TAB_ROUTES.OVERVIEW,
            TOURNAMENT_ROUTES.STREAMS,
            LOBBY_TAB_ROUTES.MATCHES,
            LOBBY_TAB_ROUTES.BRACKETS,
            COMMUNITY_ROUTES.COMMUNITY,
            COMMUNITY_TAB_ROUTES.STREAMS,
        ],
    });

    if (!match) return;

    switch (match.path) {
        case BASE_URL:
            return Mixpanel.track(OPEN_TWITCH_STREAM_MY_SCHEDULE_EVENT, { matchSeriesId, competitionId });
        case TOURNAMENT_ROUTES.LOBBY:
        case LOBBY_TAB_ROUTES.OVERVIEW:
            return Mixpanel.track(OPEN_TWITCH_STREAM_COMPETITION_OVERVIEW_EVENT, { matchSeriesId, competitionId });
        case TOURNAMENT_ROUTES.STREAMS:
            return Mixpanel.track(OPEN_TWITCH_STREAM_COMPETITION_STREAMS_PAGE_EVENT, { matchSeriesId, competitionId });
        case LOBBY_TAB_ROUTES.MATCHES:
            return Mixpanel.track(OPEN_TWITCH_STREAM_COMPETITION_MATCHES_OVERVIEW_EVENT, {
                matchSeriesId,
                competitionId,
            });
        case LOBBY_TAB_ROUTES.BRACKETS:
            return Mixpanel.track(OPEN_TWITCH_STREAM_COMPETITION_BRACKETS_OVERVIEW_EVENT, {
                matchSeriesId,
                competitionId,
            });
        case COMMUNITY_ROUTES.COMMUNITY:
            return Mixpanel.track(OPEN_TWITCH_STREAM_COMMUNITY_OVERVIEW_EVENT, { matchSeriesId, competitionId });
        case COMMUNITY_TAB_ROUTES.STREAMS:
            return Mixpanel.track(OPEN_TWITCH_STREAM_COMMUNITY_STREAMS_PAGE_EVENT, { matchSeriesId, competitionId });
    }
};

const getCustomTwitchTagLabel = (
    twitchTagState: TwitchTagState,
    match: MatchSeries,
    t: TFunction<("translation" | "common")[]>
) => {
    const isNow = match.status === MatchStatus.Started;

    if (twitchTagState === TwitchTagState.LIVE && isNow) return t("translation:basics.tag.twitch.watch_live_now");

    if (twitchTagState === TwitchTagState.RECORD) return t("translation:basics.tag.twitch.watch_stream");

    const matchStartDate = match.startingAt;

    if (!matchStartDate) {
        return t("translation:basics.tag.twitch.live_stream");
    }

    const matchDate = new Date(matchStartDate);
    const isMatchStartToday = isToday(matchDate);

    if (twitchTagState === TwitchTagState.SCHEDULED) {
        return `${
            isMatchStartToday ? t("common:relative_date.today") : formatDateTime(matchDate, "dd/MM")
        } - ${formatDateTime(matchDate, "HH:mm")}`;
    }

    return "";
};

const showPlayer = (matchSeries: MatchSeries) => !!getTwitchTagState(matchSeries);

export {
    OPEN_TWITCH_PAGE_EVENT,
    OPEN_TWITCH_STREAM_COMMUNITY_OVERVIEW_EVENT,
    OPEN_TWITCH_STREAM_COMMUNITY_STREAMS_PAGE_EVENT,
    OPEN_TWITCH_STREAM_COMPETITION_BRACKETS_OVERVIEW_EVENT,
    OPEN_TWITCH_STREAM_COMPETITION_MATCHES_OVERVIEW_EVENT,
    OPEN_TWITCH_STREAM_COMPETITION_OVERVIEW_EVENT,
    OPEN_TWITCH_STREAM_COMPETITION_STREAMS_PAGE_EVENT,
    OPEN_TWITCH_STREAM_MY_SCHEDULE_EVENT,
    TWITCH_LIBRARY_LINK,
    getCustomTwitchTagLabel,
    getPlayerId,
    getPlayerOptions,
    getStreamCredentials,
    getTwitchTagProps,
    getTwitchTagState,
    getVideoCredentials,
    showPlayer,
    trackTwitchEvent,
    validateTwitchStreamingUrl,
};
