import { IconButton, styled } from "@mui/material";

export const NavigationButton = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== "active",
})<{ active?: boolean }>(({ theme, active }) => ({
    // color: theme.colors.primary_text,
    // border: `1px solid ${theme.colors.greys[8]}`,
    // borderRadius: "25%",
    // width: "3rem",
    // height: "3rem",
    // border: `1px solid ${theme.colors.greys[14]}`,
    // backgroundImage: "linear-gradient(180deg, #383B4C 0%, rgba(31, 33, 43, 0) 100%)",
    // color: "#DCDDE2",
    // transition: "color, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    // "&:hover": {
    //     color: theme.colors.white,
    //     boxShadow: "0px 0px 2px rgba(255, 255, 255, 0.5)",
    //     border: `1px solid ${theme.colors.greys[12]}`,
    //     backgroundImage: "unset",
    //     backgroundColor: theme.colors.greys[13],
    // },
    // ...(active && {
    //     color: theme.colors.white,
    //     border: `1px solid ${theme.colors.greys[12]}`,
    //     backgroundImage: "unset",
    //     backgroundColor: theme.colors.greys[13],
    //     "& svg": {
    //         filter: "drop-shadow(4px 4px 15px rgba(236, 0, 142, 0.35)) drop-shadow(-2px -2px 15px rgba(255, 199, 0, 0.35)) drop-shadow(0px 0px 5px rgba(255, 107, 0, 0.5))",
    //     },
    // }),
}));

// export const NavigationButton = styled(IconButton, {
//     shouldForwardProp: (prop) => prop !== "active",
// })<{ active?: boolean }>(({ theme, active }) => ({
//     width: "3rem",
//     height: "3rem",
//     border: `1px solid ${theme.colors.greys[14]}`,
//     backgroundImage: "linear-gradient(180deg, #383B4C 0%, rgba(31, 33, 43, 0) 100%)",
//     color: "#DCDDE2",
//     transition: "color, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

//     "&:hover": {
//         color: theme.colors.white,
//         boxShadow: "0px 0px 2px rgba(255, 255, 255, 0.5)",
//         border: `1px solid ${theme.colors.greys[12]}`,
//         backgroundImage: "unset",
//         backgroundColor: theme.colors.greys[13],
//     },

//     ...(active && {
//         color: theme.colors.white,
//         border: `1px solid ${theme.colors.greys[12]}`,
//         backgroundImage: "unset",
//         backgroundColor: theme.colors.greys[13],

//         "& svg": {
//             filter: "drop-shadow(4px 4px 15px rgba(236, 0, 142, 0.35)) drop-shadow(-2px -2px 15px rgba(255, 199, 0, 0.35)) drop-shadow(0px 0px 5px rgba(255, 107, 0, 0.5))",
//         },
//     }),
// }));
