import type { CustomField } from "@masterblaster/api";
import { CustomFieldIntent, CustomFieldType } from "@masterblaster/api";
import type { MbSelectProps, MbTextFieldProps } from "@masterblaster/basics";
import { MbNumberField, MbSelect, MbTextField, Switch } from "@masterblaster/basics";
import { MenuItem } from "@mui/material";

export interface EditableFieldProps<T> extends Omit<MbTextFieldProps, "value" | "onChange"> {
    field: CustomField;
    showLabel?: boolean;
    value: T;
    onChanged?: (value: T | undefined) => void;
}

export const EditableCustomField = <T,>(props: EditableFieldProps<T>) => {
    const { field, showLabel, value, onChanged, fullWidth, ...rest } = props;

    if (field.intent === CustomFieldIntent.LinkButton) {
        return null;
    }

    if (field.type !== CustomFieldType.Boolean && field.intent === CustomFieldIntent.List) {
        const val = value ?? field.defaultValue;
        return (
            <Selector
                field={field}
                value={val as string | number | undefined}
                fullWidth={fullWidth}
                onChange={(e) => {
                    const changed = e.target.value as T;
                    onChanged?.(changed);
                }}
            />
        );
    }

    const text = field.displayName ?? field.field;
    if (field.type === CustomFieldType.Boolean) {
        const val = (value ?? field.defaultValue ?? false) as boolean;
        return (
            <Switch
                label={showLabel ? text : undefined}
                tooltip={showLabel ? field.description : undefined}
                checked={val}
                onChanged={(checked) => onChanged?.(checked as T)}
            />
        );
    }

    if (field.type === CustomFieldType.Number) {
        return (
            <MbNumberField
                label={showLabel ? text : undefined}
                placeholder={text}
                fullWidth={fullWidth}
                allowUndefinedValue
                tooltip={showLabel ? field.description : undefined}
                value={value}
                onNumberChanged={(n) => {
                    onChanged?.(n as T | undefined);
                }}
            />
        );
    }

    return (
        <MbTextField
            label={showLabel ? text : undefined}
            placeholder={text}
            fullWidth={fullWidth}
            tooltip={showLabel ? field.description : undefined}
            value={value ?? field.defaultValue ?? ""}
            onChange={(e) => {
                onChanged?.((e.currentTarget.value ?? "") as T);
            }}
            {...rest}
        />
    );
};

interface SelectorProps<T extends string | number> extends MbSelectProps<T> {
    field: CustomField;
}
const Selector = <T extends string | number>(props: SelectorProps<T>) => {
    const { field, ...rest } = props;
    return (
        <MbSelect {...rest}>
            {field.options.map((o, idx) => {
                if (typeof o === "boolean") {
                    return null;
                }
                return (
                    <MenuItem key={idx} value={o}>
                        {o}
                    </MenuItem>
                );
            })}
        </MbSelect>
    );
};
