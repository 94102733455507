import type { MbSelectProps } from "@masterblaster/basics";
import { MbSelect } from "@masterblaster/basics";
import MenuItem from "@mui/material/MenuItem";
import type { FC } from "react";
import { useTranslation } from "react-i18next";

const defaultOptions = [1, 2, 3, 5, 7, 9];

interface BestOfXSelectorProps extends MbSelectProps<number> {
    numberOfMaps: number | undefined;
    options?: number[];
    disabled?: boolean;
    onChanged?(numberOfMaps: number): void;
}

export const BestOfXSelector: FC<BestOfXSelectorProps> = ({
    numberOfMaps,
    options,
    disabled,
    onChanged,
    ...selectProps
}) => {
    const { t } = useTranslation("common");

    const bestOfSelectorOptions = options ?? defaultOptions;

    return (
        <MbSelect
            fullWidth
            label={t("shared.best_of_format")}
            placeholder={{ label: t("shared.select_format"), value: 0 }}
            displayEmpty
            value={numberOfMaps || 0}
            onChange={(e) => onChanged?.(e.target.value as number)}
            disabled={disabled}
            {...selectProps}
        >
            {bestOfSelectorOptions.map((x) => (
                <MenuItem key={x} value={x}>{`BO${x}`}</MenuItem>
            ))}
        </MbSelect>
    );
};
