import { Header } from "@masterblaster/basics";
import type { GameMap } from "@masterblaster/api";
import { TeamWidget } from "@components/team/TeamWidget";
import BlockIcon from "@mui/icons-material/Block";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ConstructionIcon from "@mui/icons-material/Construction";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import type { MapVetoMap } from "@services/VetoApi";
import { useVeto, VetoMapStatus } from "@services/VetoApi";
import _ from "lodash";
import type { FC, MouseEvent } from "react";
import type { MatchSeries } from "../api/MatchSeries";
import { showModal } from "@store/ModalStore";

const MapInfoContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    flex: 3,

    "& > :not(:last-child)": {
        marginRight: "1rem",
    },
});

const TeamInfoContainer = styled("div")({
    display: "flex",
    alignItems: "center",
    flex: 3,

    "& > :not(:last-child)": {
        marginRight: "0.5rem",
    },
});

const VetoLogWrapper = styled("li")({
    display: "flex",
    justifyContent: "space-between",
    ":not(:last-child)": {
        borderBottom: "1px solid rgba(255, 255, 255, 0.08)",
    },
});

const Separator = styled("div")({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 2,

    "::before": {
        content: "''",
        display: "block",
        height: "1px",
        width: "16px",
        backgroundColor: "rgba(255, 255, 255, 0.3)",
    },
});

const PickedMapName = styled("span")(({ theme }) => ({
    color: theme.colors.white,
    fontWeight: "bold",
}));

const MapInfoList = styled("ul")({
    margin: 0,
    padding: 0,
    listStyle: "none",
});

const SystemContainer = styled(Box)({
    display: "flex",
    alignItems: "center",
    margin: "0.75rem",
    "& > :not(:last-child)": {
        marginRight: "0.5rem",
    },
});

interface VetoLogModalProps {
    onClose: (event: MouseEvent) => void;
    matchSeries: MatchSeries;
}

export const isVetoLogVisible = (matchSeries: MatchSeries) => {
    return matchSeries.settings.useVeto && matchSeries.settings.isVetoCompleted;
};

export const showVetoLogModal = async (matchSeries: MatchSeries) => {
    return await showModal((onClose) => <VetoLogModal matchSeries={matchSeries} onClose={onClose} />);
};

const getDisplayName = (maps: GameMap[], mapName: string) => maps.find((map) => map.name === mapName);

export const VetoLogModal: FC<VetoLogModalProps> = ({ matchSeries, onClose }) => {
    const { veto, maps } = useVeto(matchSeries?.settings.mapVetoId, matchSeries?.gameId);

    const getMapInfo = (map: MapVetoMap, index: number) => {
        const mapIndex = <span>#{index}</span>;
        const mapInfo = getDisplayName(maps, map.name);
        let mapName = <span>{mapInfo?.displayName ?? map.name}</span>;
        let mapIcon: JSX.Element;

        switch (map.status) {
            case VetoMapStatus.Ban:
                mapIcon = <BlockIcon sx={{ color: "#FF5E5E" }} />;
                break;
            case VetoMapStatus.Decider:
            case VetoMapStatus.Pick:
                mapIcon = <CheckIcon sx={{ color: "#73CF2A" }} />;
                mapName = <PickedMapName>{mapInfo?.displayName ?? map.name}</PickedMapName>;
                break;
            default:
                mapIcon = <CloseIcon />;
                break;
        }

        return (
            <>
                {mapIndex}
                {mapIcon}
                {mapName}
            </>
        );
    };

    const getTeamInfo = (teamId: string | undefined, status: VetoMapStatus) => {
        const { team } = matchSeries.teams.find((x) => x.team?.id === teamId) ?? {};

        if (!team && status === VetoMapStatus.Pick) {
            return (
                <SystemContainer>
                    <ConstructionIcon />
                    <span>Decider</span>
                </SystemContainer>
            );
        } else if (status === VetoMapStatus.NotSelected) {
            return (
                <SystemContainer>
                    <ConstructionIcon />
                    <span>Not used</span>
                </SystemContainer>
            );
        }

        if (!team) {
            return null;
        }

        return (
            <TeamWidget
                team={team}
                onClick={() => {
                    // Don't navigate when in the modal. Might pop the TeamPopover..
                }}
            />
        );
    };

    return (
        <Dialog open={true} fullWidth={true} maxWidth="sm" onClose={onClose}>
            <DialogTitle
                sx={{
                    padding: "1rem",
                    borderBottom: "1px solid rgba(255, 255, 255, 0.08)",
                }}
            >
                <Header sx={{ fontSize: "2rem", marginBottom: 0, color: (theme) => theme.colors.primary_text }}>
                    Veto for R{matchSeries.roundIndex}M{matchSeries.matchIndex}
                </Header>
                <Header sx={{ fontSize: "0.8rem", margin: 0, color: (theme) => theme.colors.primary_text }}>
                    Best of {veto?.numberOfMaps}
                </Header>
            </DialogTitle>
            <DialogContent>
                <MapInfoList>
                    {_.chain(veto?.maps)
                        .orderBy((map) => map.chosenAt)
                        .map((map, index) => (
                            <VetoLogWrapper key={index}>
                                <MapInfoContainer>{getMapInfo(map, index + 1)}</MapInfoContainer>
                                <Separator />
                                <TeamInfoContainer
                                    sx={(theme) => ({
                                        justifyContent: "flex-start",
                                        [theme.breakpoints.down("sm")]: {
                                            justifyContent: "flex-end",
                                        },
                                    })}
                                >
                                    {getTeamInfo(map.chosenByTeamId, map.status)}
                                </TeamInfoContainer>
                            </VetoLogWrapper>
                        ))
                        .value()}
                </MapInfoList>
            </DialogContent>
            <DialogActions sx={{ borderTop: "1px solid rgba(255, 255, 255, 0.08)" }}>
                <Button variant="contained" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};
