/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { FC } from "react";
import { useRef, useState } from "react";
import type { TimePickerProps } from "@mui/x-date-pickers/TimePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import GlobalStyles from "@mui/material/GlobalStyles";
import type { Theme } from "@mui/material/styles";

import { useClickOutside } from "./useClickOutside";

import type { MbTextFieldProps } from ".";
import { MbTextField } from "./MbTextField";
import { convertPickerTextFieldProps } from "./utils";
import { GfLabelWrapper } from "../GfLabelWrapper";

export interface MbTimePickerProps extends Omit<TimePickerProps<Date>, "renderInput"> {
    TextFieldProps?: MbTextFieldProps;
}

export const MbTimePicker: FC<MbTimePickerProps> = ({ TextFieldProps, ...timePickerProps }) => {
    const [open, setOpen] = useState<boolean>(false);
    const popperRef = useRef<HTMLDivElement | null>(null);

    const { value, ...rest } = timePickerProps;
    const time = value ? value : null;

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useClickOutside(open, popperRef, handleClose);

    return (
        <>
            {/* @ts-ignore */}
            <GlobalStyles styles={timePickerStyles} />
            <GfLabelWrapper label={TextFieldProps?.label} subLabel={TextFieldProps?.subLabel}>
                <TimePicker
                    value={time}
                    open={open}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    {...rest}
                    slotProps={{
                        textField: (inputProps) => {
                            const convertedInputProps = convertPickerTextFieldProps(inputProps);

                            const { color, error, ...restInputProps } = convertedInputProps;

                            const hasError = TextFieldProps?.error ?? (color === "error" || error);
                            const helpText = TextFieldProps?.helpText ?? (hasError ? "Wrong time format" : undefined);

                            return (
                                <MbTextField
                                    error={hasError}
                                    success={color === "success"}
                                    helpText={helpText}
                                    {...restInputProps}
                                    {...TextFieldProps}
                                />
                            );
                        },
                        popper: { className: "time-picker_desktop", ref: popperRef },
                        dialog: { className: "time-picker_mobile" },
                    }}
                />
            </GfLabelWrapper>
        </>
    );
};

const timePickerStyles = (theme: Theme) => ({
    ".time-picker_mobile .PrivatePickersToolbar-root + div > div > div:not(.input-picker), .time-picker_desktop .MuiClockPicker-arrowSwitcher + div > div":
        {
            background: theme.colors.greys[13],
            boxSizing: "content-box",
            padding: "4px",

            "& > div:nth-of-type(2)": {
                backgroundColor: "#FFF",
                width: "0.5rem",
                height: "0.5rem",
            },

            "& > div:nth-of-type(3)": {
                backgroundColor: "#FFF",

                "& > div": {
                    backgroundColor: "transparent !important",
                    borderColor: "transparent",
                    position: "relative",
                    zIndex: 1,

                    "&::before": {
                        content: "''",
                        position: "absolute",
                        inset: "-16px",
                        borderRadius: "50%",
                        boxSizing: "border-box",
                        padding: "1px",
                        background:
                            "linear-gradient(#FFF 0%, #FFF 100%), linear-gradient(266.21deg, #EC008E 2.76%, #FFC700 96.55%)",
                        boxShadow:
                            "4px 4px 15px rgba(236, 0, 142, 0.35), -2px -2px 15px rgba(255, 199, 0, 0.35), 0px 0px 5px rgba(255, 107, 0, 0.5)",
                        backgroundClip: "content-box, padding-box",
                    },
                },
            },

            "& > div[role='listbox'] > span": {
                color: theme.colors.greys[4],

                "&.Mui-selected": {
                    color: "#000",
                },
            },
        },
    ".time-picker_mobile .MuiDialogActions-root": {
        "& button:nth-of-type(1)": {
            color: theme.colors.greys[15],
            background: theme.colors.greys[1],
            ":hover": {
                background: theme.colors.greys[0],
            },
            ":disabled": {
                color: theme.colors.greys[5],
                background: theme.colors.greys[2],
            },
        },
        "& button:nth-of-type(2)": {
            color: theme.colors.greys[0],
            background: theme.colors.greys[10],
            ":hover": {
                background: theme.colors.greys[7],
            },
            ":focus-visible": {
                background: theme.colors.greys[7],
            },
            ":disabled": {
                color: theme.colors.greys[7],
            },
        },
    },
});
