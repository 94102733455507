import { type TextFieldProps } from "@mui/material";

import type { MbTextFieldProps } from ".";

const excludeProps: Array<keyof TextFieldProps> = ["label", "margin", "onKeyDown", "onKeyUp", "variant", "children"];

export const convertPickerTextFieldProps = (props: TextFieldProps): MbTextFieldProps =>
    Object.keys(props).reduce<MbTextFieldProps>(
        (newProps, key) =>
            excludeProps.includes(key as keyof TextFieldProps)
                ? newProps
                : { ...newProps, [key]: props[key as keyof TextFieldProps] },
        {}
    );
