import { CompetitionFormat, CompetitionStatus, sendCommand } from "@masterblaster/api";
import type { MatchRescheduleProposal, MatchSeries } from "../api/MatchSeries";
import { MatchRescheduleProposalStatus, MatchStatus } from "../api/MatchSeries";

export const getPendingProposal = (proposals: MatchRescheduleProposal[]) =>
    proposals.find((proposal) => proposal.status === MatchRescheduleProposalStatus.Pending);

export const isRescheduleVisible = (matchSeries: MatchSeries) => {
    const proposal = getPendingProposal(matchSeries.matchScheduleProposals);
    if (proposal) {
        return false;
    }

    return Boolean(
        matchSeries.startingAt &&
            matchSeries.isMatchReschedulingEnabled &&
            !matchSeries.settings.twitchStreamingUrl &&
            matchSeries.status === MatchStatus.NotStarted &&
            matchSeries.competitionFormat === CompetitionFormat.RoundRobin &&
            matchSeries.competitionStatus === CompetitionStatus.Started &&
            matchSeries.lastPossibleMatchRescheduleDate
    );
};

export const isCancelRescheduleVisible = (matchSeries: MatchSeries, teamId: string | undefined) => {
    const proposal = getPendingProposal(matchSeries.matchScheduleProposals);
    if (!proposal) {
        return false;
    }

    const hasAccess = proposal?.teamId === teamId;
    if (!hasAccess) {
        return false;
    }

    return Boolean(
        matchSeries.startingAt &&
            matchSeries.isMatchReschedulingEnabled &&
            matchSeries.status === MatchStatus.NotStarted &&
            matchSeries.competitionFormat === CompetitionFormat.RoundRobin &&
            matchSeries.competitionStatus === CompetitionStatus.Started
    );
};

export const proposeMatchReschedule = (matchSeriesId: string, teamId: string, scheduledAt: string) =>
    sendCommand<string>("ProposeMatchScheduleCommand", {
        matchSeriesId,
        teamId,
        scheduledAt,
    });

export const updateMatchRescheduleProposal = (matchSeriesId: string, proposalId: string, scheduledAt: string) =>
    sendCommand("UpdateMatchScheduleProposalCommand", {
        id: proposalId,
        scheduledAt,
        matchSeriesId,
    });

export const acceptMatchScheduleProposal = (proposalId: string, matchSeriesId: string) =>
    sendCommand("AcceptMatchScheduleProposalCommand", {
        id: proposalId,
        matchSeriesId,
    });

export const rejectMatchScheduleProposal = (proposalId: string, matchSeriesId: string) =>
    sendCommand("RejectMatchScheduleProposalCommand", {
        id: proposalId,
        matchSeriesId,
    });

export const cancelMatchReschedule = (matchSeries: MatchSeries) => {
    const proposal = getPendingProposal(matchSeries.matchScheduleProposals);
    if (!proposal) {
        return;
    }

    return sendCommand<string>("RemoveMatchScheduleProposal", {
        id: proposal.id,
        matchSeriesId: matchSeries.id,
    });
};
