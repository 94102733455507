import { emptyPaginationResult } from "@masterblaster/api";
import { HomeApplyCommunityBackground } from "@masterblaster/assets";
import { DetailsText, EmptyView, ErrorText, Intro, SpinnerButton } from "@masterblaster/basics";
import { Box, LinearProgress, Pagination } from "@mui/material";
import { styled } from "@mui/material/styles";
import type { ApplicationState } from "@store/core";
import { COMMUNITY_ROUTES } from "@utils/routes";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import type { CommunityOverviewModel } from "./CommunitiesApi";
import { getCommunities } from "./CommunitiesApi";
import { CommunityCard } from "./CommunityCard";

export const Communities = () => {
    const [communities, setCommunities] = useState(emptyPaginationResult<CommunityOverviewModel>());
    const [myCommunities, setMyCommunities] = useState<CommunityOverviewModel[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const { t } = useTranslation(["common", "translation"]);

    useEffect(() => {
        setIsLoading(true);
        getCommunities(page).then((x) => {
            setCommunities(x.communities);
            setMyCommunities(x.myCommunities);
            setIsLoading(false);
        });
    }, [page]);

    const pageSize = communities.pageSize ?? 20;

    return (
        <Box>
            <Helmet>
                <title>{t("common:shared.communities")}</title>
            </Helmet>
            <Intro
                header={t("common:shared.communities")}
                subheader={t("translation:components.communities.help_text")}
            />
            {isLoading && <LinearProgress />}
            <Box marginTop="2rem">
                <CreateCommunityButton />
            </Box>

            {!communities || communities.totalCount === 0 ? (
                <EmptyView
                    src={HomeApplyCommunityBackground}
                    header={t("translation:components.communities.no_communities_found")}
                    subheader={t("translation:components.communities.come_back")}
                    maxWidth={244}
                    alt="Astronaut"
                />
            ) : (
                <Box marginTop="4rem">
                    <CommunityList
                        communities={myCommunities}
                        label={t("translation:components.communities.my_communities")}
                    />
                    <CommunityList
                        communities={communities.data}
                        label={t("translation:components.communities.all_communities")}
                    />
                    {!isLoading && communities.totalCount >= pageSize && (
                        <Box display="flex" justifyContent="center" marginTop="2rem">
                            <Pagination
                                count={Math.ceil(communities.totalCount / pageSize)}
                                page={page}
                                size="large"
                                onChange={(_, value) => setPage(value)}
                            />
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};

const CreateCommunityButton = () => {
    const user = useSelector((state: ApplicationState) => state.session.user);
    const history = useHistory();
    const { t } = useTranslation(["translation", "validation"]);

    if (!user) {
        return null;
    }

    return (
        <Box display="flex" flexDirection="column">
            <Box>
                <SpinnerButton
                    variant="contained"
                    color="primary"
                    disabled={!user?.phoneNumberVerifiedAt}
                    onClick={() => history.push(COMMUNITY_ROUTES.CREATE_COMMUNITY)}
                >
                    {t("translation:components.communities.create_your_community")}
                </SpinnerButton>
            </Box>
            {!user?.phoneNumberVerifiedAt && (
                <ErrorText size="xs" sx={{ marginTop: "0.5rem" }}>
                    {t("validation:components.communities.must_verify_phone_number")}
                </ErrorText>
            )}
        </Box>
    );
};

const CommunityList = ({ communities, label }: { communities: CommunityOverviewModel[]; label: string }) => {
    if (communities.length === 0) {
        return null;
    }

    return (
        <Box marginTop="4rem">
            <DetailsText>{label}</DetailsText>
            <Container>
                {communities.map((x) => {
                    return (
                        <Box key={x.id}>
                            <CommunityCard community={x} />
                        </Box>
                    );
                })}
            </Container>
        </Box>
    );
};

const Container = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    margin: "0 -0.5rem",
    "&>div": {
        flex: "1 50%",
        maxWidth: 350,
        boxSizing: "border-box",
        margin: "0.5rem 0.5rem",
    },
});
