import type { PaginationResult } from "@masterblaster/api";
import { CompetitionStatus, formatDateTime } from "@masterblaster/api";
import { GfLink, Header, OrdinalRank, Trophy } from "@masterblaster/basics";
import { useIsMobile } from "@masterblaster/theme";
import type { Theme } from "@mui/material";
import {
    Box,
    Fade,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import type { TournamentHistory } from "@services/Tournament";
import { useGame } from "@src/config";
import { generateLink } from "@utils/generateLink";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export const CompetitionHistoryTable = (props: {
    tournaments: TournamentHistory[];
    showTeamName?: boolean;
    gameId: string;
    isLoading: boolean;
}) => {
    const { gameId, showTeamName, isLoading } = props;
    const { t } = useTranslation(["common", "translation"]);

    const [page, setPage] = useState<number>(0);

    const tournamentPerPage = 20;
    const tournaments = props.tournaments.filter((g) => g.gameId === gameId);

    return (
        <Box width="100%">
            <Header size="lg">{t("translation:components.players.tournament_history_table.title")}</Header>
            <Box marginY={2} width={1} alignItems="center">
                {isLoading && <LinearProgress />}
            </Box>

            {!isLoading && tournaments?.length === 0 && <NoTournaments gameId={gameId} />}
            {!isLoading && tournaments?.length > 0 && (
                <Table style={{ marginTop: 16 }}>
                    <StyledTableHead>
                        <TableRow
                            sx={{
                                "& th": {
                                    color: (theme) => theme.colors.secondary_text,
                                },
                                background: (theme) => theme.colors.greys[16],
                            }}
                        >
                            <TableCell>{t("common:shared.name")}</TableCell>
                            <TableCell>{t("common:shared.date")}</TableCell>
                            <TableCell>{t("common:shared.placement")}</TableCell>
                            <TableCell>{t("common:shared.prize_pool")}</TableCell>
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {tournaments
                            .slice(page * tournamentPerPage, page * tournamentPerPage + tournamentPerPage)
                            .map((tournament, idx) => {
                                const rank = tournament.rank ?? 1000;
                                return (
                                    <TableRow
                                        key={tournament.id}
                                        sx={{
                                            background: (theme) => (idx % 2 === 0 ? "inherit" : theme.colors.greys[16]),
                                        }}
                                    >
                                        <TableCell>
                                            <GfLink
                                                underline
                                                to={generateLink("TOURNAMENT_ROUTES.LOBBY", {
                                                    id: tournament.id,
                                                })}
                                            >
                                                {tournament.name}
                                            </GfLink>
                                        </TableCell>
                                        <TableCell>
                                            {tournament.status >= CompetitionStatus.Completed && (
                                                <span>{formatDateTime(tournament.date)}</span>
                                            )}
                                            {tournament.status < CompetitionStatus.Completed && (
                                                <span>{t("common:shared.in_progress")}</span>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {rank < 4 && (
                                                <Box display="flex" alignItems="center">
                                                    <Trophy rank={tournament.rank} style={{ marginRight: "0.5rem" }} />
                                                    <OrdinalRank rank={tournament.rank} />
                                                    {showTeamName && (
                                                        <GfLink
                                                            underline
                                                            style={{ marginLeft: "0.5rem" }}
                                                            to={generateLink("TEAM_ROUTES.TEAM", {
                                                                id: tournament.teamId,
                                                            })}
                                                        >
                                                            ({tournament.teamName})
                                                        </GfLink>
                                                    )}
                                                </Box>
                                            )}
                                        </TableCell>
                                        <TableCell>{tournament.winnings}</TableCell>
                                    </TableRow>
                                );
                            })}

                        <TableRow>
                            {tournaments && tournaments.length > tournamentPerPage && (
                                <TablePagination
                                    page={page}
                                    colSpan={4}
                                    count={tournaments.length}
                                    rowsPerPage={tournamentPerPage}
                                    rowsPerPageOptions={[tournamentPerPage]}
                                    style={{ borderStyle: "none" }}
                                    onPageChange={(_, page) => setPage(page)}
                                />
                            )}
                        </TableRow>
                    </TableBody>
                </Table>
            )}
        </Box>
    );
};

const StyledTableHead = styled(TableHead)(({ theme }) => ({
    "&>*": {
        color: theme.colors.secondary_text,
    },
}));

const NoTournaments = ({ gameId }: { gameId: string }) => {
    const { colors } = useTheme<Theme>();
    const { t } = useTranslation("translation", {
        keyPrefix: "components.players.tournament_history_table",
    });
    const { settings } = useGame(gameId);

    return (
        <Box
            width="100%"
            display="flex"
            flexWrap="wrap"
            padding="16px"
            alignItems="center"
            style={{ color: colors.black, border: `2px solid ${colors.border}` }}
        >
            <span>
                {t("no_game_tournaments", {
                    game: settings.name,
                })}
            </span>
        </Box>
    );
};

export const TournamentHistoryItem = (props: { tournament: TournamentHistory }) => {
    const { tournament } = props;
    const history = useHistory();
    const isMobile = useIsMobile();
    const { colors } = useTheme<Theme>();
    const { t } = useTranslation(["translation", "common"]);

    return (
        <Fade key={tournament.id} in timeout={200}>
            <Box
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                style={{ borderTop: `2px solid ${colors.border}`, cursor: "pointer" }}
                onClick={() =>
                    history.push(
                        generateLink("TOURNAMENT_ROUTES.LOBBY", {
                            id: tournament.id,
                        })
                    )
                }
            >
                <Box className="text-overflow" width={isMobile ? "65%" : "30%"} padding="20px 16px">
                    {tournament.name}
                </Box>

                <Box className="text-overflow" width={isMobile ? "32%" : "20%"} padding="20px 16px">
                    {tournament.status >= CompetitionStatus.Completed && <span>{formatDateTime(tournament.date)}</span>}
                    {tournament.status < CompetitionStatus.Completed && <span>{t("common:shared.in_progress")}</span>}
                </Box>

                <Box display="flex" alignItems="center" width={isMobile ? "35%" : "30%"} padding="20px 16px">
                    <Trophy rank={tournament.rank} style={{ marginRight: "0.5rem" }} />
                    <OrdinalRank rank={tournament.rank} />
                </Box>
                <Box className="text-overflow" width={isMobile ? "35%" : "20%"} padding="20px 16px">
                    {tournament.winnings}
                </Box>
            </Box>
        </Fade>
    );
};
