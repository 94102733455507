import { MoreVert } from "@mui/icons-material";
import type { IconButtonProps, MenuItemProps, PaperProps, PopoverOrigin } from "@mui/material";
import { Box, Button, Fade, IconButton, Menu, MenuItem } from "@mui/material";
import clsx from "clsx";
import {
    cloneElement,
    forwardRef,
    useState,
    type CSSProperties,
    type FC,
    type PropsWithChildren,
    type ReactElement,
    type ReactNode,
} from "react";
import { GfLink } from "../GfLink";

export interface GfMenuItemProps extends MenuItemProps {
    onClose?: (e: any) => void;
    visible?: boolean;
    icon?: ReactElement | ReactNode;
    label?: string;
    to?: string;
}

export const MbMenuItem = forwardRef<any, GfMenuItemProps>(function GfMenuItem(props, ref) {
    const { onClose, visible, onClick, children, to, ...rest } = props;

    if (visible === false) {
        return null;
    }

    const labelStyle = props.icon ? { marginLeft: "0.5em" } : {};

    return (
        <MenuItem
            ref={ref}
            {...rest}
            onClick={(e) => {
                if (onClose) {
                    onClose(e);
                }
                if (onClick) {
                    onClick(e);
                }
            }}
        >
            <WrapContentInLink to={to}>
                {props.label && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        {props.icon && (
                            <Box display="flex" alignItems="center">
                                {props.icon}
                            </Box>
                        )}
                        <span style={labelStyle}>{props.label}</span>
                    </Box>
                )}
                {!props.label && <Box sx={{ display: "flex", alignItems: "center" }}>{children}</Box>}
            </WrapContentInLink>
        </MenuItem>
    );
});

const WrapContentInLink: FC<PropsWithChildren<{ to?: string }>> = (props) => {
    const { to, children } = props;

    if (to) {
        return (
            <GfLink to={to} style={{ width: "100%" }}>
                {children}
            </GfLink>
        );
    }

    return <>{children}</>;
};

export interface MbMenuProps {
    className?: string;
    disablePortal?: boolean;
    children: ReactNode;
    buttonStyle?: CSSProperties;
    icon?: ReactElement;
    iconButtonProps?: IconButtonProps;
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    onOpen?: () => void;
    text?: (expanded: boolean) => ReactElement;
}

export const MbMenu = (props: MbMenuProps) => {
    const [anchorEl, setAnchorEl] = useState();

    const close = (e: any) => {
        e.stopPropagation();
        setAnchorEl(undefined);
    };

    const onClick = (e: any) => {
        e.stopPropagation();
        const target = e.currentTarget;
        setAnchorEl(target);
        props.onOpen?.();
    };

    const children = Array.isArray(props.children) ? props.children : [props.children];
    const text = props.text ? props.text(!!anchorEl) : undefined;
    const icon = props.icon ? props.icon : <MoreVert />;
    return (
        <>
            {props.text && (
                <Button
                    size="small"
                    color="primary"
                    className={props.className}
                    style={{ ...props.buttonStyle }}
                    sx={{ minWidth: "auto" }}
                    onClick={onClick}
                >
                    {text}
                </Button>
            )}

            {!props.text && (
                <IconButton
                    size="small"
                    className={clsx(props.className)}
                    style={{ ...props.buttonStyle }}
                    {...props.iconButtonProps}
                    onClick={onClick}
                >
                    {icon}
                </IconButton>
            )}

            <Menu
                MenuListProps={{ disablePadding: true }}
                slotProps={{
                    paper: {
                        elevation: 2,
                        sx: {
                            mt: 1.5,
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        },
                    },
                }}
                TransitionComponent={Fade}
                disableScrollLock={true}
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: "right", vertical: "bottom", ...props.anchorOrigin }}
                transformOrigin={{ horizontal: "right", vertical: "top", ...props.transformOrigin }}
                open={!!anchorEl}
                onClose={close}
                disablePortal={props.disablePortal}
            >
                {children.map((x, idx) => {
                    if (!x || typeof x === "boolean") {
                        return x;
                    }

                    const { onClick, ...rest } = x.props;
                    return cloneElement(x, {
                        key: idx,
                        onClose: close,
                        onClick: (e: any) => {
                            e.stopPropagation?.();
                            close(e);
                            onClick?.(e);
                        },
                        ...rest,
                    });
                })}
            </Menu>
        </>
    );
};
