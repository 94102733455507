export const loadScript = (src: string) => {
    if (!src) return;

    const scriptElement = document.createElement("script");
    scriptElement.setAttribute("type", "text/javascript");
    scriptElement.setAttribute("src", src);
    document.body.appendChild(scriptElement);

    return scriptElement;
};
