import { CompetitionTeamPlayerRole } from "@components/team/TeamService";
import type { TournamentDto } from "@masterblaster/api";
import { CompetitionFormat } from "@masterblaster/api";
import { MbMenu, MbMenuItem } from "@masterblaster/basics";
import { AccessLevels } from "@mb/auth";
import { Info as InfoIcon, MoreVert } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useAuthorization } from "@services/AuthSerivce";
import type { ApplicationState } from "@store/core";
import { showConfirmDialog } from "@utils/DialogHelpers";
import { generateLink } from "@utils/generateLink";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { deleteMatchSeries, useMatchViewMode } from "./api/MatchApi";
import type { MatchSeries } from "./api/MatchSeries";
import { getMatchSeriesTeamAdmin, getMatchSeriesTeamPlayer } from "./api/MatchSeries";
import { useMatchDrawer } from "./drawer/MatchDrawer";
import { useHistoryDrawer } from "./drawer/MatchHistoryDrawer";
import { showMatchRescheduleProposalDialog } from "./reschedule";
import { getPendingProposal, isCancelRescheduleVisible, isRescheduleVisible } from "./reschedule/MatchRescheduleApi";
import { showMatchRescheduleInquiryDialog } from "./reschedule/MatchRescheduleInquiryDialog";
import { isVetoLogVisible, showVetoLogModal } from "./veto/VetoLogModal";

export const MatchSeriesMenu = (props: { matchSeries: MatchSeries; competition?: TournamentDto }) => {
    const { matchSeries } = props;

    const { t } = useTranslation(["common", "translation"]);
    const isGameAdmin = useAuthorization("Tournament", matchSeries.competitionId, AccessLevels.GameAdmin);
    const user = useSelector((state: ApplicationState) => state.session.user);
    const { viewMode } = useMatchViewMode(matchSeries);

    const matchConsoleUrl = useMemo(
        () => generateLink("MATCH_ROUTES.ADMIN_LOBBY", { id: matchSeries.id }),
        [matchSeries]
    );
    const { openMatchDrawer, matchDrawer } = useMatchDrawer(matchSeries, viewMode, props.competition);
    const { openMatchHistory, matchHistoryDrawer } = useHistoryDrawer(matchSeries.id);

    const { showMatchReschedule, showCancelMatchReschedule, showVetoLog, teamPlayer } = useMemo(() => {
        const teamPlayer =
            getMatchSeriesTeamPlayer(matchSeries.teams, user?.id, CompetitionTeamPlayerRole.TeamLeader) ??
            getMatchSeriesTeamAdmin(matchSeries.teams, user?.id);
        const showMatchReschedule = isRescheduleVisible(matchSeries);
        const showCancelMatchReschedule = isCancelRescheduleVisible(matchSeries, teamPlayer?.teamId);
        const proposal = getPendingProposal(matchSeries.matchScheduleProposals);
        const showVetoLog = isVetoLogVisible(matchSeries);

        return { showMatchReschedule, showCancelMatchReschedule, showVetoLog, proposal, teamPlayer };
    }, [matchSeries, user]);

    const showMenu = isGameAdmin || (teamPlayer && (showMatchReschedule || showCancelMatchReschedule));
    const showDeleteMatch = matchSeries.competitionFormat === CompetitionFormat.BattleRoyale;

    if (!showMenu && !showVetoLog) {
        return null;
    }

    if (!showMenu && showVetoLog) {
        return (
            <IconButton size="small" onClick={() => showVetoLogModal(matchSeries)}>
                <InfoIcon />
            </IconButton>
        );
    }

    return (
        <>
            <MbMenu icon={<MoreVert sx={{ color: (theme) => theme.colors.white }} />}>
                <MbMenuItem visible={isGameAdmin} onClick={() => openMatchDrawer()}>
                    {t("common:shared.edit_match")}
                </MbMenuItem>
                <MbMenuItem visible={isGameAdmin} to={matchConsoleUrl}>
                    Console
                </MbMenuItem>
                <MbMenuItem visible={isGameAdmin} onClick={() => openMatchHistory()}>
                    {t("common:button_actions.history")}
                </MbMenuItem>
                <MbMenuItem visible={showVetoLog} onClick={() => showVetoLogModal(matchSeries)}>
                    {t("translation:components.tournament.match.match_series_menu.veto_log")}
                </MbMenuItem>
                <MbMenuItem
                    visible={showMatchReschedule}
                    onClick={async () => {
                        await showMatchRescheduleProposalDialog(matchSeries, user?.id);
                    }}
                >
                    {t("common:shared.reschedule_match")}
                </MbMenuItem>
                <MbMenuItem
                    visible={showCancelMatchReschedule}
                    onClick={async () => await showMatchRescheduleInquiryDialog(matchSeries, true, "decline")}
                >
                    {t("common:shared.cancel_reschedule")}
                </MbMenuItem>
                <MbMenuItem
                    sx={{ color: (theme) => theme.colors.error }}
                    visible={showDeleteMatch}
                    onClick={async () => {
                        const confirmed = await showConfirmDialog(
                            t("common:button_actions.delete_match"),
                            t("common:shared.are_you_sure")
                        );
                        if (confirmed) {
                            await deleteMatchSeries(matchSeries.id);
                        }
                    }}
                >
                    {t("common:button_actions.delete_match")}
                </MbMenuItem>
            </MbMenu>
            {matchDrawer}
            {matchHistoryDrawer}
        </>
    );
};
