import { Trans } from "react-i18next";
import { MatchToaster } from "@masterblaster/assets";
import { Notification } from "../Notification";
import { NotificationTitle } from "../shared";
import type { NotificationElementProps } from "../NotificationElementProps";
import type { NotificationPayload } from "../NotificationService";

interface ChallengeStarted extends NotificationPayload {
    challengeId: string;
    challengeNumber: number;
}

export const NotificationChallengeStarted = ({ notification, context, navigateTo }: NotificationElementProps) => {
    const data = notification.payload as ChallengeStarted;

    const handleClick = async () => {
        await context.markAsRead(notification.id);
        const url = `/challenge/${data.challengeId}`;
        navigateTo(url);
    };

    return (
        <Notification
            createdAt={notification.createdAt}
            isRead={!!notification.readAt}
            image={MatchToaster}
            title={
                <NotificationTitle>
                    <Trans
                        ns="translation"
                        i18nKey="components.notifications.notification_type.challenge_started"
                        components={[<strong key={0} />]}
                        values={{
                            number: data.challengeNumber,
                        }}
                    />
                </NotificationTitle>
            }
            onClick={handleClick}
        />
    );
};
