import type { FC } from "react";
import { isValidElement } from "react";
import type { ButtonProps } from "@mui/material/Button";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";

import { formatAgo } from "@masterblaster/api";

import { NotificationTitle, NotificationImage, NotificationActionResult } from "./shared";
import { parseISO } from "date-fns";

const getNonPropagatingHandler = (handler?: (event: React.MouseEvent<HTMLButtonElement>) => void) => {
    if (!handler) return;

    return (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        handler(event);
    };
};

export interface NotificationProps {
    createdAt: string;
    title: string | React.ReactElement;
    image?: string | React.ReactElement;
    actionResult?: string | React.ReactElement;
    buttonActions?: ButtonProps[];
    isRead?: boolean;
    onClick?: () => void;
}

export const Notification: FC<NotificationProps> = ({
    createdAt,
    title,
    actionResult,
    image,
    buttonActions,
    isRead,
    onClick,
}) => {
    const notificationTitle = isValidElement(title) ? title : <NotificationTitle>{title}</NotificationTitle>;

    const notificationImage = isValidElement(image) ? (
        <NotificationImageContainer>{image}</NotificationImageContainer>
    ) : image ? (
        <NotificationImageContainer>
            <NotificationImage src={image as any} alt="Notification image" />
        </NotificationImageContainer>
    ) : (
        <NotificationImageContainer>
            <NotificationsNoneRoundedIcon />
        </NotificationImageContainer>
    );

    const notificationActionResult = isValidElement(actionResult)
        ? actionResult
        : actionResult && <NotificationActionResult>{actionResult}</NotificationActionResult>;

    const notificationsActions =
        buttonActions?.map((buttonProps, index) => (
            <Button key={index} size="small" {...buttonProps} onClick={getNonPropagatingHandler(buttonProps.onClick)} />
        )) ?? [];

    const clickable = !!onClick;

    const createdAtDate = parseISO(createdAt);
    const createdAtDistanceToNow = formatAgo(createdAtDate);

    return (
        <NotificationContainer clickable={clickable ? 1 : 0} onClick={onClick}>
            {notificationImage}
            <NotificationMiddleContainer>
                <NotificationHeader>{notificationTitle}</NotificationHeader>
                <NotificationBody>
                    <NotificationDate dateTime={createdAt}>{createdAtDistanceToNow}</NotificationDate>
                    {notificationActionResult}
                </NotificationBody>
                {notificationsActions && <NotificationFooter>{notificationsActions}</NotificationFooter>}
            </NotificationMiddleContainer>
            {!isRead && <NotificationDot />}
        </NotificationContainer>
    );
};

const NotificationDate = styled("time")(({ theme }) => ({
    fontSize: "0.625rem",
    fontWeight: "bold",
    lineHeight: "1rem",
    textTransform: "uppercase",
    letterSpacing: "0.4px",
    color: theme.colors.greys[4],
}));

const NotificationImageContainer = styled("figure")(({ theme }) => ({
    width: "3rem",
    height: "3rem",
    flexShrink: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: theme.colors.greys[13],
    margin: "0 0.75rem 0 0",
}));

const NotificationContainer = styled("article")<{ clickable?: number }>(({ theme, clickable }) => ({
    boxSizing: "border-box",
    padding: "1rem 1rem 0.5rem 0.5rem",
    display: "flex",
    borderRadius: "3px",
    overflow: "hidden",
    transition: "background-color, box-shadow 150ms linear",

    "&:hover": {
        backgroundColor: theme.colors.greys[13],
        boxShadow: "0px 0px 2px rgba(255, 255, 255, 0.5)",
        cursor: clickable ? "pointer" : "default",
    },
}));

const NotificationMiddleContainer = styled("div")({
    flexGrow: 1,
});

const NotificationHeader = styled("header")({
    marginBottom: "0.25rem",
});

const NotificationBody = styled("section")({
    display: "flex",
    flexDirection: "column",

    "& > :not(:last-child)": {
        marginBottom: "0.25rem",
    },

    "& + footer": {
        marginTop: "0.5rem",
    },
});

const NotificationFooter = styled("footer")({
    display: "flex",
    flexWrap: "wrap",

    "& > :not(:last-child)": {
        marginRight: "0.5rem",
    },
});

const NotificationDot = styled("div")({
    width: "0.625rem",
    height: "0.625rem",
    backgroundImage: "linear-gradient(180deg, #FFC700 0%, #FF6B00 50.52%, #EC008E 100%)",
    borderRadius: "50%",
    marginLeft: "1rem",
    alignSelf: "center",
    flexShrink: 0,
});
