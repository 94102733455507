import { bannerSlice } from "@store/BannerStore";
import { TokenStore } from "@mb/auth";
import { getDispatch, getStore } from "@store/core";
import { applicationVersion } from "@src/config/AppConfigKeeper";
import { showCommandError, showError, showGetError } from "./Error";
import { configureHttp } from "@masterblaster/api";

const forceReloadIfVersionMismatch = (headers: Headers) => {
    const serverVersion = headers.get("X-Api-Version");
    const clientVersion = applicationVersion;

    if (!serverVersion || !clientVersion) {
        return;
    }

    const forceReload = serverVersion !== clientVersion;
    const dispatch = getDispatch();
    const state = getStore()?.getState();

    if (dispatch) {
        if (forceReload) {
            console.warn(
                `Client and server version mismatch, next navigation will be hard. client=${clientVersion}, server=${serverVersion}`
            );

            dispatch(bannerSlice.actions.openReloadNotice());
        } else if (state?.banners.isReloadNoticeOpen) {
            dispatch(bannerSlice.actions.closeReloadNotice());
        }
    }
};

export const configureHttpModule = () => {
    const opts = {
        applicationVersion,
        showCommandError,
        showError,
        showGetError,
        getToken: TokenStore.getToken,
        removeToken: TokenStore.removeToken,
        forceReloadIfVersionMismatch,
    };
    configureHttp(opts);
};
