import { getSwr } from "@masterblaster/api";
import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import type { AccessLevels, UserData, UserLoginInfo } from "@mb/auth";
import { isAdministrator, isSuperAdministrator, isTenantAdministrator } from "@mb/auth";
import type { ApplicationState } from "@store/index";
import { useUserSubscription } from "@messaging/core";
import type { UserChanged } from "@messaging/core";
import { useTenant } from "@src/config";

export type ResourceName = "Community" | "Tournament" | "CsTrial" | "Organization";
export const useAuthorization = (
    resourceName: ResourceName,
    resourceId: string | undefined,
    accessLevel: AccessLevels
) => {
    const acl = useSelector((state: ApplicationState) => state.session.acl);

    const { tenantId } = useTenant();
    const isTenantAdmin = tenantId ? isTenantAdministrator(tenantId) : false;

    const isAdmin = isAdministrator() || isSuperAdministrator() || isTenantAdmin;

    const hasAccess = useMemo(() => {
        if (!resourceId) {
            return false;
        } else if (isAdmin) {
            return true;
        } else {
            const entry = acl?.filter(
                (x) => x.resourceName === resourceName && x.resourceId === resourceId && x.access >= accessLevel
            );

            return entry?.length > 0;
        }
    }, [isAdmin, acl, resourceName, resourceId, accessLevel]);

    return hasAccess;
};

export const useCurrentUser = () => {
    const { data, mutate, isLoading } = getSwr<UserData>(`/api/authentication/currentUser`, { showErrorToast: false });

    useUserSubscription<UserChanged>(
        "UserChanged",
        () => {
            mutate();
        },
        [mutate]
    );

    return {
        ...data,
    };
};

export const useUserLogins = () => {
    const user = useSelector((state: ApplicationState) => state.session.user);
    const { data: logins, mutate } = getSwr<UserLoginInfo[]>(`api/authentication/logins`);

    useEffect(() => {
        mutate();
    }, [user?.id, mutate]);

    useUserSubscription<UserChanged>(
        "UserChanged",
        () => {
            mutate();
        },
        [mutate]
    );

    return { logins, refresh: mutate };
};
