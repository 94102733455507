import type { CsMatchConnectionInfo } from "@components/competitions/tournament/games/csgo";
import type { MatchSeries, MatchSeriesCsGameSettings, MatchSeriesTeam } from "@components/matches/api/MatchSeries";
import { MatchSeriesTeamPlayerStatus } from "@components/matches/api/MatchSeries";
import { TimeCountDown } from "@components/matches/shared/CountDown";
import { CompetitionTeamPlayerRole } from "@components/team/TeamService";
import { GameType, customFormatDuration } from "@masterblaster/api";
import { getFontSize } from "@masterblaster/basics";
import { Box } from "@mui/material";
import { addSeconds, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

export const TimeToJoin = ({ matchSeries }: { matchSeries: MatchSeries }) => {
    const { t } = useTranslation("translation", {
        keyPrefix: "components.tournament.games.cs_go.match_drawer.time_to_join",
    });

    if (matchSeries.game === GameType.CSGO) {
        const connectionInfo = matchSeries.connectionInfo.fields as unknown as CsMatchConnectionInfo;
        const settings = matchSeries.gameSettings as MatchSeriesCsGameSettings;

        if (!connectionInfo?.readyAt || !settings.useAutoForfeit) {
            return null;
        }

        const readyAt = parseISO(connectionInfo.readyAt);
        const expires = addSeconds(readyAt, settings.forfeitTimeoutInSeconds);

        const isTeamReady = (team: MatchSeriesTeam) =>
            !team.players.some(
                (x) => x.role !== CompetitionTeamPlayerRole.None && x.status !== MatchSeriesTeamPlayerStatus.Ready
            );

        const allTeamsReady = matchSeries.teams.every((x) => isTeamReady(x));
        if (allTeamsReady) {
            return null;
        }

        return (
            <Box display="flex" alignItems="center" style={{ fontSize: getFontSize("md"), margin: "1rem 0" }}>
                <span style={{ marginRight: "0.5rem" }}>{t("title")}</span>
                <TimeCountDown date={expires}>
                    {({ duration }) => <span>{customFormatDuration(duration)}</span>}
                </TimeCountDown>
            </Box>
        );
    }

    return <span>{t("join_asap")}</span>;
};
