import type { FC } from "react";
import type { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import type { ButtonBaseProps } from "@mui/material/ButtonBase";
import ButtonBase from "@mui/material/ButtonBase";
import { styled } from "@mui/material/styles";

import { SocialTwitchIcon } from "@masterblaster/assets";
import { TwitchTagState } from "@masterblaster/api";

export interface TwitchTagProps extends ButtonBaseProps {
    state: TwitchTagState;
    small?: boolean;
    hideLabel?: boolean;
    fixedWidth?: boolean;
    href?: string;
}

const getTagLabelByState = (state: TwitchTagState, t: TFunction<"translation">) => {
    switch (state) {
        case TwitchTagState.LIVE:
            return t("basics.tag.twitch.watch_live");
        case TwitchTagState.RECORD:
            return t("basics.tag.twitch.watch_stream");
        case TwitchTagState.SCHEDULED:
            return t("basics.tag.twitch.live_stream");
    }
};

export const TwitchTag: FC<TwitchTagProps> = ({
    fixedWidth,
    hideLabel,
    small,
    state,
    children,
    ...buttonBaseProps
}) => {
    const { t } = useTranslation("translation");
    const hasChildren = typeof children === "string" || typeof children === "object";
    const title = hideLabel ? (typeof children === "string" ? children : getTagLabelByState(state, t)) : undefined;

    return (
        <TwitchTagContainer
            small={small}
            state={state}
            fixedWidth={!hideLabel && fixedWidth}
            title={title}
            {...buttonBaseProps}
        >
            {!hideLabel && (
                <TwitchTagTextContainer>{hasChildren ? children : getTagLabelByState(state, t)}</TwitchTagTextContainer>
            )}
            <SocialTwitchIcon />
        </TwitchTagContainer>
    );
};

const TwitchTagContainer = styled(ButtonBase, {
    shouldForwardProp: (prop) => prop !== "state" && prop !== "small" && prop !== "fixedWidth",
})<{
    state: TwitchTagState;
    small?: boolean;
    fixedWidth?: boolean;
}>(({ theme, small, state, fixedWidth }) => ({
    padding: small ? "0.125rem 0.4375rem" : "0.3125rem 0.4375rem",
    borderRadius: "5px",
    border: "1px solid currentColor",
    fontStyle: "italic",
    fontSize: "0.875rem",
    lineHeight: "0.875rem",

    ":hover": {
        boxShadow: theme.colors.button_hover_shadow,
    },

    "& > svg": {
        width: small ? "1.125rem" : "1.25rem",
        height: small ? "1.125rem" : "1.25rem",

        ".st0": {
            fill: "none",
        },

        ".st1": {
            fill: "currentColor",
        },
    },

    ...(state === TwitchTagState.LIVE && {
        color: theme.colors.white,
        fontWeight: "bold",
        borderColor: "transparent",
        backgroundImage:
            "linear-gradient(270deg, #B8006E 0%, #A86815 100%), linear-gradient(270deg, #EC008E 0%, #FFC700 100%)",
        backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",
    }),

    ...(state === TwitchTagState.RECORD && {
        color: theme.colors.greys[2],
    }),

    ...(state === TwitchTagState.SCHEDULED && {
        color: theme.colors.greys[4],
    }),

    ...(fixedWidth && {
        width: "7.8125rem",
        padding: small ? "0.125rem 0" : "0.3125rem 0",
    }),
}));

const TwitchTagTextContainer = styled("span")({
    marginRight: "4px",
});
