import { get, sendCommand } from "@masterblaster/api";
import { useEffect, useState } from "react";
import { useTopicIdSubscription } from "@messaging/hooks";
import type { OwnerSubscriptionsChanged } from "@messaging/core";
import { useSelector } from "react-redux";
import type { ApplicationState } from "@store/core";
import type { SubscriptionOwnerType, SubscriptionType } from "./SubscriptionEnums";

export interface SubscriptionMember {
    playerId: string;
}

export interface Subscription {
    id: string;
    name: string;

    ownerType: SubscriptionOwnerType;
    ownerId: string;

    subscriptionType: SubscriptionType;
    subscribeToId: string;

    numberOfSeats: number;
    seatAssignments: SubscriptionMember[];
}

export const useOrganizationSubscriptions = (organizationId: string | undefined) => {
    const user = useSelector((state: ApplicationState) => state.session.user);
    const [subscriptions, setSubscriptions] = useState<Subscription[]>();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (organizationId && user) {
            setIsLoading(true);
            getOrganizationSubscriptions(organizationId)
                .then(setSubscriptions)
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
    }, [organizationId, user]);

    useTopicIdSubscription<OwnerSubscriptionsChanged>(
        organizationId,
        "OwnerSubscriptionsChanged",
        (evt) => {
            setSubscriptions(evt.subscriptions);
        },
        [setSubscriptions]
    );

    return {
        subscriptions,
        isLoading,
    };
};

export const getOrganizationSubscriptions = (organizationId: string) =>
    get<Subscription[]>(`api/subscription/org/${organizationId}`);

/*
    Commands
*/
export const assignSubscriptionSeat = (subscriptionId: string, playerId: string) =>
    sendCommand("AssignSubscriptionSeatCommand", {
        subscriptionId,
        playerId,
    });

export const unassignSubscriptionSeat = (subscriptionId: string, playerId: string) =>
    sendCommand("UnassignSubscriptionSeatCommand", {
        subscriptionId,
        playerId,
    });

/*
    Back office
*/
export const createSubscription = (
    ownerId: string,
    ownerType: SubscriptionOwnerType,
    subscribeToId: string,
    subscriptionType: SubscriptionType
) =>
    sendCommand("CreateSubscriptionCommand", {
        ownerId,
        ownerType,
        subscribeToId,
        subscriptionType,
    });

export const updateSubscription = (subscriptionId: string, numberOfSeats: number) =>
    sendCommand("UpdateSubscriptionCommand", {
        subscriptionId,
        numberOfSeats,
    });
