import { styled } from "@mui/material";

import type { BasicLabelProps } from "./BasicLabel";
import { BasicLabel } from "./BasicLabel";

export const OpenLabel = styled((props) => <BasicLabel {...props}>OPEN</BasicLabel>)(({ theme }) => ({
    color: theme.colors.prize_color,
    backgroundColor: "transparent",
    padding: 0,
    textShadow:
        "4px 4px 15px rgba(236, 0, 142, 0.35), -2px -2px 15px rgba(255, 199, 0, 0.35), 0px 0px 5px rgba(255, 107, 0, 0.5)",
}));

export const RegisteredLabel = styled((props) => <BasicLabel {...props}>Registered</BasicLabel>)(({ theme }) => ({
    color: theme.colors.white,
    backgroundColor: theme.colors.main_color,
}));

export const CheckedInLabel = styled((props) => <BasicLabel {...props}>Checked In</BasicLabel>)(({ theme }) => ({
    color: theme.colors.white,
    backgroundColor: theme.colors.success,
}));

export const FinishedLabel = styled((props) => <BasicLabel {...props}>Finished</BasicLabel>)(({ theme }) => ({
    color: theme.colors.black,
    backgroundColor: theme.colors.silver,
}));

export const StoppedLabel = styled((props) => <BasicLabel {...props}>Stopped</BasicLabel>)(({ theme }) => ({
    color: theme.colors.black,
    backgroundColor: theme.colors.error,
}));

export const OngoingLabel = styled((props) => <BasicLabel {...props}>Ongoing</BasicLabel>)(({ theme }) => ({
    color: theme.colors.white,
    backgroundColor: theme.colors.main_color,
}));

export const BetaLabel = styled((props: BasicLabelProps) => <BasicLabel {...props}>Beta</BasicLabel>)(({ theme }) => ({
    color: theme.colors.white,
    backgroundColor: theme.colors.accent_gradient_dark,
}));

export const BetaBadge = styled((props) => <BasicLabel {...props}>Beta</BasicLabel>)(({ theme }) => ({
    background: theme.colors.accent_gradient_dark,
    color: theme.colors.white,
    padding: "0rem 0.35rem",
    fontSize: 14,
    fontWeight: "normal",
    borderRadius: theme.shape.borderRadius,
}));

export const GoldBadge = styled((props: BasicLabelProps) => <BasicLabel {...props}>Gold</BasicLabel>)(({ theme }) => ({
    background: theme.colors.fraymcoin,
    color: theme.colors.white,
    padding: "1rem",
    fontWeight: "bold",
    fontSize: "1em",
    borderRadius: theme.shape.borderRadius,

    [theme.breakpoints.down("sm")]: {
        padding: "0.5rem",
    },
}));
