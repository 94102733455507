import { Box, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { type FC, type PropsWithChildren } from "react";

export const DrawerHeader: FC<PropsWithChildren<{ onClose(): void }>> = ({ onClose, children }) => {
    return (
        <Box
            display="flex"
            justifyContent="flex-start"
            sx={{ padding: "0.5rem", background: (theme) => theme.colors.secondary_background }}
        >
            <IconButton size="small" onClick={onClose}>
                <CloseIcon fontSize="small" />
            </IconButton>
            <Box display="flex" width={1} justifyContent="center">
                {children}
            </Box>
        </Box>
    );
};
