import { Box, styled } from "@mui/material";

export const PageSidebarContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    position: "relative",
    flexDirection: "column",
    width: 330,
    marginRight: theme.spacing(4),
    borderRadius: "0.75rem",
    padding: theme.spacing(2, 3),
    background: theme.colors.contrast_background,
    border: `1px solid ${theme.colors.border}`,
    gap: theme.spacing(2),
    zIndex: 1,
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
}));
