import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import { LinkBehaviour } from "@masterblaster/basics";
import { CardContainer } from "./shared";
import type { FC } from "react";
import { Box } from "@mui/material";

export interface MobileMatchCardProps {
    isActive: boolean;
    href: string;
    topSection: React.ReactElement;
    middleSection: React.ReactElement;
    bottomSection?: React.ReactElement;
    menuSection?: React.ReactElement;
    onClick?: () => void;
}

export const MobileMatchCard: FC<MobileMatchCardProps> = ({
    isActive,
    href,
    menuSection,
    topSection,
    middleSection,
    bottomSection,
    onClick,
}) => {
    return (
        <CardContainer isMobile active={isActive}>
            <TopContainer active={isActive}>
                {topSection}
                <MenuContainer>{menuSection}</MenuContainer>
            </TopContainer>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    boxSizing: "border-box",
                    padding: "0.75rem 1rem 1rem",
                }}
            >
                {middleSection}
            </Box>
            <BottomContainer>
                {bottomSection}
                <IconButton
                    size="small"
                    component={LinkBehaviour}
                    href={href}
                    onClick={onClick}
                    style={{ marginLeft: "auto" }}
                >
                    <ChevronRightRoundedIcon sx={{ fontSize: "1.25rem" }} />
                </IconButton>
            </BottomContainer>
        </CardContainer>
    );
};

const TopContainer = styled("div", {
    shouldForwardProp: (prop) => prop !== "active",
})<{ active?: boolean }>(({ theme, active }) => ({
    position: "relative",
    height: "3rem",
    backgroundColor: active ? theme.colors.greys[12] : theme.colors.greys[14],
    display: "flex",
    padding: "0.25rem 0.75rem 0.25rem 0.5rem",
    alignItems: "center",
}));

const BottomContainer = styled("div")(({ theme }) => ({
    boxSizing: "border-box",
    padding: "0.25rem 0.75rem 0.25rem 0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    backgroundColor: theme.colors.input_border_color,
}));

const MenuContainer = styled("div")({
    position: "absolute",
    right: "0.5rem",
});
