import { lazy, Suspense } from "react";
import { GfLoader } from "../GfLoader";
import type { TipTapEditorProps } from "./tiptap/TipTapEditorProps";

const TipTapEditor = lazy(() => import("./tiptap"));

export const RichTextEditor = (props: TipTapEditorProps) => {
    return (
        <Suspense fallback={<GfLoader />}>
            <TipTapEditor {...props} />
        </Suspense>
    );
};
