import { MatchType } from "@components/matches";
import type { IMatchSeriesTeam } from "@components/matches";
import { Skeleton, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MatchViewMode } from "@components/matches/api/MatchApi";

export const EmptyTeamBox = ({
    matchSeriesTeam,
    matchType,
    viewMode,
}: {
    matchSeriesTeam: IMatchSeriesTeam | undefined;
    viewMode: MatchViewMode;
    matchType: MatchType;
}) => {
    const { t } = useTranslation("translation", {
        keyPrefix: "components.tournament.match.empty_team_box",
    });

    if (!matchSeriesTeam?.source) {
        if (viewMode === MatchViewMode.Seeding) {
            return <span>Waiting for seeding</span>;
        }

        if (matchType === MatchType.Bye) {
            return <span>Bye</span>;
        }

        return <ThemedSkeleton />;
    }

    if (matchSeriesTeam?.source) {
        const seedText = matchSeriesTeam.useLoser
            ? t("loser_of", {
                  match: matchSeriesTeam.source,
              })
            : t("winner_of", {
                  match: matchSeriesTeam.source,
              });
        return <span>{seedText}</span>;
    }

    return <ThemedSkeleton />;
};

const ThemedSkeleton = styled((props) => <Skeleton variant="text" {...props} />)(({ theme }) => ({
    background: theme.colors.greys[3],
    width: 150,
}));
