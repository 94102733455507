import type { TenantUiConfig } from "@masterblaster/api";

export interface AppConfig {
    stripePublicKey: string;
    configCatPublicKey: string;
    mixpanelToken: string;
    tenantId: string | undefined;
    tenantUiConfig: TenantUiConfig;
}

const viteVersion = import.meta.env.VITE_VERSION as unknown as string;
const environmentName = import.meta.env.VITE_ENVIRONMENT_NAME as unknown as string;
export const applicationVersion: string = viteVersion || "0.0.0.0";
export const environment = import.meta.env.MODE;

export const isProduction = () => {
    const env = environmentName?.toLowerCase();
    return env === "master";
};

export const isDev = () => {
    const env = environment?.toLowerCase();
    return env === "development";
};
