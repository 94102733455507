import type { FC, PropsWithChildren } from "react";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface MatchRescheduleDialogTitleProps {
    onClose: () => void;
}

export const MatchRescheduleDialogTitle: FC<PropsWithChildren<MatchRescheduleDialogTitleProps>> = ({
    children,
    onClose,
}) => {
    return (
        <StyledDialogTitle>
            <span>{children}</span>
            <CloseButton aria-label="close" onClick={onClose}>
                <CloseIcon />
            </CloseButton>
        </StyledDialogTitle>
    );
};

const StyledDialogTitle = styled(DialogTitle)({
    position: "relative",
    fontSize: "2rem",
    lineHeight: "1.185",
    padding: "1.25rem",
    paddingRight: "3.75rem",
    fontWeight: 300,
    background: "#181920",
    boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
});

const CloseButton = styled(IconButton)({
    position: "absolute",
    right: "0.75rem",
    top: "50%",
    transform: "translateY(-50%)",
});
