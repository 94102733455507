import type { ExtractRouteParams } from "react-router";
import { generatePath } from "react-router";
import { ALL_ROUTES } from "./routes";

type IsFunction<T, R, Fallback = T> = T extends (...args: any[]) => any ? R : Fallback;

type IsObject<T, R, Fallback = T> = IsFunction<T, Fallback, T extends object ? R : Fallback>;

type Tail<S> = S extends `${string}.${infer T}` ? Tail<T> : S;

type Value<T> = T[keyof T];

type FlattenStepOne<T> = {
    [K in keyof T as K extends string ? IsObject<T[K], `${K}.${keyof T[K] & string}`, K> : K]: IsObject<
        T[K],
        { [key in keyof T[K]]: T[K][key] }
    >;
};

type FlattenStepTwo<T> = {
    [a in keyof T]: IsObject<T[a], Value<{ [M in keyof T[a] as M extends Tail<a> ? M : never]: T[a][M] }>>;
};

type FlattenOneLevel<T> = FlattenStepTwo<FlattenStepOne<T>>;

type Flatten<T> = T extends FlattenOneLevel<T> ? T : Flatten<FlattenOneLevel<T>>;

type AllRoutesType = typeof ALL_ROUTES;
type RouteNamesUnion = keyof Flatten<AllRoutesType>;

type HasParams<T, Fallback = undefined> = T extends Record<string, never> ? Fallback : T;

type Params<S extends RouteNamesUnion> = HasParams<
    ExtractRouteParams<
        S extends `${infer H}.${infer T}`
            ? H extends keyof AllRoutesType
                ? T extends keyof AllRoutesType[H]
                    ? AllRoutesType[H][T]
                    : undefined
                : undefined
            : S extends keyof AllRoutesType
              ? AllRoutesType[S]
              : undefined
    >
>;

const generateLink = <T extends RouteNamesUnion>(
    route: T,
    params?: T extends RouteNamesUnion ? Params<T> : undefined,
    queryParams?: Record<string, string | undefined>
) => {
    const keys = route.split(".");
    let url: string;

    if (keys.length === 1) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        url = generatePath(ALL_ROUTES[keys[0]], params);
    } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        url = generatePath(ALL_ROUTES[keys[0]][keys[1]], params);
    }

    const entries = Object.entries(queryParams ?? {}).filter(([key, value]) => value !== undefined) as string[][];
    const p = new URLSearchParams(entries);
    return queryParams ? `${url}?${p.toString()}` : url;
};

export { generateLink };
