import { CompetitionTeamPlayerRole } from "@components/team/TeamService";
import type { MatchSeries } from "@components/matches/api/MatchSeries";
import { getMatchSeriesTeamAdmin, getMatchSeriesTeamPlayer, MatchStatus } from "@components/matches/api/MatchSeries";
import {
    getPendingProposal,
    isCancelRescheduleVisible,
    isRescheduleVisible,
} from "@components/matches/reschedule/MatchRescheduleApi";
import { isVetoLogVisible } from "@components/matches/veto/VetoLogModal";

const DESKTOP_CARD_MIN_WIDTH = 608;
const DESKTOP_CARD_MIN_WIDTH_WITH_TWITCH_TAG = 640;
const DESKTOP_CARD_MIN_WIDTH_WITH_MENU = 712;
const DESKTOP_CARD_MIN_WIDTH_WITH_RESCHEDULE = 760;
const DESKTOP_CARD_MIN_WIDTH_WITH_EVERYTHING = 980;

const canSeeMatchRescheduleBar = (matchSeries: MatchSeries, userId: string) => {
    const matchNotStarted = matchSeries.status === MatchStatus.NotStarted;

    if (!matchNotStarted) return false;

    const hasProposal = !!getPendingProposal(matchSeries.matchScheduleProposals);

    if (!hasProposal) return false;

    return !!getMatchSeriesTeamPlayer(matchSeries.teams, userId, CompetitionTeamPlayerRole.TeamLeader);
};

const canSeeSeriesMenu = (matchSeries: MatchSeries, userId: string, isGameAdmin?: boolean) => {
    const teamPlayer =
        getMatchSeriesTeamPlayer(matchSeries.teams, userId, CompetitionTeamPlayerRole.TeamLeader) ??
        getMatchSeriesTeamAdmin(matchSeries.teams, userId);

    const showVetoLog = isVetoLogVisible(matchSeries);

    const showMatchReschedule = isRescheduleVisible(matchSeries);
    const showCancelMatchReschedule = isCancelRescheduleVisible(matchSeries, teamPlayer?.teamId);

    const userHasAccessToReschedule = teamPlayer !== undefined;
    const showMenu = isGameAdmin || (userHasAccessToReschedule && (showMatchReschedule || showCancelMatchReschedule));

    return showMenu || showVetoLog;
};

export {
    canSeeMatchRescheduleBar,
    canSeeSeriesMenu,
    DESKTOP_CARD_MIN_WIDTH,
    DESKTOP_CARD_MIN_WIDTH_WITH_TWITCH_TAG,
    DESKTOP_CARD_MIN_WIDTH_WITH_MENU,
    DESKTOP_CARD_MIN_WIDTH_WITH_RESCHEDULE,
    DESKTOP_CARD_MIN_WIDTH_WITH_EVERYTHING,
};
