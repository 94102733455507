import { validateTwitchStreamingUrl } from "@components/stream";
import { DrawerSection, MbSwitch, MbTextField } from "@masterblaster/basics";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import type { ChangeEventHandler, FC, FocusEventHandler } from "react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import type { MatchSeriesTwitchStreamingOptions } from "../api/MatchApi";
import { setMatchSeriesTwitchStreaming } from "../api/MatchApi";
import type { MatchSeries } from "../api/MatchSeries";

export interface TwitchSectionProps {
    matchSeries: MatchSeries;
}

export const TwitchSection: FC<TwitchSectionProps> = ({ matchSeries }) => {
    const { isTwitchStreamingEnabled, twitchStreamingUrl } = matchSeries.settings;

    const [streamingOptions, setStreamingOptions] = useState<Omit<MatchSeriesTwitchStreamingOptions, "matchSeriesId">>({
        isTwitchStreamingEnabled: isTwitchStreamingEnabled ?? false,
        twitchStreamingUrl: twitchStreamingUrl ?? "",
    });
    const [twitchStreamingUrlError, setTwitchStreamingUrlError] = useState<string>("");

    const { t } = useTranslation(["translation", "common", "validation"]);

    const disableInput = !streamingOptions.isTwitchStreamingEnabled;
    const disableButton = !streamingOptions.twitchStreamingUrl || !!twitchStreamingUrlError;
    const hasChanges =
        isTwitchStreamingEnabled !== streamingOptions.isTwitchStreamingEnabled ||
        twitchStreamingUrl !== streamingOptions.twitchStreamingUrl;

    const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            const { name, value } = event.target;

            if (name === "isTwitchStreamingEnabled")
                return setStreamingOptions((prevState) => ({ ...prevState, [name]: !prevState[name] }));

            if (twitchStreamingUrlError) {
                const validationError = validateTwitchStreamingUrl(value, t);

                !validationError && setTwitchStreamingUrlError("");
            }

            return setStreamingOptions((prevState) => ({ ...prevState, [name]: value }));
        },
        [t, twitchStreamingUrlError]
    );

    const handleBlur: FocusEventHandler<HTMLInputElement> = useCallback(
        (e) => {
            const { value } = e.target;
            const validationError = validateTwitchStreamingUrl(value, t);

            if (!validationError) return;

            setTwitchStreamingUrlError(validationError);
        },
        [t]
    );

    const handleSave = useCallback(() => {
        setMatchSeriesTwitchStreaming({
            matchSeriesId: matchSeries.id,
            isTwitchStreamingEnabled: streamingOptions.isTwitchStreamingEnabled,
            twitchStreamingUrl: streamingOptions.twitchStreamingUrl,
        });
    }, [matchSeries.id, streamingOptions.isTwitchStreamingEnabled, streamingOptions.twitchStreamingUrl]);

    return (
        <TwitchSectionContainer>
            <DrawerSection>
                {t("translation:components.tournament.match.drawer.twitch_section.streaming")}
            </DrawerSection>
            <MbSwitch
                size="small"
                name="isTwitchStreamingEnabled"
                onChange={handleChange}
                checked={streamingOptions.isTwitchStreamingEnabled}
                label={t("translation:components.tournament.match.drawer.twitch_section.add_stream")}
                subLabel={t("translation:components.tournament.match.drawer.twitch_section.add_stream_help_text")}
            />
            <MbTextField
                fullWidth
                name="twitchStreamingUrl"
                error={!!twitchStreamingUrlError}
                helpText={twitchStreamingUrlError}
                onChange={handleChange}
                onBlur={handleBlur}
                value={streamingOptions.twitchStreamingUrl}
                disabled={disableInput}
                label={t("translation:components.tournament.match.drawer.twitch_section.streaming_link")}
                placeholder={t("translation:components.tournament.match.drawer.twitch_section.paste_streaming_link")}
            />
            {hasChanges && (
                <Button variant="contained" color="primary" size="small" disabled={disableButton} onClick={handleSave}>
                    {t("common:button_actions.save")}
                </Button>
            )}
        </TwitchSectionContainer>
    );
};

const TwitchSectionContainer = styled("div")({
    "& > :not(:last-child)": {
        marginBottom: "1rem",
    },
});
