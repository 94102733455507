import { getImageUrl } from "@masterblaster/api";
import type { BoxProps } from "@mui/material";
import { Box } from "@mui/material";
import type { GameSettings } from "@masterblaster/api";
import type { FC } from "react";

export type GameIconSize = "large" | "medium" | "small" | "xs";

const getSize = (size: GameIconSize | undefined) => {
    switch (size) {
        case "xs": {
            return {
                // padding: "6px",
                boxSize: "1rem",
                imageSize: "0.75rem",
            };
        }
        case "small":
            return {
                padding: "6px",
                boxSize: "1.25rem",
                imageSize: "1rem",
            };
        case "medium":
            return {
                padding: "6px",
                boxSize: "42px",
                imageSize: "30px",
            };
        case "large":
        default:
            return {
                padding: "6px",
                boxSize: "64px",
                imageSize: "46px",
            };
    }
};

interface GameIconProps extends BoxProps {
    gameSettings: GameSettings;
    size?: GameIconSize;
}

export const GameIcon: FC<GameIconProps> = ({ gameSettings, size, sx, ...boxProps }) => {
    if (!gameSettings) {
        return null;
    }

    const sizes = getSize(size);

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{
                backgroundColor: (theme) => gameSettings?.backgroundColor ?? theme.colors.white,
                width: sizes.boxSize,
                height: sizes.boxSize,
                padding: sizes.padding,
                boxSizing: "border-box",
                ...sx,
            }}
            {...boxProps}
        >
            {gameSettings && (
                <img
                    src={getImageUrl(gameSettings?.iconImage?.imageId)}
                    style={{ width: sizes.imageSize, height: sizes.imageSize, display: "block" }}
                    alt="Game icon"
                />
            )}
        </Box>
    );
};
