import type { MouseEvent } from "react";
import { Trans } from "react-i18next";

import { handleTeamInvite } from "@components/team/TeamService";
import { getTeamGravatarUrl, GfLink } from "@masterblaster/basics";
import { generateLink } from "@utils/generateLink";

import { Notification } from "../Notification";
import { NotificationTitle } from "../shared";
import type { NotificationPayload } from "../NotificationService";
import type { NotificationElementProps } from "../NotificationElementProps";
import { getImageUrl } from "@masterblaster/api";

interface TeamMemberInvited extends NotificationPayload {
    teamId: string;
    teamName: string;
    bannerImageId: string | undefined;
    invitedById: string;
    invitedByNick: string;
}

export const NotificationTeamMemberInvite = ({ notification, context, navigateTo, t }: NotificationElementProps) => {
    const { invitedById, invitedByNick, teamId, bannerImageId, teamName } = notification.payload as TeamMemberInvited;
    const teamLink = generateLink("TEAM_ROUTES.OVERVIEW", {
        id: teamId,
    });

    const hasSenderInfo = Boolean(invitedById && invitedByNick);
    const senderLink = invitedById
        ? generateLink("PLAYER_PROFILE_TAB_ROUTES.OVERVIEW", {
              id: invitedById,
          })
        : "";

    const handleActionClick = async (accept: boolean) => {
        const result = await handleTeamInvite(teamId, accept);

        if (result.success && accept) {
            await context.markAsRead(notification.id);
        }
    };
    const handleNotificationClick = () => navigateTo(teamLink);
    const handleLinkClick = (link: string) => (event: MouseEvent<HTMLAnchorElement>) => {
        event.stopPropagation();
        navigateTo(link);
    };

    return (
        <Notification
            createdAt={notification.createdAt}
            isRead={!!notification.readAt}
            image={bannerImageId ? getImageUrl(bannerImageId) : getTeamGravatarUrl(teamName)}
            title={
                <NotificationTitle>
                    {!notification.readAt ? (
                        <span>
                            {hasSenderInfo ? (
                                <Trans
                                    ns="translation"
                                    i18nKey="components.notifications.notification_type.team_member_invite.player_invite_you"
                                    values={{ teamName, playerName: invitedByNick }}
                                    components={[
                                        <GfLink
                                            underline
                                            key="0"
                                            to={senderLink}
                                            onClick={handleLinkClick(senderLink)}
                                        />,
                                        <GfLink underline key="1" to={teamLink} onClick={handleLinkClick(teamLink)} />,
                                    ]}
                                />
                            ) : (
                                <Trans
                                    ns="translation"
                                    i18nKey="components.notifications.notification_type.team_member_invite.you_invited"
                                    values={{ teamName }}
                                    components={[
                                        <GfLink underline key="0" to={teamLink} onClick={handleLinkClick(teamLink)} />,
                                    ]}
                                />
                            )}
                        </span>
                    ) : (
                        <span>
                            <Trans
                                ns="translation"
                                i18nKey="components.notifications.notification_type.team_member_invite.you_joined_team"
                                components={[
                                    <GfLink underline key="0" to={teamLink} onClick={handleLinkClick(teamLink)} />,
                                ]}
                                values={{ teamName }}
                            />
                        </span>
                    )}
                </NotificationTitle>
            }
            buttonActions={
                !notification.readAt
                    ? [
                          {
                              variant: "contained",
                              children: t("common:button_actions.accept"),
                              onClick: () => handleActionClick(true),
                          },
                          {
                              variant: "contained",
                              color: "secondary",
                              children: t("common:button_actions.decline", {
                                  defaultValue: "Decline",
                              }),
                              onClick: () => handleActionClick(false),
                          },
                      ]
                    : undefined
            }
            onClick={handleNotificationClick}
        />
    );
};
