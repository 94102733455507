import type { MatchModel, MatchSeries } from "@components/matches/api/MatchSeries";
import { MatchStatus } from "@components/matches/api/MatchSeries";
import { formatDateTime } from "@masterblaster/api";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { isToday } from "date-fns";
import { useTranslation } from "react-i18next";
import { MatchStatusText } from "./MatchStatusText";
import _ from "lodash";
import { useMemo } from "react";

interface MatchSeriesStartingAtProps {
    matchSeries: MatchSeries;
    // startingAt: string | undefined;
    // status: MatchStatus;
    // series: MatchModel[];
    // statusText?: string;
}

// export const MatchSeriesStartingAt = ({ startingAt, status, series, statusText }: MatchSeriesStartingAtProps) => {
export const MatchSeriesStartingAt = ({ matchSeries }: MatchSeriesStartingAtProps) => {
    const { t } = useTranslation(["translation", "common"]);
    const { startingAt, status, series } = matchSeries;
    const lastStartedMatch = useMemo(
        () => matchSeries.series.findLast((x) => x.status === MatchStatus.Started),
        [matchSeries.series]
    );
    const statusText = lastStartedMatch ? lastStartedMatch.statusText : undefined;

    if (status >= MatchStatus.Starting) {
        const lastRunningMap = _.chain(series)
            .orderBy((x) => x.index)
            .filter((x) => x.status >= MatchStatus.Starting && x.status <= MatchStatus.Postgame)
            .first()
            .value();

        return (
            <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center", flexWrap: "wrap" }}>
                <MatchStatusText status={status} />

                {lastRunningMap && (
                    <>
                        <Box>•</Box>
                        <Box>{lastRunningMap.displayName}</Box>
                    </>
                )}

                {statusText && (
                    <>
                        <Box>•</Box>
                        <StatusTextInfo>{statusText}</StatusTextInfo>
                    </>
                )}
            </Box>
        );
    }

    if (!startingAt) {
        return <EmptyInfo>{t("translation:components.tournament.match.starting_asap")}</EmptyInfo>;
    }

    const matchDate = new Date(startingAt);
    const isMatchStartToday = isToday(matchDate);

    const dateStr = isMatchStartToday
        ? `${t("common:relative_date.today")} - `
        : `${formatDateTime(matchDate, "dd.MM.yyyy")} - `;

    return (
        <DatePart>
            {dateStr}
            <TimePart>{formatDateTime(matchDate, "HH:mm")}</TimePart>
        </DatePart>
    );
};

const DatePart = styled("div")(({ theme }) => ({}));

const StatusTextInfo = styled("span")(({ theme }) => ({}));

const TimePart = styled("span")({});

const EmptyInfo = styled("p")(({ theme }) => ({
    margin: 0,
}));
