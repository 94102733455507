import type { FC } from "react";
import { useMemo } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { LinkBehaviour } from "@masterblaster/basics";
import { generateLink } from "@utils/generateLink";

import { StreamsEmptyView } from "./StreamsEmptyView";
import type { MatchSeries } from "@components/matches/api/MatchSeries";
import { MatchSeriesCard } from "@components/cards/match/MatchSeriesCard";
import { getCustomTwitchTagLabel } from "./twitch";

export interface StreamsSectionProps {
    variant: "competition" | "community" | "league";
    matches: MatchSeries[];
    showCompetitionLink?: boolean;
    id: string;
}

export const StreamsSection: FC<StreamsSectionProps> = ({ variant, matches, showCompetitionLink, id }) => {
    const { t } = useTranslation("translation", {
        keyPrefix: "components.stream",
    });
    const slicedMatches = useMemo(() => (matches.length > 5 ? matches.slice(0, 5) : matches), [matches]);
    if (variant === "competition" && matches.length === 0) {
        return null;
    }

    const isCompetitionPage = variant === "competition" || variant === "league";
    let seeMoreLink: string;
    switch (variant) {
        case "competition":
            seeMoreLink = generateLink("TOURNAMENT_ROUTES.STREAMS", {
                id,
            });
            break;
        case "community":
            seeMoreLink = generateLink("COMMUNITY_TAB_ROUTES.STREAMS", {
                id,
            });
            break;
        case "league":
            seeMoreLink = generateLink("LEAGUE_ROUTES.STREAMS", {
                leagueId: id,
            });
            break;
    }

    const showSeeMoreButton = matches?.length > 5;

    return (
        <div>
            <StreamsSectionHeader>
                <StreamsSectionHeading>{t("streamed_matches")}</StreamsSectionHeading>
                {showSeeMoreButton && (
                    <Button LinkComponent={LinkBehaviour} endIcon={<ChevronRightIcon />} href={seeMoreLink}>
                        {t("see_more")}
                    </Button>
                )}
            </StreamsSectionHeader>
            <StreamsSectionBody>
                {slicedMatches.length > 0 ? (
                    slicedMatches.map((matchSeries, index) => (
                        <MatchSeriesCard
                            key={index}
                            matchSeries={matchSeries}
                            showCompetitionLink={showCompetitionLink}
                            getCustomTwitchTagLabel={!isCompetitionPage ? getCustomTwitchTagLabel : undefined}
                        />
                    ))
                ) : (
                    <StreamsEmptyView />
                )}
            </StreamsSectionBody>
        </div>
    );
};

const StreamsSectionHeader = styled("header")({
    marginBottom: "1rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
});

const StreamsSectionHeading = styled("h3")(({ theme }) => ({
    margin: 0,
    fontSize: "1.125rem",
    lineHeight: "1.5625rem",
    color: theme.colors.greys[2],
}));

const StreamsSectionBody = styled("div")({
    "& > :not(:last-child)": {
        marginBottom: "0.5rem",
    },
});
