import type { PlayerDto } from "@masterblaster/api";
import { get, getSwr, sendCommand, uploadImage } from "@masterblaster/api";
import type { SocialLink } from "@masterblaster/basics";
import { useTopicIdSubscription } from "@messaging/core";
import { useUserSubscription } from "@messaging/hooks";
import type { Theme } from "@mui/material";
import { useTheme } from "@mui/material";
import type { TFunction } from "i18next";

export enum TeamJoinStatus {
    None = 0,
    Joined = 2,
    Invited = 5,
    Withdrawn = 10,
}

export enum CompetitionTeamPlayerRole {
    None = 0,
    TeamLeader = 10,
    Player = 20,
    Substitute = 30,
    Coach = 40,
}

export enum TeamPlayerJoinType {
    Invite = 10,
    JoinRequest = 20,
}

export interface TeamPlayerDto {
    playerId: string;
    player: PlayerDto;
    pendingInvitation: boolean;
    type: TeamPlayerJoinType;
    isAdministrator: boolean;
}

export interface TeamDto {
    id: string;
    organizationOwnerId: string | undefined;
    tag: string;
    name: string;
    flag?: string;
    shortHandle?: string;
    gameId: string;
    players: TeamPlayerDto[];
    socialLinks: SocialLink[];

    avatarImageId: string | undefined;
    bannerImageId: string | undefined;
}

export interface SelectedTournamentTeamPlayer {
    playerId: string;
    role: CompetitionTeamPlayerRole;
    checkedIn?: boolean;
}

export interface CompetitionTeamPlayer {
    playerId: string;
    player: PlayerDto;
    role: CompetitionTeamPlayerRole;
    checkedIn: boolean;
    addedAt: string;
}

export interface CompetitionTeamDto {
    id: string;
    teamId: string;
    team: TeamDto;
    competitions: { competitionId: string; disqualified: boolean; disqualifiedReason: string | undefined }[];
    organizationId: string | undefined;
    players: CompetitionTeamPlayer[];
    joinStatus?: TeamJoinStatus;
    enlistedAt?: string;
}

export interface UpdateTeamRequest {
    id: string;
    name: string;
    shortHandle: string;
    gameId: string;
    socialLinks: SocialLink[];
}

interface MapStats {
    mapName: string;
    mapDisplayName: string;
    result: number;
    total: number;
}

export interface AggregatedTeamStats {
    teamId: string;
    matchWinRatio?: number;
    bestMap?: MapStats;
    worstMap?: MapStats;
    bestPlayer?: {
        playerId: string;
        kdRatio: number;
    };
}

/*
    HOOKS
*/

export const useRoleColor = (role: CompetitionTeamPlayerRole | undefined) => {
    const { colors } = useTheme<Theme>();

    switch (role) {
        case CompetitionTeamPlayerRole.TeamLeader:
            return colors.teamLeader;
        case CompetitionTeamPlayerRole.Player:
            return colors.player;
        case CompetitionTeamPlayerRole.Substitute:
            return colors.substitute;
        case CompetitionTeamPlayerRole.Coach:
            return colors.substitute;
        default:
            return null;
    }
};

export const getTournamentTeamPlayerRoleLabel = (
    role: CompetitionTeamPlayerRole,
    t: TFunction<("common" | "translation" | "validation")[]>
) => {
    switch (role) {
        case CompetitionTeamPlayerRole.None:
            return t("common:shared.not_selected");
        case CompetitionTeamPlayerRole.Substitute:
            return t("common:shared.substitute");
        case CompetitionTeamPlayerRole.Player:
            return t("common:shared.player");
        case CompetitionTeamPlayerRole.TeamLeader:
            return t("common:shared.team_leader");
        case CompetitionTeamPlayerRole.Coach:
            return t("common:shared.coach");
        default:
            return "";
    }
};

/*
 *  Api
 */

export const useTeam = (teamId?: string, cached: boolean = false) => {
    const { data: team, mutate: fetchTeam, isLoading } = getTeam(teamId, cached);

    useTopicIdSubscription(teamId, ["TeamUpdated", "TeamMemberAdded", "TeamMemberRemoved"], () => fetchTeam(), [
        fetchTeam,
    ]);

    return {
        team,
        isLoading,
        setTeam: fetchTeam,
    };
};

export const getTeamsForPlayer = (playerId: string) => get<TeamDto[]>(`api/Team/player/${playerId}`);

export const useTeamsForPlayer = (playerId: string | undefined | null) =>
    getSwr<TeamDto[]>(playerId ? `api/Team/player/${playerId}` : null);

export const usePlayerTeams = (playerId: string | undefined) => {
    const { data: teams, mutate } = useTeamsForPlayer(playerId);

    useUserSubscription(
        ["TeamCreated", "TeamDeleted", "TeamMemberAdded", "TeamMemberRemoved"],
        () => {
            if (!playerId) return;
            mutate();
        },
        [mutate, playerId]
    );

    return {
        teams,
    };
};

export const getTeamStats = (teamId: string) => getSwr<AggregatedTeamStats>(`api/team/stats/${teamId}`);

export const inviteTeamMember = (teamId: string, playerId: string) =>
    sendCommand<TeamDto>("InviteTeamMember", { teamId: teamId, playerId: playerId });

export const teamJoinRequest = (teamId: string, playerId: string) =>
    sendCommand<TeamDto>("TeamJoinRequestCommand", { teamId: teamId, playerId: playerId });

export const handleTeamJoinRequest = (teamId: string, playerId: string, accepted: boolean) =>
    sendCommand<TeamDto>("HandleTeamJoinRequestCommand", { teamId: teamId, playerId: playerId, accepted: accepted });

export const addTeamMember = (teamId: string, playerId: string) =>
    sendCommand<TeamDto>("AddTeamMemberCommand", { teamId, playerId });
export const removeTeamMember = (teamId: string, playerId: string) =>
    sendCommand<TeamDto>("RemoveTeamMemberCommand", { teamId, playerId });
export const addTeamAdmin = (teamId: string, playerId: string) =>
    sendCommand<TeamDto>("AddTeamAdminCommand", { teamId, playerId });
export const removeTeamAdmin = (teamId: string, playerId: string) =>
    sendCommand<TeamDto>("RemoveTeamAdminCommand", { teamId, playerId });

export const updateTeam = (team: UpdateTeamRequest) => sendCommand<string>("UpdateTeam", team);
export const deleteTeam = (teamId: string) => sendCommand<string>("DeleteTeamCommand", { teamId });
export const leaveTeam = (teamId: string) => sendCommand("LeaveTeam", { teamId });
export const changeTeamImage = (teamId: string, imageId: string) => sendCommand("ChangeTeamImage", { teamId, imageId });
export const handleTeamInvite = (teamId: string, accepted: boolean) =>
    sendCommand("HandleTeamInvite", { teamId, accepted });

export const addOrganizationTeamPlayer = (organizationId: string, teamId: string, playerId: string) =>
    sendCommand("AddOrganizationTeamPlayerCommand", {
        organizationId,
        teamId,
        playerId,
    });

export const changeTeamOrgOwner = (teamId: string, organizationId: string | undefined) =>
    sendCommand("ChangeTeamOrgOwnerCommand", {
        organizationId,
        teamId,
    });

export const getAvailableTeamsForTournament = (competitionId: string) =>
    get<{ message: string; teams: TeamDto[]; teamSize: number }>(`api/Team/currentPlayer/${competitionId}`);

export const getTeam = (teamId: string | undefined, cached: boolean = false) => {
    const base = cached ? "api/Team/cached" : "api/Team";
    const url = teamId ? `${base}/${teamId}` : null;
    return getSwr<TeamDto>(url);
};

/*
    Create team
*/
interface CreateTeamRequest {
    gameId: string;
    organizationId?: string;
    name: string;
    shortHandle: string | undefined;
    invitedPlayerIds: string[];
    image?: Blob;
    avatarImageId?: string;
}
export const createTeam = async (team: CreateTeamRequest) => {
    const result = await sendCommand<string>("CreateTeam", team);

    if (result.success && result.data) {
        const avatarImageId = team.image
            ? await uploadImage("UploadTeamImageCommand", team.image, { teamId: result.data, type: "Avatar" })
            : undefined;
    }

    return result;
};
