import type { Editor } from "@tiptap/react";

export type AlignmentVariants = "left" | "right" | "center" | "justify";
export type HeadingLevels = 1 | 2 | 3 | 4 | 5 | 6;

export class EditorCommands {
    private editor: Editor;

    constructor(editor: Editor) {
        this.editor = editor;
    }

    private getCommandStart() {
        return this.editor.chain().focus();
    }

    public bold() {
        this.getCommandStart().toggleBold().run();
    }

    public italic() {
        this.getCommandStart().toggleItalic().run();
    }

    public bulletList() {
        this.getCommandStart().toggleBulletList().run();
    }

    public orderedList() {
        this.getCommandStart().toggleOrderedList().run();
    }

    public strike() {
        this.getCommandStart().toggleStrike().run();
    }

    public codeBlock() {
        this.getCommandStart().toggleCodeBlock().run();
    }

    public blockquote() {
        this.getCommandStart().toggleBlockquote().run();
    }

    public setAlignment(variant: AlignmentVariants) {
        this.getCommandStart().setTextAlign(variant).run();
    }

    public setHeading(level: HeadingLevels) {
        const paragraphTextAlign = this.editor.getAttributes("paragraph").textAlign;
        const headingTextAlign = this.editor.getAttributes("heading").textAlign;

        this.getCommandStart().toggleHeading({ level }).run();

        this.setAlignment(paragraphTextAlign ?? headingTextAlign ?? "left");
    }
}
