export const LEAGUE_BASE = "/league";
export const LEAGUE_ROUTES = {
    CREATE: `${LEAGUE_BASE}/create/:communityId?`,
    JOIN: `${LEAGUE_BASE}/:leagueId/join`,
    LEAGUE_BACKOFFICE: `${LEAGUE_BASE}/:leagueId/edit`,
    LEAGUE_PAGE: `${LEAGUE_BASE}/:leagueId`,
    MATCHES: `${LEAGUE_BASE}/:leagueId/matches`,
    DASHBOARD: `${LEAGUE_BASE}/:leagueId/dashboard`,
    OVERVIEW: `${LEAGUE_BASE}/:leagueId/overview`,
    FORMAT: `${LEAGUE_BASE}/:leagueId/format`,
    LEAGUE_TEAM_PAGE: `${LEAGUE_BASE}/:leagueId/team/:teamId`,
    MANAGE_TEAM: `${LEAGUE_BASE}/:leagueId/manage/:competitionTeamId`,
    STREAMS: `${LEAGUE_BASE}/:leagueId/streams`,
} as const;
