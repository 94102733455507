import { formatDateTime } from "@masterblaster/api";
import type { Theme } from "@mui/material";
import { useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { showModal } from "@store/core";
import type { FC } from "react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import type { MatchSeries } from "../api/MatchSeries";
import { MatchRescheduleProposalStatus } from "../api/MatchSeries";
import { acceptMatchScheduleProposal, getPendingProposal, rejectMatchScheduleProposal } from "./MatchRescheduleApi";
import { showMatchRescheduleConfirmedDialog } from "./MatchRescheduleConfirmedDialog";
import { MatchRescheduleDateInfo } from "./MatchRescheduleDateInfo";
import { MatchRescheduleDialogTitle } from "./MatchRescheduleDialogTitle";
import { MatchRescheduleTeamBox } from "./MatchRescheduleTeamBox";
import { StyledDialog, StyledDialogActions, StyledDialogContent } from "./shared";

export const showMatchRescheduleInquiryDialog = async (
    matchSeries: MatchSeries,
    showOnlyConfirmModal: boolean,
    variant?: "accept" | "decline"
) => {
    const proposal = getPendingProposal(matchSeries.matchScheduleProposals);

    if (proposal?.status !== MatchRescheduleProposalStatus.Pending) {
        return;
    }

    const handleAccept = async () => {
        const result = await acceptMatchScheduleProposal(proposal.id, proposal.matchSeriesId);
        if (result.success) {
            await showMatchRescheduleConfirmedDialog(matchSeries, proposal.scheduledAt);
        }
    };

    const handleDecline = async () => await rejectMatchScheduleProposal(proposal.id, proposal.matchSeriesId);

    await showModal((onClose) => (
        <MatchRescheduleInquiryDialog
            matchSeries={matchSeries}
            proposalScheduledAt={proposal.scheduledAt}
            onAccept={handleAccept}
            onDecline={handleDecline}
            onCancelDialog={onClose}
            showOnlyConfirmModal={showOnlyConfirmModal}
            variant={variant}
        />
    ));
};

export type MatchRescheduleInquiryDialogProps = {
    matchSeries: MatchSeries;
    proposalScheduledAt: string;
    variant?: "accept" | "decline";
    showOnlyConfirmModal?: boolean;
    onAccept: (matchSeries: MatchSeries) => void;
    onDecline: () => void;
    onCancelDialog: () => void;
};

export const MatchRescheduleInquiryDialog: FC<MatchRescheduleInquiryDialogProps> = ({
    matchSeries,
    variant,
    proposalScheduledAt,
    showOnlyConfirmModal,
    onAccept,
    onDecline,
    onCancelDialog,
}) => {
    const [confirmModalVariant, setConfirmModalVariant] = useState(showOnlyConfirmModal ? variant : null);
    const { colors } = useTheme<Theme>();
    const { t } = useTranslation(["common", "translation"]);

    const isConfirmed = !!confirmModalVariant;
    const isAcceptDialog = confirmModalVariant === "accept";
    const buttonColor = isAcceptDialog ? "success" : "error";

    const currentMatchDate = new Date(matchSeries?.startingAt ?? new Date());

    const handleClose = () => {
        setConfirmModalVariant(showOnlyConfirmModal ? variant : null);
        onCancelDialog();
    };

    const handleBack = () => setConfirmModalVariant(null);

    const handleAction = (action: "accept" | "decline") => () => setConfirmModalVariant(action);

    const handleConfirm = () => {
        if (isAcceptDialog) {
            onAccept(matchSeries);
        } else {
            onDecline();
        }

        handleClose();
    };

    return (
        <StyledDialog open={true} fullWidth={true} onClose={handleClose}>
            <MatchRescheduleDialogTitle onClose={handleClose}>
                {t("translation:components.tournament.match.reschedule.inquiry_dialog.title", {
                    matchName: matchSeries.name,
                })}
            </MatchRescheduleDialogTitle>
            <StyledDialogContent>
                <MatchRescheduleTeamBox teams={matchSeries?.teams} mb="1.375rem" />
                <MatchRescheduleDateInfo isActive={false} mb="1.250rem">
                    {t("translation:components.tournament.match.reschedule.current_date", {
                        date: formatDateTime(currentMatchDate),
                    })}
                </MatchRescheduleDateInfo>

                <Typography variant="body1" mb="0.625rem" textAlign="center">
                    {!isConfirmed &&
                        t("translation:components.tournament.match.reschedule.inquiry_dialog.request_change_schedule")}
                    {isConfirmed && (
                        <span>
                            {
                                <Trans
                                    ns="translation"
                                    i18nKey="components.tournament.match.reschedule.inquiry_dialog.are_you_sure_you_want_change_schedule"
                                    components={[
                                        <span
                                            key={0}
                                            style={{ color: isAcceptDialog ? colors.green_base : colors.red_base }}
                                        />,
                                    ]}
                                    values={{
                                        action: isAcceptDialog ? "accept" : "decline",
                                    }}
                                />
                            }
                        </span>
                    )}
                </Typography>

                <MatchRescheduleDateInfo isActive mb="0.625rem">
                    {t("translation:components.tournament.match.reschedule.suggested_new_date", {
                        date: formatDateTime(proposalScheduledAt),
                    })}
                </MatchRescheduleDateInfo>

                <StyledDialogActions>
                    {isConfirmed ? (
                        <>
                            {showOnlyConfirmModal ? (
                                <Button variant="text" onClick={handleClose}>
                                    {t("common:button_actions.close")}
                                </Button>
                            ) : (
                                <Button variant="text" onClick={handleBack}>
                                    {t("common:button_actions.back")}
                                </Button>
                            )}
                            <Button variant="contained" color={buttonColor} onClick={handleConfirm}>
                                {t("common:button_actions.confirm")}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button variant="text" color="error" onClick={handleAction("decline")}>
                                {t("common:button_actions.decline")}
                            </Button>
                            <Button variant="contained" onClick={handleAction("accept")}>
                                {t("common:button_actions.accept")}
                            </Button>
                        </>
                    )}
                </StyledDialogActions>
            </StyledDialogContent>
        </StyledDialog>
    );
};
