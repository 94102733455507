import type { MutableRefObject, Ref, RefCallback } from "react";
import { forwardRef, useRef } from "react";
import { styled } from "@mui/material/styles";
import type { InputBaseProps } from "@mui/material/InputBase";
import InputBase from "@mui/material/InputBase";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

const mergeRefs = <T = any,>(refs: Array<Ref<T> | undefined>): RefCallback<T> => {
    return (value) => {
        refs.forEach((ref) => {
            if (typeof ref === "function") {
                ref(value);
            } else if (ref !== null && ref !== undefined) {
                (ref as MutableRefObject<T | null>).current = value;
            }
        });
    };
};

export interface MbInputProps extends InputBaseProps {
    success?: boolean;
    variant?: "solid" | "transparent";
}

export const MbInput = forwardRef<any, MbInputProps>(function MbInput(
    { value, disabled, success, error, type, fullWidth, startAdornment, variant, onChange, rows, ...restProps },
    forwardedRef
) {
    const internalRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);

    const inputStartAdornment =
        type === "search" ? (
            <InputAdornment position="start">
                <SearchIcon />
            </InputAdornment>
        ) : (
            startAdornment
        );

    if (variant === "transparent")
        return (
            <TransparentInputBase
                {...restProps}
                error={error}
                ref={forwardedRef}
                type={type}
                value={value}
                onChange={onChange}
                inputRef={mergeRefs([restProps.inputRef, internalRef])}
                disabled={disabled}
                startAdornment={inputStartAdornment}
            />
        );

    return (
        <InputBorder ref={forwardedRef} disabled={disabled} success={success} error={error} fullWidth={fullWidth}>
            <InputBase
                {...restProps}
                rows={rows ?? 5}
                type={type}
                value={value}
                onChange={onChange}
                inputRef={mergeRefs([restProps.inputRef, internalRef])}
                error={error}
                disabled={disabled}
                fullWidth={fullWidth}
                startAdornment={inputStartAdornment}
            />
        </InputBorder>
    );
});

const TransparentInputBase = styled(InputBase)(({ theme }) => ({
    background: "transparent",
    borderBottom: `1px solid ${theme.colors.greys[6]}`,
    padding: "0.75rem 0",
    borderRadius: 0,

    "&.Mui-focused": {
        borderImageSource: "linear-gradient(266.21deg, #EC008E 2.76%, #FFC700 96.55%)",
        borderImageSlice: "10",
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
    },

    "&.Mui-disabled": {
        borderBottom: `1px solid ${theme.colors.greys[9]}`,
    },
}));

const InputBorder = styled("div", {
    shouldForwardProp: (prop) => prop !== "disabled" && prop !== "error" && prop !== "success" && prop !== "fullWidth",
})<{
    disabled?: boolean;
    error?: boolean;
    success?: boolean;
    fullWidth?: boolean;
}>(({ theme, disabled, error, success, fullWidth }) => ({
    boxSizing: "border-box",
    borderRadius: "3px",
    padding: "1px",
    background: theme.colors.greys[9],
    width: fullWidth ? "100%" : "fit-content",

    "&:focus-within": {
        background: "linear-gradient(266.21deg, #EC008E 2.76%, #FFC700 96.55%)",
    },

    ...(disabled && {
        background: theme.colors.greys[11],
    }),

    ...(success && {
        background: theme.colors.greens[4],
    }),

    ...(error && {
        background: theme.colors.reds[4],
    }),
}));
