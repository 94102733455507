import { Box, styled } from "@mui/material";

export const DrawerContent = styled(Box)(({ theme }) => ({
    width: "450px",
    [theme.breakpoints.between("xs", "md")]: {
        width: "calc(100vw - 1rem);",
    },
    padding: "1rem",
    "& > *": {
        margin: "0.5rem 0",
    },
    zIndex: 10,
    // background: theme.colors.contrast_background,
    color: theme.colors.primary_text,
}));
