import { useCallback } from "react";
import { useQuery } from "../hooks";

const CAMPAIGN_KEYWORDS = ["utm_source", "utm_medium", "utm_campaign", "utm_content", "utm_term"];
export const useUtmStorage = () => {
    const { getQueryParam } = useQuery();

    const storeUtm = useCallback(() => {
        const utm = CAMPAIGN_KEYWORDS.map((key) => {
            const value = getQueryParam(key);
            return {
                key,
                value,
            };
        })
            .filter((x) => x.value)
            .reduce(
                (p, c) => {
                    return { ...p, [c.key]: c.value ?? "" };
                },
                {} as Record<string, string>
            );

        try {
            const raw = localStorage.getItem("UTM") ?? "{}";
            const prev = JSON.parse(raw);
            const prevSource = prev["utm_source"];
            const newSource = utm["utm_source"];
            // const hasChanged = newSource !== prevSource;
            // if (newSource && hasChanged) {
            if (newSource) {
                const json = JSON.stringify(utm);
                localStorage.setItem("UTM", json);
                const domain = getDomain();
                const c = `_utm=${json};domain=${domain};samesite=strict`;
                document.cookie = c;
            }
        } catch (error) {}
    }, [getQueryParam]);

    return {
        storeUtm,
    };
};

const getDomain = () => {
    const parts = window.location.hostname.split(".");
    if (parts.length <= 2) {
        return window.location.hostname;
    }

    parts.shift();
    const currentdomain = parts.join(".");

    return currentdomain;
};

export const addUtmHeaders = (headers: Headers) => {
    const utm = getCookie("_utm");
    if (utm) {
        headers.append("X-Utm", utm);
    }
};

export function getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    if (parts.length == 2) {
        return parts.pop()?.split(";").shift();
    }
}
