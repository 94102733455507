import { useMemo } from "react";
import type { Theme } from "@mui/material";
import { useTheme, CircularProgress, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

type Size = "small" | "medium" | "large" | undefined;
const getSize = (size: Size) => {
    switch (size) {
        default:
            return 65;
        case "small":
            return 40;
        case "medium":
            return 65;
        case "large":
            return 80;
    }
};

export const GfLoader = ({ text, fullwidth, size }: { text?: string; fullwidth?: boolean; size?: Size }) => {
    const { colors } = useTheme<Theme>();
    const { t } = useTranslation("translation", {
        keyPrefix: "basics.loading_indicator",
    });

    const progressSize = useMemo(() => getSize(size), [size]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "1rem",
                width: fullwidth ? "100%" : "auto",
            }}
        >
            <CircularProgress size={progressSize} style={{ color: colors.greys[1] }} />
            <LoadingText sx={{ color: colors.greys[1] }}>
                {text ? text : t("text")}..
                <span className="blink-1">.</span>
            </LoadingText>
        </Box>
    );
};

export const GfLoaderFullPage = () => (
    <Box
        sx={{
            display: "flex",
            width: 1,
            backgroundColor: (theme) => theme.colors.background,
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
        }}
    >
        <GfLoader />
    </Box>
);

const LoadingText = styled("div")({
    fontSize: 14,
    textTransform: "uppercase",
    textAlign: "center",
    marginTop: 8,
});
