import { useMemo } from "react";
import { useHistory, useLocation } from "react-router";

export const useQuery = () => {
    const history = useHistory();
    const { search } = useLocation();

    const params = useMemo(() => new URLSearchParams(search), [search]);

    return {
        params,
        getQueryParam: (name: string) => {
            const val = params.get(name);
            if (val) {
                return val;
            } else {
                return undefined;
            }
        },
        hasQueryParam: (name: string) => params.has(name),
        removeQueryParam: (name: string) => {
            params.delete(name);
            history.replace({
                search: params.toString(),
            });
        },
    };
};
