import type { ImageMetaData } from "@masterblaster/api";
import { getImageUrl, uploadImage } from "@masterblaster/api";
import type { CropShape, ImageCropResult } from "@masterblaster/basics";
import { ImageCropper } from "@masterblaster/basics";
import { AddPhotoAlternate } from "@mui/icons-material";
import type { ButtonProps, SxProps, Theme } from "@mui/material";
import { Box, Button } from "@mui/material";
import { showModal } from "@store/core";
import { type FC, type PropsWithChildren } from "react";
import type { Area } from "react-easy-crop";
import { useTranslation } from "react-i18next";

export type EditableImageButtonStyle = "small" | "large";
export interface Image {
    imageId: string | undefined;
    originalImageId?: string;
}

export interface EditableImageProps {
    uploadImageCommand: string;
    instantUpload?: boolean;
    image?: Image;
    allowEdit?: boolean;
    disabled?: boolean;
    zoom?: boolean;
    buttonStyle?: EditableImageButtonStyle;
    rotation?: boolean;
    dimensions?: { width: number; height: number };
    cropShape?: CropShape;
    sx?: SxProps<Theme>;
    imageMetadata?: ImageMetaData;
    onImageChanged?(images: { imageId: string | undefined; image: Blob; cropArea: Area }): void;
}

export const EditableImage: FC<PropsWithChildren<EditableImageProps>> = (props) => {
    const { instantUpload = true } = props;
    const aspect = props.dimensions ? props.dimensions.width / props.dimensions.height : undefined;

    const showCropper = async () => {
        const result = await showModal<ImageCropResult | undefined>((onClose) => (
            <ImageCropper
                imageSrc={getImageUrl(props.image?.imageId) ?? ""}
                aspect={aspect}
                dimensions={props.dimensions}
                rotation={props.rotation}
                zoom={props.zoom}
                cropShape={props.cropShape}
                onClose={onClose}
            />
        ));
        if (!result || !result.croppedImage) {
            return;
        }

        const imageId = instantUpload
            ? await uploadImage(props.uploadImageCommand, result.croppedImage, props.imageMetadata, result.cropArea)
            : undefined;

        props.onImageChanged?.({
            imageId,
            image: result.croppedImage,
            cropArea: result.cropArea,
        });
    };

    const large = props.buttonStyle === "large";
    return (
        <Box sx={{ display: "inline-flex", position: "relative", ...props.sx }}>
            {props.children}
            {props.allowEdit && (
                <Box
                    sx={
                        props.children
                            ? {
                                  position: "absolute",
                                  top: large ? "0.5rem" : 0,
                                  right: large ? "0.5rem" : 0,
                                  zIndex: 1,
                              }
                            : {}
                    }
                >
                    <EditButton size={props.buttonStyle} disabled={props.disabled} onClick={() => showCropper()} />
                </Box>
            )}
        </Box>
    );
};

const EditButton = ({ size, ...rest }: ButtonProps) => {
    const { t } = useTranslation("translation", {
        keyPrefix: "basics.images.editable_image",
    });

    const sizeSx = size === "large" ? {} : { padding: 0, minWidth: "auto" };
    return (
        <Button variant="contained" sx={{ ...sizeSx }} size="small" {...rest}>
            <AddPhotoAlternate fontSize="small" />
            {size === "large" && <span style={{ marginLeft: "0.5rem" }}>{t("appearance")}</span>}
        </Button>
    );
};
