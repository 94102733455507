import type { PaginationResult, SearchV2Result } from "@masterblaster/api";
import { Autocomplete, Avatar, Box, Chip, CircularProgress } from "@mui/material";
import type {
    AutocompleteProps,
    AutocompleteRenderGetTagProps,
    AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import type { HTMLAttributes } from "react";
import { useEffect, useMemo, useState } from "react";
import type { MbTextFieldProps } from "./MbTextField";
import { MbTextField } from "./MbTextField";

type AutocompleteChangeHandler = AutocompleteProps<SearchV2Result, boolean, boolean, boolean>["onChange"];
type AutocompleteInputChangeHandler = AutocompleteProps<SearchV2Result, boolean, boolean, boolean>["onInputChange"];

export type AutocompleteResultValue = string | SearchV2Result | (string | SearchV2Result)[] | null;
export interface MbSearchProps
    extends Omit<
        AutocompleteProps<SearchV2Result, boolean, boolean, boolean>,
        | "options"
        | "loading"
        | "inputValue"
        | "onInputChange"
        | "getOptionLabel"
        | "renderOption"
        | "renderTags"
        | "renderInput"
    > {
    clearOnSelect?: boolean;
    debounceDelay?: number;
    minNumberOfCharsToSearch?: number;
    preloadData?: boolean;
    textFieldProps?: MbTextFieldProps;
    onInputChange?: (value: string | undefined) => void;
    searchFunction: (query: string) => Promise<SearchV2Result[]> | Promise<PaginationResult<SearchV2Result>>;
}

export const MbSearch = ({
    clearOnSelect,
    debounceDelay,
    minNumberOfCharsToSearch = 2,
    preloadData,
    textFieldProps,
    searchFunction,
    onInputChange,
    onChange,
    ...autocompleteProps
}: MbSearchProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [options, setOptions] = useState<SearchV2Result[]>([]);
    const [value, setValue] = useState<AutocompleteResultValue>(autocompleteProps.multiple ? [] : null);
    const [inputValue, setInputValue] = useState<string>("");

    const clearOptions = clearOnSelect ? { clearOnBlur: true, blurOnSelect: true } : {};

    const debounsedSearchFunction = useMemo(
        () => AwesomeDebouncePromise(searchFunction, debounceDelay ?? 200),
        [searchFunction, debounceDelay]
    );

    useEffect(() => {
        if (preloadData) {
            debounsedSearchFunction("").then((x) => {
                if (Array.isArray(x)) {
                    setOptions(x);
                } else {
                    setOptions(x.data);
                }
            });
        }
    }, [preloadData, debounsedSearchFunction]);

    const handleInputChange: AutocompleteInputChangeHandler = (_, query, reason) => {
        if (clearOnSelect && reason === "reset") {
            setInputValue("");
            onInputChange?.("");
            setOptions([]);
            setValue(autocompleteProps.multiple ? [] : null);
            return;
        }

        setInputValue(query);
        onInputChange?.(query);

        if (!query || query?.trim()?.length < minNumberOfCharsToSearch) {
            setOptions([]);
            return;
        }

        setLoading(true);
        debounsedSearchFunction(query).then((x) => {
            if (Array.isArray(x)) {
                setOptions(x);
            } else {
                setOptions(x.data);
            }

            setLoading(false);
        });
    };

    const handleChange: AutocompleteChangeHandler = (...args) => {
        const value = args[1];
        setValue(value);
        onChange?.(...args);
    };

    const getOptionLabel = (option: SearchV2Result | string) => {
        if (typeof option === "string") {
            return option;
        }

        return option?.displayText;
    };

    const renderOption = (props: HTMLAttributes<HTMLLIElement>, option: SearchV2Result) => {
        const label = getOptionLabel(option);
        return (
            <li {...props} key={option.id}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                    <Avatar src={option?.avatarUrl} sx={{ width: 30, height: 30 }} />
                    <span>{label}</span>
                </Box>
            </li>
        );
    };

    const renderTags = (options: SearchV2Result[], getTagProps: AutocompleteRenderGetTagProps) =>
        options.map((option, index) => (
            <Chip
                avatar={<Avatar alt="Search result image" src={option.avatarUrl} />}
                label={option.displayText}
                {...getTagProps({ index })}
                key={option.id}
            />
        ));

    const renderInput = ({ InputLabelProps, InputProps, ...inputProps }: AutocompleteRenderInputParams) => (
        <MbTextField
            type="search"
            {...textFieldProps}
            InputProps={{
                ...InputProps,
                ...textFieldProps?.InputProps,
                endAdornment: (
                    <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {InputProps.endAdornment}
                    </>
                ),
            }}
            {...inputProps}
        />
    );

    return (
        <>
            <Autocomplete
                {...clearOptions}
                {...autocompleteProps}
                options={options}
                loading={loading}
                value={value}
                inputValue={inputValue}
                onChange={handleChange}
                onInputChange={handleInputChange}
                getOptionLabel={(option: SearchV2Result | string) => getOptionLabel(option as SearchV2Result)}
                renderOption={renderOption}
                renderTags={renderTags}
                renderInput={renderInput}
                componentsProps={{
                    clearIndicator: {
                        size: "small",
                    },
                }}
            />
        </>
    );
};
