import type { CompetitionTeamDto } from "@components/team";
import type { TournamentDto } from "@masterblaster/api";
import { CompetitionFormat, sendCommand } from "@masterblaster/api";
import {
    BlockContainer,
    DrawerContext,
    DrawerSection,
    ErrorText,
    MbSelect,
    SpinnerButton,
} from "@masterblaster/basics";
import { MenuItem } from "@mui/material";
import _ from "lodash";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    defaultCompetitionContextValues,
    useTournamentContext,
} from "../../competitions/tournament/lobby/TournamentContext";
import type { MatchSeries } from "../api/MatchSeries";
import { MatchStatus } from "../api/MatchSeries";

export const TeamsInput = ({
    tournament,
    competitionTeams,
    matchSeries,
}: {
    tournament: TournamentDto;
    competitionTeams: CompetitionTeamDto[];
    matchSeries: MatchSeries;
}) => {
    const context = useTournamentContext();
    const inLobbyContext = context !== undefined;
    // Falling back to some default values when not in the context of a lobby.
    const { matches, ui } = context ?? defaultCompetitionContextValues;

    const { t } = useTranslation(["common", "translation", "validation"]);

    const [a, b] = matchSeries.teams;
    const [teamA, setTeamA] = useState(a?.team?.id ?? "none");
    const [teamB, setTeamB] = useState(b?.team?.id ?? "none");

    const { hasChanges, setKeyChanged, clearKeyChanged } = useContext(DrawerContext);
    const changeKey = "MatchTeams";

    const onSaveTeams = async () => {
        ui.setIsCalculatingBrackets(true);
        const result = await sendCommand("UpdateMatchSeriesTeams", {
            matchSeriesId: matchSeries.id,
            teamA: teamA === "none" ? undefined : teamA,
            teamB: teamB === "none" ? undefined : teamB,
        });
        if (result.success) {
            clearKeyChanged(changeKey);
        }
    };

    if (!tournament) {
        return null;
    }

    const seededTeams = matches
        .filter((x) => tournament.format !== CompetitionFormat.RoundRobin || x.roundIndex === matchSeries.roundIndex)
        .flatMap((x) => x.teams.map((y) => y.team?.id))
        .filter((x) => x !== undefined) as string[];

    const disabled = matchSeries.status >= MatchStatus.Starting;

    return (
        <BlockContainer>
            <DrawerSection>{t("common:shared.teams")}</DrawerSection>
            {disabled && <ErrorText size="sm">{t("validation:components.tournament.cannot_change_teams")}</ErrorText>}
            <TeamInput
                teamId={teamA}
                competitionTeams={competitionTeams}
                seededTeams={inLobbyContext ? seededTeams : undefined}
                label={t("translation:components.tournament.match.drawer.teams_input.team_name", {
                    name: "A",
                })}
                disabled={disabled}
                onChanged={(teamId) => {
                    setTeamA(teamId);
                    setKeyChanged(changeKey);
                }}
            />
            <TeamInput
                teamId={teamB}
                competitionTeams={competitionTeams}
                seededTeams={inLobbyContext ? seededTeams : undefined}
                label={t("translation:components.tournament.match.drawer.teams_input.team_name", {
                    name: "B",
                })}
                disabled={disabled}
                onChanged={(teamId) => {
                    setTeamB(teamId);
                    setKeyChanged(changeKey);
                }}
            />
            {hasChanges(changeKey) && (
                <SpinnerButton variant="contained" color="primary" size="small" onClick={onSaveTeams}>
                    {t("common:button_actions.save")}
                </SpinnerButton>
            )}
        </BlockContainer>
    );
};

export const TeamInput = ({
    teamId,
    competitionTeams,
    seededTeams,
    label,
    disabled,
    onChanged,
}: {
    teamId: string;
    competitionTeams: CompetitionTeamDto[];
    label: string;
    seededTeams?: string[];
    disabled?: boolean;
    onChanged?(teamId: string): void;
}) => {
    const isSeeded = (teamId: string | undefined) => seededTeams?.find((x) => x === teamId) !== undefined;
    const { t } = useTranslation(["common", "translation"]);

    const options = [
        { label: t("common:shared.not_selected"), value: "none" },
        ..._.chain(competitionTeams)
            .map((x) => {
                const seeded = isSeeded(x.team?.id);
                const label = seeded
                    ? ` - ${t("translation:components.tournament.match.drawer.teams_input.seeded")}`
                    : ` - ${t("translation:components.tournament.match.drawer.teams_input.unseeded")}`;
                const seedLabel = seededTeams === undefined ? "" : label;
                return {
                    label: `${x.team?.name}${seedLabel}`,
                    value: x.team?.id,
                    seeded,
                };
            })
            .orderBy((x) => x.seeded)
            .value(),
    ];

    return (
        <MbSelect
            fullWidth
            disabled={disabled}
            label={label}
            value={teamId}
            onChange={(event) => onChanged?.(event.target.value)}
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </MbSelect>
    );
};
