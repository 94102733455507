import { Grid, styled } from "@mui/material";
import type { CSSProperties } from "react";
import { PlayStation, Windows, Xbox } from "@masterblaster/assets";
import { BasicLabel } from "./BasicLabel";

const getPlatformLogo = (platform: string) => {
    switch (platform.trim()) {
        case "PS":
            return PlayStation;
        case "XBOX":
            return Xbox;
        case "PC":
            return Windows;
        default:
            return undefined;
    }
};

export const PlatformLabel = ({ platform, transparent }: { platform: string; transparent?: boolean }) => {
    const logo = getPlatformLogo(platform);

    return (
        <BasicLabel transparent={transparent}>
            <StyledSpan>{platform}</StyledSpan>
            <img src={logo} alt={platform} style={{ height: "55%", objectFit: "contain" }} />
        </BasicLabel>
    );
};

const StyledSpan = styled("span")(({ theme }) => ({
    color: theme.colors.white,
    marginRight: "0.35rem",
}));

export const PlatformLabels = ({
    platforms,
    style,
    transparentBackground,
}: {
    platforms: string[];
    style?: CSSProperties;
    transparentBackground?: boolean;
}) => {
    if (!platforms || platforms.length === 0) {
        return null;
    }

    return (
        <Grid container item spacing={1} style={style}>
            {platforms.map((x) => (
                <Grid item key={x}>
                    <PlatformLabel transparent={transparentBackground} platform={x} />
                </Grid>
            ))}
        </Grid>
    );
};
