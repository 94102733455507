import { useImperativeHandle, forwardRef, useEffect, useRef } from "react";
import type { PlayerOptions, TwitchPlayer as TwitchPlayerType } from "twitch-embed";
import { styled } from "@mui/material/styles";
import { useTwitchPlayer } from "./useTwitchPlayer";

export interface TwitchPlayerProps {
    id: string;
    options: Omit<PlayerOptions, "height" | "width">;
    scrollToPlayer?: boolean;
}

export type PlayerRef = TwitchPlayerType | null;

export const TwitchPlayer = forwardRef<PlayerRef, TwitchPlayerProps>(({ id, options, scrollToPlayer }, ref) => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    const { player, loadStateRef } = useTwitchPlayer({
        playerId: id,
        playerOptions: {
            height: "100%",
            width: "100%",
            autoplay: false,
            ...options,
        },
    });

    useEffect(() => {
        if (!scrollToPlayer || !player) return;

        if (loadStateRef.current) {
            setTimeout(
                () =>
                    containerRef.current?.scrollIntoView({
                        behavior: "smooth",
                    }),
                300
            );

            return;
        }
        const iframe = containerRef.current?.children[0] as HTMLIFrameElement | undefined;

        const handleLoad = () => {
            containerRef.current?.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        };

        iframe?.addEventListener("load", handleLoad, {
            once: true,
        });

        return () => {
            iframe?.removeEventListener("load", handleLoad);
        };
    }, [loadStateRef, player, scrollToPlayer]);

    useImperativeHandle<PlayerRef, PlayerRef>(ref, () => player, [player]);

    return (
        <TwitchPlayerWrapper>
            <TwitchPlayerContainer id={id} ref={containerRef} />
        </TwitchPlayerWrapper>
    );
});

TwitchPlayer.displayName = "TwitchPlayer";

const TwitchPlayerWrapper = styled("div")({
    position: "relative",
    height: 0,
    paddingTop: "56.25%",
});

const TwitchPlayerContainer = styled("div")({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
});
