import type { ColorsType } from "@masterblaster/theme";
import { darkModeColors } from "@masterblaster/theme";
import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

export interface ThemeState {
    themeColors: ColorsType;
}

const initialState = {
    themeColors: darkModeColors,
};

export const themeSlice = createSlice({
    name: "theme",
    initialState,
    reducers: {
        setTheme(state, action: PayloadAction<ColorsType>) {
            state.themeColors = action.payload;
        },
    },
});

export const { setTheme } = themeSlice.actions;
