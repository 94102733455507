import { GameMode, sendCommand } from "@masterblaster/api";
import { BlockContainer, ButtonContainer, GfLabel, MbSelect, MbTextField, SpinnerButton } from "@masterblaster/basics";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { Box, Divider, IconButton, MenuItem } from "@mui/material";
import { useGame } from "@src/config";
import { showConfirmDialog } from "@utils/DialogHelpers";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import type { MatchModel, MatchSeries, MatchTeamScore } from "../api/MatchSeries";
import { MatchStatus, getMatchStatusEnums } from "../api/MatchSeries";
import { ScoreInput } from "./ScoreInputs";

export const MatchSeriesMatchesInput = ({ matchSeries }: { matchSeries: MatchSeries }) => {
    const { t } = useTranslation("common");

    const onSaveMatch = async (match: MatchModel) => {
        const teamScores = Object.fromEntries(match.teamScores.map((x) => [x.teamId, x.value]));
        const result = await sendCommand<MatchModel>("CreateOrUpdateMatchSeriesMatchCommand", {
            matchSeriesId: matchSeries.id,
            matchId: match.id,
            index: match.index,
            displayName: match.displayName,
            name: match.name,
            status: match.status,
            teamScores,
        });
        return result.success;
    };

    const onDeleteMatch = async (matchIndex: number) => {
        const confirmed = await showConfirmDialog(t("shared.delete_map"), t("shared.are_you_sure"));
        if (confirmed) {
            const result = await sendCommand("DeleteMatchSeriesMatch", {
                matchSeriesId: matchSeries.id,
                matchIndex,
            });
            if (result.success) {
                return true;
            }
        }

        return false;
    };

    return (
        <MatchesInput
            matchSeries={matchSeries}
            matches={matchSeries.series}
            onSave={onSaveMatch}
            onDelete={onDeleteMatch}
        />
    );
};

export const MatchesInput = (props: {
    matchSeries: MatchSeries;
    matches: MatchModel[];
    onSave(match: MatchModel): Promise<boolean>;
    onDelete(matchIndex: number): Promise<boolean>;
}) => {
    const [matches, setMatches] = useState(props.matches);
    const { t } = useTranslation("common");

    return (
        <BlockContainer>
            {matches.map((x, idx) => {
                return (
                    <Box key={idx}>
                        <MatchInput
                            matchSeries={props.matchSeries}
                            match={x}
                            onSave={props.onSave}
                            onDelete={async (matchIndex) => {
                                const result = await props.onDelete(matchIndex);
                                if (result) {
                                    setMatches(matches.filter((x) => x.index !== matchIndex));
                                }
                            }}
                        />
                    </Box>
                );
            })}

            <SpinnerButton
                variant="contained"
                onClick={() => {
                    setMatches([
                        ...matches,
                        {
                            id: "",
                            index: matches.length + 1,
                            name: "",
                            displayName: "",
                            status: MatchStatus.NotStarted,
                            statusText: "",
                            enteredPostgameAt: undefined,
                            createdAt: undefined,
                            teamScores: [],
                        } as MatchModel,
                    ]);
                }}
            >
                {t("button_actions.add")}
            </SpinnerButton>
        </BlockContainer>
    );
};

export const MatchInput = (props: {
    matchSeries: MatchSeries;
    match: MatchModel;
    onSave?(match: MatchModel): Promise<boolean>;
    onDelete?(matchIndex: number): Promise<void> | void;
    onNameChanged?(match: MatchModel): void;
}) => {
    const { matchSeries, onSave, onDelete } = props;
    const { t } = useTranslation(["common", "translation", "validation"]);

    const [match, setMatch] = useState(props.match);
    const [changed, setChanged] = useState(false);
    const [displayNameError, setDisplayNameError] = useState("");
    const [mapNameError, setMapNameError] = useState("");

    const { settings } = useGame(matchSeries.gameId);
    const options = getMatchStatusEnums(MatchStatus);

    const teamScores = _.chain(matchSeries.teams)
        .map((x) => {
            const teamScore =
                match.teamScores.find((y) => y.teamId === x.teamId) ??
                ({
                    competitionId: matchSeries.competitionId,
                    matchSeriesId: matchSeries.id,
                    matchId: props.match.id,
                    teamId: x.teamId,
                    value: undefined,
                } as MatchTeamScore);
            return {
                score: teamScore,
                teamName: x.team?.name ?? "",
            };
        })
        .orderBy((x) => x.teamName)
        .value();

    return (
        <BlockContainer display="flex" flexDirection="column">
            <GfLabel style={{ whiteSpace: "nowrap" }}>
                {t("common:shared.match_number", {
                    number: match.index,
                })}
            </GfLabel>

            <ButtonContainer>
                <MbTextField
                    value={match.displayName ?? ""}
                    fullWidth
                    error={!!displayNameError}
                    helpText={displayNameError}
                    placeholder={t("translation:components.tournament.match.drawer.matches_input.map_display_name")}
                    label={t("common:shared.display_name")}
                    onChange={(e) => {
                        const value = e.currentTarget.value ?? "";
                        const v = { ...match, displayName: value };
                        setMatch(v);
                        setChanged(true);

                        if (value.length === 0) {
                            setDisplayNameError(t("validation:shared.name_cannot_be_empty"));
                        } else {
                            setDisplayNameError("");
                            props.onNameChanged?.(v);
                        }
                    }}
                />

                <MbTextField
                    value={match.name ?? ""}
                    fullWidth
                    placeholder={t("translation:components.tournament.match.drawer.matches_input.map_name")}
                    label={t("translation:components.tournament.match.drawer.matches_input.map_name")}
                    error={!!mapNameError}
                    helpText={mapNameError}
                    onChange={(e) => {
                        const value = e.currentTarget.value ?? "";
                        const v = { ...match, name: value };
                        setMatch(v);
                        setChanged(true);

                        if (value.length === 0) {
                            setMapNameError(t("validation:shared.name_cannot_be_empty"));
                        } else {
                            setMapNameError("");
                            props.onNameChanged?.(v);
                        }
                    }}
                />
            </ButtonContainer>
            {settings.mode === GameMode.Head2Head && (
                <ButtonContainer>
                    {teamScores.map((x, idx) => {
                        return (
                            <ScoreInput
                                key={idx}
                                name={x.teamName}
                                score={x.score.value}
                                onChanged={(e) => {
                                    setMatch((m) => ({
                                        ...m,
                                        teamScores: [
                                            ...m.teamScores.filter((y) => y.teamId !== x.score.teamId),
                                            { ...x.score, value: e },
                                        ],
                                    }));
                                    setChanged(true);
                                }}
                            />
                        );
                    })}
                </ButtonContainer>
            )}
            <MbSelect
                value={match.status}
                onChange={(event) => {
                    const status = event.target.value as MatchStatus;
                    setMatch((prevMatch) => ({ ...prevMatch, status }));
                    setChanged(true);
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.text}
                    </MenuItem>
                ))}
            </MbSelect>
            <ButtonContainer>
                <IconButton size="small" onClick={() => onDelete?.(match.index)}>
                    <DeleteIcon fontSize="small" />
                </IconButton>
            </ButtonContainer>

            {onSave && changed && (
                <SpinnerButton
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={async () => {
                        const result = await onSave?.(match);
                        if (result) {
                            setChanged(false);
                        }
                    }}
                >
                    {t("common:button_actions.save")}
                </SpinnerButton>
            )}

            <Divider />
        </BlockContainer>
    );
};
