import { NavigationButton } from "@masterblaster/basics";
import { alertHeight } from "@masterblaster/theme";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";
import { Badge, Fade, IconButton, Popover, styled } from "@mui/material";
import { useHasAlert } from "@utils/useHasAlert";
import type { MouseEvent } from "react";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { NotificationElementDispatcher } from "./NotificationElementDispatcher";
import { NotificationsContext } from "./NotificationService";

const notificationHeightThreshold = 86;
const notificationMobileHeightThreshold = 76;

export const NotificationsButton = () => {
    const history = useHistory();
    const { t } = useTranslation(["common", "translation"]);

    const hasAlert = useHasAlert();

    const [open, setOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();
    const context = useContext(NotificationsContext);
    const { notifications, unreadChats, resetNotificationCount, count } = context;

    const navigateTo = useCallback(
        (url: string) => {
            history.push(url);
            setOpen(false);
        },
        [history]
    );

    const handleOpen = (event: MouseEvent<HTMLElement>) => {
        !anchorEl && setAnchorEl(event.currentTarget);
        setOpen(true);
        resetNotificationCount();
    };

    const handleClose = () => setOpen(false);

    const totalCount = count + unreadChats.filter((x) => x.payload["unread"] > 0).length;
    return (
        <>
            <Badge badgeContent={totalCount} overlap="circular" color="error">
                <NavigationButton active={open} onClick={handleOpen}>
                    <NotificationsNoneRoundedIcon />
                </NavigationButton>
            </Badge>
            <Popover
                disableScrollLock
                open={open}
                anchorEl={anchorEl}
                TransitionComponent={Fade}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                PaperProps={{
                    sx: {
                        transform: "translateY(4px)",
                    },
                }}
                marginThreshold={24}
                onClose={handleClose}
            >
                <NotificationContainer hasAlert={hasAlert}>
                    <NotificationHeader>
                        <NotificationHeading>
                            {t("translation:components.notifications.notification_button.notifications")}
                        </NotificationHeading>
                        <IconButton size="small" onClick={handleClose}>
                            <CloseRoundedIcon />
                        </IconButton>
                    </NotificationHeader>
                    <NotificationBody>
                        {unreadChats.map((x) => {
                            if (x.payload.unread === 0) {
                                return null;
                            }

                            return (
                                <NotificationElementDispatcher
                                    key={x.id}
                                    notification={x}
                                    context={context}
                                    navigateTo={navigateTo}
                                    t={t}
                                />
                            );
                        })}
                        {notifications.map((x) => (
                            <NotificationElementDispatcher
                                key={x.id}
                                notification={x}
                                context={context}
                                navigateTo={navigateTo}
                                t={t}
                            />
                        ))}
                        {unreadChats.length === 0 && notifications.length === 0 && (
                            <NotificationPlaceholder>
                                {t("translation:components.notifications.notification_button.nothing_to_show")}
                            </NotificationPlaceholder>
                        )}
                    </NotificationBody>
                </NotificationContainer>
            </Popover>
        </>
    );
};

const NotificationContainer = styled("div", {
    shouldForwardProp: (prop) => prop !== "hasAlert",
})<{ hasAlert?: boolean }>(({ theme, hasAlert }) => ({
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    minWidth: "min(21.5rem, 100vw - 32px)",
    overflowY: "auto",
    maxHeight: `min(54rem, 100vh - ${
        hasAlert ? alertHeight + notificationHeightThreshold : notificationHeightThreshold
    }px)`,

    "::-webkit-scrollbar": {
        width: "0.5rem",
    },

    "::-webkit-scrollbar-track": {
        backgroundColor: theme.colors.greys[15],
    },

    "::-webkit-scrollbar-thumb": {
        backgroundColor: theme.colors.greys[12],
        borderRadius: "4px",
    },

    "::-webkit-scrollbar-thumb:hover": {
        backgroundColor: theme.colors.greys[11],
    },

    [theme.breakpoints.down("md")]: {
        maxHeight: `min(54rem, 100vh - ${
            hasAlert ? alertHeight + notificationMobileHeightThreshold : notificationMobileHeightThreshold
        }px)`,
    },
}));

const NotificationHeader = styled("header")(({ theme }) => ({
    position: "sticky",
    top: "0",
    zIndex: 1,
    boxSizing: "border-box",
    padding: "1rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: theme.colors.greys[15],
}));

const NotificationHeading = styled("h2")(({ theme }) => ({
    margin: "0",
    fontSize: "1.25rem",
    fontWeight: "bold",
    lineHeight: "1.625rem",
    color: theme.colors.white,
}));

const NotificationBody = styled("div")({
    boxSizing: "border-box",
    padding: "1rem 1rem 0.5rem 0.5rem",

    "& > :not(:last-child)": {
        marginBottom: "0.5rem",
    },
});

const NotificationPlaceholder = styled("p")({
    paddingLeft: "0.5rem",
    marginTop: 0,
});
