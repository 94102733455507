import {} from "react";

export const getEnumList = <T extends object>(
    item: T,
    toText?: (value: T[keyof T]) => string
): Array<{
    value: T[keyof T];
    name: string;
    text: string;
}> => {
    const items = Object.entries(item);
    return items
        .filter(([key, value]) => typeof value !== "string")
        .map(([key, value]) => {
            return {
                value,
                name: key,
                text: toText ? toText(value) : key,
            };
        });
};
