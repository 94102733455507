import { EventEmitter } from "eventemitter3";
import { useEffect, useCallback } from "react";

const emitter = new EventEmitter();

interface ClientEvent {}

export class ShowErrorEvent implements ClientEvent {
    constructor(public readonly error: string) {}
}

export const publishEvent = <T extends ClientEvent>(message: T) => {
    const eventName = Object.getPrototypeOf(message).constructor.name;
    const listneres = emitter.listeners(eventName);
    console.debug(`Published: ${eventName} to '${listneres.length}'`, message);

    emitter.emit(eventName, message);
};

type DependencyList = readonly unknown[];
export const useEventSubscription = <T extends ClientEvent>(
    name: string,
    callback: (payload: T) => void,
    deps?: DependencyList
) => {
    const log = console.debug;
    const wrappedCallback = useCallback(
        (args: T) => {
            log(`Triggering callback for: ${name}`, args);
            callback(args);
        },
        [name, log, callback]
    );

    useEffect(() => {
        log(`Adding listerner for: name=${name}, count=${emitter.listenerCount(name)}`);
        emitter.on(name, wrappedCallback);
        log(`Added listerner for: name=${name}, count=${emitter.listenerCount(name)}`);

        return () => {
            log(`Removing listerner for: name=${name}, count=${emitter.listenerCount(name)}`);
            emitter.removeListener(name, wrappedCallback);
            log(`Removed listerner for: name=${name}, count=${emitter.listenerCount(name)}`);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, log, wrappedCallback, ...(deps ?? [])]);
};
