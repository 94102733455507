import type { MatchSeries } from "@components/matches/api/MatchSeries";
import { Box } from "@mui/material";

import { PubgMatchStatsWidget } from "./PubgMatchStatsWidget";

export const PubgMatchDrawer = ({ matchSeries }: { matchSeries: MatchSeries }) => {
    return (
        <Box>
            <PubgMatchStatsWidget matchSeries={matchSeries} />
        </Box>
    );
};
