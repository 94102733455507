import type { CustomFieldValues, CustomField } from "./GameTypes";
import { CustomFieldType, CustomFieldVisibleFor } from "./GameTypes";
import type { IUser } from "../players";

export const isCustomFieldVisible = (field: CustomField, user: IUser | undefined, isPlayer: boolean) => {
    switch (field.visibleFor) {
        case CustomFieldVisibleFor.Player:
            return user !== undefined && isPlayer;
        case CustomFieldVisibleFor.Authenticated:
            return user !== undefined && !isPlayer;
        case CustomFieldVisibleFor.Everyone:
            return true;
    }
};

export const getPrimaryScoreFieldValue = (values: CustomFieldValues | undefined, fields: CustomField[]) => {
    const primaryField = fields.find((x) => x.primaryScoreField);
    const value = primaryField && values ? values[primaryField.field] : undefined;

    const result = value ?? primaryField?.defaultValue;
    if (primaryField?.type === CustomFieldType.Number && result !== undefined && typeof result === "string") {
        const num = parseInt(result);
        return num;
    }

    return result as number | undefined;
};
