import { isAdministrator } from "@mb/auth";
import { useAppConfigContext } from "./AppConfigContext";
import type { GameSetup, GameType } from "@masterblaster/api";
import { useGames as useGameApi } from "@masterblaster/api";

export const useGames = (getUnpublished?: boolean) => {
    const { games: allGames } = useAppConfigContext();
    const isAdmin = isAdministrator();

    return useGameApi(allGames, isAdmin, getUnpublished);
};

export function useGame<T extends string | undefined>(gameId: T): T extends string ? GameSetup : undefined {
    const { games } = useGames(true);

    if (gameId === undefined) {
        return undefined as any;
    }

    const game = games.find((x) => x.settings.id === gameId);
    if (!game) {
        return undefined as any;
    }

    return game as any;
}

export function useGameByType<T extends GameType | undefined>(gameType: T): T extends GameType ? GameSetup : undefined {
    const { games } = useGames(true);

    if (gameType === undefined) {
        return undefined as any;
    }

    const game = games.find((x) => x.settings.game === gameType);
    if (!game) {
        return undefined as any;
    }

    return game as any;
}
