import { styled } from "@mui/material";
import type { LinkProps } from "react-router-dom";
import { Link } from "react-router-dom";

const getColor = (color?: string) => `${color ?? "inherit"} !important`;

const getStyles = (color?: string, underline?: boolean) => ({
    color: getColor(color),
    textDecoration: underline ? "underline" : "none  !important",
    "&:hover": {
        color: getColor(color),
    },
    "&:visited": {
        color: getColor(color),
    },
});

interface MbLinkProps {
    color?: string;
    underline?: boolean;
}

export const MbRouterLink = styled(({ color, underline, ...rest }: LinkProps & MbLinkProps) => (
    <Link {...rest} />
))<MbLinkProps>(({ color, underline }) => getStyles(color, underline));

export const MbAnchor = styled("a", {
    shouldForwardProp: (prop) => prop !== "color" && prop !== "underline",
})<MbLinkProps>(({ color, underline }) => getStyles(color, underline));
