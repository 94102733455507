import type { IUser } from "@masterblaster/api";
import * as Sentry from "@sentry/browser";
import { applicationVersion, environment, isProduction } from "./AppConfigKeeper";
import { configureMixPanel } from "@utils/mixpanel";

export const configureExternalServices = (user?: IUser) => {
    configureSentry(user);
    configureIntercom(user); // Problem with this: not run if user is not logged in. Running it anyways at L94 etc.
    configureMixPanel(user);
};

const configureSentry = (user?: IUser) => {
    if (user) {
        Sentry.setUser({
            id: user.id,
            username: user.eMail,
            email: user.eMail,
            nick: user.nickName,
            isPhoneNumberConfirmed: Boolean(user.phoneNumberVerifiedAt),
            name: user.name,
        });
    } else {
        Sentry.setUser({});
    }
};

export const initializeSentry = () => {
    if (isProduction()) {
        Sentry.init({
            dsn: "https://5949019746774ac19670ceb3ab1b157f@sentry.io/1766926",
            environment: environment,
            release: applicationVersion,
            replaysSessionSampleRate: isProduction() ? 0.15 : 1.0,
            replaysOnErrorSampleRate: 1.0,
            attachStacktrace: true,
            integrations: [
                new Sentry.BrowserTracing(),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
            tracesSampleRate: 0.25,
        });
    }
};

// Initialize Intercom
declare global {
    interface Window {
        Intercom: any;
    }
}
const configureIntercom = (user?: IUser) => {
    if (!isProduction()) {
        return;
    }

    if (user) {
        window.Intercom("boot", {
            api_base: "https://api-iam.intercom.io",
            app_id: "g34e6xyv",
            email: user.eMail,
            user_id: user.id,
            name: user.name,
            phone: user.phoneNumber,
            "Phone Number Confirmed": Boolean(user.phoneNumberVerifiedAt),
        });
    } else {
        window.Intercom("boot", {
            app_id: "g34e6xyv",
            api_base: "https://api-iam.intercom.io",
        });
    }
};
