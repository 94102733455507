import { ButtonContainer, SpinnerButton } from "@masterblaster/basics";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slider,
    Typography,
    styled,
    useMediaQuery,
    useTheme,
    type Theme,
} from "@mui/material";

import { getOrientation } from "get-orientation";
import { useCallback, useMemo, useRef, useState } from "react";
import Cropper from "react-easy-crop";
import type { Area, MediaSize, Point, Size } from "react-easy-crop";
import { useTranslation } from "react-i18next";
import { GfLabel } from "../labels/GfLabel";
import { getCroppedImg, getRotatedImage } from "./CanvasUtils";
import { ShowErrorEvent, publishEvent } from "@masterblaster/pubsub";

const ORIENTATION_TO_ANGLE = {
    "3": 180,
    "6": 90,
    "8": -90,
} as { [key: string]: number };

const readFile = (file: Blob) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    });
};

export type CropShape = "round" | "rect";

export interface ImageCropResult {
    originalImage: Blob | undefined;
    cropArea: Area;
    croppedImage: Blob | undefined;
}

export const ImageCropper = (props: {
    zoom?: boolean;
    rotation?: boolean;
    cropShape?: CropShape;
    imageSrc: string;
    aspect: number | undefined;
    dimensions?: { width: number; height: number } | undefined;
    onClose(result: ImageCropResult | undefined): void;
}) => {
    const { t } = useTranslation(["validation", "common", "translation"]);
    const theme = useTheme<Theme>();
    const ref = useRef<HTMLDivElement>();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const [imageSrc, setImageSrc] = useState(props.imageSrc);
    const [imageData, setImageData] = useState<Blob>();
    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
    const [cropSize, setCropSize] = useState<Size>();
    const [mediaSize, setMediaSize] = useState<MediaSize>({
        width: 0,
        height: 0,
        naturalWidth: 0,
        naturalHeight: 0,
    });
    // const [objectFit, setObjectFit] = useState<CropperProps["objectFit"]>("cover");

    const onOk = async () => {
        if (!croppedAreaPixels) {
            return;
        }

        try {
            const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels, rotation);
            console.log("done", { croppedImage });

            const size = imageData?.size || 0;
            if (size > 1024 * 1024 * 4) {
                publishEvent(new ShowErrorEvent(t("validation:basics.images.image_cropper.image_too_large")));
                return;
            } else {
                props.onClose({
                    originalImage: imageData,
                    cropArea: croppedAreaPixels,
                    croppedImage,
                });
            }
        } catch (e) {
            console.error(e);
        }
    };

    const onFileChange = async (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setImageData(file);
            let imageDataUrl = await readFile(file);

            // apply rotation if needed
            const orientation = await getOrientation(file);
            const rotation = ORIENTATION_TO_ANGLE[orientation.toString()];
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl as string, rotation);
            }

            setImageSrc(imageDataUrl as string);
            setCroppedAreaPixels(undefined);
            setCropSize(undefined);
        }
    };

    const handleCropChange = useCallback((location: Point) => {
        setCrop(location);
    }, []);

    const handleCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
        // setCroppedArea(croppedArea);
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const handleCropSizeChanged = useCallback((value: Size) => {
        setCropSize(value);
        console.log(`Crop size`, value);
    }, []);

    const minZoom = useMemo(() => {
        if (cropSize) {
            const heightZoom = cropSize?.height / mediaSize.height;
            const widthZoom = cropSize?.width / mediaSize.width;

            const z = Math.max(widthZoom, heightZoom);
            console.log("Min zoom", cropSize, mediaSize, heightZoom, widthZoom, z);
            return z;
        } else {
            return 1;
        }
    }, [mediaSize, cropSize]);

    return (
        <Box>
            <Dialog open={true} fullWidth={true} fullScreen={fullScreen} maxWidth="md">
                <DialogTitle>{t("translation:basics.images.image_cropper.file_upload")}</DialogTitle>
                <DialogContent>
                    <Box width="100%">
                        <CropContainer ref={ref}>
                            <Cropper
                                image={imageSrc}
                                crop={crop}
                                zoom={zoom}
                                minZoom={minZoom}
                                zoomSpeed={0.05}
                                cropShape={props.cropShape}
                                cropSize={cropSize}
                                rotation={rotation}
                                restrictPosition={true}
                                // objectFit={"cover"}
                                aspect={props.aspect}
                                showGrid={true}
                                onCropChange={handleCropChange}
                                onCropComplete={handleCropComplete}
                                onCropSizeChange={handleCropSizeChanged}
                                setMediaSize={setMediaSize}
                                onZoomChange={(z) => {
                                    setZoom(z);
                                    console.log("Zoom", z);
                                }}
                                onMediaLoaded={(media) => {
                                    if (!props.aspect) {
                                        setCropSize({ height: media.height, width: media.width });
                                    }
                                }}
                                // onMediaLoaded={(media) => {
                                //     if (ref.current && props.aspect) {
                                //         const height = ref.current.clientHeight;
                                //         const width = ref.current.clientWidth;

                                //         const cSize = getCropSize(
                                //             media.width,
                                //             media.height,
                                //             width,
                                //             height,
                                //             props.aspect
                                //         );
                                //         setCropSize(cSize);
                                //     } else {
                                //         setCropSize({ width: media.width, height: media.height });
                                //     }

                                //     const minX = Math.round((media.height / media.naturalHeight) * 100) / 100;
                                //     const minY = Math.round((media.width / media.naturalWidth) * 100) / 100;
                                //     // const fit =
                                //     //     media.naturalWidth === media.naturalHeight ? "auto-cover" : "horizontal-cover";
                                //     const fit = minX === minY ? "auto-cover" : "horizontal-cover";

                                //     setObjectFit(fit);
                                //     console.log(
                                //         // `Area: ${width}x${height}`,
                                //         `Media: ${fit}`,
                                //         `MinX: ${minX}`,
                                //         `MinY: ${minY}`,
                                //         media
                                //     );
                                // }}
                            />
                        </CropContainer>
                        <Box width="100%" marginTop="0.5rem">
                            <Button fullWidth variant="contained" component="label">
                                <span>{t("translation:basics.images.image_cropper.select_file")}</span>
                                <input style={{ display: "none" }} type="file" onChange={(f) => onFileChange(f)} />
                            </Button>
                        </Box>
                        {props.dimensions && (
                            <Box display="flex" width="100%" justifyContent="center" marginTop="0.5rem">
                                <GfLabel>
                                    {t("translation:basics.images.image_cropper.criteria", {
                                        width: props.dimensions.width,
                                        height: props.dimensions.height,
                                    })}
                                </GfLabel>
                            </Box>
                        )}
                    </Box>
                    <Box display="flex" flexDirection="column" flexGrow={1}>
                        {props.zoom && (
                            <SliderContainer>
                                <SliderLabel variant="overline">
                                    {t("translation:basics.images.image_cropper.zoom")}
                                </SliderLabel>
                                <StyledSlider
                                    value={zoom}
                                    min={1}
                                    max={3}
                                    step={0.01}
                                    aria-labelledby={t("translation:basics.images.image_cropper.zoom")}
                                    onChange={(_, zoom) => setZoom(zoom as number)}
                                />
                            </SliderContainer>
                        )}
                        {props.rotation && (
                            <SliderContainer>
                                <SliderLabel variant="overline">
                                    {t("translation:basics.images.image_cropper.rotation")}
                                </SliderLabel>
                                <StyledSlider
                                    value={rotation}
                                    min={-180}
                                    max={180}
                                    step={1}
                                    aria-labelledby={t("translation:basics.images.image_cropper.rotation")}
                                    onChange={(_, rotation) => setRotation(rotation as number)}
                                />
                            </SliderContainer>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <ButtonContainer>
                        <SpinnerButton variant="contained" color="primary" onClick={() => props.onClose(undefined)}>
                            {t("common:button_actions.cancel")}
                        </SpinnerButton>
                        <SpinnerButton variant="contained" color="primary" onClick={onOk}>
                            {t("common:button_actions.ok")}
                        </SpinnerButton>
                    </ButtonContainer>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

const CropContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    width: "100%",
    height: 200,
    border: "1px solid #333",
    [theme.breakpoints.up("sm")]: {
        height: 400,
    },
}));

const SliderContainer = styled("div")({
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
});

const SliderLabel = styled(Typography)({
    minWidth: 80,
});

const StyledSlider = styled(Slider)(({ theme }) => ({
    padding: "22px 0px",
    marginLeft: 16,
    [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        alignItems: "center",
        margin: "0 16px",
    },
}));

// The following code is copied from react-easy-crop because functions are not exported
// form the package
const getCropSize = (
    mediaWidth: number,
    mediaHeight: number,
    containerWidth: number,
    containerHeight: number,
    aspect: number,
    rotation = 0
): Size => {
    const { width, height } = rotateSize(mediaWidth, mediaHeight, rotation);
    const fittingWidth = Math.min(width, containerWidth);
    const fittingHeight = Math.min(height, containerHeight);

    if (fittingWidth > fittingHeight * aspect) {
        return {
            width: fittingHeight * aspect,
            height: fittingHeight,
        };
    }

    return {
        width: fittingWidth,
        height: fittingWidth / aspect,
    };
};
const getRadianAngle = (degreeValue: number) => {
    return (degreeValue * Math.PI) / 180;
};

const rotateSize = (width: number, height: number, rotation: number): Size => {
    const rotRad = getRadianAngle(rotation);

    return {
        width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    };
};
