import { useState } from "react";
import type { MbTextFieldProps } from "@masterblaster/basics";
import { MbTextField } from "@masterblaster/basics";
import { useTranslation } from "react-i18next";

interface ShortHandleProps extends Omit<MbTextFieldProps, "onChange"> {
    onChange(shortHandle: string): void;
}
export const ShortHandle = (props: ShortHandleProps) => {
    const { value, onChange, ...rest } = props;
    const [shortHandle, setShortHandle] = useState(value);
    const { t } = useTranslation("translation", {
        keyPrefix: "components.team.short_handle",
    });

    const handleChanged = (shortHandle: string) => {
        const value = shortHandle?.replace(/[^A-Z0-9]*/g, "") ?? "";
        setShortHandle(value);
        onChange(shortHandle);
    };

    return (
        <MbTextField
            label={t("label")}
            placeholder={t("placeholder")}
            inputProps={{ maxLength: 5 }}
            value={shortHandle}
            onChange={(e) => handleChanged(e.target.value?.toUpperCase())}
            {...rest}
        />
    );
};
