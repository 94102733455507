import { HttpError } from "./HttpError";
import { addUtmHeaders } from "../tracking";
import useSWR from "swr";

export interface IResponse {
    success: boolean;
    errors: string[];
}
export interface AResponse<TReturnObject> extends IResponse {
    data: TReturnObject;
}

interface HttpOptions {
    showCommandError: (commandName: string, error: any) => Promise<any>;
    showGetError: (url: string, error: any) => Promise<any>;
    showError: (error: string) => Promise<any>;
    getToken: () => string | null;
    removeToken: () => void;
    forceReloadIfVersionMismatch: (headers: Headers) => void;
    applicationVersion: string;
}

const APPLICATION_VERSION_HEADER = "X-Application-Version";

let _options = undefined as HttpOptions | undefined;

export const configureHttp = (options: HttpOptions) => {
    console.log("Http options configured!");
    _options = options;
};

const getOptions = () => {
    if (!_options) {
        console.error("Http options not initialized!");
        throw new Error("Http options not initialized");
    }

    return _options;
};

const getApplicationVersion = () => getOptions().applicationVersion;
const getToken = () => getOptions().getToken();
const removeToken = () => getOptions().removeToken();
const showCommandError = async (commandName: string, error: any) => getOptions().showCommandError(commandName, error);
const showGetError = async (url: string, error: any) => getOptions().showGetError(url, error);
const showError = async (error: string) => getOptions().showError(error);
const forceReloadIfVersionMismatch = (headers: Headers) => getOptions().forceReloadIfVersionMismatch(headers);

const isDownloadableFile = (headers: Headers) => {
    let filename: string | undefined = undefined;

    if (headers) {
        const disposition = headers.get("Content-Disposition");
        const type = headers.get("Content-Type");

        if (disposition?.startsWith("attachment") && type === "application/octet-stream") {
            const result = /attachment; filename="(.*)"/.exec(disposition);
            if (result?.length && result.length > 1) {
                filename = result[1];
            }
        }
    }

    return filename;
};

export interface PaginationResult<T> {
    data: T[];
    totalCount: number;
    page?: number;
    pageSize?: number;
    pageCount?: number;
}

export const emptyPaginationResult = <T>() => {
    return {
        totalCount: 0,
        data: [],
    } as PaginationResult<T>;
};

export interface RequestResponse<T> {
    success: boolean;
    error: string;
    data: T | null;
    statusCode: number;
}

export type PostResponse<T> = RequestResponse<T>;

export interface CommandResponse<T> {
    success: boolean;
    error: string;
    data: T | null;
}

export const sendCommand = async <T = boolean>(
    commandName: string,
    data: Record<string, any> | string | undefined = {},
    options: { showError?: boolean } = { showError: true }
): Promise<CommandResponse<T>> => {
    try {
        if (!commandName.endsWith("Command")) {
            commandName += "Command";
        }

        const result = await request<T>("POST", `api/command/${commandName}`, data);
        if (result.error && options.showError) {
            await showError(result.error);
        }
        return result;
    } catch (err) {
        await showCommandError(commandName, err);

        throw err;
    }
};

export const getSwr = <T>(
    url: string | null,
    options?: {
        includeCredentials?: boolean;
        showErrorToast?: boolean;
        headers?: Headers;
        map?: (args: T) => T;
    }
) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useSWR(url, (key) => get<T>(key, options).then((x) => options?.map?.(x) ?? x), {
        revalidateOnFocus: false,
    });
};

export const get = async <T>(
    url: string,
    options?: {
        includeCredentials?: boolean;
        showErrorToast?: boolean;
        headers?: Headers;
    }
): Promise<T> => {
    try {
        const result = await request<T>("GET", url, undefined, options?.includeCredentials, options?.headers);
        if (result.success) {
            return result.data as T;
        }

        throw new HttpError("get", url, result.statusCode, result.error || "Unknown http error");
    } catch (err) {
        if (options?.showErrorToast !== false) {
            await showGetError(url, err);
        }

        throw err;
    }
};

export const post = async <T>(
    url: string,
    data: Record<string, any> | string | undefined = {},
    options?: { includeCredentials?: boolean; showErrorToast?: boolean }
): Promise<T> => {
    try {
        const result = await request<T>("POST", url, data, options?.includeCredentials);

        if (result.success) {
            return result.data as T;
        }

        throw new HttpError("post", url, result.statusCode, result.error || "Unknown http error");
    } catch (err) {
        if (options?.showErrorToast !== false) {
            await showGetError(url, err);
        }
        throw err;
    }
};

export const deleteResource = async (url: string): Promise<boolean> => {
    const result = await request<boolean>("DELETE", url);
    if (result.statusCode === 200) {
        return true;
    }

    throw new Error(result.error || "Unknown http error");
};

export const request = async <T>(
    method: string,
    url: string,
    data?: Record<string, any> | string | undefined,
    includeCredentials?: boolean,
    requestHeaders?: Headers
): Promise<RequestResponse<T>> => {
    const headers = requestHeaders ?? new Headers();
    if (!requestHeaders) {
        headers.set("Authorization", `Bearer ${getToken()}`);
        headers.set("Content-Type", "application/json");
        headers.set(APPLICATION_VERSION_HEADER, getApplicationVersion());
    }

    addUtmHeaders(headers);

    const payload = JSON.stringify(data);
    const response = await fetch(url, {
        method,
        headers,
        credentials: includeCredentials ? "include" : "omit",
        body: payload,
    });

    const text = await response.text();

    forceReloadIfVersionMismatch(response.headers);

    if (response.ok) {
        const downloadFile = isDownloadableFile(response.headers);
        if (downloadFile) {
            const blob = await response.blob();
            return {
                success: true,
                error: "",
                data: blob as unknown as T,
                statusCode: response.status,
            };
        } else {
            const data = text.length ? (JSON.parse(text) as T | null) : null;
            return {
                success: true,
                error: "",
                data,
                statusCode: response.status,
            };
        }
    } else if (response.status === 401) {
        const authHeader = response.headers.get("www-authenticate");
        const hasTokenExpired = authHeader?.includes("token expired");
        const token = getToken();
        if (hasTokenExpired && token) {
            removeToken();
            window.location.replace(`${window.location.pathname}?expired`);
            await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        return {
            success: false,
            data: null,
            error: text || "Access denied",
            statusCode: response.status,
        };
    } else if (response.status === 403 || response.status === 404) {
        throw new Error(text ?? "Access denied");
    } else if (response.status === 500) {
        throw new Error(`Request failed: error=${response.statusText} url=${url}, method=${method}, body=${payload}`);
    } else {
        return {
            success: false,
            data: null,
            error: text ?? "Unexpected error",
            statusCode: response.status,
        };
    }
};

export type ImageMetaData = { [key: string]: string | number };
export const uploadImage = async (
    uploadImageCommand: string,
    image: Blob,
    metadata?: ImageMetaData,
    cropArea?: { width: number; height: number; x: number; y: number }
) => {
    const formData = new FormData();
    formData.append("filepond", image);

    const getData = () => {
        const data = metadata ?? {};
        if (cropArea) {
            return { ...data, ["cropArea"]: cropArea };
        }

        return data;
    };

    const data = getData();
    formData.append("filepond", JSON.stringify(data));

    const response = await fetch(`api/command/${uploadImageCommand}`, {
        method: "POST",
        headers: {
            authorization: `Bearer ${getToken()}`,
        },
        body: formData,
    });

    const text = await response.text();
    if (response.status === 400) {
        showError(text);
        return undefined;
    }

    return text;
};

export class RestUtilities {
    static get<T extends IResponse>(url: string, authorize = true): Promise<T> {
        return RestUtilities.request<T>("GET", url, undefined, authorize);
    }

    static delete<T extends IResponse>(url: string, authorize = true): Promise<T> {
        return RestUtilities.request<T>("DELETE", url, undefined, authorize);
    }

    static post<T extends IResponse>(url: string, data: Record<string, any> | string, authorize = true): Promise<T> {
        return RestUtilities.request<T>("POST", url, data, authorize);
    }

    private static request<T extends IResponse>(
        method: string,
        url: string,
        data?: Record<string, any> | string,
        authorize = true
    ): Promise<T> {
        let body: BodyInit | null | undefined;
        const headers = new Headers();

        if (authorize) {
            headers.set("Authorization", `Bearer ${getToken() ?? ""}`);
            headers.set("Accept", "application/json");
        }

        headers.set(APPLICATION_VERSION_HEADER, getApplicationVersion());

        if (data) {
            if (typeof data === "object") {
                headers.set("Content-Type", "application/json");
                body = JSON.stringify(data);
            } else {
                headers.set("Content-Type", "application/x-www-form-urlencoded");
                body = data;
            }
        }

        return fetch(url, {
            method,
            headers,
            body,
        }).then((response) => {
            forceReloadIfVersionMismatch(response.headers);
            if (authorize && getToken() && response.status === 401) {
                // Unauthorized; redirect to sign-in
                removeToken();
                window.location.replace(`${window.location.pathname}?expired`);
            }

            if (response.ok) {
                const json = response.json() as unknown;
                return json as T;
            }

            return {
                success: false,
                errors: [response.statusText],
            } as T;
        });
    }
}
