/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { FC } from "react";
import { useRef, useState } from "react";
import type { DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import GlobalStyles from "@mui/material/GlobalStyles";
import type { Theme } from "@mui/material/styles";

import { useClickOutside } from "./useClickOutside";

import type { MbTextFieldProps } from ".";
import { MbTextField } from "./MbTextField";
import { convertPickerTextFieldProps } from "./utils";
import { GfLabelWrapper } from "../GfLabelWrapper";

export interface MbDatePickerProps extends Omit<DatePickerProps<Date>, "renderInput" | "InputProps"> {
    TextFieldProps?: MbTextFieldProps;
}

export const MbDatePicker: FC<MbDatePickerProps> = ({ TextFieldProps, ...datePickerProps }) => {
    const [open, setOpen] = useState<boolean>(false);
    const popperRef = useRef<HTMLDivElement | null>(null);

    const { value, ...rest } = datePickerProps;
    const date = value ? value : null;

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useClickOutside(open, popperRef, handleClose);

    return (
        <>
            {/* @ts-ignore */}
            <GlobalStyles styles={datePickerStyles} />
            <GfLabelWrapper label={TextFieldProps?.label} subLabel={TextFieldProps?.subLabel}>
                <DatePicker
                    value={date}
                    open={open}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    {...rest}
                    slotProps={{
                        textField: (inputProps) => {
                            const convertedInputProps = convertPickerTextFieldProps(inputProps);

                            const { color, error, ...restInputProps } = convertedInputProps;

                            const hasError = TextFieldProps?.error ?? (color === "error" || error);
                            const helpText = TextFieldProps?.helpText ?? (hasError ? "Wrong date format" : undefined);

                            return (
                                <MbTextField
                                    error={hasError}
                                    success={color === "success"}
                                    helpText={helpText}
                                    {...restInputProps}
                                    {...TextFieldProps}
                                />
                            );
                        },
                        popper: { className: "date-picker_desktop", ref: popperRef },
                        dialog: { className: "date-picker_mobile" },
                    }}
                />
            </GfLabelWrapper>
        </>
    );
};

const datePickerStyles = (theme: Theme) => ({
    ".date-picker_mobile": {
        "& div[role='grid']": {
            overflow: "visible",
        },

        "& .MuiDialogActions-root": {
            "& button:nth-of-type(1)": {
                color: theme.colors.greys[15],
                background: theme.colors.greys[1],
                ":hover": {
                    background: theme.colors.greys[0],
                },
                ":disabled": {
                    color: theme.colors.greys[5],
                    background: theme.colors.greys[2],
                },
            },
            "& button:nth-of-type(2)": {
                color: theme.colors.greys[0],
                background: theme.colors.greys[10],
                ":hover": {
                    background: theme.colors.greys[7],
                },
                ":focus-visible": {
                    background: theme.colors.greys[7],
                },
                ":disabled": {
                    color: theme.colors.greys[7],
                },
            },
        },
    },
    ".date-picker_desktop div[role='grid']": {
        overflow: "visible",
    },
});
