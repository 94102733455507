import { usePlayerOrganizations } from "@components/organizations";
import { usePlayerTeams } from "@components/team";
import { getGames, getSwr, useUtmStorage } from "@masterblaster/api";
import { GfLoaderFullPage } from "@masterblaster/basics";
import type { PlayerServerMessage, UserChanged } from "@messaging/core";
import { initializeSignalR, restartSignalrConnection, useUserSubscription } from "@messaging/core";
import { showError } from "@services/Error";
import type { ApplicationState } from "@store/core";
import { actions, useAppDispatch } from "@store/core";
import { initMixPanel } from "@utils/mixpanel";
import { ConfigCatProvider } from "configcat-react";
import { useEffect, useState, type FC, type PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { AppConfigContext } from "./AppConfigContext";
import type { AppConfig } from "./AppConfigKeeper";
import { applicationVersion, environment } from "./AppConfigKeeper";
import { configureExternalServices } from "./ConfigureExternalServices";
import { setTheme } from "@store/ThemeStore";
import { darkModeColors, type ColorsType } from "@masterblaster/theme";

export const AppConfigProvider: FC<PropsWithChildren<unknown>> = (props) => {
    const dispatch = useAppDispatch();
    const [isLoadingUser, setIsLoadingUser] = useState(true);
    const user = useSelector((state: ApplicationState) => state.session.user);

    const { teams } = usePlayerTeams(user?.id);
    const { storeUtm } = useUtmStorage();
    const { playerOrganizations } = usePlayerOrganizations();

    const { data: config, isLoading } = getSwr<AppConfig>(`api/config`);

    useEffect(() => {
        // SignalR
        initializeSignalR();
    }, []);

    useEffect(() => {
        storeUtm();
    }, [storeUtm]);

    useEffect(() => {
        setIsLoadingUser(true);
        dispatch(actions.setUser())
            .unwrap()
            .then(() => setIsLoadingUser(false))
            .catch(() => setIsLoadingUser(false));
    }, [dispatch]);

    useEffect(() => {
        if (config?.mixpanelToken) {
            initMixPanel(config?.mixpanelToken);
        }
        if (config?.tenantUiConfig) {
            // based on config.tenantUiConfig.colorStyle, set the theme by setting the newly created redux state for theme, effectively moving from localStorage to redux.
            // After theme redux state has been set, we will need to apply this theme state to the ThemeSwitcher
            if (config.tenantUiConfig.colorStyle) {
                dispatch(setTheme(config.tenantUiConfig.colorStyle));
                console.log("Theme set to custom tenant theme: ", config.tenantUiConfig.colorStyle);
            } else {
                dispatch(setTheme(darkModeColors));
                console.log("Theme set to default darkModeColors: ", darkModeColors);
            }
        }
    }, [config, dispatch]);

    useEffect(() => {
        configureExternalServices(user);
        restartSignalrConnection();
    }, [user]);

    const { data: games, isLoading: isLoadingGames } = getGames();

    useUserSubscription<UserChanged>("UserChanged", () => {
        dispatch(actions.setUser());
    });

    useUserSubscription<PlayerServerMessage>("PlayerServerMessage", (evt) => {
        showError(evt.message);
    });

    if (isLoading || isLoadingUser || !config || isLoadingGames) {
        return <GfLoaderFullPage />;
    }

    return (
        <AppConfigContext.Provider
            value={{
                environment,
                version: applicationVersion,
                appConfig: config,
                isLoading,
                playerTeams: teams ?? [],
                playerOrganizations: playerOrganizations ?? [],
                games: games ?? [],
            }}
        >
            <ConfigCatProvider sdkKey={config.configCatPublicKey}>{props.children}</ConfigCatProvider>
        </AppConfigContext.Provider>
    );
};
