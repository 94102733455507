import { GameSelector } from "@components/gameAccounts";
import { GameType, useQuery } from "@masterblaster/api";
import { DefaultTeamAvatar } from "@masterblaster/assets";
import { Base64Image, getTeamGravatarUrl, Intro, SpinnerButton } from "@masterblaster/basics";
import { teamAvatarAspectRatio } from "@masterblaster/theme";
import { Delete as DeleteIcon } from "@mui/icons-material";
import type { SxProps, Theme } from "@mui/material";
import { Box, IconButton, List, styled } from "@mui/material";
import { isSignedIn } from "@mb/auth";
import { showError } from "@services/Error";
import { useGame } from "@src/config";
import type { ApplicationState } from "@store/index";
import { generateLink } from "@utils/generateLink";
import { BASE_URL } from "@utils/routes";
import type { JSXElementConstructor } from "react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { PlayerListItem } from "./PlayerListItem";
import { ShortHandle } from "./ShortHandle";
import { TeamNameInput } from "./TeamNameInput";
import { createTeam } from "./TeamService";
import { EditableImage } from "@src/images/EditableImage";
import type { SelectedPlayer } from "@components/search";
import { PlayerSearchAutocomplete } from "@components/search";

export const useCreateTeam = (organizationId?: string) => {
    const { t } = useTranslation("validation");

    const getEmptyForm = useCallback(
        (gameId: string) => ({
            gameId: gameId,
            name: "",
            shortHandle: "",
            image: undefined,
            selectedPlayers: [],
            isTeamNameValid: false,
            validationError: "",
        }),
        []
    );

    const setupTeam = useCallback(
        async (form: CreateTeamFormData) => {
            if (form.gameId === undefined) {
                showError(t("components.team.missing_game_type"));
                return;
            }

            if (!form.name) {
                showError(t("components.team.missing_valid_name"));
                return;
            }

            const result = await createTeam({
                gameId: form.gameId,
                organizationId: organizationId,
                name: form.name,
                shortHandle: form.shortHandle,
                invitedPlayerIds: form.selectedPlayers?.map((x) => x.id) ?? [],
                image: form.image,
            });

            if (result.success && result.data) {
                return result.data;
            }

            return undefined;
        },
        [organizationId, t]
    );

    const isValid = useCallback(
        (form: CreateTeamFormData) => Boolean(form?.name && form?.isTeamNameValid && form?.gameId !== undefined),
        []
    );

    return {
        setupTeam,
        isValid,
        getEmptyForm,
    };
};

export const CreateTeamPage = () => {
    const { t } = useTranslation(["common", "translation", "validation"]);
    const { getQueryParam } = useQuery();
    const history = useHistory();

    const { setupTeam, isValid } = useCreateTeam();
    const gameId = getQueryParam("gameId");
    const organizationId = getQueryParam("organizationId");
    const returnUrl = getQueryParam("return");

    const handleCreate = async () => {
        const teamId = await setupTeam(form);
        if (!teamId) {
            return;
        }

        if (returnUrl) {
            history.push(returnUrl);
        } else {
            history.push(
                generateLink("TEAM_ROUTES.TEAM", {
                    id: teamId,
                })
            );
        }
    };

    const [form, setForm] = useState<CreateTeamFormData>({
        gameId: gameId,
        name: "",
        shortHandle: "",
        image: undefined,
        selectedPlayers: [],
        isTeamNameValid: false,
        validationError: "",
    });

    if (!isSignedIn()) {
        return <Redirect to={BASE_URL} />;
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "3rem" }}>
            <Intro
                header={t("common:button_actions.create_a_new_team")}
                subheader={t("translation:components.team.create_team.start_playing_tournaments")}
            />
            <CreateTeamComponent
                enableGameSelector={!gameId}
                enablePlayerSelector={!organizationId}
                formData={form}
                onChange={setForm}
                saveButton={() => (
                    <SpinnerButton
                        disabled={!isValid(form)}
                        variant="contained"
                        sx={{ marginTop: "1rem" }}
                        onClick={handleCreate}
                    >
                        {t("translation:components.team.create_team.set_it_up")}
                    </SpinnerButton>
                )}
            />
        </Box>
    );
};

export interface CreateTeamFormData {
    gameId?: string | undefined;

    image?: Blob | undefined;
    name?: string;
    shortHandle?: string;
    selectedPlayers?: SelectedPlayer[];

    isTeamNameValid?: boolean;
    validationError?: string | undefined;
}

interface CreateTeamComponentProps {
    enableGameSelector?: boolean;
    enablePlayerSelector?: boolean;
    formData: CreateTeamFormData;
    saveButton?: JSXElementConstructor<unknown>;
    containerSx?: SxProps<Theme>;
    onChange: (formData: CreateTeamFormData) => void;
}
export const CreateTeamComponent = (props: CreateTeamComponentProps) => {
    const { t } = useTranslation(["common", "translation", "validation"]);
    const { formData: req, saveButton: SaveButton } = props;
    const user = useSelector((state: ApplicationState) => state.session.user);
    const handleChange = (val: CreateTeamFormData) => {
        props.onChange(val);
    };

    const gameSetup = useGame(req.gameId);
    const selectedPlayers = req.selectedPlayers ?? [];
    return (
        <CreateTeamContainer sx={props.containerSx}>
            <Box sx={{ padding: "0 1rem 1rem 1rem" }}>
                <EditableImage
                    uploadImageCommand="UploadTeamImageCommand"
                    allowEdit={true}
                    instantUpload={false}
                    dimensions={teamAvatarAspectRatio}
                    onImageChanged={({ imageId, image }) => handleChange({ ...req, image })}
                >
                    <Base64Image
                        blob={req.image}
                        fallbackSrc={req.name ? getTeamGravatarUrl(req.name) : DefaultTeamAvatar}
                        sx={{ width: 128, height: 128 }}
                    />
                </EditableImage>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", padding: "0 1rem", width: 1, gap: "1rem" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                    <TeamNameInput
                        name={req.name}
                        onChanged={(value, valid) => {
                            handleChange({ ...req, name: value, isTeamNameValid: valid });
                        }}
                    />
                    {gameSetup?.settings.game === GameType.PUBG && (
                        <ShortHandle
                            value={req.shortHandle ?? ""}
                            onChange={(shortHandle) => handleChange({ ...req, shortHandle })}
                            InputProps={{ fullWidth: true }}
                        />
                    )}
                </Box>
                {props.enableGameSelector && (
                    <GameSelector
                        fullWidth
                        label={t("common:shared.game")}
                        gameId={req.gameId}
                        onChanged={(gameId) => handleChange({ ...req, gameId })}
                    />
                )}

                {props.enablePlayerSelector && (
                    <>
                        <PlayerSearchAutocomplete
                            currentPlayerId={user?.id}
                            selectedPlayers={selectedPlayers}
                            clearOnSelect
                            onSelected={(player) => {
                                handleChange({ ...req, selectedPlayers: [...selectedPlayers, player] });
                            }}
                        />

                        {selectedPlayers.length > 0 && (
                            <List>
                                {selectedPlayers.map((player, idx) => {
                                    return (
                                        <PlayerListItem
                                            key={player.id}
                                            player={{
                                                id: player.id,
                                                nickName: player.nickName,
                                                avatarUrl: player.avatarUrl,
                                                gameAccounts: [],
                                            }}
                                            listItemSecondaryAction={
                                                <IconButton
                                                    onClick={() =>
                                                        handleChange({
                                                            ...req,
                                                            selectedPlayers: [
                                                                ...selectedPlayers.filter((x) => x.id !== player.id),
                                                            ],
                                                        })
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            }
                                        />
                                    );
                                })}
                            </List>
                        )}
                    </>
                )}
                {SaveButton && <SaveButton />}
            </Box>
        </CreateTeamContainer>
    );
};

const CreateTeamContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
    },
}));
