import { EmptyTeamBox } from "@components/competitions/tournament/formats/common/EmptyTeamBox";
import type { MatchSeriesTeam, MatchType } from "@components/matches";
import { MatchViewMode } from "@components/matches";
import type { IUser } from "@masterblaster/api";
import { GameMode } from "@masterblaster/api";
import { TeamAvatar } from "@masterblaster/basics";
import { useIsMobile } from "@masterblaster/theme";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { generateLink } from "@utils/generateLink";
import _ from "lodash";
import type { FC, JSXElementConstructor, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export type MatchTeamsProps = {
    gameMode: GameMode;
    teams: MatchSeriesTeam[];
    user: IUser | undefined;
    viewMode: MatchViewMode;
    matchType: MatchType;
    centerPiece: ReactNode;
};

export const MatchTeams: FC<MatchTeamsProps> = ({ gameMode, teams, user, viewMode, matchType, centerPiece }) => {
    const orderedTeams = _.orderBy(teams, (x) => x.slot);
    const userTeam = teams.find((team) => team.players.some((player) => player.playerId === user?.id)) ?? teams[0];

    switch (gameMode) {
        case GameMode.Head2Head:
            return (
                <Head2HeadTeams
                    teamA={orderedTeams[0]}
                    teamB={orderedTeams[1]}
                    centerPiece={centerPiece}
                    viewMode={viewMode}
                    matchType={matchType}
                />
            );
        case GameMode.FreeForAll:
            return (
                <BattleRoyaleTeams
                    userTeam={userTeam}
                    viewMode={viewMode}
                    centerPiece={centerPiece}
                    teamsInGroup={teams.length - 1}
                />
            );
        default:
            return null;
    }
};

interface Head2HeadTeamsProps {
    teamA: MatchSeriesTeam;
    teamB?: MatchSeriesTeam;
    viewMode: MatchViewMode;
    matchType: MatchType;
    centerPiece: ReactNode;
}
export const Head2HeadTeams: FC<Head2HeadTeamsProps> = ({ teamA, teamB, viewMode, matchType, centerPiece }) => {
    return (
        <>
            <CardTeamBox
                matchTeam={teamA}
                viewMode={viewMode}
                emptyTeam={({ matchTeam }) => (
                    <EmptyTeamBox matchSeriesTeam={matchTeam} viewMode={viewMode} matchType={matchType} />
                )}
            />
            {centerPiece}
            <CardTeamBox
                matchTeam={teamB}
                viewMode={viewMode}
                reverse
                emptyTeam={({ matchTeam }) => (
                    <EmptyTeamBox matchSeriesTeam={matchTeam} viewMode={viewMode} matchType={matchType} />
                )}
            />
        </>
    );
};

interface BattleRoyaleTeamsProps {
    userTeam: MatchSeriesTeam;
    viewMode: MatchViewMode;
    teamsInGroup: number;
    centerPiece: ReactNode;
}
export const BattleRoyaleTeams: FC<BattleRoyaleTeamsProps> = ({ userTeam, viewMode, teamsInGroup, centerPiece }) => {
    const { t } = useTranslation("common");
    const isMobile = useIsMobile();

    return (
        <>
            <CardTeamBox
                matchTeam={userTeam}
                viewMode={viewMode}
                emptyTeam={(matchTeam) => (
                    <>
                        <TeamName>{t("shared.teams")}</TeamName>
                        <TextTeamAvatar>{Math.max(teamsInGroup, 0) ?? "?"}</TextTeamAvatar>
                    </>
                )}
            />
            {centerPiece}
            <CardTeamBox
                matchTeam={undefined}
                viewMode={viewMode}
                reverse={!isMobile}
                emptyTeam={(matchTeam) => (
                    <>
                        <TeamName>{t("shared.teams")}</TeamName>
                        <TextTeamAvatar>{Math.max(teamsInGroup, 0) ?? "?"}</TextTeamAvatar>
                    </>
                )}
            />
        </>
    );
};

export const CardTeamBox = ({
    matchTeam,
    reverse,
    viewMode,
    emptyTeam: EmptyTeam,
}: {
    matchTeam: MatchSeriesTeam | undefined;
    reverse?: boolean;
    viewMode?: MatchViewMode;
    emptyTeam?: JSXElementConstructor<{ matchTeam: MatchSeriesTeam | undefined; viewMode: MatchViewMode | undefined }>;
}) => {
    const team = matchTeam?.team;
    const isTeamVisible =
        viewMode === undefined ? true : viewMode === MatchViewMode.Normal || viewMode === MatchViewMode.AdminSeeding;

    return (
        <TeamBox reverse={reverse}>
            {team && isTeamVisible && (
                <>
                    <TeamLink
                        title={team.name}
                        to={generateLink("TEAM_ROUTES.TEAM", {
                            id: team.id,
                        })}
                    />
                    <TeamName>{team.name}</TeamName>
                    <TeamAvatar size="2rem" team={team} />
                </>
            )}
            {(!team || !isTeamVisible) && EmptyTeam && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        margin: "0 1rem",
                        height: "40px",
                    }}
                >
                    <EmptyTeam matchTeam={matchTeam} viewMode={viewMode} />
                </Box>
            )}
        </TeamBox>
    );
};

const TeamBox = styled("div", {
    shouldForwardProp: (prop) => prop !== "reverse",
})<{ reverse?: boolean }>(({ reverse }) => ({
    flex: "1 0",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: reverse ? "start" : "end",
    flexDirection: reverse ? "row-reverse" : "row",
    borderRadius: reverse ? "20px 0 0 20px" : "0 20px 20px 0",
    overflow: "hidden",
    gap: "0.5rem",
}));

const TeamLink = styled(Link)({
    position: "absolute",
    zIndex: 1,
    inset: 0,
});

const TeamName = styled("p")(({ theme }) => ({
    margin: 0,
    color: theme.colors.white,
    textDecoration: "none",
    fontSize: "0.875rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
}));

const TextTeamAvatar = styled("div")(({ theme }) => ({
    width: "2.5rem",
    height: "2.5rem",
    flexShrink: 0,
    boxSizing: "border-box",
    borderRadius: "50%",
    backgroundColor: theme.colors.greys[10],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.875rem",
    fontWeight: "bold",
    lineHeight: "1.375rem",
    color: theme.colors.white,
}));
