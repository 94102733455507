import type { IUser } from "@masterblaster/api";
import type { LoginResponseData, UserResourceAclEntry } from "./types";

export const getItem = (key: string) => {
    try {
        if (!window.localStorage) {
            return null;
        }

        return window.localStorage.getItem(key);
    } catch (err) {
        return null;
    }
};

export const setItem = (key: string, value: string) => {
    try {
        if (!window.localStorage) {
            return null;
        }

        window.localStorage.setItem(key, value);
    } catch (err) {}
};

const removeItem = (key: string) => {
    if (!window.localStorage) {
        return null;
    }

    window.localStorage.removeItem(key);
};

export class TokenStore {
    static storageKey = "token";
    static userKey = "currentUser";
    static acls = "acls";

    static hasImpersonationContext() {
        return getItem(`actual_${this.storageKey}`) !== null;
    }

    static impersonate(data: LoginResponseData) {
        const token = getItem(`actual_${this.storageKey}`);
        if (!token) {
            setItem(`actual_${this.storageKey}`, getItem(this.storageKey) ?? "");
            setItem(`actual_${this.acls}`, getItem(this.acls) ?? "");
            setItem(`actual_${this.userKey}`, getItem(this.userKey) ?? "");
        }

        this.set(data.token, data.user, data.acls);
    }

    static endImpersonation() {
        const token = getItem(`actual_${this.storageKey}`);
        const acls = getItem(`actual_${this.acls}`);
        const user = getItem(`actual_${this.userKey}`);

        if (!token || !acls || !user) {
            throw new Error("Invalid context");
        }

        removeItem(`actual_${this.storageKey}`);
        removeItem(`actual_${this.acls}`);
        removeItem(`actual_${this.userKey}`);

        setItem(this.storageKey, token);
        setItem(this.acls, acls);
        setItem(this.userKey, user);
    }

    static set(token: string, user: IUser, acls: UserResourceAclEntry[]) {
        this.setToken(token);
        this.setUser(user);
        this.setAcl(acls);
    }

    static unset(): void {
        this.removeToken();
        this.removeUser();
        removeItem(this.acls);
    }

    static getToken() {
        return getItem(TokenStore.storageKey);
    }

    static setToken(token: string) {
        setItem(this.storageKey, token);
    }

    static removeToken(): void {
        removeItem(this.storageKey);
    }

    static removeUser(): void {
        removeItem(this.userKey);
        removeItem(this.acls);
    }

    static getUser(): IUser | null {
        const userString = getItem(this.userKey);
        if (!userString) {
            return null;
        }
        const user: IUser = JSON.parse(userString) as IUser;

        return user;
    }

    static setAcl(acls: UserResourceAclEntry[]) {
        const json = JSON.stringify(acls);
        setItem(this.acls, json);
    }

    static setUser(user: IUser | null): void {
        if (user === null) {
            this.removeUser();
            return;
        }

        const userJson = JSON.stringify(user);
        setItem(this.userKey, userJson);
    }
}

export default TokenStore;
