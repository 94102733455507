import type { IUser } from "@masterblaster/api";
import type { LoginResponse, UserResourceAclEntry, VerifyCodeResponse } from "@mb/auth";
import { TokenStore, autoSignIn, getCurrentUser, isSignedIn, signIn, verifyTwoFactor } from "@mb/auth";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type LoginOptions = {
    email: string;
    password: string;
};

type VerifyCodeOptions = {
    email: string;
    code: string;
};

const setUser = createAsyncThunk("user/setUser", async (_, thunkAPI) => {
    const isUserSignedIn = isSignedIn();

    if (!isUserSignedIn) thunkAPI.rejectWithValue(null);

    try {
        const response = await getCurrentUser();
        return response;
    } catch {
        return thunkAPI.rejectWithValue(null);
    }
});

const autoLogin = createAsyncThunk<LoginResponse | undefined, string>("user/autoLogin", async (uuid, thunkAPI) => {
    const isUserSignedIn = isSignedIn();

    if (isUserSignedIn) thunkAPI.rejectWithValue(undefined);

    try {
        const response = await autoSignIn(uuid);
        return response;
    } catch {
        return thunkAPI.rejectWithValue(undefined);
    }
});

const login = createAsyncThunk("user/login", async ({ email, password }: LoginOptions, thunkAPI) => {
    try {
        const response = await signIn(email, password);
        return response;
    } catch {
        return thunkAPI.rejectWithValue(undefined);
    }
});

const verifyUser = createAsyncThunk<VerifyCodeResponse | undefined, VerifyCodeOptions>(
    "user/verifyUser",
    async ({ email, code }, thunkAPI) => {
        const isUserSignedIn = isSignedIn();

        if (isUserSignedIn) thunkAPI.rejectWithValue(undefined);

        try {
            const response = await verifyTwoFactor(email, code);
            return response;
        } catch {
            return thunkAPI.rejectWithValue(undefined);
        }
    }
);

export interface SessionState {
    user: IUser | undefined;
    acl: UserResourceAclEntry[];
    token: string | undefined;
    isPendingVerification?: boolean;
}

const initialState: SessionState = {
    user: undefined,
    acl: [],
    token: TokenStore.getToken() ?? undefined,
};

export const sessionSlice = createSlice({
    name: "session",
    initialState,
    reducers: {
        logout() {
            TokenStore.unset();
            return {
                ...initialState,
                token: undefined,
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setUser.fulfilled, (state, action) => {
            state.user = action.payload?.user;
            state.acl = action.payload?.acl ?? [];
        });
        builder.addCase(autoLogin.fulfilled, (state, { payload }) => {
            if (payload?.success && payload?.data) {
                state.user = payload.data.user;
                state.acl = payload.data.acls;
                state.isPendingVerification = payload.data.isPendingVerification;
                state.token = payload.data.token;
            }
        });
        builder.addCase(login.fulfilled, (state, { payload }) => {
            if (payload?.success && payload?.data) {
                state.user = payload.data.user;
                state.acl = payload.data.acls;
                state.isPendingVerification = payload.data.isPendingVerification;
                state.token = payload.data.token;
            }
        });
        builder.addCase(verifyUser.fulfilled, (state, { payload }) => {
            if (payload?.success && payload?.data) {
                state.user = payload.data.user;
                state.acl = payload.data.acls;
                state.isPendingVerification = payload.data.isPendingVerification;
                state.token = payload.data.token;
            }
        });
    },
});

export const actions = {
    setUser,
    autoLogin,
    login,
    verifyUser,
    ...sessionSlice.actions,
};
