import { styled } from "@mui/material/styles";

export const MenuContainer = styled("div", {
    shouldForwardProp: (prop) => prop !== "active",
})<{ active?: boolean }>(({ theme, active }) => ({
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",
    boxSizing: "border-box",
    padding: "0 0.4rem",
    backgroundColor: active ? theme.colors.greys[12] : theme.colors.greys[14],
}));
