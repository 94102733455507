import { createContext, memo, useCallback, useContext, useState, type FC, type PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import type { SpinnerButtonProps } from "../buttons/SpinnerButton";
import { SpinnerButton } from "../buttons/SpinnerButton";

export const DrawerContext = createContext<{
    changeKeys: string[];
    hasUnsavedData: boolean;
    clear: () => void;
    hasChanges: (key: string) => boolean;
    setKeyChanged: (key: string) => void;
    clearKeyChanged: (key: string) => void;
    closeDrawer: (open: boolean) => Promise<boolean>;
}>(undefined!);

export const useDrawerContext = (key?: string) => {
    const context = useContext(DrawerContext);
    const keyChanged = useCallback(() => (key ? context.setKeyChanged(key) : undefined), [context, key]);
    const clearChanged = useCallback(() => (key ? context.clearKeyChanged(key) : undefined), [context, key]);
    // const SaveButton = useMemo(
    //     (props: Omit<DrawerSaveButtonProps, "changeKey">) => (key ? <DrawerSaveButton changeKey={key} /> : null),
    //     [key]
    // );

    // eslint-disable-next-line react/display-name
    const SaveButton = memo((props: Omit<DrawerSaveButtonProps, "changeKey">) =>
        key ? <DrawerSaveButton changeKey={key} {...props} /> : null
    );

    return {
        ...context,
        keyChanged,
        clearChanged,
        SaveButton,
    };
};

export const DrawerContextProvider: FC<
    PropsWithChildren<{ showConfirmDialog: (title: string, text: string) => Promise<boolean> }>
> = (props) => {
    const [changes, setChanges] = useState<string[]>([]);
    const { t } = useTranslation("translation", {
        keyPrefix: "basics.drawers.drawer_context",
    });

    const onClear = () => setChanges([]);
    const onHasChanges = (key: string) => {
        if (!changes.some((x) => x === key)) {
            const newKeys = [...changes, key];
            setChanges(newKeys);
        }
    };

    const onSavedChanges = (key: string) => {
        setChanges((c) => c.filter((x) => x !== key));
    };

    const onCloseDrawer = async (open: boolean) => {
        if (open && changes.length > 0) {
            const confirmed = await props.showConfirmDialog(t("confirm_dialog_title"), t("confirm_dialog_description"));
            if (!confirmed) {
                return false;
            } else {
                onClear();
            }
        }

        return true;
    };
    return (
        <DrawerContext.Provider
            value={{
                changeKeys: changes,
                hasUnsavedData: changes.length > 0,
                hasChanges: (key: string) => changes.some((x) => x === key),
                setKeyChanged: onHasChanges,
                clearKeyChanged: onSavedChanges,
                clear: onClear,
                closeDrawer: onCloseDrawer,
            }}
        >
            {props.children}
        </DrawerContext.Provider>
    );
};

interface DrawerSaveButtonProps extends SpinnerButtonProps {
    changeKey: string;
    onClick: () => Promise<any>;
}
export const DrawerSaveButton = (props: DrawerSaveButtonProps) => {
    const { changeKey, onClick, ...rest } = props;
    const { hasChanges, clearKeyChanged } = useDrawerContext();
    const { t } = useTranslation("common", {
        keyPrefix: "button_actions",
    });

    if (!hasChanges(changeKey)) {
        return null;
    }

    return (
        <SpinnerButton
            variant="contained"
            color="primary"
            size="small"
            onClick={async () => {
                await onClick();
                clearKeyChanged(changeKey);
            }}
            {...rest}
        >
            {t("save")}
        </SpinnerButton>
    );
};
