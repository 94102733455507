import { lighten, darken, alpha } from "@mui/material";

interface Palette {
    name: string;
    color: string;
    step: number;
    count?: number;
}

export const generateColorPalette = (palette: Palette) => {
    const n = palette.count ? [...Array(palette.count).keys()] : [...Array(1 / palette.step).keys()];
    const step = palette.count ? 1 / palette.count : palette.step;
    const steps = [
        1 / step,
        ...n.reverse(),
        ...n
            .filter((x) => x > 0)
            .map((x) => x * -1)
            .reverse(),
        -1 / step,
    ];

    return steps.map((x) => {
        const tone = Math.abs(x * step);
        const color = x > 0 ? lighten(palette.color, tone) : darken(palette.color, tone);
        return {
            color,
            step: x,
        };
    });
};

const commonColors = {
    white: "#FFFFFF",
    black: "#383B4C",

    grey_base: "#50556C",
    green_base: "#73CF2A",
    red_base: "#E82E2E",
    warning_base: "#FFD600",
    pink_base: "#EC008E",

    accent_base: "#745E11",
    accent_gradient_color_1: "#282B36",
    accent_gradient_color_2: "#6C1026",

    yellow_base: "#FFC700",

    button_focused_shadow: "0px 0px 0px 1px #181920, 0px 0px 0px 3px #FF52C2;",
    button_hover_shadow: "0px 0px 2px rgba(255, 255, 255, 0.5);",

    main_color: "#B9BBC4",
    important_color: "#F58400",

    /*
        Prizes
    */
    gold: "#c08329",
    silver: "#9d9d9d",
    bronze: "#6D4000",

    /*
        Player roles
    */
    teamLeader: "#6C77D8",
    player: "#6CA4D8",
    substitute: "#50556C",
    player_offline: "#767676",
    player_online: "#008700",
    player_ready: "#EC008E",

    /*
        Social badge
    */
    facebook: "#1DA1F2",
    instagram: "#1DA1F2",
    google: "#1DA1F2",
    twitter: "#1DA1F2",
    twitch: "#7289DA",
    discord: "#7289DA",
    vk: "#4a76a8",
    steam: "#7bae34",

    /*
        Fraym coins
    */
    fraymcoin: "#ffc12a",

    /*
        Forms
    */
    success: "#36BA2C",
    error: "#EC5858",
    error_gradient: `linear-gradient(86.21deg, #5C2D30 3.11%, #8B3B3D 96.9%)`,

    /*
        Match status
    */
    match_starting: "#36BA2C",
    match_started: "#0081ff",

    /*
        Tournament cards
    */
    tournament_card_gradient_start: "rgba(40,42,54, 1)",
    tournament_card_gradient_end: "rgba(0,0,0, 0)",
};

export const palettes = [
    { name: "grey", color: commonColors.grey_base, step: 0.1 },
    { name: "green", color: commonColors.green_base, step: 0.2 },
    { name: "red", color: commonColors.red_base, step: 0.2 },
    { name: "warning", color: commonColors.warning_base, step: 0.2 },
    { name: "yellow", color: commonColors.yellow_base, count: 3 },
] as Palette[];

const greys = generateColorPalette(palettes[0]);
const greens = generateColorPalette(palettes[1]);
const reds = generateColorPalette(palettes[2]);
const warnings = generateColorPalette(palettes[3]);
const yellows = generateColorPalette(palettes[4]);

export const darkModeColors = {
    ...commonColors,

    /*
        Palettes
    */
    greys: greys.map((x) => x.color),
    reds: reds.map((x) => x.color),
    greens: greens.map((x) => x.color),
    warnings: warnings.map((x) => x.color),
    yellows: yellows.map((x) => x.color),

    primary_text: "#ffffff",
    secondary_text: "#858898",
    disabled_text: greys[9].color,

    background: "#0D0E12",
    secondary_background: "#13141B",
    contrast_background: "#13141B",
    darkgray_background: "#EC008E",

    white: commonColors.white,
    black: commonColors.black,

    shadow_color: "rgb(0, 0, 0)",
    /*
        Links
    */
    link_foreground_color: "#41DDFF",

    /*
        Inputs
    */
    input_background_color: "#383B4C",
    input_foreground_color: "#FFFFFF",
    input_border_color: "#404456",

    /*
        Borders
    */
    border: "#282A36",

    /*
        MatchBox
    */
    matchbox_gradient: "#50556C",

    /*
        Prizes
    */
    prize_color: commonColors.yellow_base,

    /*
        Gradients
    */

    accent_text: `linear-gradient(266.21deg, ${commonColors.pink_base} 2.76%, ${commonColors.yellow_base} 96.55%)`,

    accent_gradient_light: `linear-gradient(266.21deg, ${commonColors.pink_base} 2.76%, #FFC700 96.55%)`,
    accent_gradient_medium: `linear-gradient(266.21deg, #B8006E 2.76%, #A86815 96.55%)`,
    // accent_gradient_dark: `linear-gradient(90deg, #6C1048 4%, ${commonColors.accent_base} 97%)`,
    accent_gradient_dark: `linear-gradient(86.21deg, #745E11 3.11%, #6C1048 96.9%);`,
    accent_gradient_1: `linear-gradient(86.21deg, ${alpha("#FFC700", 0.14)} 3.11%, ${alpha(
        commonColors.pink_base,
        0
    )} 96.89%)`,
    accent_gradient_2: `linear-gradient(90deg, ${alpha(commonColors.accent_gradient_color_1, 0.05)} 0%, ${alpha(
        commonColors.accent_gradient_color_2,
        0.25
    )} 15.63%, ${commonColors.accent_base} 50.52%, ${alpha(commonColors.accent_gradient_color_2, 0.25)} 83.85%, ${alpha(
        commonColors.accent_gradient_color_1,
        0.05
    )} 100%)`,

    green_gradient_1: `linear-gradient(90deg, ${greens[8].color} 0%, ${greens[9].color} 100%);`,
    green_gradient_2: `linear-gradient(90deg, ${alpha(greens[7].color, 0.05)} 0%, ${alpha(
        greens[7].color,
        0.12
    )} 15.63%, ${greens[7].color} 50.52%, ${alpha(greens[7].color, 0.12)} 83.85%, ${alpha(
        greens[7].color,
        0.05
    )} 100%);`,

    grey_gradient_1: `linear-gradient(180deg, ${greys[13].color} 0%, ${greys[16].color} 100%)`,
    grey_gradient_2: `linear-gradient(90deg, ${greys[13].color} 0%, ${greys[16].color} 100%)`,
    grey_gradient_3: `linear-gradient(90deg, ${greys[15].color} 0%, ${greys[12].color} 49.48%, ${greys[15].color} 100%)`,

    red_gradient_1: `linear-gradient(90deg, #282A36 0%, #704646 49.48%, #282A36 100%)`,

    white_gradient_1: `linear-gradient(90deg, ${alpha(commonColors.white, 0)} 0%, ${alpha(
        commonColors.white,
        0.1
    )} 50.52%, ${alpha(commonColors.white, 0)} 100%)`,
    white_gradient_2: `linear-gradient(90deg, ${alpha(commonColors.white, 0)} 0%, ${alpha(
        commonColors.white,
        0.3
    )} 100%)`,
    white_gradient_3: `linear-gradient(90deg, ${alpha(commonColors.white, 0.3)} 0%, ${alpha(
        commonColors.white,
        0
    )} 100%)`,
};

export type ColorsType = typeof darkModeColors;

// TODO: remove the use of localStorage as state. This will be refactored to use redux state.
// const storageColors = localStorage.getItem("colors");
// let tenantDefined = storageColors ? (JSON.parse(storageColors) as ColorsType) : undefined;
// export const hasTenantDefinedColors = () => tenantDefined !== undefined;
// export const setColors = (colors: ColorsType) => {
//     tenantDefined = colors;
//     localStorage.setItem("colors", JSON.stringify(colors));
// };
// export const resetColors = () => {
//     tenantDefined = undefined;
//     localStorage.removeItem("colors");
// };

// export const tenantDefinedColors = { ...darkModeColors, ...tenantDefined };
export const borderRadius = 8;
export const borderWidth = 2;
export const boxShadow = "0px 3px 10px #00000030";
