export enum SearchV2ResultObjectType {
    Player = 10,
    Organization = 20,
    Competition = 30,
    League = 40,
    Tournament = 50,
    Match = 60,
    Team = 70,
    Community = 80,
    FragTrial = 90,
}

// SearchV2PlayerResult is the result of a search made by a user (non-admin)
export interface SearchV2Result {
    id: string;
    objectId: string;
    objectType: SearchV2ResultObjectType;
    objectName: string;
    avatarUrl: string | undefined;
    displayText: string;
    searchableProperties: Record<string, string>;
}
