import { DrawerContext, MbSelect, DrawerSaveButton, ButtonContainer } from "@masterblaster/basics";
import { Box, MenuItem } from "@mui/material";
import { sendCommand } from "@masterblaster/api";
import { useContext, useEffect, useState } from "react";
import type { MatchSeries } from "../api/MatchSeries";
import { getMatchStatusEnums, MatchStatus } from "../api/MatchSeries";

export const MatchStatusSelector = ({ matchSeries }: { matchSeries: MatchSeries }) => {
    const [status, setStatus] = useState(matchSeries.status ?? MatchStatus.NotStarted);

    const { hasChanges, setKeyChanged, clearKeyChanged } = useContext(DrawerContext);
    const changeKey = "MatchStatus";

    const changed = hasChanges(changeKey);
    useEffect(() => {
        if (!changed) {
            setStatus(matchSeries.status);
        }
    }, [changed, matchSeries.status]);

    const handleUpdateStatus = async () => {
        const result = await sendCommand("UpdateMatchSeriesStatus", { matchSeriesId: matchSeries.id, status });
        if (result.success) {
            clearKeyChanged(changeKey);
        }
    };

    const options = getMatchStatusEnums(MatchStatus);

    return (
        <Box sx={{ display: "flex", flexDirection: "column " }}>
            <MbSelect
                value={status}
                onChange={(event) => {
                    setKeyChanged(changeKey);
                    setStatus(event.target.value as MatchStatus);
                }}
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.text}
                    </MenuItem>
                ))}
            </MbSelect>
            {changed && (
                <ButtonContainer sx={{ marginTop: "1rem" }}>
                    <DrawerSaveButton changeKey={changeKey} onClick={handleUpdateStatus} />
                </ButtonContainer>
            )}
        </Box>
    );
};
