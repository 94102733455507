import type { CompetitionFormat, GameType } from "@masterblaster/api";

export enum CompetitionStatus {
    None = 0,
    Created = 2,
    Started = 16,
    Completed = 32,
    Stopped = 64,
}

export enum CompetitionImageType {
    Image = 1,
    Sponsor = 2,
}

export interface IPrize {
    id: string;
    rank: number;
    amount: number;
    description: string;
    image?: {
        imageId: string;
        originalImageId?: string;
    };
}

export interface CompetitionImage {
    imageType: CompetitionImageType;
    imageId: string;
    originalImageId?: string;
}

export interface CompetitionEmailConfig {
    welcomeEmailTemplateId?: string;
    orderSummaryEmailTemplateId?: string;
    teamInviteEmailTemplateId?: string;
}

export interface CompetitionSchedule {
    isScheduled: boolean;
    scheduledTime: string | undefined;
    registrationCloses: string | undefined;
    checkInOpens: string | undefined;
    checkInCloses: string | undefined;
}

export interface TeamSetup {
    enableManageTeamAfterStart: boolean;
    teamSize: number;
    numberOfSubstitutes: number;
    maxTeams: number | undefined;
}

export interface PrizeConfiguration {
    prizePoolDescription: string;
    prizes: IPrize[];
}

export enum CompetitionBuyInMode {
    Free = 0,
    BuyIn = 10,
}

export enum CompetitionAccessMode {
    NotSet = 0,
    Public = 1,
    Private = 2,
    League = 3,
}

export interface CompetitionAccessSetupDto {
    mode: CompetitionAccessMode;
}

export interface VetoConfiguration {
    useVeto: boolean;
    useVetoSide: boolean;
    useSnakeOrder: boolean;
    useAutoVeto: boolean;
    timeToVetoFirstMapInSeconds: number;
    timeToVetoInSeconds: number;
    mapPoolId: string | undefined;
}

export interface SwissConfiguration {
    maxNumberOfRounds: number | undefined;
    maxTierJumps: number;
    acceleratedPairing: boolean;
    replayAllowed: boolean;
}

export interface CompetitionConfiguration {
    grandFinalAdvantage?: number;
    swiss: SwissConfiguration;
}

export interface CompetitionType {
    // 10 = Tournament, 20 = League
    id: number;
    name: string;
}

export interface CompetitionDto {
    id: string;
    game: GameType;
    competitionType: CompetitionType;
    gameId: string;
    scoreConfigId: string | undefined;
    name: string;
    parentLeagueId: string | undefined;
    parentLeagueName: string | undefined;
    information: string;
    communityOwnerId: string | undefined;
    status: CompetitionStatus;
    teamSetup: TeamSetup;
    images: CompetitionImage[];
    access: CompetitionAccessSetupDto;
    email: CompetitionEmailConfig;
    checkIn: { enableAutomaticCheckIn: boolean };
    published: boolean;
    publishAt: string | undefined;
    buyInMode: CompetitionBuyInMode;
    prizeConfiguration: PrizeConfiguration;
    featured: boolean;
    frontPage: boolean;
    platforms: string[];
    created: string | null;
    supportLink: string;
}

export interface ReadyUpConfiguration {
    enableForfeitTimer: boolean;
    timeToReadyUpBeforeForfeitInSeconds: number | undefined;
}

export interface TournamentDto extends CompetitionDto {
    format: CompetitionFormat;
    config: CompetitionConfiguration;
    veto: VetoConfiguration;
    readyUp: ReadyUpConfiguration;
    schedule: CompetitionSchedule;
    hasBronzeFinal: boolean;
    gameMode: string;
    createdBy: string;
    standings: {
        teamId: string;
        rank: number;
    }[];
    validationMessages: string;

    isMatchReschedulingEnabled: boolean;
    lastPossibleMatchRescheduleDate: string | undefined;
}

export interface IPhoneNumberCountry {
    name: string;
    iso2: string;
    iso3: string;
    code: string;
    flagUrl: string;
}

export interface IFrozenFundsData {
    playerId: string;
    totalAmount: number;
}
