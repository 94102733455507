import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { MbSelect } from "@masterblaster/basics";
import { MenuItem } from "@mui/material";
import { getEnumList } from "@utils/enums";
import { TwitchTagState } from "@masterblaster/api";

export interface StreamStatusSelectorProps {
    value: TwitchTagState | undefined;
    onChanged?(status: TwitchTagState): void;
}

export const StreamStatusSelector: FC<StreamStatusSelectorProps> = ({ value, onChanged }) => {
    const { t } = useTranslation("translation", {
        keyPrefix: "components.stream.status_selector",
    });

    const rawOptions = getEnumList(TwitchTagState, (status) =>
        t(TwitchTagState[status].toLowerCase() as Lowercase<keyof typeof TwitchTagState>)
    );

    return (
        <MbSelect<number>
            displayEmpty
            value={value ?? -1}
            onChange={(e) => {
                onChanged?.(e.target.value as TwitchTagState);
            }}
            placeholder={{ label: t("show_all"), value: -1 }}
        >
            {rawOptions.map((x, idx) => {
                return (
                    <MenuItem key={idx} value={x.value}>
                        {x.text}
                    </MenuItem>
                );
            })}
        </MbSelect>
    );
};
