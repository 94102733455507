import type { TypographyProps } from "@mui/material";
import { styled, Typography } from "@mui/material";
import type { ElementType, ReactNode } from "react";
import type { TextSize } from "./utils";
import { getFontSize, getHeaderColor } from "./utils";

export interface HeaderProps {
    size?: TextSize;
    bold?: boolean;
    noMargins?: boolean;
    children?: ReactNode;
}

type HeaderType<C extends ElementType> = TypographyProps<C, { component?: C }> & HeaderProps;

export function Header<C extends ElementType>(props: HeaderType<C>) {
    return <HeaderInteral {...props}></HeaderInteral>;
}

const HeaderInteral = styled(({ size, bold, noMargins, ...rest }: HeaderProps & TypographyProps) => (
    <Typography {...rest} />
))<HeaderProps>(({ theme, size, bold, noMargins }) => ({
    color: getHeaderColor(size, theme.colors),
    fontSize: getFontSize(size),
    margin: noMargins ? 0 : "0.5rem 0",
    fontWeight: bold ? "bold" : "normal", //getHeaderWeight(size),
}));
