import type { MouseEvent } from "react";
import { Trans } from "react-i18next";
import { handleTeamJoinRequest } from "@components/team/TeamService";
import { getTeamGravatarUrl, GfLink } from "@masterblaster/basics";
import { generateLink } from "@utils/generateLink";
import { Notification } from "../Notification";
import { NotificationTitle } from "../shared";
import type { NotificationPayload } from "../NotificationService";
import type { NotificationElementProps } from "../NotificationElementProps";
import { getImageUrl } from "@masterblaster/api";

interface TeamJoinRequested extends NotificationPayload {
    teamId: string;
    teamName: string;
    bannerImageId: string | undefined;
    requestedById: string;
    requestedByNick: string;
}

export const NotificationTeamJoinRequest = ({ notification, context, navigateTo, t }: NotificationElementProps) => {
    const { requestedById, requestedByNick, teamId, bannerImageId, teamName } =
        notification.payload as TeamJoinRequested;
    const teamLink = generateLink("TEAM_ROUTES.OVERVIEW", {
        id: teamId,
    });

    // const hasSenderInfo = Boolean(requestedById && requestedByNick);
    const senderLink = requestedById
        ? generateLink("PLAYER_PROFILE_TAB_ROUTES.OVERVIEW", {
              id: requestedById,
          })
        : "";

    const handleActionClick = async (accepted: boolean) => {
        const result = await handleTeamJoinRequest(teamId, requestedById, accepted);

        if (result.success) {
            await context.markAsRead(notification.id);
        }
    };

    const handleNotificationClick = () => navigateTo(teamLink);
    const handleLinkClick = (link: string) => (event: MouseEvent<HTMLAnchorElement>) => {
        event.stopPropagation();
        navigateTo(link);
    };

    return (
        <Notification
            createdAt={notification.createdAt}
            isRead={!!notification.readAt}
            image={bannerImageId ? getImageUrl(bannerImageId) : getTeamGravatarUrl(teamName)}
            title={
                <NotificationTitle>
                    {!notification.readAt ? (
                        <span>
                            <Trans
                                ns="translation"
                                i18nKey="components.notifications.notification_type.team_join_request.player_team_join_request"
                                values={{ teamName, playerNickName: requestedByNick }}
                                components={[
                                    <GfLink underline key="0" to={senderLink} onClick={handleLinkClick(senderLink)} />,
                                    <GfLink underline key="1" to={teamLink} onClick={handleLinkClick(teamLink)} />,
                                ]}
                            />
                        </span>
                    ) : (
                        <span>
                            <Trans
                                ns="translation"
                                i18nKey="components.notifications.notification_type.team_join_request.player_team_join_request_reviewed"
                                values={{ teamName, playerNickName: requestedByNick }}
                                components={[
                                    <GfLink underline key="0" to={senderLink} onClick={handleLinkClick(senderLink)} />,
                                    <GfLink underline key="1" to={teamLink} onClick={handleLinkClick(teamLink)} />,
                                ]}
                            />
                        </span>
                    )}
                </NotificationTitle>
            }
            buttonActions={
                !notification.readAt
                    ? [
                          {
                              variant: "contained",
                              children: t("common:button_actions.accept"),
                              onClick: () => handleActionClick(true),
                          },
                          {
                              variant: "contained",
                              color: "secondary",
                              children: t("common:button_actions.decline", {
                                  defaultValue: "Decline",
                              }),
                              onClick: () => handleActionClick(false),
                          },
                      ]
                    : undefined
            }
            onClick={handleNotificationClick}
        />
    );
};
