import { TooltipIcon } from "@masterblaster/basics";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CompetitionTeamPlayerRole, getTournamentTeamPlayerRoleLabel } from "./TeamService";

export const RoleLabel = ({
    role,
    enableRoleDescription,
}: {
    role?: CompetitionTeamPlayerRole;
    enableRoleDescription?: boolean;
}) => {
    const { t } = useTranslation(["common", "translation"]);
    const roleDescription = useRoleDescription(role);

    if (!role) {
        return null;
    }

    const text = getTournamentTeamPlayerRoleLabel(role, t);

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                whiteSpace: "nowrap",
                color: (theme) => theme.colors.secondary_text,
            }}
        >
            {text}
            {enableRoleDescription && roleDescription && (
                <TooltipIcon title={roleDescription} iconSx={{ fontSize: "1rem" }} />
            )}
        </Box>
    );
};

const useRoleDescription = (role?: CompetitionTeamPlayerRole) => {
    const { t } = useTranslation("translation", {
        keyPrefix: "components.tournament.join.role_descriptions",
    });

    switch (role) {
        case CompetitionTeamPlayerRole.TeamLeader:
            return t("team_leader_desc");
        case CompetitionTeamPlayerRole.Player:
            return t("player_desc");
        case CompetitionTeamPlayerRole.Substitute:
            return t("substitues_desc");
        case CompetitionTeamPlayerRole.Coach:
            return t("coach_desc");
        default:
            return undefined;
    }
};
