import type { SubscriptionEvents, BroadcastEvents, UserEvents } from "./events";
import { getConnection } from "./signalRClient";
import { HubConnectionState } from "@microsoft/signalr";

type messageTypes = "TopicSubscription" | "UserSubscription" | "Broadcast";

const log = console.log;
const trace = console.trace;
const debug = (_message?: any, ..._optionalParams: any[]) => void 0;
// const debug = console.debug;

const subscriptionMap: { [key: string]: number | undefined } = {};

const getSubscription = (topic: string) => {
    const count = subscriptionMap[topic];
    return count === undefined ? 0 : count;
};
const addSubscription = (topic: string) => {
    const count = getSubscription(topic);
    subscriptionMap[topic] = count + 1;
};

const removeSubscription = (topic: string) => {
    const count = getSubscription(topic);
    const newCount = count - 1;
    subscriptionMap[topic] = newCount < 0 ? 0 : newCount;

    return newCount;
};

const unsubscribe = async (topic: string) => {
    const count = removeSubscription(topic);
    if (count > 0) {
        debug(`There are still subscriptions on topic '${topic}': count=${count}`);
        return;
    }

    const connection = await getConnection();
    if (connection.state === HubConnectionState.Connected) {
        await connection.send("Unsubscribe", topic);
        subscriptionMap[topic] = 0;
        debug(`Unsubscribed: topic=${topic}`);
    }
};

const getTopicKey = (topic: string, eventName: string) => (topic ? `${eventName}:${topic}` : eventName);

export const subscribeToTopic = <T>(
    topic: string | string[] | undefined,
    event: SubscriptionEvents | SubscriptionEvents[],
    callback: (payload: T, event?: SubscriptionEvents) => void
) => {
    const eventNames = Array.isArray(event) ? event : ([] as SubscriptionEvents[]).concat(event);
    const topics = Array.isArray(topic) ? topic : topic ? [topic] : [];

    const events = topics.flatMap((t) => {
        return eventNames.map((e) => ({
            topic: t,
            name: e,
            key: getTopicKey(t, e),
        }));
    });

    const invokeCallback =
        (key: string, eventName: string) =>
        (payload: T, messageType: messageTypes, topicKey: string, event: SubscriptionEvents) => {
            if (key === topicKey && messageType === "TopicSubscription") {
                log(
                    `Received topic event: subscribed topic=${key}, incoming topic=${topicKey}, messageType=${messageType}, event=${event}, payload=`,
                    payload
                );
                callback(payload, event);
            } else {
                debug(
                    `Topic doesn't match: subscribed topic=${key}, incoming topic=${topicKey}, messageType=${messageType}, event=${event}, payload=`,
                    payload
                );
            }
        };

    const callbacks = events.map((x) => {
        return {
            name: x.name,
            topicKey: x.key,
            invoker: invokeCallback(x.key, x.name),
        };
    });

    callbacks.forEach((x) => {
        debug(`Subscribing: topic=${x.topicKey}`);
        addSubscription(x.topicKey);
    });

    getConnection().then((connection) => {
        connection.onreconnected(() => {
            callbacks.forEach((x) => {
                debug(`Resubscribing: topic=${x.topicKey}`);
                connection.send("Subscribe", x.topicKey);
            });
        });

        callbacks.forEach((x) => {
            connection.send("Subscribe", x.topicKey);
            debug(`Subscribed: topic=${x.topicKey}, count=${getSubscription(x.topicKey)}`);
            connection.on(x.name, x.invoker);
        });
    });

    return () => {
        callbacks.forEach((x) => {
            unsubscribe(x.topicKey);
        });

        getConnection().then((connection) => {
            callbacks.forEach((x) => {
                connection.off(x.name, x.invoker);
            });
        });
    };
};

export const subscribeToBroadcastEvent = <T>(
    event: BroadcastEvents | BroadcastEvents[],
    callback: (payload: T, event?: BroadcastEvents) => void
) => {
    return subscribeToEvent("Broadcast", event, callback);
};

export const subscribeToUserEvent = <T>(
    event: UserEvents | UserEvents[],
    callback: (payload: T, event?: UserEvents) => void
) => {
    return subscribeToEvent("UserSubscription", event, callback);
};

const subscribeToEvent = <T, TEvent extends string>(
    filterOnMessageType: messageTypes,
    event: TEvent | TEvent[],
    callback: (payload: T, event?: TEvent) => void
) => {
    const names = Array.isArray(event) ? event : ([] as TEvent[]).concat(event);

    const invokeCallback = (name: string) => (payload: T, messageType: messageTypes, userId: string, event: TEvent) => {
        if (messageType === filterOnMessageType) {
            log(
                `Received ${messageType} event: name=${name}, messageType=${messageType}, userId=${userId}, event=${event}, payload:`,
                payload
            );
            callback(payload, event);
        } else {
            debug(
                `Message type not correct ${filterOnMessageType}, dropping: name=${name}, messageType=${messageType}, userId=${userId}, event=${event}, payload:`,
                payload
            );
        }
    };

    const callbacks = names.map((x) => ({
        name: x,
        invoker: invokeCallback(x),
    }));

    getConnection().then((connection) => {
        callbacks.forEach((x) => {
            debug(`Subscribing: type=${filterOnMessageType}, event=${x.name}`);
            connection.on(x.name, x.invoker);
        });
    });

    return () => {
        getConnection().then((connection) => {
            callbacks.forEach((x) => {
                debug(`Unubscribing: type=${filterOnMessageType}, event=${x.name}`);
                connection.off(x.name, x.invoker);
            });
        });
    };
};
