import type { DialogProps } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { SpinnerButton } from "@masterblaster/basics";
import { useTranslation } from "react-i18next";

interface ConfirmDialogProps {
    onClose: (result: boolean) => void;
    title: string;
    description: string;
    maxWidth?: DialogProps["maxWidth"];
}
export const ConfirmDialog = (props: ConfirmDialogProps) => {
    const { title, description, onClose, maxWidth, ...rest } = props;
    const { t } = useTranslation("common", {
        keyPrefix: "button_actions",
    });

    return (
        <Dialog
            open={true}
            fullWidth
            maxWidth={maxWidth ?? "sm"}
            onClose={() => onClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            {...rest}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent style={{ whiteSpace: "pre-wrap" }}>{description}</DialogContent>
            <DialogActions>
                <SpinnerButton variant="contained" color="secondary" onClick={() => onClose(false)}>
                    {t("cancel")}
                </SpinnerButton>
                <SpinnerButton variant="contained" color="primary" onClick={() => onClose(true)}>
                    {t("ok")}
                </SpinnerButton>
            </DialogActions>
        </Dialog>
    );
};
