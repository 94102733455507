import { Box, IconButton, List, ListItem, ListItemText } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { removeMatchSeriesTeam } from "../api/MatchApi";
import type { MatchSeries } from "../api/MatchSeries";
import { useTranslation } from "react-i18next";
import { showConfirmDialog } from "@utils/DialogHelpers";

export const MatchSeriesTeamsForSuperAdmins = ({ matchSeries }: { matchSeries: MatchSeries }) => {
    const [teams, setTeams] = useState(matchSeries.teams);
    const { t } = useTranslation(["common", "translation"]);

    useEffect(() => {
        setTeams(matchSeries.teams);
    }, [matchSeries.teams]);

    const onRemove = async (teamId: string) => {
        const confirmed = await showConfirmDialog(t("common:shared.remove_team"), t("common:shared.are_you_sure"));
        if (confirmed) {
            const result = await removeMatchSeriesTeam(matchSeries.id, teamId);
            if (result.success) {
                setTeams(teams.filter((x) => x.team?.id !== teamId));
            }
        }
    };

    return (
        <Box>
            <List dense={true}>
                {teams
                    .filter((x) => x.team)
                    .map((x) => {
                        return (
                            <ListItem
                                key={x.slot}
                                secondaryAction={
                                    <IconButton edge="end" aria-label="delete" onClick={() => onRemove(x.team!.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemText
                                    primary={
                                        x.team?.name ??
                                        t(
                                            "translation:components.tournament.match.drawer.match_series_teams_for_platform_admins.slot_number",
                                            {
                                                number: x.slot,
                                            }
                                        )
                                    }
                                />
                            </ListItem>
                        );
                    })}
            </List>
        </Box>
    );
};
