import { SpinnerButton } from "../buttons/SpinnerButton";
import type { ButtonProps } from "@mui/material";
import * as Sentry from "@sentry/browser";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileMetadata from "filepond-plugin-file-metadata";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
// import FilePondPluginImagePreview from "filepond-plugin-image-preview";
// import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import type { FilePondErrorDescription, FilePondFile, FilePondOptions } from "filepond";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import type { FC, PropsWithChildren, ReactElement } from "react";
import { Children, cloneElement, forwardRef, isValidElement, useCallback, useRef, useState } from "react";
import type { FilePondProps } from "react-filepond";
import { FilePond, registerPlugin } from "react-filepond";
import { useTranslation } from "react-i18next";
import { ShowErrorEvent, publishEvent } from "@masterblaster/pubsub";

// registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileEncode);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginImageResize);
// registerPlugin(FilePondPluginImageTransform);
registerPlugin(FilePondPluginFileMetadata);

interface FileUploadProps {
    uploadEndpoint: string;
    acceptFileTypes?: string[];
    resize?: {
        width?: number;
        height?: number;
        mode?: FilePondOptions["imageResizeMode"];
    };
    maxSize?: string;
    token: string | null;
    metadata?: { [key: string]: any };
    componentProps?: { filePond?: FilePondProps };
    onUploadingImage?(fileId: string): void;
    onUploadFailed?(error: string | undefined, fileId: string | undefined): void;
    onUploadCompleted?(imageId?: string, error?: string, filename?: string, fileId?: string): void;
    onFilesChanged?(files: FilePondFile[]): void;
}

export const useFileUpload = (props: FileUploadProps) => {
    const ref = useRef<FilePond>(null);
    const component = <FileUpload ref={ref} {...props} />;

    const addFile = useCallback(() => ref.current?.browse(), [ref]);
    const removeFile = useCallback((fileId: string) => ref.current?.removeFile(fileId), [ref]);
    const upload = useCallback(
        (metadata?: { [key: string]: any }) => {
            const files = ref.current?.getFiles();
            const metadataEntries = Object.entries(metadata ?? {});
            for (const file of files ?? []) {
                metadataEntries.forEach(([key, value]) => {
                    file.setMetadata(key, value);
                });
            }

            return ref.current?.processFiles();
        },
        [ref]
    );

    return {
        component,
        addFile,
        removeFile,
        upload,
    };
};

export type ResizeMode = "cover";
export const FileUpload = forwardRef<FilePond, FileUploadProps>((props, ref) => {
    const {
        onUploadingImage,
        onUploadCompleted,
        onUploadFailed,
        onFilesChanged,
        uploadEndpoint: uploadEndpoint,
        acceptFileTypes = ["image/*"],
        resize,
        maxSize,
        componentProps,
    } = props;

    const { t } = useTranslation("validation", {
        keyPrefix: "basics.images.file_upload_button",
    });

    const getErrorString = useCallback(
        (error: (FilePondErrorDescription & { main: string; sub: string }) | null) => {
            if (!error) {
                return undefined;
            }

            if (error.body) {
                return `${t("upload_failed")}: ${error.body}`;
            }

            if (error.main) {
                let e = `${t("upload_failed")}: ${error.main}`;
                if (error.sub) {
                    e += ` - ${error.sub}`;
                }

                return e;
            }

            return t("upload_failed");
        },
        [t]
    );

    return (
        <div style={{ display: "none" }}>
            <FilePond
                ref={ref}
                allowMultiple={false}
                allowBrowse={true}
                allowDrop={true}
                allowPaste={true}
                // Start FilePondPluginImageResize
                allowImageResize={true}
                imageResizeTargetWidth={resize?.width}
                imageResizeTargetHeight={resize?.height}
                imageResizeMode={resize?.mode}
                imageResizeUpscale={false}
                // End FilePondPluginImageResize

                // Start FilePondPluginFileValidateSize
                allowFileSizeValidation={true}
                maxFileSize={maxSize ?? "2MB"}
                // End FilePondPluginFileValidateSize

                // Start FilePondPluginFileValidateType
                acceptedFileTypes={acceptFileTypes}
                // End FilePondPluginFileValidateType

                // Start FilePondPluginFileEncode
                allowFileEncode={true}
                // End FilePondPluginFileEncode

                // Start FilePondPluginFileMetadata
                allowFileMetadata={true}
                fileMetadataObject={props.metadata ?? {}}
                // End FilePondPluginFileMetadata

                server={{
                    url: uploadEndpoint,
                    headers: {
                        authorization: `Bearer ${props.token}`,
                    },
                }}
                oninit={() => {
                    console.log("FilePond instance has initialised", ref);
                }}
                onupdatefiles={(files) => {
                    onFilesChanged?.(files);
                }}
                onerror={(error, file, status) => {
                    const errorMessage = getErrorString(error as any);
                    onUploadFailed?.(errorMessage, file?.id);

                    if (errorMessage && status.main !== "Error during load") {
                        publishEvent(new ShowErrorEvent(errorMessage));
                    }
                    Sentry.withScope((scope) => {
                        scope.setExtra("file", JSON.stringify(file, null, 4));
                        scope.setExtra("status", status);
                        scope.setExtra("error", JSON.stringify(error));
                        Sentry.captureException(error);
                    });
                }}
                onprocessfilestart={(file) => {
                    onUploadingImage?.(file.id);
                }}
                onprocessfile={(error, file) => {
                    const errorMessage = getErrorString(error as any);
                    if (error) {
                        onUploadFailed?.(errorMessage, file?.filename);
                    } else {
                        onUploadCompleted?.(file?.serverId, errorMessage, file?.filename, file?.id);
                    }
                }}
                {...componentProps?.filePond}
            />
        </div>
    );
});
FileUpload.displayName = "FileUpload";

interface FileUploadButtonProps extends ButtonProps {
    onUploadClick?(): Promise<boolean> | boolean;
    fileUploadProps: FileUploadProps;
}
export const FileUploadButton: FC<PropsWithChildren<FileUploadButtonProps>> = (props) => {
    const { onUploadClick, title, fileUploadProps, children, ...rest } = props;
    const [isUploading, setIsUploading] = useState(false);

    const { component, addFile } = useFileUpload({
        onUploadingImage: () => {
            setIsUploading(true);
        },
        onUploadCompleted: () => {
            setIsUploading(false);
        },
        onUploadFailed: () => {
            setIsUploading(false);
        },
        ...fileUploadProps,
    });

    const handleUploadClick = useCallback(async () => {
        const result = await onUploadClick?.();
        if (result ?? true) {
            addFile();
        }
    }, [onUploadClick, addFile]);

    return (
        <>
            <SpinnerButton
                variant="contained"
                color="primary"
                isLoading={isUploading}
                onClick={handleUploadClick}
                {...rest}
            >
                {children}
            </SpinnerButton>
            {component}
        </>
    );
};

interface FileUploadComponentProps extends ButtonProps {
    onUploadClick?(): Promise<boolean> | Promise<void> | boolean | void;
    fileUploadProps: FileUploadProps;
}
export const FileUploadComponent: FC<PropsWithChildren<FileUploadComponentProps>> = (props) => {
    const { onUploadClick, title, fileUploadProps, children, ...rest } = props;
    const [isUploading, setIsUploading] = useState(false);

    const { component, addFile } = useFileUpload({
        onUploadingImage: () => {
            setIsUploading(true);
        },
        onUploadCompleted: () => {
            setIsUploading(false);
        },
        onUploadFailed: () => {
            setIsUploading(false);
        },
        ...fileUploadProps,
    });

    const handleUploadClick = useCallback(async () => {
        const result = await onUploadClick?.();
        if (result ?? true) {
            addFile();
        }
    }, [onUploadClick, addFile]);

    return (
        <>
            {Children.map(children, (child) => {
                if (!isValidElement(child)) return child;

                const x = Children.only<ReactElement>(child);
                if (!x) {
                    return null;
                }
                return cloneElement(x, { onClick: handleUploadClick });
            })}
            {component}
        </>
    );
};
