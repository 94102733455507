import type { PubgMatchStatsUpdated } from "@messaging/core";
import { useTopicIdSubscription } from "@messaging/core";
import { get, getSwr, sendCommand } from "@masterblaster/api";

export interface PubgMatchStats {
    id: string;
    competitionId: string;
    matchSeriesId: string;

    pubgMatchId: string;
    fetchedAt: string;
    createdAt: string;
    nextPollAt: string;

    reason: string;
    failedAt: string;

    startPollAt: string | undefined;
    endPollAt: string | undefined;
    pollInterval: string | undefined;

    lookupBasedOnPlayerIds: string[];
    mismatchingTeams: PubgMismatchingTeam[];
}
export interface PubgMismatchingTeam {
    teamId: string;
    players: {
        playerId: string;
        accountId: string;
    }[];
}

export enum PubgPerspective {
    None = 0,
    FPP = 1,
    TPP = 2,
}

export enum PubgGameMode {
    None = 0,
    Esports = 1,
    Normal = 2,
    War = 3,
    Zombie = 4,
    Conquest = 5,
    MatchMaking = 6,
}

export interface PubgEnumValue {
    value: number;
    name: string;
    description: string;
}

export interface PubgCompetitionSettings {
    competitionId: string;
    gameMode: PubgGameMode;
    perspective: PubgPerspective;
    platformRegion: number;
    isCustomMatch: boolean;
}

export interface PubgConstants {
    platformRegions: PubgEnumValue[];
    gameModes: PubgEnumValue[];
}

export interface PlayerSeedInfo {
    competitionId: string;
    groupIds: string[];
    tournamentTeamId: string | undefined;
}

export const usePubgMatchStats = (matchSeriesId: string) => {
    const { data: matchStats, mutate } = getPubgMatchStats(matchSeriesId);

    useTopicIdSubscription<PubgMatchStatsUpdated>(
        matchStats?.id,
        "PubgMatchStatsUpdated",
        (evt) => {
            mutate(evt.matchStats, { revalidate: false });
        },
        [mutate]
    );

    return {
        matchStats,
    };
};

const getPubgMatchStats = (matchSeriesId: string) => getSwr<PubgMatchStats>(`api/pubg/match/stats/${matchSeriesId}`);

export const updatePubgTournamentSettings = (pubgTournamentSettings: PubgCompetitionSettings) =>
    sendCommand("UpdatePubgCompetitionSettingsCommand", pubgTournamentSettings);

export const getPubgTournamentConfiguration = (competitionId: string) =>
    get<PubgCompetitionSettings>(`api/pubg/lobby/${competitionId}/config`);

export const fetchPubgMatchStats = (matchSeriesId: string, matchId: string, pubgMatchId: string) =>
    sendCommand("FetchPubgMatchStatsCommand", {
        matchSeriesId,
        matchId,
        pubgMatchId,
    });
export const stopPubgStatsPoll = (matchSeriesId: string) =>
    sendCommand("StopPubgStatsPollCommand", {
        matchSeriesId,
    });
export const getPubgConstants = () => get<PubgConstants>(`api/pubg/constants`);
