import type { SxProps, Theme } from "@mui/material";
import { Avatar } from "@mui/material";
import { DefaultCommunityAvatar } from "@masterblaster/assets";
import { useState } from "react";

type CommunityAvatarSize = "small" | "smedium" | "medium";
export const CommunityAvatar = ({
    url,
    size,
    sx,
}: {
    url: string | undefined;
    size?: CommunityAvatarSize;
    sx?: SxProps<Theme>;
}) => {
    const [src, setSrc] = useState(url);
    const getSize = () => {
        switch (size) {
            case "small":
                return 30;
            case "smedium":
                return 34;
            case "medium":
            default:
                return 40;
        }
    };

    const avatarSize = getSize();

    return (
        <Avatar
            src={src || DefaultCommunityAvatar}
            imgProps={{
                onError: () => {
                    setSrc(DefaultCommunityAvatar);
                },
            }}
            sx={{
                border: (theme) => `1px solid ${theme.colors.greys[8]}`,
                borderRadius: "50%",
                boxSizing: "border-box",
                width: avatarSize,
                height: avatarSize,
                "& .MuiSvgIcon-root-MuiAvatar-fallback": {
                    color: "red",
                },
                ...sx,
            }}
        />
    );
};
