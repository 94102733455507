import { MbTextField } from "@masterblaster/basics";
import { CircularProgress, InputAdornment } from "@mui/material";
import type { CSSProperties } from "react";
import { useEffect, useState, useCallback } from "react";
import { searchTeam } from "@services/Search";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { useTranslation } from "react-i18next";

const typingInterval = 250;

const validateName = (name: string) => searchTeam(name, true);
export const validateNameDebounced = AwesomeDebouncePromise(validateName, typingInterval);

export const TeamNameInput = (props: {
    name: string | undefined;
    teamId?: string;
    disabled?: boolean;
    style?: CSSProperties;
    onChanged(name: string, isValid: boolean): void;
}) => {
    const { teamId, disabled, style, onChanged } = props;
    const { t } = useTranslation(["common", "translation", "validation"]);

    const [name, setName] = useState(props.name);
    const [isTeamNameValid, setIsTeamNameValid] = useState<boolean>();
    const [isSearching, setIsSearching] = useState(false);
    const isNameExist = !!name;
    const isNameChanged = true;

    const validateName = useCallback(
        (name: string) => {
            setIsSearching(true);
            return validateNameDebounced(name).then((response) => {
                const data = response?.data ?? [];
                const empty = data.length === 0;
                const sameObject = data.length === 1 && data[0].objectId === teamId;
                const isValid = empty || sameObject;

                // const isValid = response && response.data && response.data.length === 0;
                setIsSearching(false);

                return isValid;
            });
        },
        [teamId]
    );

    useEffect(() => {
        if (!name || name?.toLowerCase() === props.name?.toLowerCase()) {
            return;
        }

        validateName(name).then((isValid) => {
            setIsTeamNameValid(isValid);
            onChanged(name, isValid);
        });
    }, [name, props.name, validateName, onChanged]);

    const getTeamNameHelpText = () => {
        if (isNameExist && isNameChanged && !isSearching) {
            return isTeamNameValid
                ? t("validation:components.team.team_name_available")
                : t("validation:components.team.team_name_exist");
        }
    };

    const hasError = isNameExist && isNameChanged && isTeamNameValid === false;
    const isSuccess = isNameExist && isNameChanged && isTeamNameValid;

    return (
        <MbTextField
            id="new-team-name"
            fullWidth
            autoComplete="teamname"
            placeholder={t("translation:components.team.team_name_input.placeholder")}
            error={hasError}
            success={isSuccess}
            helpText={getTeamNameHelpText()}
            value={name}
            disabled={disabled}
            label={t("common:shared.team_name")}
            InputProps={{
                endAdornment: isSearching ? (
                    <InputAdornment position="end">
                        <CircularProgress size={18} />
                    </InputAdornment>
                ) : undefined,
            }}
            style={style}
            onChange={(e) => setName(e.currentTarget.value)}
            autoFocus
        />
    );
};
