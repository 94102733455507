import { styled } from "@mui/material";
import type { BasicLabelProps } from "./BasicLabel";
import { BasicLabel } from "./BasicLabel";

interface SubscriptionLabelProps extends BasicLabelProps {
    name?: string;
}
export const SubscriptionLabel = styled(({ name, ...rest }: SubscriptionLabelProps) => {
    const text = name ? `${name} Access` : "No Access";
    return (
        <BasicLabel {...rest}>
            <span>{text}</span>
        </BasicLabel>
    );
})<SubscriptionLabelProps>(({ theme, name }) => ({
    color: theme.colors.white,
    border: name ? "1px" : `1px solid ${theme.colors.greys[13]}`,
    background: name ? theme.colors.accent_gradient_dark : theme.colors.greys[15],
}));
