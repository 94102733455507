import type { SxProps, Theme } from "@mui/material";
import { useTheme, Box } from "@mui/material";
import { useIsMobile } from "@masterblaster/theme";

type SubHeaderSizes = "small" | "large";

const getSize = (size: SubHeaderSizes) => {
    switch (size) {
        case "small":
            return "14px";
        default:
            return "16px";
    }
};

export const Intro = (props: {
    header: string;
    subheader?: string;
    subheaderSize?: SubHeaderSizes;
    sx?: SxProps<Theme>;
}) => {
    const { subheaderSize } = props;
    const isMobile = useIsMobile();
    const headerSize = 28;
    const subHeaderSize = subheaderSize ? getSize(subheaderSize) : headerSize;

    const { colors } = useTheme<Theme>();

    return (
        <Box display="flex" flexDirection="column" sx={props.sx}>
            <span
                style={{
                    fontWeight: "bold",
                    fontSize: isMobile ? `calc(${headerSize}px * 0.8)` : headerSize,
                    lineHeight: isMobile ? `calc(${headerSize}px * 0.8)` : `calc(${headerSize}px * 0.9)`,
                }}
            >
                {props.header}
            </span>
            {props.subheader && (
                <span
                    style={{
                        marginTop: "0.5rem",
                        fontWeight: "normal",
                        fontSize: isMobile ? `calc(${subHeaderSize} * 0.9)` : subHeaderSize,
                        lineHeight: isMobile ? `calc(${subHeaderSize}px * 0.9)` : `calc(${subHeaderSize}px * 0.9)`,
                        color: colors.secondary_text,
                    }}
                >
                    {props.subheader}
                </span>
            )}
        </Box>
    );
};
