import type { CommunityModel } from "@components/communities/CommunitiesApi";
import { CommunityImageType } from "@components/communities/CommunitiesApi";
import { getImageUrl } from "@masterblaster/api";
import { CommunityAvatar, Header } from "@masterblaster/basics";
import { Box, Typography, styled } from "@mui/material";
import { generateLink } from "@utils/generateLink";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

export const PageHostedBy = ({ community }: { community: CommunityModel | undefined }) => {
    const { t } = useTranslation("common");

    if (!community) {
        return null;
    }

    const avatarImage = community?.images.find((x) => x.imageType === CommunityImageType.Avatar);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(1) }}>
            <Typography sx={{ fontWeight: 600 }}>{t("shared.hosted_by")}</Typography>
            <StyledRouterLink
                to={generateLink("COMMUNITY_ROUTES.COMMUNITY", {
                    id: community?.id,
                })}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                    <CommunityAvatar
                        url={getImageUrl(avatarImage?.imageId)}
                        sx={{
                            width: "2.5rem",
                            height: "2.5rem",
                            fontSize: "1.5em",
                        }}
                    />
                    <Header size="sm" sx={{ textAlign: "center", color: (theme) => theme.colors.primary_text }}>
                        {community?.name}
                    </Header>
                </Box>
            </StyledRouterLink>
        </Box>
    );
};

const StyledRouterLink = styled(RouterLink)({
    color: "inherit",
    textDecoration: "none",
    "&:hover": {
        color: "inherit",
    },
    "&:visited": {
        color: "inherit",
    },
});
