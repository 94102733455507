// import { CreateOrganizationPage } from "./pages/CreateOrganizationPage";
// import { OrganizationContextContainer } from "./OrganizationContextContainer";
export const ORG_ROUTES = {
    CREATE: "/organization/create",
    JOIN: "/organization/:id/join",
    MEMBERS: "/organization/:id/members",
    TEAMS: "/organization/:id/teams",
    SETTINGS: "/organization/:id/settings",
    PAGE: "/organization/:id",
    BACKOFFICE: "/backoffice/organizations",
} as const;
