import type { TeamDto } from "@components/team";
import type {
    OrganizationTeamAdded,
    OrganizationTeamDeleted,
    OrganizationTeamsUpdated,
    OrganizationUpdated,
    PlayerOrganizationsChanged,
} from "@messaging/core";
import { useTopicIdSubscription, useUserSubscription } from "@messaging/core";
import { getSwr, sendCommand, uploadImage } from "@masterblaster/api";
import type { PlayerDto } from "@masterblaster/api";
import type { ApplicationState } from "@store/core";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export enum OrganizationImageType {
    Avatar = 1,
    Cover = 2,
}

export interface OrganizationImage {
    imageType: OrganizationImageType;
    imageId: string;
    originalImageId?: string;
}

export enum OrganizationRoles {
    Player = 5,
    Administrator = 10,
}

export interface OrganizationMember {
    player: PlayerDto;
    email: string | undefined;
    name: string | undefined;
    playerId: string;
    role: OrganizationRoles;
    addedAt: string | undefined;
    invitedAt: string | undefined;
}
export interface Organization {
    id: string;
    name: string;
    ownerId: string;

    members: OrganizationMember[];
    images: OrganizationImage[];
}

export interface PlayerOrganizationModel {
    id: string;
    name: string;
    images: OrganizationImage[];
    isAdmin: boolean;
}

/* 
    Hooks
*/

export const useOrganization = (organizationId: string | undefined) => {
    const {
        data: teams,
        mutate: mutuateTeams,
        isLoading: isLoadingTeams,
        isValidating: isValidatingTeams,
    } = getOrganizationTeams(organizationId);
    const {
        data: org,
        mutate: setOrg,
        isLoading: isLoadingOrg,
        isValidating: isValidatingOrg,
    } = getOrganization(organizationId);
    const {
        data: players,
        mutate: mutatePlayers,
        isLoading: isLoadingOrgPlayers,
        isValidating: isValidatingPlayers,
    } = getOrganizationPlayers(organizationId);

    useTopicIdSubscription<OrganizationUpdated>(
        organizationId,
        "OrganizationUpdated",
        (evt) => {
            setOrg(evt.organization);
        },
        [setOrg]
    );

    useTopicIdSubscription<OrganizationTeamAdded>(
        organizationId,
        "OrganizationTeamAdded",
        (evt) => {
            mutuateTeams();
        },
        [mutuateTeams]
    );

    useTopicIdSubscription<OrganizationTeamDeleted>(
        organizationId,
        "OrganizationTeamDeleted",
        (evt) => {
            mutuateTeams((prev) => {
                if (!prev) return prev;

                const updated = prev.filter((x) => x.id !== evt.teamId);
                return [...updated];
            });
        },
        [mutatePlayers]
    );

    useTopicIdSubscription<OrganizationTeamsUpdated>(
        organizationId,
        "OrganizationTeamsUpdated",
        (evt) => {
            mutuateTeams();
            mutatePlayers();
        },
        [mutuateTeams, mutatePlayers]
    );

    const isLoading = isLoadingTeams || isLoadingOrgPlayers || isLoadingOrg;
    const isValidating = isValidatingTeams || isValidatingPlayers || isValidatingOrg;
    return {
        organization: org,
        teams: teams ?? [],
        isLoadingTeams,
        players: players ?? [],
        isLoading,
        isValidating,
    };
};

export const usePlayerOrganizations = () => {
    const user = useSelector((state: ApplicationState) => state.session.user);
    const { data: playerOrgs, mutate, isLoading } = getPlayerOrganizations(user?.id);

    useEffect(() => {
        mutate();
    }, [user, mutate]);

    useUserSubscription<PlayerOrganizationsChanged>(
        "PlayerOrganizationsChanged",
        (evt) => {
            mutate();
        },
        [mutate]
    );

    return {
        playerOrganizations: playerOrgs ?? [],
        isLoading,
        refresh: mutate,
    };
};

/*
    Commands
*/
export const createOrganization = async (name: string, image?: Blob) => {
    const result = await sendCommand<string>("CreateOrganizationCommand", {
        name,
    });

    if (result.success && result.data && image) {
        await uploadImage("UploadOrganizationImageCommand", image, {
            organizationId: result.data,
            imageType: OrganizationImageType.Avatar,
        });
    }

    return result;
};

export const updateOrganizationName = (organizationId: string, name: string) =>
    sendCommand("UpdateOrganizationNameCommand", {
        organizationId,
        name,
    });

export const inviteOrganizationPlayer = (organizationId: string, playerId: string) =>
    sendCommand<string>("InviteOrganizationPlayerCommand", {
        organizationId,
        playerId,
    });

export const inviteOrganizationPlayerByEmail = (organizationId: string, email: string) =>
    sendCommand<string>("InviteOrganizationPlayerCommand", {
        organizationId,
        email,
    });

export const cancelOrganizationPlayerInvite = (organizationId: string, playerId: string) =>
    sendCommand("CancelOrganizationPlayerInviteCommand", {
        organizationId,
        playerId,
    });

export const acceptOrganizationInvite = (organizationId: string) =>
    sendCommand("AcceptOrganizationInviteCommand", {
        organizationId,
    });

export const rejectOrganizationInvite = (organizationId: string) =>
    sendCommand("RejectOrganizationInviteCommand", {
        organizationId,
    });

export const addOrganizationPlayer = (organizationId: string, playerId: string) =>
    sendCommand("AddOrganizationPlayerCommand", {
        organizationId,
        playerId,
    });

export const removeOrganizationPlayer = (organizationId: string, playerId: string) =>
    sendCommand("RemoveOrganizationPlayerCommand", {
        organizationId,
        playerId,
    });

export const addOrganizationAdmin = (organizationId: string, playerId: string) =>
    sendCommand("AddOrganizationAdminCommand", {
        organizationId,
        playerId,
    });

export const removeOrganizationAdmin = (organizationId: string, playerId: string) =>
    sendCommand("RemoveOrganizationAdminCommand", {
        organizationId,
        playerId,
    });

export const leaveOrganization = (organizationId: string) =>
    sendCommand("LeaveOrganizationCommand", { organizationId });

export const deleteOrganization = (organizationId: string) =>
    sendCommand("DeleteOrganizationCommand", { organizationId });

/*
    Rest
*/
export const getOrganization = (organizationId: string | undefined) =>
    getSwr<Organization>(organizationId ? `api/organization/${organizationId}` : null);

export const getAllOrganizations = () => getSwr<Organization[]>(`api/organization/all`);

const getPlayerOrganizations = (playerId: string | undefined) =>
    getSwr<PlayerOrganizationModel[]>(playerId ? `api/organization/player` : null);

const getOrganizationPlayers = (organizationId: string | undefined) =>
    getSwr<PlayerDto[]>(organizationId ? `api/organization/${organizationId}/players` : null);
const getOrganizationTeams = (organizationId: string | undefined) =>
    getSwr<TeamDto[]>(organizationId ? `api/organization/${organizationId}/teams` : null);
