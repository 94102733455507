import { styled } from "@mui/material";
import type { BasicLabelProps } from "./BasicLabel";
import { BasicLabel } from "./BasicLabel";

interface TeamRoleLabelProps extends BasicLabelProps {
    playerProps: { isAdministrator: boolean; pendingInvitation: boolean };
}
export const TeamRoleLabel = styled(({ playerProps, ...rest }: TeamRoleLabelProps) => {
    const roleLabel = playerProps.isAdministrator ? "Team admin" : "Member";
    const label = playerProps.pendingInvitation ? "Invited" : roleLabel;
    return <BasicLabel {...rest}>{label}</BasicLabel>;
})<TeamRoleLabelProps>(({ theme }) => ({
    color: theme.colors.white,
    border: `1px solid ${theme.colors.greys[6]}`,
    backgroundColor: theme.colors.greys[11],
}));
