import { Close as CloseIcon } from "@mui/icons-material";
import type { DialogProps, Theme, DialogTitleProps } from "@mui/material";
import { Box, Dialog, DialogTitle, IconButton, styled, useMediaQuery } from "@mui/material";

interface MbDialogTitleProps extends DialogTitleProps {
    onClose: () => void;
}

export const MbDialogTitle = styled(({ onClose, children, ...rest }: MbDialogTitleProps) => (
    <DialogTitle {...rest}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: 1 }}>
            {children}
            <IconButton onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </Box>
    </DialogTitle>
))<MbDialogTitleProps>({});

interface MbDialogProps extends Omit<DialogProps, "open"> {
    title: string;
    onClose: () => void;
}
export const MbDialog = styled(({ children, title, onClose, ...rest }: MbDialogProps) => {
    const fullScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.down("md"));

    return (
        <Dialog open={true} fullWidth={true} fullScreen={fullScreen} onClose={onClose} {...rest}>
            <MbDialogTitle onClose={onClose}>{title}</MbDialogTitle>
            {children}
        </Dialog>
    );
})<MbDialogProps>({});
