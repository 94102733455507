import type { Theme } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import { createContext, useContext, type FC, type PropsWithChildren } from "react";
import { createCustomTheme, darkTheme } from "./theme";
import type { ColorsType } from "./designFeatures";

interface ThemeSwitcherProps {
    themeColors: ColorsType;
}

interface ThemeSwitcherContextProps {
    theme: Theme;
    isDark: boolean;
}

export const ThemeSwitcherContext = createContext<ThemeSwitcherContextProps>(undefined!);

export const ThemeSwitcher: FC<PropsWithChildren<ThemeSwitcherProps>> = ({ themeColors, children }) => {
    const getTheme = () => {
        return createCustomTheme(themeColors, "dark");
    };

    const theme = getTheme();
    const isDark = theme === darkTheme;

    return (
        <ThemeSwitcherContext.Provider value={{ theme, isDark }}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ThemeSwitcherContext.Provider>
    );
};

export const useThemeSwitcher = () => {
    const context = useContext(ThemeSwitcherContext);
    if (!context) {
        throw new Error("useThemeSwitcher must be used within a ThemeSwitcher");
    }
    return context;
};
