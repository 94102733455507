import type { MouseEvent } from "react";
import { Trans } from "react-i18next";
import { getTeamGravatarUrl, GfLink } from "@masterblaster/basics";
import { generateLink } from "@utils/generateLink";
import { Notification } from "../Notification";
import { NotificationTitle } from "../shared";
import type { NotificationPayload } from "../NotificationService";
import type { NotificationElementProps } from "../NotificationElementProps";
import { getImageUrl } from "@masterblaster/api";

interface TeamJoinRequestAccepted extends NotificationPayload {
    teamId: string;
    teamName: string;
    orgId: string | undefined;
    orgName: string | undefined;
    bannerImageId: string | undefined;
}

export const NotificationTeamJoinRequestAccepted = ({ notification, navigateTo }: NotificationElementProps) => {
    const { teamId, bannerImageId, teamName, orgId, orgName } = notification.payload as TeamJoinRequestAccepted;
    const teamLink = generateLink("TEAM_ROUTES.OVERVIEW", {
        id: teamId,
    });
    const orgLink = orgId
        ? generateLink("ORG_ROUTES.PAGE", {
              id: orgId,
          })
        : "";

    const handleNotificationClick = () => navigateTo(teamLink);
    const handleLinkClick = (link: string) => (event: MouseEvent<HTMLAnchorElement>) => {
        event.stopPropagation();
        navigateTo(link);
    };

    return (
        <Notification
            createdAt={notification.createdAt}
            isRead={!!notification.readAt}
            image={bannerImageId ? getImageUrl(bannerImageId) : getTeamGravatarUrl(teamName)}
            title={
                <NotificationTitle>
                    <span>
                        <Trans
                            ns="translation"
                            i18nKey={
                                orgName
                                    ? "components.notifications.notification_type.team_join_request.your_player_team_join_request_accepted_org"
                                    : "components.notifications.notification_type.team_join_request.your_player_team_join_request_accepted"
                            }
                            values={orgName ? { teamName, orgName } : { teamName }}
                            components={
                                orgId
                                    ? [
                                          <GfLink
                                              underline
                                              key="0"
                                              to={teamLink}
                                              onClick={handleLinkClick(teamLink)}
                                          />,
                                          <GfLink underline key="1" to={orgLink} onClick={handleLinkClick(orgLink)} />,
                                      ]
                                    : [<GfLink underline key="0" to={teamLink} onClick={handleLinkClick(teamLink)} />]
                            }
                        />
                    </span>
                </NotificationTitle>
            }
            onClick={handleNotificationClick}
        />
    );
};
