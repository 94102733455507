import type { FormControlLabelProps, SwitchProps as MaterialSwitchProps } from "@mui/material";
import { Box, FormControlLabel, styled, Switch as MaterialSwitch } from "@mui/material";
import { TooltipIcon } from "@masterblaster/basics";

export interface SwitchProps extends MaterialSwitchProps {
    label?: string;
    tooltip?: string;
    checked: boolean;
    disabled?: boolean;
    labelPlacement?: FormControlLabelProps["labelPlacement"];
    onChanged?(checked: boolean): void;
}

export const Switch = (props: SwitchProps) => {
    const { label, tooltip, onChanged, labelPlacement, ...rest } = props;
    return (
        <FormControlLabel
            labelPlacement={labelPlacement}
            control={<StyledMaterialSwitch onChange={(e) => onChanged?.(e.target.checked)} {...rest} />}
            label={
                <Box display="flex" alignItems="center">
                    <span>{label}</span>
                    &nbsp;
                    <TooltipIcon title={tooltip} />
                </Box>
            }
            style={{ margin: 0 }}
        />
    );
};

const StyledMaterialSwitch = styled(MaterialSwitch)(({ theme }) => ({
    "& .MuiSwitch-switchBase": {
        color: theme.colors.greys[5], // Switch - off
        "&.Mui-checked": {
            color: theme.colors.greys[0], // Switch - on
            "& + .MuiSwitch-track": {
                // Background - on
                backgroundColor: theme.colors.greys[9],
                opacity: 1,
            },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
            // Switch - focus-visible
            // color: "#33cf4d",
        },
        "&.Mui-focusVisible + .MuiSwitch-track": {
            // Background - focus-visible
            boxShadow: theme.colors.button_focused_shadow,
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
            // Switch
            color: theme.colors.greys[11],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
            // Background
            backgroundColor: theme.colors.greys[13],
            opacity: 1,
        },
    },
    // "& .MuiSwitch-thumb": {
    //     boxSizing: "border-box",
    // },
    "& .MuiSwitch-track": {
        backgroundColor: theme.colors.greys[14], // Background off
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
            duration: 500,
        }),

        "&.Mui-focusVisible": {
            boxShadow: theme.colors.button_focused_shadow,
        },
        // "&.Mui-checked": {
        //     backgroundColor: "red",
        // },
    },
}));
