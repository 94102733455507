import type { MbTextFieldProps } from "./MbTextField";
import { MbTextField } from "./MbTextField";

export interface MbNumberFieldProps extends MbTextFieldProps {
    onNumberChanged?(value: number | undefined): void;
    defaultValue?: number;
    allowUndefinedValue?: boolean;
}

export const MbNumberField = (props: MbNumberFieldProps) => {
    const { onNumberChanged, onChange, value, allowUndefinedValue, ...rest } = props;

    const parse = (value: string, defaultValue = 0) => {
        const num = parseInt(value);
        if (isNaN(num)) {
            return allowUndefinedValue ? undefined : defaultValue;
        }
        return num;
    };

    return (
        <MbTextField
            value={value ?? ""}
            onChange={(e) => {
                if (props.onChange) {
                    props.onChange(e);
                }

                if (props.onNumberChanged) {
                    const value = parse(e.target.value);
                    props.onNumberChanged(value);
                }
            }}
            {...rest}
        />
    );
};
