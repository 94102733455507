import type { PaginationResult, SearchV2Result } from "@masterblaster/api";
import type { AutocompleteResultValue, MbSearchProps } from "@masterblaster/basics";
import { MbSearch } from "@masterblaster/basics";
import { isAdministrator } from "@mb/auth";
import { searchPlayer } from "@services/Search";
import { useTranslation } from "react-i18next";

export type SelectedPlayer = {
    id: string;
    nickName: string | undefined;
    avatarUrl?: string;
};

export interface PlayerSearchAutocompleteProps extends Omit<MbSearchProps, "searchFunction"> {
    currentPlayerId: string | undefined;
    selectedPlayers?: SelectedPlayer | SelectedPlayer[];
    label?: string;
    placeholder?: string;
    filterCurrentUser?: boolean;
    disabled?: boolean;
    searchFunction?: (query: string) => Promise<SearchV2Result[]> | Promise<PaginationResult<SearchV2Result>>;
    onSelected(player: SelectedPlayer): void;
}

export const PlayerSearchAutocomplete = (props: PlayerSearchAutocompleteProps) => {
    const {
        currentPlayerId,
        selectedPlayers,
        label,
        placeholder,
        filterCurrentUser = !isAdministrator(),
        disabled = false,
        searchFunction,
        onSelected,
        ...mbSearchProps
    } = props;
    const { t } = useTranslation("translation", {
        keyPrefix: "basics.search.player_search_autocomplete",
    });

    const handleChange = (_: unknown, value: AutocompleteResultValue) => {
        if (!value || typeof value === "string" || Array.isArray(value) || !value?.id) {
            return;
        }

        onSelected({
            id: value.id,
            nickName: value.displayText,
            avatarUrl: value.avatarUrl,
        });
    };

    const filterOptions = (options: SearchV2Result[]) =>
        options.filter((option) => {
            const isCurrentUser = filterCurrentUser && currentPlayerId ? option.id === currentPlayerId : false;
            const isSelectedOption = Array.isArray(selectedPlayers)
                ? selectedPlayers?.some((player) => player.id === option.id)
                : selectedPlayers?.id === option.id;

            return !isCurrentUser && !isSelectedOption;
        });

    return (
        <MbSearch
            disabled={disabled}
            style={{ width: "100%" }}
            noOptionsText={t("no_options_text")}
            filterOptions={filterOptions}
            searchFunction={searchFunction ?? searchPlayer}
            onChange={handleChange}
            textFieldProps={{
                fullWidth: true,
                label: label ?? t("label"),
                placeholder: placeholder ?? t("placeholder"),
            }}
            {...mbSearchProps}
        />
    );
};
