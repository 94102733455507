import type { MatchSeriesTeamPlayer } from "@components/matches/api/MatchSeries";
import { CompetitionTeamPlayerRole } from "@components/team/TeamService";
import type { GameMap } from "@masterblaster/api";
import { getMaps, getSwr, sendCommand } from "@masterblaster/api";
import type { VetoUpdated } from "@messaging/core";
import { useTopicIdSubscription } from "@messaging/core";
import type { Theme } from "@mui/material";
import { useTheme } from "@mui/material";
import { createContext, useContext } from "react";

export enum VetoMapStatus {
    NotSelected = 0,
    Ban = 1,
    Pick = 2,
    Decider = 3,
}

export enum MapSide {
    None = 0,
    CT = 10,
    T = 20,
    Knife = 30,
}

export interface MapVetoMap {
    name: string;
    index: number;
    status: VetoMapStatus;
    chosenByTeamId: string | undefined;
    chosenAt: string | undefined;
    autoChosen: boolean;
    side: MapSide | undefined;
}

export interface MapSelection {
    status: VetoMapStatus;
    teamId: string | undefined;
}

export interface MapVeto {
    id: string;
    numberOfMaps: number;
    maps: MapVetoMap[];
    mapSelections: MapSelection[] | undefined;
    nextTeamToChoose: string | undefined;
    nextTeamToSelectSide: string | undefined;
    nextMapToSelectSideOn: string | undefined;
    nextAction: VetoMapStatus;
    lastMapSelectedAt: string;
    completed: boolean;
    createdAt: string;
    useSelectSides: boolean;
}

export const selectSide = (mapVetoId: string, side: MapSide) =>
    sendCommand<MapVeto>("SelectSide", {
        mapVetoId,
        side,
    });

export const vetoMap = (mapVetoId: string, mapName?: string) =>
    sendCommand<MapVeto>("VetoMap", {
        mapVetoId,
        mapName,
    });

interface VetoContextProps {
    veto: MapVeto;
    maps: GameMap[];
    setVeto(veto: MapVeto): void;
}

export const VetoContext = createContext<VetoContextProps>(undefined!);

export const useVetoContext = () => {
    return useContext(VetoContext);
};

export const useVeto = (vetoId: string | undefined, gameId: string | undefined) => {
    const { data: veto, mutate, isLoading: isLoadingVeto } = getSwr<MapVeto>(vetoId ? `/api/veto/${vetoId}` : null);
    const { data: maps, isLoading: isLoadingMaps } = getMaps(gameId);

    useTopicIdSubscription<VetoUpdated>(vetoId, "VetoUpdated", (evt) => {
        mutate(evt.veto, { revalidate: false });
    });

    return {
        veto,
        maps: maps ?? [],
        isLoading: isLoadingVeto || isLoadingMaps,
        setVeto: (veto: MapVeto) => mutate(veto, { revalidate: false }),
    };
};

export const isPlayerAllowedToVeto = (
    veto: MapVeto | undefined,
    matchSeriesTeamPlayer: MatchSeriesTeamPlayer | undefined,
    teamId: string | undefined,
    isGameAdmin: boolean,
    isTeamAdmin: boolean
) => {
    if (!veto || (!teamId && !isGameAdmin)) {
        return false;
    }

    const isAllowedToVeto = matchSeriesTeamPlayer?.role === CompetitionTeamPlayerRole.TeamLeader || isTeamAdmin;
    const vetoEnabled = veto.nextTeamToChoose === teamId && isAllowedToVeto;
    const sideSelectionEnabled = veto.nextTeamToSelectSide === teamId && isAllowedToVeto;
    return isGameAdmin || vetoEnabled || sideSelectionEnabled;
};

export const useVetoStatusColor = () => {
    const { colors } = useTheme<Theme>();

    const getStatusColor = (status: VetoMapStatus | undefined) => {
        switch (status) {
            case VetoMapStatus.Ban:
                return colors.reds[4];
            case VetoMapStatus.Pick:
            case VetoMapStatus.Decider:
                return colors.greens[4];
            default:
                return colors.white;
        }
    };

    const getVetoMapColors = (status: VetoMapStatus | undefined) => {
        switch (status) {
            case VetoMapStatus.Ban:
                return [colors.reds[8], colors.reds[7], colors.greys[7]];
            case VetoMapStatus.Pick:
            case VetoMapStatus.Decider:
                return [colors.greens[6], colors.greens[5], colors.white];
            default:
                return [colors.greys[14], colors.greys[10], colors.white];
        }
    };

    return {
        getStatusColor,
        getVetoMapColors,
    };
};
