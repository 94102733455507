import type { FC } from "react";
import { useState } from "react";
import type { Editor } from "@tiptap/react";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";

import { EditorCommands } from "../utils/editorCommands";
import { alignOptions, defaultOptions } from "./editorOptions";
import { HeadingSelector } from "./HeadingSelector";
// import { ImageSelector } from "./ImageSelector";
import { LinkEditor } from "./LinkEditor";

export interface ToolbapItemBaseProps {
    editor: Editor;
    editorCommands: EditorCommands;
}

const ToolbarContainer = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    gap: "4px",
    padding: "0.5rem",
});

const OptionWrapper = styled("div")({
    "& > :not(:last-child)": {
        marginRight: "4px",
    },
});

interface EditorToolbarProps {
    editor: Editor;
}

export const EditorToolbar: FC<EditorToolbarProps> = ({ editor }) => {
    const [editorCommands] = useState(new EditorCommands(editor));

    return (
        <ToolbarContainer>
            <OptionWrapper>
                {defaultOptions.map(({ key, label, icon: Icon }) => (
                    <ToggleButton
                        key={key}
                        value={key}
                        title={label}
                        selected={editor.isActive(key)}
                        onClick={editorCommands[key].bind(editorCommands)}
                    >
                        {Icon && <Icon />}
                    </ToggleButton>
                ))}
            </OptionWrapper>
            <OptionWrapper>
                {alignOptions.map(({ key, label, icon: Icon }) => (
                    <ToggleButton
                        key={key}
                        value={key}
                        title={label}
                        selected={editor.isActive({ textAlign: key })}
                        onClick={editorCommands.setAlignment.bind(editorCommands, key)}
                    >
                        {Icon && <Icon />}
                    </ToggleButton>
                ))}
            </OptionWrapper>
            <OptionWrapper>
                {/* <ImageSelector editor={editor} editorCommands={editorCommands} /> */}
                <LinkEditor editor={editor} editorCommands={editorCommands} />
            </OptionWrapper>
            <HeadingSelector editor={editor} editorCommands={editorCommands} />
        </ToolbarContainer>
    );
};
