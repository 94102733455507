import { formatDateTime, get } from "@masterblaster/api";
import { DrawerContent, DrawerHeader, DrawerSection, Header } from "@masterblaster/basics";
import { Box, Drawer, List, ListItem, ListItemText, ToggleButton, ToggleButtonGroup, styled } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface MatchSeriesChangeLog {
    id: string;
    competitionId: string;
    matchSeriesId: string;
    rawEventName: string;
    eventName: string;
    title: string;
    description: string;
    createdById: string;
    createdByName: string;
    createdAt: string;
}

export const useHistoryDrawer = (matchSeriesId: string) => {
    const [open, setOpen] = useState(false);

    const drawer = useMemo(
        () => <MatchHistoryDrawer matchSeriesId={matchSeriesId} open={open} onClose={() => setOpen(false)} />,
        [matchSeriesId, open]
    );

    return {
        openMatchHistory: () => setOpen(true),
        closeMatchHistory: () => setOpen(false),
        matchHistoryDrawer: drawer,
    };
};

export const MatchHistoryDrawer = (props: { matchSeriesId: string; open: boolean; onClose?: () => void }) => {
    const { matchSeriesId } = props;
    const { t } = useTranslation(["common", "translation"]);

    const [filters, setFilters] = useState(["players", "matchScore"]);
    const [changeLog, setChangeLog] = useState<MatchSeriesChangeLog[]>([]);
    const close = () => {
        props.onClose?.();
    };

    useEffect(() => {
        if (props.open) {
            get<MatchSeriesChangeLog[]>(`api/matches/changelog/${matchSeriesId}`).then((x) => {
                setChangeLog(x);
            });
        }
    }, [matchSeriesId, props.open]);

    const getItemText = (event: MatchSeriesChangeLog) => {
        if (event.description) {
            return `${event.title} - ${event.description}`;
        }

        return event.title;
    };

    const filtered = changeLog
        .filter((x) => {
            return filters.indexOf("players") === -1 ? x.rawEventName !== "MatchSeriesPlayerStatusChangedEvent" : true;
        })
        .filter((x) => {
            return filters.indexOf("matchScore") === -1 ? x.rawEventName !== "MatchSeriesMatchScoreUpdatedEvent" : true;
        });

    return (
        <Drawer anchor="right" open={props.open} onClick={(e) => e.stopPropagation()} onClose={close}>
            <DrawerHeader onClose={() => close()} />
            <DrawerContent>
                <DrawerSection>
                    {t("translation:components.tournament.match.drawer.match_history_drawer.match_history")}
                </DrawerSection>

                <Box>
                    <Header size="sm">
                        {t("translation:components.tournament.match.drawer.match_history_drawer.filters")}
                    </Header>
                    <ToggleButtonGroup
                        size="small"
                        value={filters}
                        onChange={(_, filters) => {
                            setFilters(filters);
                        }}
                    >
                        <ToggleButton value="players">
                            {t("translation:components.tournament.match.drawer.match_history_drawer.player_connect")}
                        </ToggleButton>
                        <ToggleButton value="matchScore">{t("common:shared.match_score")}</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <List dense>
                    {filtered.map((x, idx) => {
                        return (
                            <ListItem
                                disableGutters
                                key={idx}
                                style={{ flexDirection: "column", alignItems: "flex-start" }}
                            >
                                <ListItemText primary={getItemText(x)} style={{ marginBottom: 0 }} />
                                <SecondaryTextBox>
                                    {x.eventName !== x.title && <span>{x.eventName}</span>}
                                    <span style={{ fontSize: "0.7em" }}>
                                        {t(
                                            "translation:components.tournament.match.drawer.match_history_drawer.created",
                                            {
                                                createdAt: formatDateTime(x.createdAt, "Ppp"),
                                                createdBy: x.createdByName,
                                            }
                                        )}
                                    </span>
                                </SecondaryTextBox>
                            </ListItem>
                        );
                    })}
                </List>
            </DrawerContent>
        </Drawer>
    );
};

const SecondaryTextBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    color: theme.colors.secondary_text,
    fontSize: "0.9em",
}));
