import { styled } from "@mui/material";
import { type ReactNode } from "react";
import type { TextSize } from "./utils";
import { getFontSize } from "./utils";

interface ErrorTextProps {
    size?: TextSize;
    children: ReactNode;
}

export const ErrorText = styled(({ size, ...rest }: ErrorTextProps) => <span {...rest} />)<ErrorTextProps>(
    ({ theme, size }) => ({
        color: theme.colors.error,
        fontSize: getFontSize(size),
    })
);
