import type { CsPlayerStats } from "@components/matches/api/MatchApi";
import { type CsPlugin } from "@components/matches/api/MatchSeries";
import { GameType, get, getSwr, sendCommand } from "@masterblaster/api";
import type { CsCompetitionSettingsUpdated, CsPlayerStatsUpdated } from "@messaging/core";
import { useTopicIdSubscription } from "@messaging/core";
import { useCallback } from "react";

export enum CsVersion {
    Cs2_meta = 15,
    Cs2_matchxy = 20,
}
export interface CsServerConsoleLine {
    timestamp: string;
    line: string;
}

export interface CsServerEventWrapper {
    event: CsServerEvent;
    type: string;
    text: string;
    line: string;
}

export interface CsServerEvent {
    matchId: string;
    timestamp: string;
}

export interface CsCompetitionSettings {
    competitionId: string;
    locationId: string;
    useAutoForfeit: boolean;
    forfeitTimeoutInSeconds: number;
    plugins: CsPlugin[];
    version: CsVersion;
}

export interface CompetitionMatchDemo {
    competitionId: string;
    matchId: string;
    matchIndex: string;
    matchName: string;
    teamA: string;
    teamB: string;
    demos: string[];
}

export const updateCsTournamentSettings = (settings: CsCompetitionSettings) =>
    sendCommand("UpdateCsCompetitionSettings", settings);

export const getCsTournamentSettings = (competitionId: string) =>
    get<CsCompetitionSettings>(`api/cs/competition_settings/${competitionId}`);

export const useCsTournamentSettings = (competitionId: string | undefined, game: GameType | undefined) => {
    const { data: settings, mutate } = getSwr<CsCompetitionSettings>(
        competitionId && game === GameType.CSGO ? `api/cs/competition_settings/${competitionId}` : null
    );
    const setSettings = useCallback(
        (settings: CsCompetitionSettings) => mutate(settings, { revalidate: false }),
        [mutate]
    );

    useTopicIdSubscription<CsCompetitionSettingsUpdated>(
        competitionId,
        ["CsCompetitionSettingsUpdated"],
        (evt) => {
            mutate(evt.settings);
        },
        [mutate]
    );

    return {
        settings,
        setSettings,
    };
};

export const useCsPlayerStats = (matchSeriesId: string) => {
    const { data: playerStats, mutate } = getSwr<CsPlayerStats[]>(`api/cs/match_stats/${matchSeriesId}`);

    useTopicIdSubscription<CsPlayerStatsUpdated>(
        matchSeriesId,
        "CsPlayerStatsUpdated",
        (evt) => {
            mutate(evt.playerStats, { revalidate: false });
        },
        [mutate]
    );

    return playerStats ?? [];
};

export const getCompetitionDemos = (competitionId: string) =>
    getSwr<CompetitionMatchDemo[]>(`api/matches/demos/competition/${competitionId}`);
