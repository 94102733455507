import { AccentContainer } from "@masterblaster/basics";
import { styled } from "@mui/material/styles";

export interface AccentContainerProps {
    isMobile?: boolean;
    active?: boolean;
}
export const CardContainer = styled(AccentContainer, {
    shouldForwardProp: (prop) => prop !== "isMobile" && prop !== "active",
})<AccentContainerProps>(({ theme, active, isMobile }) => ({
    position: "relative",
    display: "flex",
    boxSizing: "border-box",
    padding: 0,
    backgroundColor: theme.colors.border,
    boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.4)",

    ...(isMobile && {
        flexDirection: "column",
    }),

    ...(active && {
        boxShadow:
            "4px 4px 15px rgba(236, 0, 142, 0.35), -2px -2px 15px rgba(255, 199, 0, 0.35), 0px 0px 5px rgba(255, 107, 0, 0.5)",
    }),
}));
