import { SpinnerButton } from "@masterblaster/basics";

import type { CsMatchConnectionInfo } from "@components/competitions/tournament/games/csgo";
import { CsGameServerStatus } from "@components/competitions/tournament/games/csgo";
import { GameType } from "@masterblaster/api";
import { useGame } from "@src/config";
import { useTranslation } from "react-i18next";
import {
    completeMatchSeries,
    resetMatchSeries,
    startMatchSeries,
    stopMatchSeries,
    stopMatchSeriesAutoForfeit,
} from "../api/MatchApi";
import type { MatchSeries, MatchSeriesCsGameSettings } from "../api/MatchSeries";
import { MatchStatus } from "../api/MatchSeries";

const MATCH_CONTROLS_TRANSLATION_STRING = "components.tournament.match.drawer.match_controls";

export const useMatchControlsState = (matchSeries: MatchSeries) => {
    const { t } = useTranslation("translation", {
        keyPrefix: MATCH_CONTROLS_TRANSLATION_STRING,
    });

    if (matchSeries.game === GameType.CSGO) {
        const connectionInfo = matchSeries.connectionInfo.fields as unknown as CsMatchConnectionInfo;
        const stopping = connectionInfo?.status === CsGameServerStatus.Stopping;
        return {
            startDisabled: stopping,
            stopDisabled: stopping,
            stop: {
                visible: !stopping,
            },
            helperText: stopping ? t("helper_text") : "",
        };
    }

    return { startDisabled: false, stop: { visible: true }, helperText: "" };
};

export const MatchControls = ({ matchSeries }: { matchSeries: MatchSeries }) => {
    const states = useMatchControlsState(matchSeries);

    return (
        <>
            <StartMatchCommand matchSeries={matchSeries} disabled={states.startDisabled} />
            {states.stop.visible && <StopMatchCommand matchSeries={matchSeries} />}
            <DisableAutomaticForfeit matchSeries={matchSeries} />
            <ResetMatchCommand matchSeries={matchSeries} />
            <CompleteMatchCommand matchSeries={matchSeries} />
        </>
    );
};

const StartMatchCommand = ({ matchSeries, disabled }: { matchSeries: MatchSeries; disabled?: boolean }) => {
    const { t } = useTranslation("common");

    const { status } = matchSeries;
    if (status !== MatchStatus.NotStarted) {
        return null;
    }

    return (
        <SpinnerButton
            disabled={disabled}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => startMatchSeries(matchSeries.id)}
        >
            {t("button_actions.start_match")}
        </SpinnerButton>
    );
};

const DisableAutomaticForfeit = ({ matchSeries }: { matchSeries: MatchSeries }) => {
    const { t } = useTranslation("translation", {
        keyPrefix: MATCH_CONTROLS_TRANSLATION_STRING,
    });
    const gameSetup = useGame(matchSeries.gameId);
    if (gameSetup?.settings.game !== GameType.CSGO) {
        return null;
    }

    if (matchSeries.status !== MatchStatus.Started) {
        return null;
    }

    const settings = matchSeries.gameSettings as MatchSeriesCsGameSettings;
    if (!settings.useAutoForfeit) {
        return null;
    }

    return (
        <SpinnerButton
            variant="contained"
            size="small"
            color="error"
            onClick={() => stopMatchSeriesAutoForfeit(matchSeries.id)}
        >
            {t("disable_auto_forfeit")}
        </SpinnerButton>
    );
};

const StopMatchCommand = ({ matchSeries, disabled }: { matchSeries: MatchSeries; disabled?: boolean }) => {
    const { t } = useTranslation("common");
    const showStop = matchSeries.status !== MatchStatus.NotStarted && matchSeries.status !== MatchStatus.Finished;

    if (!showStop) {
        return null;
    }

    return (
        <SpinnerButton
            disabled={disabled}
            variant="contained"
            size="small"
            color="error"
            onClick={() => stopMatchSeries(matchSeries.id, t)}
        >
            {t("shared.stop_match")}
        </SpinnerButton>
    );
};

const ResetMatchCommand = ({ matchSeries }: { matchSeries: MatchSeries }) => {
    const { t } = useTranslation(["common", "translation"]);
    const showReset =
        (matchSeries.status === MatchStatus.NotStarted &&
            (matchSeries.series.length > 0 || matchSeries.settings.mapVetoId)) ||
        matchSeries.status === MatchStatus.Finished;

    if (!showReset) {
        return null;
    }

    return (
        <SpinnerButton
            variant="contained"
            color="error"
            size="small"
            onClick={() => resetMatchSeries(matchSeries.id, t)}
        >
            {t("common:shared.reset_match")}
        </SpinnerButton>
    );
};

const CompleteMatchCommand = ({ matchSeries }: { matchSeries: MatchSeries }) => {
    const { t } = useTranslation("translation", {
        keyPrefix: MATCH_CONTROLS_TRANSLATION_STRING,
    });
    const showComplete = matchSeries.status > MatchStatus.NotStarted && matchSeries.status < MatchStatus.Finished;

    if (!showComplete) {
        return null;
    }

    return (
        <SpinnerButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => completeMatchSeries(matchSeries.id)}
        >
            {t("complete_match")}
        </SpinnerButton>
    );
};
