import { deleteResource } from "@masterblaster/api";

export const deleteImage = (imageId: string, endpoint: string) => deleteResource(`${endpoint}/${imageId}`);

export const getFallbackImage = (dim: { width: number; height: number }, text?: string) => {
    return `https://via.placeholder.com/${dim.width}x${dim.height}?text=${text ?? ""}`;
};

export const getImageUrl = (imageId: string | undefined) => {
    if (!imageId) {
        return undefined;
    }

    return `api/Image/${imageId}`;
};

export const getUploadUrl = (uploadCommand: string) => `api/command/${uploadCommand}`;
