import { get, getSwr, sendCommand } from "@masterblaster/api";
export interface GameServerLocation {
    name: string;
    description: string;
}

export interface GameServerUserData {
    templateName: string;
    ownerId: string;
    gsltId: string;
    eacId: string;
    lastUsed: string | undefined;
}

export interface GameServer {
    id: string;
    userData: GameServerUserData;
    on: boolean;
    serverPoolId: string;
    occupied: boolean;
    lastUpdated: Date;
    poolName: string;
    name: string;
    joinSecret: string;
    authenticationKey: string;
    gameServerLoginToken: string;
    vac: number;
    protocol: number;
    tags: string;
    version: string;
    os: string;
    appId: number;
    richServerName: string;
    currentMap: string;
    nextMap: string;
    ff: string;
    timelimit: string;
    host: string;
    mods: string[];
    playerCount: string;
    gameName: string;
    serverId: string;
    ip: string;
    port: number;
    rawServerId: number;
    rconSecret: string;
    lastPing: number;
    avgPing: number;
    countryName: string;
    currentPlayers: number;
    maxPlayers: number;
}

export interface GsltAccounts {
    servers: GsltServer[];
    isBanned: boolean;
    expires: number;
    actor: string;
    lastActionTime: number;
}

export interface GsltServer {
    steamid: string;
    appid: string;
    loginToken: string;
    memo: string;
    isDeleted: boolean;
    isExpired: boolean;
    rtLastLogon: number;
}

export const ReleaseGameServer = (serverId: string) => sendCommand("ReleaseGameServer", { serverId });
export const DeleteMatchConfig = (serverId: string) => sendCommand("DeleteMatchConfig", { serverId });

export const getCsServerLocations = () => getSwr<GameServerLocation[]>("/api/cs/gameserver/serverlocations");
export const getCsServers = (competitionId?: string) => {
    let url = `/api/cs/gameserver/servers`;
    if (competitionId) {
        url += `?competitionId=${competitionId}`;
    }
    return get<GameServer[]>(url);
};
export const getGsltAccounts = () => get<GsltAccounts>(`api/cs/backoffice/gslt`);
