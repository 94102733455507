import { useState, useCallback, useMemo, forwardRef } from "react";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import type { MbTextFieldProps } from "./MbTextField";
import { MbTextField } from "./MbTextField";

type MbPasswordFieldProps = Omit<MbTextFieldProps, "type" | "endAdornment">;
export const MbPasswordField = forwardRef<any, MbPasswordFieldProps>(function MbPasswordField(props, ref) {
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

    const type = useMemo(() => (isPasswordVisible ? "text" : "password"), [isPasswordVisible]);

    const togglePasswordVisibility = useCallback(() => setIsPasswordVisible((prevState) => !prevState), []);

    return (
        <MbTextField
            {...props}
            ref={ref}
            type={type}
            endAdornment={
                <VisibilityToggleButton tabIndex={-1} disableRipple onClick={togglePasswordVisibility}>
                    {isPasswordVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </VisibilityToggleButton>
            }
        />
    );
});

const VisibilityToggleButton = styled(IconButton)(({ theme }) => ({
    width: "auto",
    height: "auto",

    "& svg": {
        fontSize: "1.375rem",
        color: theme.colors.greys[4],
    },
}));
