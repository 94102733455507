import { GfLink, PlayerAvatar, PlayerAvatarStatus } from "@masterblaster/basics";
import { MatchSeriesTeamPlayerStatus } from "@components/matches/api/MatchSeries";
import { Box } from "@mui/material";
import { generateLink } from "@utils/generateLink";
import type { CSSProperties } from "react";
import { RoleLabel } from "./RoleLabel";
import type { CompetitionTeamPlayerRole } from "./TeamService";

export const mapStatus = (status: MatchSeriesTeamPlayerStatus | undefined) => {
    switch (status) {
        case MatchSeriesTeamPlayerStatus.Offline:
            return PlayerAvatarStatus.Offline;
        case MatchSeriesTeamPlayerStatus.Online:
            return PlayerAvatarStatus.Offline;
        case MatchSeriesTeamPlayerStatus.Ready:
            return PlayerAvatarStatus.Ready;
        default:
            return undefined;
    }
};

export const PlayerWidget = ({
    id,
    name,
    avatarUrl,
    role,
    status,
    reverse,
    style,
    avatarStyle,
}: {
    id: string;
    name: string;
    avatarUrl: string | undefined;
    role?: CompetitionTeamPlayerRole;
    status?: MatchSeriesTeamPlayerStatus;
    reverse?: boolean;
    style?: CSSProperties;
    avatarStyle?: CSSProperties;
}) => {
    const direction = reverse ? "row-reverse" : "row";
    const href = generateLink("PLAYER_PROFILE_ROUTE", {
        id,
    });
    return (
        <Box display="flex" alignItems="center" overflow="hidden" flexGrow={1} flexDirection={direction}>
            <GfLink to={href} style={style}>
                <PlayerAvatar player={{ name, avatarUrl }} status={mapStatus(status)} style={avatarStyle} />
            </GfLink>
            <Box width="1rem" flexShrink={0} />
            <GfLink to={href} style={style}>
                <Box display="flex" flexDirection="column" alignItems={reverse ? "flex-end" : "flex-start"}>
                    <span
                        title={name}
                        style={{
                            lineHeight: "1.25em",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "inline-block",
                            width: "100%",
                            textAlign: reverse ? "right" : "left",
                            maxWidth: "100%",
                        }}
                    >
                        {name}
                    </span>
                    <RoleLabel role={role} />
                </Box>
            </GfLink>
        </Box>
    );
};
