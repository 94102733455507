import type { FC, SVGProps } from "react";
import type { Theme } from "@mui/material";
import { styled } from "@mui/material";
import type { MUIStyledCommonProps } from "@mui/system";

import {
    CalendarFontIcon,
    CogwheelFontIcon,
    CookiesFontIcon,
    DiscordFontIcon,
    FacebookFontIcon,
    GoogleFontIcon,
    HomeFontIcon,
    InstagramFontIcon,
    LogoutFontIcon,
    ProfileFontIcon,
    ReportFontIcon,
    TeamsFontIcon,
    TournamentFontIcon,
    TwitchFontIcon,
    TwitterFontIcon,
    VKFontIcon,
} from "@masterblaster/assets";

export type IconVariants =
    | "calendar"
    | "cogwheel"
    | "cookies"
    | "discord"
    | "facebook"
    | "google"
    | "home"
    | "instagram"
    | "logout"
    | "profile"
    | "report"
    | "teams"
    | "tournament"
    | "twitch"
    | "twitter"
    | "vk";

const ICON_BY_VARIANT: Record<IconVariants, FC<SVGProps<SVGSVGElement>>> = {
    calendar: CalendarFontIcon,
    cogwheel: CogwheelFontIcon,
    cookies: CookiesFontIcon,
    discord: DiscordFontIcon,
    facebook: FacebookFontIcon,
    google: GoogleFontIcon,
    home: HomeFontIcon,
    instagram: InstagramFontIcon,
    logout: LogoutFontIcon,
    profile: ProfileFontIcon,
    report: ReportFontIcon,
    teams: TeamsFontIcon,
    tournament: TournamentFontIcon,
    twitch: TwitchFontIcon,
    twitter: TwitterFontIcon,
    vk: VKFontIcon,
};

export interface IconProps extends SVGProps<SVGSVGElement>, MUIStyledCommonProps<Theme> {
    variant: IconVariants;
}

export const Icon: FC<IconProps> = ({ variant, ...svgProps }) => {
    const Icon = ICON_BY_VARIANT[variant];

    const StyledIcon = styled(Icon)({
        height: "1em",
        width: "auto",
        fontSize: "1em",
    });

    return <StyledIcon {...svgProps} />;
};
