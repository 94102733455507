import { Chat as ChatIcon } from "@mui/icons-material";
import type { NotificationElementProps } from "../NotificationElementProps";
import { NotificationTitle } from "../shared";
import { Trans } from "react-i18next";
import { Notification } from "../Notification";
import { ChatRoomType } from "@masterblaster/chat";
import { generateLink } from "@utils/generateLink";

export interface ChatUnreadMessagesPayload {
    roomId: string;
    roomName: string | undefined;
    roomType: ChatRoomType;
    unread: number;
}

const generateChatRoomUrl = (roomId: string, roomType: ChatRoomType) => {
    switch (roomType) {
        case ChatRoomType.Match:
            return generateLink("MATCH_ROUTES.LOBBY_CHAT", {
                id: roomId,
            });
        default:
            return undefined;
    }
};

export const NotificationChatUnreadMessages = ({ notification, context, navigateTo }: NotificationElementProps) => {
    const data = notification.payload as ChatUnreadMessagesPayload;

    const handleClick = () => {
        const url = generateChatRoomUrl(data.roomId, data.roomType);
        if (url) {
            navigateTo(url);
        }
    };

    return (
        <Notification
            createdAt={notification.createdAt}
            isRead={!!notification.readAt}
            image={<ChatIcon />}
            title={
                <NotificationTitle>
                    <Trans
                        ns="translation"
                        i18nKey="components.notifications.notification_type.chat.unread"
                        components={[<strong key={0} />]}
                        values={{
                            unread: data.unread,
                            roomName: data.roomName,
                        }}
                    />
                </NotificationTitle>
            }
            onClick={handleClick}
        />
    );
};
