import type { CompetitionTeamDto } from "@components/team";
import { CompetitionTeamPlayerRole } from "@components/team";
import type { CompetitionSchedule, PlayerDto } from "@masterblaster/api";
import { CompetitionStatus } from "@masterblaster/api";
import { isAfter, parseISO } from "date-fns";

export const timeFormatString = "MMM Do YYYY HH:mm";

export const isPlayerEntered = (competitionTeams: CompetitionTeamDto[], player?: PlayerDto): boolean => {
    if (!player) return false;
    if (competitionTeams.length === 0) return false;
    return competitionTeams.some((tt) =>
        tt.players.some((sp) => sp.playerId === player.id && sp.role !== CompetitionTeamPlayerRole.None)
    );
};

export const hasCompetitionStarted = (status: CompetitionStatus) => {
    return status >= CompetitionStatus.Started && status < CompetitionStatus.Completed;
};

export const isRegistrationClosed = (schedule: CompetitionSchedule): boolean => {
    if (!schedule.isScheduled || !schedule.registrationCloses) return false;

    return isRegClosed(schedule.registrationCloses);
};

export const isRegOpen = (registrationOpens: string | undefined, registrationCloses: string | undefined) => {
    if (!registrationOpens) {
        return false;
    }

    const date = parseISO(registrationOpens);
    const isAfterRegOpen = isAfter(new Date(), date);
    const regClosed = isRegClosed(registrationCloses);

    return isAfterRegOpen && !regClosed;
};

export const isRegClosed = (registrationCloses: string | undefined) => {
    if (!registrationCloses) {
        return false;
    }

    const date = parseISO(registrationCloses);
    return isAfter(new Date(), date);
};
