import { MatchSeriesMenu } from "@components/matches/MatchSeriesMenu";
import { MatchSeriesFormat, MatchStatus, type MatchSeries } from "@components/matches/api/MatchSeries";
import { MatchRescheduleBar } from "@components/matches/reschedule";
import { getTwitchTagProps } from "@components/stream";
import type { TwitchTagState } from "@masterblaster/api";
import { TwitchTag } from "@masterblaster/basics";
import { AccessLevels } from "@mb/auth";
import { Box, styled } from "@mui/material";
import { useAuthorization } from "@services/AuthSerivce";
import { useGame } from "@src/config";
import { GameTag } from "@src/config/games/components/GameTag";
import type { ApplicationState } from "@store/index";
import { generateLink } from "@utils/generateLink";
import { useContainerQuery } from "@utils/useContainerQuery";
import type { TFunction } from "i18next";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DesktopMatchCard } from "./components/DesktopMatchCard";
import { MatchCardCenterPiece } from "./components/MatchCardCenterPiece";
import { MatchCompetitionName, MatchName, MatchNameAndStatus } from "./components/MatchCardInfo";
import { MobileMatchCard } from "./components/MobileMatchCard";
import {
    DESKTOP_CARD_MIN_WIDTH,
    DESKTOP_CARD_MIN_WIDTH_WITH_EVERYTHING,
    DESKTOP_CARD_MIN_WIDTH_WITH_MENU,
    DESKTOP_CARD_MIN_WIDTH_WITH_RESCHEDULE,
    DESKTOP_CARD_MIN_WIDTH_WITH_TWITCH_TAG,
    canSeeMatchRescheduleBar,
    canSeeSeriesMenu,
} from "./helpers";
import { MatchSeriesStartingAt } from "./components/MatchSeriesStartingAt";
import { MatchScoreBox, VerticalDivider } from "./components/MatchScoreBox";

export interface MatchSeriesCardProps {
    matchSeries: MatchSeries;
    getCustomTwitchTagLabel?: (
        state: TwitchTagState,
        match: MatchSeries,
        t: TFunction<("translation" | "common")[]>
    ) => string;
    showCompetitionLink?: boolean;
    showGameTag?: boolean;
}

export const MatchSeriesCard = ({
    matchSeries,
    getCustomTwitchTagLabel,
    showGameTag = false,
    showCompetitionLink = true,
}: MatchSeriesCardProps) => {
    const containerRef = useRef<HTMLDivElement>(null);

    const user = useSelector((state: ApplicationState) => state.session.user);
    const { t } = useTranslation(["common", "translation"]);
    const gameSetup = useGame(matchSeries.gameId);

    const [
        isMobileByDefault,
        isMobileIfReschedulePresented,
        hideLabel,
        hideLabelIfMenuPresented,
        hideLabelIfRescheduleBarPresented,
    ] = useContainerQuery({
        elementRef: containerRef,
        query: [
            DESKTOP_CARD_MIN_WIDTH,
            DESKTOP_CARD_MIN_WIDTH_WITH_RESCHEDULE,
            DESKTOP_CARD_MIN_WIDTH_WITH_TWITCH_TAG,
            DESKTOP_CARD_MIN_WIDTH_WITH_MENU,
            DESKTOP_CARD_MIN_WIDTH_WITH_EVERYTHING,
        ],
    });
    const isGameAdmin = useAuthorization("Tournament", matchSeries.competitionId, AccessLevels.GameAdmin);

    const [isRescheduleBarVisible, isSeriesMenuVisible] = useMemo(
        () =>
            user?.id
                ? [canSeeMatchRescheduleBar(matchSeries, user.id), canSeeSeriesMenu(matchSeries, user.id, isGameAdmin)]
                : [false, false],
        [matchSeries, isGameAdmin, user?.id]
    );

    const isCardActive =
        matchSeries.status >= MatchStatus.Starting &&
        matchSeries.status <= MatchStatus.Started &&
        matchSeries.teams.some((team) => team.players.some((player) => player.playerId === user?.id));

    const matchLink =
        matchSeries.format === MatchSeriesFormat.Challenge
            ? generateLink("CHALLENGE_ROUTES.CHALLENGE", {
                  id: matchSeries.competitionId,
              })
            : generateLink("MATCH_ROUTES.LOBBY", {
                  id: matchSeries.id,
              });

    const isMobile = isMobileByDefault || (isRescheduleBarVisible && isMobileIfReschedulePresented);

    let twitchTagProps = getTwitchTagProps({
        matchSeries: matchSeries,
        pathname: location.pathname,
        customTagProps: {
            hideLabel:
                !isMobile &&
                (hideLabel ||
                    (isRescheduleBarVisible && hideLabelIfRescheduleBarPresented) ||
                    (isSeriesMenuVisible && hideLabelIfMenuPresented)),
        },
    });

    if (twitchTagProps && getCustomTwitchTagLabel) {
        twitchTagProps = {
            ...twitchTagProps,
            children: getCustomTwitchTagLabel(twitchTagProps.state, matchSeries, t),
        };
    }

    if (!matchSeries) return null;

    if (isMobile)
        return (
            <MatchCardContainer ref={containerRef}>
                <MobileMatchCard
                    isActive={isCardActive}
                    href={matchLink}
                    topSection={
                        <Box
                            sx={{
                                display: "flex",
                                flex: 1,
                                fontSize: "0.875rem",
                                gap: "0.5rem",
                            }}
                        >
                            <MatchSeriesStartingAt matchSeries={matchSeries} />
                            <Box>―</Box>
                            <MatchName matchSeries={matchSeries} />
                        </Box>
                    }
                    middleSection={<MatchCardCenterPiece matchSeries={matchSeries} user={user} />}
                    bottomSection={
                        <>
                            <Box
                                sx={{
                                    display: "flex",
                                    gap: "0.5rem",
                                    alignItems: "center",
                                    width: "100%",
                                    justifyContent: "space-between",
                                    paddingRight: "1rem",
                                }}
                            >
                                <MatchCompetitionName matchSeries={matchSeries} />
                                <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                                    {twitchTagProps && <TwitchTag {...twitchTagProps} hideLabel />}
                                    {showGameTag && <GameTag gameId={matchSeries.gameId} />}
                                </Box>
                            </Box>
                        </>
                    }
                    menuSection={
                        <Box>
                            <MatchRescheduleBar matchSeries={matchSeries} variant="mini" />
                            <MatchSeriesMenu matchSeries={matchSeries} />
                        </Box>
                    }
                />
            </MatchCardContainer>
        );

    return (
        <MatchCardContainer ref={containerRef}>
            <DesktopMatchCard
                isActive={isCardActive}
                href={matchLink}
                centerSection={
                    <MatchCardCenterPiece
                        matchSeries={matchSeries}
                        user={user}
                        header={({ matchSeries: ms }) => (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "0.75rem",
                                    lineHeight: "1rem",
                                    gap: "1rem",
                                    width: "100%",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flex: 1,
                                        justifyContent: "flex-end",
                                        gap: "0.5rem",
                                    }}
                                >
                                    <MatchSeriesStartingAt matchSeries={matchSeries} />
                                    <Box>―</Box>
                                    <MatchName matchSeries={matchSeries} />
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <VerticalDivider />
                                </Box>
                                <Box sx={{ display: "flex", flex: 1 }}>
                                    <MatchCompetitionName matchSeries={matchSeries} />
                                </Box>
                            </Box>
                        )}
                    />
                }
                rightSection={
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                        {twitchTagProps && <TwitchTag {...twitchTagProps} hideLabel />}
                        {showGameTag && <GameTag gameId={matchSeries.gameId} />}
                    </Box>
                }
                menuSection={
                    <>
                        <MatchRescheduleBar matchSeries={matchSeries} variant="mini" />
                        {isSeriesMenuVisible && <MatchSeriesMenu matchSeries={matchSeries} />}
                    </>
                }
            />
        </MatchCardContainer>
    );
};

const MatchCardContainer = styled("div")({
    width: "100%",
});
