import type { CSSProperties } from "react";
import type React from "react";
import type { ThemeOptions } from "@mui/material/styles";
// Supports weights 100-900
import "@fontsource-variable/inter";

declare module "@mui/material/styles" {
    interface TypographyVariants {
        h7: React.CSSProperties;
        ticker: React.CSSProperties;
        body3: React.CSSProperties;
        ["h4_light"]: React.CSSProperties;
        ["overline_bold"]: React.CSSProperties;
        ["body1_light"]: React.CSSProperties;
        ["body1_italic"]: React.CSSProperties;
        ["body2_light"]: React.CSSProperties;
        ["body2_italic"]: React.CSSProperties;
        ["body3_light"]: React.CSSProperties;
        ["body3_italic"]: React.CSSProperties;
        ["body3_bold"]: React.CSSProperties;
        ["help_text"]: React.CSSProperties;
        ["help_text_bold"]: React.CSSProperties;
        ["help_text_italic"]: React.CSSProperties;
        ["help_text_action"]: React.CSSProperties;
        ["button_small"]: React.CSSProperties;
        ["sub_nav_active"]: React.CSSProperties;
        ["sub_nav_deactivated"]: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        h7: React.CSSProperties;
        ticker: React.CSSProperties;
        body3: React.CSSProperties;
        ["h4_light"]: React.CSSProperties;
        ["overline_bold"]: React.CSSProperties;
        ["body1_light"]: React.CSSProperties;
        ["body1_italic"]: React.CSSProperties;
        ["body2_light"]: React.CSSProperties;
        ["body2_italic"]: React.CSSProperties;
        ["body3_light"]: React.CSSProperties;
        ["body3_italic"]: React.CSSProperties;
        ["body3_bold"]: React.CSSProperties;
        ["help_text"]: React.CSSProperties;
        ["help_text_bold"]: React.CSSProperties;
        ["help_text_italic"]: React.CSSProperties;
        ["help_text_action"]: React.CSSProperties;
        ["button_small"]: React.CSSProperties;
        ["sub_nav_active"]: React.CSSProperties;
        ["sub_nav_deactivated"]: React.CSSProperties;
    }
}

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        h7: true;
        ticker: true;
        body3: true;
        ["h4_light"]: true;
        ["overline_bold"]: true;
        ["body1_light"]: true;
        ["body1_italic"]: true;
        ["body2_light"]: true;
        ["body2_italic"]: true;
        ["body3_light"]: true;
        ["body3_italic"]: true;
        ["body3_bold"]: true;
        ["help_text"]: true;
        ["help_text_bold"]: true;
        ["help_text_italic"]: true;
        ["help_text_action"]: true;
        ["button_small"]: true;
        ["sub_nav_active"]: true;
        ["sub_nav_deactivated"]: true;
    }
}

const MuiTypography = {
    defaultProps: {
        variantMapping: {
            h1: "h1",
            h2: "h2",
            h3: "h3",
            h4: "h4",
            h4_light: "h4",
            h5: "h5",
            h6: "h6",
            h7: "h6",
            overline: "h6",
            overline_bold: "h6",
            subtitle1: "p",
            body1: "p",
            body1_light: "p",
            body1_italic: "p",
            subtitle2: "p",
            body2: "p",
            body2_light: "p",
            body2_italic: "p",
            body3_bold: "p",
            body3: "p",
            body3_light: "p",
            body3_italic: "p",
            help_text: "span",
            help_text_bold: "span",
            help_text_italic: "span",
            help_text_action: "span",
            button: "span",
            button_small: "span",
            sub_nav_active: "span",
            sub_nav_deactivated: "span",
            ticker: "span",
        },
    },
};

const buildVariant = (
    fontFamily: CSSProperties["fontFamily"],
    fontWeight: CSSProperties["fontWeight"],
    fontSize: CSSProperties["fontSize"],
    lineHeight: CSSProperties["lineHeight"],
    letterSpacing?: CSSProperties["letterSpacing"],
    textTransform?: CSSProperties["textTransform"],
    fontStyle?: CSSProperties["fontStyle"],
    textDecoration?: CSSProperties["textDecoration"]
) => ({
    fontFamily,
    fontWeight,
    fontSize,
    lineHeight,
    ...(fontStyle ? { fontStyle } : {}),
    ...(letterSpacing ? { letterSpacing } : {}),
    ...(textTransform ? { textTransform } : {}),
    ...(textDecoration ? { textDecoration } : {}),
});

const headingFonts = "'Inter Variable', sans-serif";
const paragraphFonts = "'Inter Variable', sans-serif";
const fontWeightLight = 300;
const fontWeightRegular = 400;
const fontWeightBold = 700;
const fontWeightExtraBold = 900;
const textDecorationUnderline = "underline";
const textTransformUppercase = "uppercase";
const fontStyleItalic = "italic";

const typography: ThemeOptions["typography"] = {
    fontFamily: "'Inter Variable', sans-serif",
    h1: buildVariant(headingFonts, fontWeightBold, "3.5rem", "4rem"),
    h2: buildVariant(headingFonts, fontWeightBold, "3rem", "3.375rem"),
    h3: buildVariant(headingFonts, fontWeightBold, "2.5rem", "3rem"),
    h4: buildVariant(headingFonts, fontWeightBold, "2rem", "2.375rem"),
    h4_light: buildVariant(headingFonts, fontWeightLight, "2rem", "2.375rem"),
    h5: buildVariant(headingFonts, fontWeightBold, "1.5rem", "1.875rem"),
    h6: buildVariant(headingFonts, fontWeightBold, "1.25rem", "1.625rem"),
    h7: buildVariant(headingFonts, fontWeightBold, "1rem", "1.625rem"),
    overline: buildVariant(paragraphFonts, fontWeightRegular, ".625rem", ".9375rem", ".025rem", textTransformUppercase),
    overline_bold: buildVariant(
        paragraphFonts,
        fontWeightBold,
        ".625rem",
        ".9375rem",
        ".025rem",
        textTransformUppercase
    ),
    subtitle1: buildVariant(paragraphFonts, fontWeightBold, "1rem", "1.5rem"),
    body1: buildVariant(paragraphFonts, fontWeightRegular, "1rem", "1.5"),
    body1_light: buildVariant(paragraphFonts, fontWeightLight, "1rem", "1.5rem"),
    body1_italic: buildVariant(
        paragraphFonts,
        fontWeightRegular,
        "1rem",
        "1.5rem",
        undefined,
        undefined,
        fontStyleItalic
    ),
    subtitle2: buildVariant(paragraphFonts, fontWeightBold, ".875rem", "1.375rem"),
    body2: buildVariant(paragraphFonts, fontWeightRegular, ".875rem", "1.375rem"),
    body2_light: buildVariant(paragraphFonts, fontWeightLight, ".875rem", "1.375rem"),
    body2_italic: buildVariant(
        paragraphFonts,
        fontWeightRegular,
        ".875rem",
        "1.375rem",
        undefined,
        undefined,
        fontStyleItalic
    ),
    body3_bold: buildVariant(paragraphFonts, fontWeightBold, ".75rem", "1.375rem"),
    body3: buildVariant(paragraphFonts, fontWeightRegular, ".75rem", "1.375rem"),
    body3_light: buildVariant(paragraphFonts, fontWeightLight, ".75rem", "1.375rem"),
    body3_italic: buildVariant(
        paragraphFonts,
        fontWeightRegular,
        ".75rem",
        "1.375rem",
        undefined,
        undefined,
        fontStyleItalic
    ),
    help_text: buildVariant(paragraphFonts, fontWeightRegular, ".75rem", "1rem", ".025rem"),
    help_text_bold: buildVariant(paragraphFonts, fontWeightBold, ".75rem", "1rem", ".025rem"),
    help_text_italic: buildVariant(
        paragraphFonts,
        fontWeightRegular,
        ".75rem",
        "1rem",
        ".025rem",
        undefined,
        fontStyleItalic
    ),
    help_text_action: buildVariant(
        paragraphFonts,
        fontWeightRegular,
        ".75rem",
        "1rem",
        ".025rem",
        undefined,
        undefined,
        textDecorationUnderline
    ),
    button: buildVariant(headingFonts, fontWeightBold, "1rem", "1.25rem"),
    button_small: buildVariant(headingFonts, fontWeightBold, ".875rem", "1rem"),
    sub_nav_active: buildVariant(paragraphFonts, fontWeightRegular, "1rem", "1.5rem"),
    sub_nav_deactivated: buildVariant(paragraphFonts, fontWeightBold, "1rem", "1.5rem"),
    ticker: buildVariant(paragraphFonts, fontWeightExtraBold, ".625rem", ".4375rem", ".025rem", textTransformUppercase),
};

export { MuiTypography, typography };
