import { getSwr, post, sendCommand } from "@masterblaster/api";
import type { PlayerDto } from "../players";
import type {
    CustomFieldValues,
    GameMap,
    GameSettings,
    GameSetup,
    PersistedCustomField,
    PersistedCustomFieldGroup,
    ScoreConfiguration,
} from "./GameTypes";

export const getGames = () => getSwr<GameSetup[]>(`api/games`);

export const getGame = (gameSetupId: string | undefined) =>
    getSwr<GameSetup>(gameSetupId ? `api/games/${gameSetupId}` : null);

export const getGameTemplate = () => getSwr<GameSetup>(`api/games/template`);

export const getMaps = (gameId: string | undefined, numberOfPlayers?: number) =>
    getSwr<GameMap[]>(
        gameId !== undefined ? `api/games/maps?gameId=${gameId}&numberOfPlayers=${numberOfPlayers}` : null
    );

export const updateGameSettings = (gameSettings: GameSettings) =>
    sendCommand("AddOrUpdateGameSettingsCommand", gameSettings);

export const addOrUpdateGameMap = (map: GameMap) => sendCommand("AddOrUpdateGameMapCommand", map);
export const deleteGameMap = (mapId: string) => sendCommand("DeleteGameMapCommand", { mapId });
export const deleteGameSetup = (gameSetupId: string) => sendCommand("DeleteGameSetupCommand", { gameSetupId });

export const addOrUpdateMapPool = (
    mapPoolId: string,
    gameId: string,
    name: string,
    useVetoSide: boolean,
    teamSizes: number[],
    customFields: CustomFieldValues,
    maps: string[]
) =>
    sendCommand("AddOrUpdateMapPoolCommand", {
        mapPoolId,
        gameId,
        name,
        useVetoSide,
        teamSizes,
        customFields,
        maps,
    });
export const deleteMapPool = (mapPoolId: string) =>
    sendCommand("DeleteMapPoolCommand", {
        mapPoolId,
    });

export const getNick = (player: PlayerDto, gameId: string) => {
    return player.gameAccounts.find((x) => x.gameId === gameId)?.nick ?? "";
};

export const getGameAccount = (player: PlayerDto | undefined, gameId: string) => {
    if (!player) {
        return undefined;
    }

    return player.gameAccounts.find((x) => x.gameId === gameId);
};

export const hasGameAccount = (user: PlayerDto | undefined, gameId: string) => {
    if (!user) {
        return false;
    }

    return user.gameAccounts.find((y) => y.gameId === gameId && y.isConnected) !== undefined;
};

/**
 * Custom fields
 */

export const getGameCustomFields = (gameId: string) =>
    getSwr<PersistedCustomField[]>(`api/games/${gameId}/custom_fields`);

export const addOrUpdateCustomField = (field: PersistedCustomField) =>
    sendCommand("AddOrUpdateCustomFieldCommand", field);

export const deleteCustomField = (id: string) => sendCommand("RemoveCustomFieldCommand", { customFieldId: id });

/**
 * Score configuration
 */
export const addOrUpdateScoreConfig = (scoreConfig: ScoreConfiguration) => {
    const vars = scoreConfig.variables.reduce((p, c) => ({ ...p, [c.name]: c.value }), {});
    return sendCommand("AddOrUpdateScoreConfigurationCommand", {
        ...scoreConfig,
        variables: vars,
    });
};

export const deleteScoreConfig = (scoreConfigId: string) =>
    sendCommand("DeleteScoreConfigurationCommand", {
        scoreConfigId,
    });

export type CalcResponse = {
    customFieldValues: CustomFieldValues;
    primaryFieldValue: number | undefined;
};
export const calculateScore = (scoreConfigId: string, fieldGroup: PersistedCustomFieldGroup, vars: CustomFieldValues) =>
    post<CalcResponse>(`api/score-config/${scoreConfigId}/calculate/${fieldGroup}`, vars);

export const getScoreConfig = (scoreConfigId: string | undefined) =>
    getSwr<ScoreConfiguration>(scoreConfigId ? `api/score-config/${scoreConfigId}` : null);
