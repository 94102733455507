import { MatchStatus, getMatchSeriesScore, type MatchSeries } from "@components/matches/api/MatchSeries";
import { GameMode } from "@masterblaster/api";
import { Block as BlockIcon, PauseCircleOutline } from "@mui/icons-material";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import { useMemo } from "react";

export const MatchScoreBox = ({ matchSeries, gameMode }: { matchSeries: MatchSeries; gameMode: GameMode }) => {
    const stats = getMatchSeriesScore(matchSeries);
    const status = matchSeries.status;

    const started = status === MatchStatus.Started;
    const showScore =
        status >= MatchStatus.Started &&
        status <= MatchStatus.Finished &&
        status !== MatchStatus.Paused &&
        status !== MatchStatus.Failed;

    const lastRunningMap = useMemo(
        () =>
            _.chain(matchSeries.series)
                .orderBy((x) => x.index)
                .filter((x) => x.status >= MatchStatus.Starting && x.status <= MatchStatus.Postgame)
                .first()
                .value(),
        [matchSeries.series]
    );

    if (gameMode === GameMode.FreeForAll || !showScore) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", minWidth: "5rem", fontSize: "0.875rem" }}>
                <Divider status={status} gameMode={gameMode} />
            </Box>
        );
    }

    return (
        <MatchScoreBoxContainer sx={{ width: "5rem", fontSize: "0.875rem" }}>
            <ScoreContainer>
                <MatchScoreLabel active={started} winner={stats.teamA.winner}>
                    {stats.teamA.score}
                </MatchScoreLabel>
                {lastRunningMap && <MapScoreLabel>[{lastRunningMap?.teamScores[0]?.value}]</MapScoreLabel>}
            </ScoreContainer>
            <Divider status={status} gameMode={gameMode} />
            <ScoreContainer>
                {lastRunningMap && <MapScoreLabel>[{lastRunningMap?.teamScores[1]?.value}]</MapScoreLabel>}
                <MatchScoreLabel active={started} winner={stats.teamB.winner}>
                    {stats.teamB.score}
                </MatchScoreLabel>
            </ScoreContainer>
        </MatchScoreBoxContainer>
    );
};

const ScoreContainer = styled(Box)(({ theme }) => ({ display: "flex", alignItems: "center", gap: "0.25rem" }));
const MapScoreLabel = styled("span")(({ theme }) => ({ fontSize: "0.75rem", color: theme.colors.secondary_text }));

const Divider = ({ status, gameMode }: { status: MatchStatus; gameMode: GameMode }) => {
    if (status <= MatchStatus.Starting) {
        return <Box sx={{ fontWeight: "bold" }}>VS</Box>;
    }

    if (status === MatchStatus.Paused) {
        return <PauseCircleOutline sx={{ color: (theme) => theme.colors.warnings[5] }} />;
    }

    if (status === MatchStatus.Failed) {
        return <BlockIcon sx={{ color: (theme) => theme.colors.reds[4] }} />;
    }

    if (gameMode === GameMode.FreeForAll) {
        return <Box sx={{ fontWeight: "bold" }}>VS</Box>;
    }

    return <VerticalDivider />;
};

export const VerticalDivider = styled(Box)({
    width: "0.063rem",
    height: "1.375rem",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
});

const MatchScoreBoxContainer = styled("div")({
    borderImageSlice: 2,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0.25rem 0rem",
    gap: "0.5rem",
    height: "2.375rem",
});

const MatchScoreLabel = styled("div", {
    shouldForwardProp: (prop) => prop !== "active" && prop !== "winner",
})<{ active?: boolean; winner?: boolean }>(({ active, winner }) => ({
    fontWeight: 700,
    color: winner ? "#73CF2A" : active ? "var(--color-text)" : "#CACCD2",
    textShadow: active
        ? "0.25rem 0.25rem 0.938rem rgba(236, 0, 142, 0.35), -0.125rem -0.125rem 0.938rem rgba(255, 199, 0, 0.35), 0 0 0.313rem rgba(255, 107, 0, 0.5)"
        : "none",
}));
