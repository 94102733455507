import {
    contentMaxWidth,
    organizationAvatarAspectRatio,
    organizationImageAspectRatio,
    sidebarMaxWidth,
} from "@masterblaster/theme";
import { Avatar, Box, styled } from "@mui/material";
import { DefaultCommunityAvatar, DefaultCommunityHeader } from "@masterblaster/assets";
import { getImageUrl } from "@masterblaster/api";
import { OrganizationImageType, type Organization } from "../../OrganizationApi";
import { EditableImage } from "@src/images/EditableImage";

export const getOrganizationAspectRatio = (type: OrganizationImageType) =>
    type === OrganizationImageType.Cover ? organizationImageAspectRatio : organizationAvatarAspectRatio;

const getButtonStyle = (type: OrganizationImageType) => (type === OrganizationImageType.Cover ? "large" : "small");

export interface OrganizationImageComponentProps {
    organization: Organization;
    canEdit: boolean;
    type: OrganizationImageType;
    onChanged?: () => void;
}

export const OrganizationImageComponent = ({
    organization,
    canEdit,
    type,
    onChanged,
}: OrganizationImageComponentProps) => {
    const image = organization.images?.find((x) => x.imageType === type);
    const imageId = image?.imageId;

    return (
        <EditableImage
            zoom
            rotation
            allowEdit={canEdit}
            uploadImageCommand="UploadOrganizationImageCommand"
            imageMetadata={{
                organizationId: organization.id,
                imageType: type,
            }}
            image={image}
            // buttonStyle={getButtonStyle(type)}
            buttonStyle={"small"}
            dimensions={getOrganizationAspectRatio(type)}
            onImageChanged={() => onChanged?.()}
        >
            {type === OrganizationImageType.Cover && (
                <OrganizationBannerContainer>
                    <img
                        src={imageId ? getImageUrl(imageId) : DefaultCommunityHeader}
                        alt={OrganizationImageType[type]}
                        style={{ width: "100%", maxHeight: 280, objectFit: "cover" }}
                    />
                </OrganizationBannerContainer>
            )}
            {type === OrganizationImageType.Avatar && (
                <OrganizationAvavar src={imageId ? getImageUrl(imageId) : DefaultCommunityAvatar} />
            )}
        </EditableImage>
    );
};

const OrganizationBannerContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    // margin: `0rem -2rem 0`,

    // "&::before": {
    //     content: "''",
    //     position: "absolute",
    //     inset: "0",
    //     backgroundImage:
    //         "linear-gradient(90deg, #181920 0%, rgba(24, 25, 32, 0) 9.37%, rgba(24, 25, 32, 0) 89.06%, #181920 100%), linear-gradient(180deg, rgba(23, 25, 32, 0) 53.12%, rgba(23, 25, 32, 0.69) 81.77%, #171920 100%)",
    // },
}));

const OrganizationAvavar = styled(Avatar)(({ theme }) => ({
    boxSizing: "border-box",
    width: "4rem",
    height: "4rem",
    backgroundColor: theme.colors.black,
    // border: `1px solid ${theme.colors.white}`,
}));
