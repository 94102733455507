import type { TextSize } from "../headers";
import { getFontSize } from "../headers";
import type { SxProps, Theme, BoxProps } from "@mui/material";
import { Box, Grid, styled } from "@mui/material";
import type { CSSProperties, FC, PropsWithChildren } from "react";
import { Children } from "react";

export interface BasicLabelProps extends BoxProps {
    transparent?: boolean;
    size?: TextSize;
}

export const BasicLabel = styled(Box, {
    shouldForwardProp: (prop) => prop !== "transparent" && prop !== "size",
})<BasicLabelProps>(({ theme, transparent, size }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: transparent ? "transparent" : theme.colors.black,
    borderRadius: theme.shape.borderRadius,
    padding: transparent ? 0 : "0 0.9rem",
    fontSize: getFontSize(size ?? "xs"),
    lineHeight: `calc(${getFontSize(size ?? "xs")} + 50%)`,
    fontWeight: "bold",
    color: theme.colors.white,
    height: `calc(${getFontSize(size ?? "xs")} + 4px)`,
}));

export const LabelGrid: FC<PropsWithChildren<{ style?: CSSProperties; sx?: SxProps<Theme> }>> = (props) => {
    return (
        <Grid container spacing={2} {...props}>
            {Children.map(props.children, (x) => {
                if (!x) {
                    return null;
                }
                return <Grid item>{x}</Grid>;
            })}
        </Grid>
    );
};

export const NameLabel = styled("h5")(({ theme }) => ({
    color: theme.colors.white,
    margin: 0,
    whiteSpace: "nowrap",
    fontWeight: "bold",
    fontSize: "1.5em",
    lineHeight: "1.25",
    overflow: "hidden",
    textOverflow: "ellipsis",
}));
