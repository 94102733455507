import { createContext, useContext } from "react";
import type { ChatConnection } from "./hooks";

export interface ChatContextProps extends ChatConnection {}

export const ChatContext = createContext<ChatContextProps>(undefined!);

export const useChatContext = () => {
    return useContext(ChatContext);
};
