import type { FC } from "react";
import IconButton from "@mui/material/IconButton";
import type { BoxProps } from "@mui/material/Box";
import Box from "@mui/material/Box";
import type { CSSObject, SxProps, Theme } from "@mui/material/styles";
import { styled, useTheme } from "@mui/material/styles";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DoDisturbAltRoundedIcon from "@mui/icons-material/DoDisturbAltRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import type { ColorsType } from "@masterblaster/theme";

type InlineNotificationVariants = "info" | "error" | "warning" | "success";
type InlineNotificationColors = "highEmphasis" | "lowEmphasis";

export interface InlineNotificationProps extends BoxProps {
    label: string;
    variant?: InlineNotificationVariants;
    color?: InlineNotificationColors;
    onClose?: () => void;
}

export const InlineNotification: FC<InlineNotificationProps> = ({
    label,
    onClose,
    color = "lowEmphasis",
    variant = "info",
    ...boxProps
}) => {
    const { colors } = useTheme();

    const icon = getInlineNotificationIcon(variant, color, colors);
    const closable = !!onClose;

    return (
        <InlineNotificationContainer variant={variant} color={color} {...boxProps}>
            {icon}
            <InlineNotificationContent>{label}</InlineNotificationContent>
            {closable && (
                <CloseButton onClick={onClose}>
                    <CloseRoundedIcon />
                </CloseButton>
            )}
        </InlineNotificationContainer>
    );
};

const getInlineNotificationIcon = (
    variant: InlineNotificationVariants,
    color: InlineNotificationColors,
    colors: ColorsType
) => {
    const defaultStyle: SxProps<Theme> = {
        fontSize: "1.125rem",
        marginRight: "0.5rem",
        color: colors.white,
    };
    const isLowEmphasis = color === "lowEmphasis";

    switch (variant) {
        case "info":
            return <InfoOutlinedIcon sx={{ ...defaultStyle }} />;
        case "error":
            return (
                <DoDisturbAltRoundedIcon sx={{ ...defaultStyle, color: isLowEmphasis ? "#FF5E5E" : colors.white }} />
            );
        case "warning":
            return (
                <WarningAmberRoundedIcon
                    sx={{ ...defaultStyle, color: isLowEmphasis ? colors.prize_color : colors.white }}
                />
            );
        case "success":
            return (
                <CheckCircleOutlineRoundedIcon
                    sx={{ ...defaultStyle, color: isLowEmphasis ? colors.green_base : colors.white }}
                />
            );
    }
};

const highEmphasisVariants: Record<InlineNotificationVariants, CSSObject> = {
    info: {
        backgroundImage: "linear-gradient(86.21deg, #6C1048 3.11%, #743A11 50.49%, #745E11 96.9%)",
    },
    error: {
        backgroundImage: "linear-gradient(86.21deg, #5C2D30 3.11%, #8B3B3D 96.9%)",
    },
    warning: {
        backgroundImage: "linear-gradient(86.21deg, #5C5214 3.11%, #8B770F 96.89%)",
    },
    success: {
        backgroundImage: "linear-gradient(90deg, #324F21 0%, #457423 100.01%)",
    },
};

const lowEmphasisVariants: Record<InlineNotificationVariants, CSSObject> = {
    info: {
        backgroundImage: "linear-gradient(86.21deg, #EC008E 3.11%, #FF6B00 50.49%, #FFC700 96.9%)",
    },
    error: {
        backgroundImage: "linear-gradient(86.21deg, #FF5D5D 3.11%, #FFB7B7 96.89%)",
    },
    warning: {
        backgroundImage: "linear-gradient(86.21deg, #FFD500 3.11%, #FFF1A8 96.89%)",
    },
    success: {
        backgroundImage: "linear-gradient(90deg, #61C713 0%, #A2E975 100%)",
    },
};

const InlineNotificationContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== "color" && prop !== "variant",
})<{
    variant: InlineNotificationVariants;
    color: InlineNotificationColors;
}>(({ color, variant }) => ({
    width: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    borderRadius: "3px",
    padding: "0.8125rem 0.625rem",

    ...(color === "highEmphasis" && highEmphasisVariants[variant]),

    ...(color === "lowEmphasis" && {
        borderRadius: "3px 3px 0 0",
        backgroundImage: "linear-gradient(272.25deg, #35354B 3.79%, #242332 96.21%)",
        boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",

        "&::after": {
            content: "''",
            position: "absolute",
            inset: "auto 0 0 0",
            height: "1px",
            ...lowEmphasisVariants[variant],
        },
    }),
}));

const InlineNotificationContent = styled("p")(({ theme }) => ({
    margin: 0,
    fontSize: "0.75rem",
    lineHeight: "1rem",
    letterSpacing: "0.4px",
    color: theme.colors.white,
}));

const CloseButton = styled(IconButton)({
    margin: "-0.25rem -0.25rem -0.25rem auto",
    padding: "0.25rem",

    "& svg": {
        fontSize: "1.125rem",
    },
});
