import type { Theme } from "@mui/material";
import { Box, styled, useTheme } from "@mui/material";
import { formatDateTime, formatTimeSpan, isTimeSpan, parseTimeSpan } from "@masterblaster/api";
import { isValid, parseISO } from "date-fns";
import type { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

type ValueType = string | number | boolean | undefined | JSX.Element;

export interface PropertyListItemData {
    label: string;
    value?: ValueType | ValueType[];
    visible?: boolean;
    href?: string | undefined;
}
export const PropertyList = (props: { properties: PropertyListItemData[]; style?: CSSProperties }) => {
    return (
        <PropertyListUl style={props.style}>
            {props.properties
                .filter((x) => x.visible === undefined || x.visible)
                .map((x, idx) => {
                    return <PropertyListItem key={idx} property={x} />;
                })}
        </PropertyListUl>
    );
};

const PropertyListItem = (props: { property: PropertyListItemData }) => {
    const { property } = props;
    const { colors } = useTheme<Theme>();

    const renderContent = () => {
        if (property.href) {
            return (
                <a
                    href={property.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: colors.primary_text }}
                >
                    {property.label}
                </a>
            );
        }

        if (property.value) {
            return <PropertyItemValue label={property.label} value={property.value} />;
        }
    };

    return <li>{renderContent()}</li>;
};

const PropertyItemValue = (props: { label: string; value: ValueType | ValueType[] }) => {
    const { t } = useTranslation("translation", {
        keyPrefix: "basics.property_list",
    });

    const getValue = (value: ValueType) => {
        if (value === undefined) {
            return "";
        }

        if (typeof value === "boolean") {
            return value ? t("yes") : t("no");
        }

        if (typeof value === "string") {
            const date = parseISO(value);

            if (isValid(date)) {
                return formatDateTime(date);
            }
        }

        if (typeof value === "object" && isTimeSpan(value)) {
            const timeSpan = parseTimeSpan(value);
            return formatTimeSpan(timeSpan);
        }

        return value;
    };

    const arrayValue = Array.isArray(props.value) ? props.value : [props.value];

    if (arrayValue.length === 0) {
        return null;
    }

    return (
        <Box display="flex">
            <Box style={{ marginRight: "0.5rem" }}>
                <strong>{props.label}</strong>
            </Box>
            <Box sx={{ display: "flex" }}>
                <PropertyListUl>
                    {arrayValue.map((x, idx) => {
                        return (
                            <li key={idx} style={{ wordWrap: "break-word" }}>
                                {getValue(x)}
                            </li>
                        );
                    })}
                </PropertyListUl>
            </Box>
        </Box>
    );
};

const PropertyListUl = styled("ul")(() => ({
    listStyle: "none",
    paddingInlineStart: 0,
}));
