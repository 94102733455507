import { LEAGUE_ROUTES } from "@components/competitions/leagues/LEAGUE_ROUTES";
import { ORG_ROUTES } from "@components/organizations/ORG_ROUTES";
import { MATCH_ROUTES } from "@components/matches/MATCH_ROUTES";
import _ from "lodash";
import { GAMESETUP_ROUTES } from "@src/config/games/GAMESETUP_ROUTES";
import { TRIALS_ROUTES, TRIAL_TAB_ROUTES } from "@components/trials/TRIAL_ROUTES";
import { TENANT_ROUTES } from "../components/tenants/TENANT_ROUTES";

const BASE_URL = "/";

const LEGAL_ROUTE = "/legal";
const PARTNERS_ROUTE = "/partners";

const COOKIE_POLICY_ROUTE = "/cookiepolicy.html";

const AUTH_ROUTES = {
    LOG_IN: "/login",
    LOG_IN_EXTERNAL: "/login/external",
    REGISTER: "/register",
    REGISTER_EXTERNAL: "/register/external",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/resetpassword/:code",
    TERMS_AND_CONDITIONS: "/terms-and-conditions",
    PRIVACY_POLICY: "/privacy-policy",
} as const;

const TOURNAMENT_ROUTES = {
    TOURNAMENTS: "/tournaments",
    LOBBY: "/lobby/:id",
    STREAMS: "/lobby/:id/streams",
    CREATE_TOURNAMENT: "/tournament/create/:communityId?",
    CREATE_TOURNAMENT_FORMAT: "/tournament/create/format/:tournamentId",
    TOURNAMENT_BACKOFFICE: `/lobby/:id/edit`,
    ENLISTMENT: "/lobby/:id/edit/enlistment",
} as const;

const LOBBY_TABS_BASE_URL = TOURNAMENT_ROUTES.LOBBY;

const LOBBY_TAB_ROUTES = {
    BASE: LOBBY_TABS_BASE_URL,
    TAB: `${LOBBY_TABS_BASE_URL}/:tab?`,
    OVERVIEW: `${LOBBY_TABS_BASE_URL}/Overview`,
    TEAMS: `${LOBBY_TABS_BASE_URL}/Teams`,
    JOIN: `${LOBBY_TABS_BASE_URL}/Join`,
    MANAGE_TEAM: `${LOBBY_TABS_BASE_URL}/Manage/:teamId`,
    BRACKETS: `${LOBBY_TABS_BASE_URL}/Brackets`,
    BATTLE_ROLAYE_MATCHES: `${LOBBY_TABS_BASE_URL}/Matches/:roundIndex?/:groupIndex?/:matchId?`,
    MATCHES: `${LOBBY_TABS_BASE_URL}/Matches/:roundIndex?/:matchId?`,
    STANDINGS: `${LOBBY_TABS_BASE_URL}/Standings`,
    SCOREBOARD: `${LOBBY_TABS_BASE_URL}/Scoreboard`,
} as const;

const COMMUNITY_ROUTES = {
    COMMUNITIES: "/communities",
    COMMUNITY: "/community/:id",
    CREATE_COMMUNITY: "/communities/create",
} as const;

const COMMUNITY_TABS_BASE_URL = COMMUNITY_ROUTES.COMMUNITY;

const COMMUNITY_TAB_ROUTES = {
    BASE: COMMUNITY_TABS_BASE_URL,
    TOURNAMENTS: `${COMMUNITY_TABS_BASE_URL}/tournaments`,
    FRAG_TRIALS: `${COMMUNITY_TABS_BASE_URL}/trials`,
    STREAMS: `${COMMUNITY_TABS_BASE_URL}/streams`,
} as const;

const TEAM_ROUTES = {
    BASE: "/team",
    TEAM: "/team/:id/:tab?",
    OVERVIEW: "/team/:id/overview",
    PLAYERS: "/team/:id/players",
    MATCHES: "/team/:id/matches",
    COMPETITIONS: "/team/:id/competitions",
    SETTINGS: "/team/:id/settings",
    CREATE_TEAM: "/team/create",
} as const;

const PLAYER_PROFILE_ROUTE = "/profile/:id";

const PLAYER_PROFILE_TAB_ROUTES = {
    BASE: PLAYER_PROFILE_ROUTE,
    TAB: `${PLAYER_PROFILE_ROUTE}/:tab?`,
    OVERVIEW: `${PLAYER_PROFILE_ROUTE}/overview`,
    SETTINGS: `${PLAYER_PROFILE_ROUTE}/settings`,
    TEAMS: `${PLAYER_PROFILE_ROUTE}/teams`,
    ORGS: `${PLAYER_PROFILE_ROUTE}/organizations`,
} as const;

const BACKOFFICE_ROUTE = "/backoffice";

const BACKOFFICE_ROUTES = {
    BASE: BACKOFFICE_ROUTE,
    TOURNAMENTS: `${BACKOFFICE_ROUTE}/tournaments`,
    TRIALS: `${BACKOFFICE_ROUTE}/cs/trials`,
    MATCHES: `${BACKOFFICE_ROUTE}/matches`,
    TEAMS: `${BACKOFFICE_ROUTE}/teams`,
    EDIT_TEAM: `${BACKOFFICE_ROUTE}/team/:id`,
    USERS: `${BACKOFFICE_ROUTE}/users`,
    GAME_SERVERS: `${BACKOFFICE_ROUTE}/gameservers`,
    BANS: `${BACKOFFICE_ROUTE}/bans`,
    SHOWCASE: `${BACKOFFICE_ROUTE}/showcase`,
    SHOWCASE_TAB: `${BACKOFFICE_ROUTE}/showcase/:tab?`,
    DEVOPS: `${BACKOFFICE_ROUTE}/devops`,
    KPI: `${BACKOFFICE_ROUTE}/kpi`,
    THEMES: `${BACKOFFICE_ROUTE}/themes`,
} as const;

const BACKOFFICE_USERS_BASE_ROUTE = BACKOFFICE_ROUTES.USERS;

const BACKOFFICE_USERS_TAB_ROUTES = {
    BASE: BACKOFFICE_USERS_BASE_ROUTE,
    EDIT_USER: `${BACKOFFICE_USERS_BASE_ROUTE}/edit`,
} as const;

const CS_SERVER_ROUTES = {
    CONSOLE: "/match/cs/console/:matchId",
    EVENTS: "/match/cs/events/:matchId",
} as const;

const LOCKED_ROUTE = "/locked";
const ONBOARDING_ROUTE = "/onboarding/:code?";

const CHALLENGE_ROUTES = {
    CHALLENGE: "/challenge/:id",
    CHALLENGES: "/challenges",
} as const;

const ALL_ROUTES = {
    BASE_URL,
    LEGAL_ROUTE,
    PARTNERS_ROUTE,
    AUTH_ROUTES,
    TOURNAMENT_ROUTES,
    LOBBY_TAB_ROUTES,
    COMMUNITY_ROUTES,
    COMMUNITY_TAB_ROUTES,
    TRIALS_ROUTES,
    TRIAL_TAB_ROUTES,
    TEAM_ROUTES,
    PLAYER_PROFILE_ROUTE,
    PLAYER_PROFILE_TAB_ROUTES,
    BACKOFFICE_ROUTE,
    BACKOFFICE_ROUTES,
    BACKOFFICE_USERS_TAB_ROUTES,
    CS_SERVER_ROUTES,
    LOCKED_ROUTE,
    ONBOARDING_ROUTE,
    CHALLENGE_ROUTES,
    LEAGUE_ROUTES,
    ORG_ROUTES,
    MATCH_ROUTES,
    GAMESETUP_ROUTES,
    TENANT_ROUTES,
} as const;

export {
    ALL_ROUTES,
    BASE_URL,
    LEGAL_ROUTE,
    PARTNERS_ROUTE,
    COOKIE_POLICY_ROUTE,
    AUTH_ROUTES,
    TOURNAMENT_ROUTES,
    LOBBY_TAB_ROUTES,
    COMMUNITY_ROUTES,
    COMMUNITY_TAB_ROUTES,
    TEAM_ROUTES,
    PLAYER_PROFILE_ROUTE,
    PLAYER_PROFILE_TAB_ROUTES,
    BACKOFFICE_ROUTE,
    BACKOFFICE_ROUTES,
    BACKOFFICE_USERS_TAB_ROUTES,
    CS_SERVER_ROUTES,
    LOCKED_ROUTE,
    ONBOARDING_ROUTE,
    CHALLENGE_ROUTES,
};
