import { useIsMobile } from "@masterblaster/theme";
import type { LinkProps, Theme } from "@mui/material";
import { Box, Link, MenuItem, styled, useTheme, Tabs } from "@mui/material";
import type { CSSProperties, FC, PropsWithChildren, ReactElement } from "react";
import { cloneElement, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { LinkBehaviour } from "./GfLink";
import { MbSelect } from "./inputs";

export const StTabs = styled(Tabs)(({ theme }) => ({
    "& .MuiTab-root": {
        textTransform: "none",
        alignItems: "flex-start",
        textAlign: "left",
    },
    "& .Mui-selected": {
        background: theme.colors.greys[15], //"#1E202A",
    },
    ".MuiTabs-indicator": {
        left: 0,
        background: "#EC008E",
    },
}));

const transitionDuration = "0.2s";
const borderWidth = 2;

interface GfTabItemProps {
    tabId?: number | string;
    label?: string;
    visible?: boolean;
    disabled?: boolean;
    status?: ReactElement;
    icon?: ReactElement;
    fullWidth?: boolean;
    href?: string;
    LinkComponent?: LinkProps["component"];
}

export const GfTabItem: FC<PropsWithChildren<GfTabItemProps>> = (props) => {
    const isMobile = useIsMobile();

    return (
        <ConditionalContainer href={props.href} fullWidth={props.fullWidth} LinkComponent={props.LinkComponent}>
            {props.label && (
                <>
                    <span>{isMobile ? props.icon || props.label : props.label}</span>
                    {props.status && (
                        <div
                            style={{
                                position: "absolute",
                                display: "block",
                                top: "-0.85rem",
                                lineHeight: "1.5rem",
                                left: 0,
                                right: 0,
                            }}
                        >
                            {!isMobile && props.status}
                        </div>
                    )}
                </>
            )}
            {!props.label && props.children}
        </ConditionalContainer>
    );
};

const ConditionalContainer: FC<
    PropsWithChildren<{
        fullWidth?: boolean;
        href?: string;
        LinkComponent?: LinkProps["component"];
    }>
> = (props) => {
    const containerStyle = useMemo(() => (props.fullWidth ? { margin: "1rem auto" } : {}), [props.fullWidth]);
    const { LinkComponent } = props;

    if (props.href) {
        return (
            <Link
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                    // color: (theme) => theme.colors.white,
                    textDecoration: "none",
                    padding: "8px 24px",
                }}
                component={LinkComponent ?? LinkBehaviour}
                href={props.href}
                style={containerStyle}
            >
                {props.children}
            </Link>
        );
    }

    return (
        <Box sx={{ whiteSpace: "nowrap", padding: "8px 24px" }} style={containerStyle}>
            {props.children}
        </Box>
    );
};

export const GfTabStrip = (props: {
    onChange?(label: string, index: number, tabId?: number | string): void;
    selectedTab?: number | string;
    children: ReactElement<GfTabItemProps>[] | ReactElement<GfTabItemProps>;
    itemStyle?: CSSProperties;
    style?: CSSProperties;
}) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    const isMobile = useIsMobile();

    const children = useMemo(
        () => (Array.isArray(props.children) ? props.children : [props.children]),
        [props.children]
    );

    const visibleChildren = children.filter((x) => {
        const v = x.props.visible;
        return v === undefined ? true : v;
    });

    const getSelectedTabIndex = useCallback(() => {
        const value = props.selectedTab;

        if (!value) return 0;

        if (typeof value === "number") {
            return value;
        }

        return visibleChildren.findIndex((x) => x.props.label === value || x.props.tabId === value);
    }, [visibleChildren, props.selectedTab]);

    useEffect(() => {
        setSelectedIndex(getSelectedTabIndex());
    }, [getSelectedTabIndex]);

    const onChange = (label: string, index: number, id?: number | string) => {
        setSelectedIndex(index);

        if (props.onChange) {
            props.onChange(label, index, id);
        }
    };

    if (isMobile) {
        return (
            <MobileTabStrip selectedIndex={selectedIndex} style={props.style} onChange={onChange}>
                {children}
            </MobileTabStrip>
        );
    }
    return (
        <Box
            sx={{
                width: "fit-content",
                display: "flex",
                flexDirection: "row",
                borderRadius: (theme) => theme.shape.borderRadius,
                background: (theme) => theme.colors.secondary_background,
            }}
            style={props.style}
        >
            {visibleChildren.map((x, idx) => {
                let classNames = "";
                if (!x.props.disabled && idx === selectedIndex) {
                    classNames += " selected";
                }

                if (x.props.disabled === true) {
                    classNames += " disabled";
                }
                return (
                    <GfTab
                        key={idx}
                        className={classNames}
                        onClick={() => {
                            if (!x.props.disabled) {
                                onChange(x.props.label ?? "", idx, x.props.tabId);
                            }
                        }}
                        style={props.itemStyle}
                    >
                        {cloneElement(x, { key: idx, ...x.props })}
                    </GfTab>
                );
            })}
        </Box>
    );
};

export const GfTabStatusItem = styled("span")(({ theme }) => ({
    fontSize: "0.78em",
    borderRadius: 4,
    padding: "0 5px",
    margin: "2px 5px 2px 5px",
    backgroundColor: theme.colors.white,
}));

const GfTab = styled(Box)(({ theme }) => ({
    transitionDuration: transitionDuration,
    border: `${borderWidth}px solid ${theme.colors.secondary_text}`,
    position: "relative",
    height: 45,
    textAlign: "center",
    fontSize: "1rem",
    fontWeight: "bold",
    borderColor: theme.colors.secondary_text,
    cursor: "default",
    "&>*": {
        color: `${theme.colors.primary_text}`,
    },
    "&>a": {
        color: `${theme.colors.primary_text}`,
    },
    "&-active": {
        borderColor: theme.colors.black,
        color: theme.colors.secondary_text,
        "&:hover": {
            transition: `${transitionDuration} ease-in-out 0s`,
            position: "relative",
            zIndex: 1,
            borderColor: theme.colors.black,
            color: theme.colors.black,
            cursor: "pointer",
        },
    },
    "&:not(:first-of-type)": {
        marginLeft: -borderWidth,
    },
    "&:first-of-type": {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
    },
    "&:last-child": {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
    },
    "&.selected": {
        borderRightStyle: "solid",
        borderRightWidth: borderWidth,
        borderLeftStyle: "solid",
        borderLeftWidth: borderWidth,
        borderColor: theme.colors.main_color,
        background: theme.colors.greys[1],
        "&>*": {
            color: theme.colors.black,
        },
        position: "relative",
        zIndex: 5,
        "&:hover": {
            cursor: "default",
            background: theme.colors.greys[0],
            borderColor: theme.colors.main_color,
            "&>*": {
                color: theme.colors.black,
            },
        },
    },
    "&.disabled": {
        "&>*": {
            color: theme.colors.secondary_text,
        },
        "&:hover": {
            borderColor: theme.colors.secondary_text,
            "&>*": {
                color: theme.colors.secondary_text,
            },
        },
    },
    "&:hover, .tab span": {
        transition: `opacity ${transitionDuration}`,
        position: "relative",
        background: theme.palette.action.hover,
        borderColor: theme.colors.primary_text,
        "&>*": {
            color: theme.colors.white,
        },
        zIndex: 1,
        cursor: "pointer",
    },
    "&:active": {
        backgroundColor: "rgba($color: #cccccc, $alpha: 0.1)",
    },
}));

const MobileTabStrip = (props: {
    selectedIndex: number | undefined;
    onChange(label: string, index: number, tabId?: number | string): void;
    children: ReactElement<GfTabItemProps>[];
    style?: CSSProperties;
}) => {
    const history = useHistory();
    const { selectedIndex, onChange, children } = props;
    const { colors } = useTheme<Theme>();

    const visibleChildren = children.filter((x) => {
        const v = x.props.visible;
        return v === undefined ? true : v;
    });

    return (
        <MbSelect
            fullWidth
            value={selectedIndex}
            style={props.style}
            onChange={(e) => {
                const idx = parseInt((e.target.value as string) ?? "");
                const x = visibleChildren[idx];
                if (x.props.href) {
                    history.push(x.props.href);
                }
                if (!x.props.disabled) {
                    onChange(x.props.label ?? "", idx, x.props.tabId);
                }
            }}
        >
            {children
                .filter((x) => {
                    const v = x.props.visible;
                    return v === undefined ? true : v;
                })
                .map((x, idx) => {
                    return (
                        <MenuItem key={idx} value={idx}>
                            <span>{x.props.label}</span>
                            {x.props.status && (
                                <span style={{ fontSize: 14, color: colors.secondary_text }}>{x.props.status}</span>
                            )}
                        </MenuItem>
                    );
                })}
        </MbSelect>
    );
};
