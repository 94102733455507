import type { SocialLink } from "./SocialBadge";
import { SocialLinkType, getSuffix } from "./SocialBadge";
import { MbTextField } from "../inputs/MbTextField";
import type { Theme } from "@mui/material";
import { useTheme, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SocialInputs = ({ links, onChanged }: { links: SocialLink[]; onChanged(links: SocialLink[]): void }) => {
    const onChange = (type: SocialLinkType, url: string) => {
        onChanged([...links.filter((x) => x.linkType !== type), { linkType: type, url: url }]);
    };

    const values = Object.values(SocialLinkType).filter((x) => {
        return typeof x !== "string";
    }) as SocialLinkType[];

    return (
        <Container>
            {values.map((x) => {
                return (
                    <Box key={x}>
                        <SocialInput type={x} links={links} onChange={onChange} />
                    </Box>
                );
            })}
        </Container>
    );
};

const Container = styled(Box)({
    "& > div": {
        margin: "1rem 0",
    },
});

export const SocialInput = (props: {
    type: SocialLinkType;
    links: SocialLink[];
    onChange: (type: SocialLinkType, url: string) => void;
}) => {
    const { type, onChange } = props;
    const { colors } = useTheme<Theme>();

    const suffix = getSuffix(type);
    const value = props.links.find((x) => x.linkType === type)?.url;

    return (
        <MbTextField
            fullWidth
            value={value || ""}
            label={SocialLinkType[type]}
            style={{ minWidth: 200 }}
            InputProps={{
                startAdornment: suffix && <span style={{ color: colors.secondary_text }}>{suffix}/</span>,
            }}
            inputProps={{ style: { paddingLeft: suffix ? 0 : "notset" } }}
            onChange={(e) => onChange(type, e.currentTarget.value)}
        />
    );
};
