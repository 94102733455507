import { useIsMobile } from "@masterblaster/theme";
import type { ButtonProps, SxProps, Theme } from "@mui/material";
import { Box, Button, styled, Typography } from "@mui/material";
import type { FC, PropsWithChildren } from "react";

interface EmptyViewProps {
    src?: string;
    header: string;
    subheader: string;
    maxWidth?: number;
    textMaxWidth?: number;
    alt?: string;
    actions?: ButtonProps[];
    sx?: SxProps<Theme>;
}

export const EmptyView = (props: EmptyViewProps) => {
    return (
        <Box>
            <EmptyComponent {...props} />
        </Box>
    );
};

export const EmptyComponent: FC<PropsWithChildren<EmptyViewProps>> = (props) => {
    const { src, header, subheader, maxWidth = 200, textMaxWidth = "100%", alt, actions } = props;
    const isMobile = useIsMobile();

    return (
        <EmptyViewPlaceholder sx={props.sx}>
            {src && <EmptyViewImage src={src} alt={alt ?? header} maxWidth={maxWidth ?? "100%"} isMobile={isMobile} />}
            <Box sx={{ maxWidth: textMaxWidth }}>
                <EmptyViewHeader>{header}</EmptyViewHeader>
                <Typography variant="body1" textAlign="center">
                    {subheader}
                </Typography>
            </Box>
            {(actions || props.children) && (
                <EmptyViewButtonGroup>
                    {actions?.map((buttonProps, index) => <Button key={index} {...buttonProps} />)}
                    {props.children}
                </EmptyViewButtonGroup>
            )}
        </EmptyViewPlaceholder>
    );
};

const EmptyViewPlaceholder = styled("div")({
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "10rem 0rem",
});

const EmptyViewImage = styled("img", {
    shouldForwardProp: (prop) => prop !== "maxWidth" && prop !== "isMobile",
})<{ maxWidth: number | string; isMobile: boolean }>(({ maxWidth, isMobile }) => ({
    width: "100%",
    padding: "1rem 0",
    maxWidth: maxWidth,
}));

const EmptyViewHeader = styled("h5")({
    fontFamily: "Inter Variable",
    fontWeight: "bold",
    fontSize: "1.5rem",
    lineHeight: "1.875rem",
    margin: "0",
    textAlign: "center",
});

const EmptyViewButtonGroup = styled("div")({
    marginTop: "2rem",

    "& > :not(:last-child)": {
        marginRight: "1rem",
    },
});
