import { useEffect, useState } from "react";
import { get } from "@masterblaster/api";
import { Comment as CommentIcon } from "@mui/icons-material";
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    RadioGroup,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";
import { FormContainer, Switch, SpinnerButton, MbRadio } from "@masterblaster/basics";
import { useTranslation } from "react-i18next";
import { showMessageDialog } from "@utils/DialogHelpers";

interface CsGameServerMatchBackup {
    id: string;
    mapNumber: number;
    roundNumber: number;
    fileName: string;
    content: string;
    createdAt: string;
    modifiedAt: string;
}

export interface CsMatchRestoreDialogResult {
    backupId: string;
    restart: boolean;
}

export const CsMatchRestoreDialog = (props: {
    matchSeriesId: string;
    onClose(args: CsMatchRestoreDialogResult | undefined): void;
}) => {
    const { matchSeriesId, onClose } = props;
    const [backups, setBackups] = useState<CsGameServerMatchBackup[]>([]);
    const [selectedBackup, setSelectedBackup] = useState("");
    const [restart, setRestart] = useState(false);
    const { t } = useTranslation(["common", "translation"]);

    useEffect(() => {
        get<CsGameServerMatchBackup[]>(`api/get5/backups/${matchSeriesId}`).then((x) => {
            setBackups(x);
        });
    }, [matchSeriesId]);

    const onShowContent = async (content: string) => {
        await showMessageDialog(t("common:shared.backup"), <p style={{ whiteSpace: "pre-wrap" }}>{content}</p>);
    };

    const getRoundNumber = (roundNumber: number) => {
        if (roundNumber === -1) {
            return "prelive";
        }

        return `Round ${roundNumber}`;
    };

    return (
        <Dialog
            open={true}
            fullWidth
            maxWidth={"sm"}
            onClose={() => onClose(undefined)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>
                {t("translation:components.tournament.games.cs_go.match_drawer.match_restore.title")}
            </DialogTitle>
            <DialogContent>
                <FormContainer>
                    <RadioGroup value={selectedBackup} onChange={(e) => setSelectedBackup(e.currentTarget.value)}>
                        <List>
                            {backups.map((x, idx) => {
                                const round = getRoundNumber(x.roundNumber);
                                const text = t(
                                    "translation:components.tournament.games.cs_go.match_drawer.match_restore.match_info",
                                    {
                                        mapNumber: x.mapNumber,
                                        round: round,
                                    }
                                );

                                return (
                                    <ListItem key={idx}>
                                        <ListItemIcon>
                                            <MbRadio value={x.id} />
                                        </ListItemIcon>
                                        <ListItemText primary={text} />
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                edge="end"
                                                aria-label="comments"
                                                onClick={() => onShowContent(x.content)}
                                                size="large"
                                            >
                                                <CommentIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </RadioGroup>

                    <Switch
                        label={t(
                            "translation:components.tournament.games.cs_go.match_drawer.match_restore.restart_server"
                        )}
                        checked={restart}
                        onChanged={(c) => setRestart(c)}
                    />
                </FormContainer>
            </DialogContent>
            <DialogActions>
                <SpinnerButton variant="contained" color="primary" onClick={() => onClose(undefined)}>
                    {t("common:button_actions.cancel")}
                </SpinnerButton>
                <SpinnerButton
                    disabled={!selectedBackup}
                    variant="contained"
                    color="primary"
                    onClick={() => onClose({ backupId: selectedBackup, restart })}
                >
                    {t("common:button_actions.ok")}
                </SpinnerButton>
            </DialogActions>
        </Dialog>
    );
};
