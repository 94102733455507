import { type CSSProperties, type FC, type PropsWithChildren } from "react";
import type { Theme } from "@mui/material";
import { Box, IconButton, useTheme, lighten } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Info, Check, Warning, Close } from "@mui/icons-material";

type Size = "small" | "medium" | "large";
type Type = "warning" | "info" | "error" | "success";

interface MessageBoxProps {
    message?: string;
    size: Size;
    type: Type;
    maxWidth?: number | string;
    noIcon?: boolean;
    onClose?(): void;
}

const TextBox = styled(Box)(({ theme }) => ({
    display: "flex",
    padding: "0 1.2rem",
    textAlign: "center",
    justifyContent: "center",
    color: theme.colors.white,
}));

const GfAlerts: FC<PropsWithChildren<MessageBoxProps>> = ({
    children,
    message,
    size,
    type,
    maxWidth,
    noIcon,
    onClose,
}) => {
    const { colors } = useTheme<Theme>();

    const getColors = () => {
        switch (type) {
            default:
                return { backgroundColor: colors.main_color };
            case "warning":
                return { backgroundColor: colors.important_color };
            case "error":
                return { backgroundColor: colors.error };
            case "success":
                return { backgroundColor: colors.success };
        }
    };

    const height = (size === "small" && 36) || (size === "medium" && 45) || (size === "large" && 60) || 45;
    const iconSize = (size === "small" && 18) || (size === "medium" && 24) || (size === "large" && 30) || 24;
    const fontSize = (size === "small" && 14) || (size === "medium" && 18) || (size === "large" && 24) || 18;
    const lineHeight = `${fontSize + 2}px`;

    const textArea: CSSProperties = { width: "100%", alignItems: "center", borderRadius: "0 3px 3px 0" };
    const baseArea: CSSProperties = { maxWidth: maxWidth || "none", alignItems: "center", borderRadius: 4 };

    const { backgroundColor } = getColors();
    const iconStyle = { fill: colors.white, fontSize: iconSize };
    return (
        <Box display="flex" style={{ ...baseArea, background: backgroundColor }}>
            {!noIcon && (
                <Box display="flex" style={{ padding: `0 ${height / 2 / 2}px`, justifyContent: "center" }}>
                    {type === "info" && <Info style={iconStyle} />}
                    {type === "warning" && <Info style={iconStyle} />}
                    {type === "success" && <Check style={iconStyle} />}
                    {type === "error" && <Warning style={iconStyle} />}
                </Box>
            )}
            <TextBox
                sx={{
                    ...textArea,
                    fontSize,
                    lineHeight,
                    height,
                    background: lighten(backgroundColor, 0.2),
                }}
            >
                {children}
                {!children && <span>{message}</span>}
            </TextBox>
            {onClose && (
                <Box display="flex" style={{ padding: `0 ${height / 2 / 2}px`, justifyContent: "center" }}>
                    <IconButton onClick={onClose} style={{ padding: 5 }} size="large">
                        <Close style={iconStyle} />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};

export default GfAlerts;
