import type { CSSProperties } from "react";
import type { Theme } from "@mui/material";
import { Box, useTheme } from "@mui/material";

import { useTranslation } from "react-i18next";
import { Icon } from "./customIcons";
import type { TFunction } from "i18next";

const useColor = (rank: number | undefined) => {
    const { colors } = useTheme<Theme>();
    if (rank === 1) {
        return colors.gold;
    }
    if (rank === 2) {
        return colors.silver;
    }

    if (rank === 3) {
        return colors.bronze;
    }

    return null;
};

export const Trophy = ({ rank, style }: { rank: number | undefined; style?: CSSProperties }) => {
    const trophyColor = useColor(rank);
    if (!trophyColor) {
        return null;
    }

    return (
        <Box display="flex" style={style} alignItems="center">
            <Icon variant="tournament" style={{ color: trophyColor, fontSize: "1.2em" }} />
        </Box>
    );
};

export const OrdinalRank = ({
    rank,
    fallback,
}: {
    rank: number | undefined;
    fallback?: string;
    style?: CSSProperties;
}) => {
    const { t } = useTranslation(["common"]);

    return <span>{getOrdinalRank(rank, t, fallback)}</span>;
};

export const getOrdinalRank = (rank: number | undefined, t: TFunction<["common"]>, fallback?: string): string => {
    if (!rank || rank === 0) {
        return fallback || "";
    }

    const j = rank % 10,
        k = rank % 100;
    if (j === 1 && k !== 11) {
        return `${rank}${t("common:trophy_places.first")}`;
    }
    if (j === 2 && k !== 12) {
        return `${rank}${t("common:trophy_places.second")}`;
    }
    if (j === 3 && k !== 13) {
        return `${rank}${t("common:trophy_places.third")}`;
    }
    return `${rank}${t("common:trophy_places.other")}`;
};
