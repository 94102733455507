import type { BoxProps } from "@mui/material";
import { Box } from "@mui/material";
import type { FC } from "react";

type ButtonContainerDirection = "row" | "column";
export interface ButtonContainerProps extends BoxProps {
    direction?: ButtonContainerDirection;
    fullWidth?: boolean;
}

export const ButtonContainer: FC<ButtonContainerProps> = (props) => {
    const { direction, fullWidth, ...rest } = props;

    const dir = direction || "row";
    const column = dir === "column";
    const columStyle = {
        "&>*:not(:first-of-type)": {
            marginTop: "0.5rem",
        },
    };
    const rowStyle = {
        "&>*:not(:last-child)": {
            marginRight: "0.5rem",
        },
    };

    const directionStyle = column ? columStyle : rowStyle;

    return (
        <Box
            display="flex"
            sx={{
                display: "flex",
                height: "auto",
                alignItems: dir === "row" ? "center" : "",
                flexDirection: dir ?? "row",
                width: fullWidth ? "100%" : undefined,
                ...directionStyle,
            }}
            {...rest}
        >
            {props.children}
        </Box>
    );
};
