import { MbSelect, type MbSelectProps } from "@masterblaster/basics";
import { CsVersion } from "./CsApi";
import { MenuItem } from "@mui/material";

interface CsVersionSelector extends MbSelectProps<number> {
    version: CsVersion | undefined;
    onChanged: (version: CsVersion) => void;
}

export const CsVersionSelector = (props: CsVersionSelector) => {
    const { version, onChanged, ...rest } = props;
    return (
        <MbSelect
            value={version ?? -1}
            placeholder={{ label: "Select CS version", value: -1 }}
            fullWidth
            {...rest}
            onChange={(e) => {
                const val = e.target.value;
                if (val !== -1) {
                    onChanged?.(val as CsVersion);
                }
            }}
        >
            <MenuItem value={CsVersion.Cs2_meta}>CS2 Internal - Meta</MenuItem>
            <MenuItem value={CsVersion.Cs2_matchxy}>CS2 Internal - MatchXY</MenuItem>
        </MbSelect>
    );
};
