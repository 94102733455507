import type { ReactNode } from "react";
import { Box, styled } from "@mui/material";

export interface PageDetailsProps {
    items?: PageDetailsItem[];
}

export interface PageDetailsItem {
    label: string;
    value: ReactNode | string;
    visible?: boolean;
}

export const PageDetails = (props: PageDetailsProps) => {
    const { items } = props;
    if (!items || items.length === 0) {
        return null;
    }

    return (
        <Box>
            {items
                .filter((x) => x.visible === undefined || x.visible === true)
                .filter((x) => x.value)
                .map((item, idx) => {
                    return (
                        <DetailsContainer key={idx}>
                            <DetailsLabel>{item.label}</DetailsLabel>
                            <DetailsValue>{item.value}</DetailsValue>
                        </DetailsContainer>
                    );
                })}
        </Box>
    );
};

const DetailsContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
}));

const DetailsLabel = styled("span")(({ theme }) => ({
    color: theme.colors.secondary_text,
    fontSize: "0.9rem",
    fontWeight: "500",
}));

const DetailsValue = styled("span")(({ theme }) => ({
    fontSize: "0.9rem",
    fontWeight: 500,
    textAlign: "right",
}));
