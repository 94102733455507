import type { SxProps, Theme } from "@mui/material";
import { Box, useTheme } from "@mui/material";
import type { CSSProperties, FC, PropsWithChildren, ReactNode } from "react";
import { TooltipIcon } from "./tooltips";

export const GfLabelWrapper: FC<
    PropsWithChildren<{
        label: ReactNode | undefined;
        subLabel?: ReactNode | undefined;
        disabled?: boolean;
        tooltip?: string;
        fullWidth?: boolean;
        style?: CSSProperties;
        sx?: SxProps<Theme>;
    }>
> = (props) => {
    const { colors } = useTheme<Theme>();

    if (props.label) {
        return (
            <Box style={{ width: props.fullWidth ? "100%" : "auto", ...props.style }} sx={props.sx}>
                <span
                    style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "0.875rem",
                        lineHeight: "1.375rem",
                        color: props.disabled ? colors.greys[10] : colors.greys[2],
                        ...props.style,
                    }}
                >
                    {props.label}
                    {props.subLabel && (
                        <span
                            style={{
                                fontSize: "0.75rem",
                                marginLeft: "0.25rem",
                                color: props.disabled ? colors.disabled_text : colors.secondary_text,
                            }}
                        >
                            {props.subLabel}
                        </span>
                    )}
                    {props.tooltip && (
                        <>
                            &nbsp;
                            <TooltipIcon title={props.tooltip} />
                        </>
                    )}
                </span>
                {props.children}
            </Box>
        );
    }

    return <Box style={props.style}>{props.children}</Box>;
};
