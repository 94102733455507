import { useSelector } from "react-redux";

import type { ApplicationState } from "@store/core";

import { useQuery } from "@masterblaster/api";

export const useHasAlert = () => {
    const { hasQueryParam } = useQuery();
    const user = useSelector((state: ApplicationState) => state.session.user);

    const changed = hasQueryParam("changed");
    const confirmed = hasQueryParam("confirmed");
    const invalid = hasQueryParam("invalid");
    const expired = hasQueryParam("expired");
    const incomplete = !!(user && !user.isProfileComplete && !window.location.pathname.match("onboarding"));

    return changed || confirmed || invalid || expired || incomplete;
};
