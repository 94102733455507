import { formatDateTime } from "@masterblaster/api";
import { styled } from "@mui/material";

export const ChatMessageSentAt = ({ date }: { date?: string | Date }) => {
    if (!date) {
        return null;
    }

    return <SentAt>{formatDateTime(date)}</SentAt>;
};

const SentAt = styled("span")(({ theme }) => ({
    fontSize: "0.75rem",
    lineHeight: "0.75rem",
    color: theme.colors.greys[8],
}));
