import { DefaultPlayerAvatar } from "@masterblaster/assets";
import md5 from "md5";
import { GfAvatar } from "./GfAvatar";
import type { CSSProperties } from "react";
import type { SxProps, Theme } from "@mui/material";
import { useTheme } from "@mui/material";

export enum PlayerAvatarStatus {
    Offline = 0,
    Online = 1,
    Ready = 2,
}

export const PlayerAvatar = ({
    player,
    status,
    style,
    sx,
}: {
    player: { name: string; avatarUrl: string | undefined } | undefined;
    status?: PlayerAvatarStatus;
    style?: CSSProperties;
    sx?: SxProps<Theme>;
}) => {
    const statusColor = useStatusColor(status);
    const border = `solid 2px ${statusColor}`;

    const getSrc = () => {
        if (!player) {
            return DefaultPlayerAvatar;
        }

        if (player.avatarUrl) {
            return player.avatarUrl;
        }

        if (player.name) {
            return `https://www.gravatar.com/avatar/${md5(player.name)}?d=retro`;
        }
    };

    const src = getSrc();
    return <GfAvatar url={src} mode="Player" noMargin style={{ border: border, ...style }} sx={sx} />;
};

export const useStatusColor = (status: PlayerAvatarStatus | undefined) => {
    const { colors } = useTheme<Theme>();

    switch (status) {
        case PlayerAvatarStatus.Offline:
            return colors.player_offline;
        case PlayerAvatarStatus.Ready:
            return colors.player_ready;
        case PlayerAvatarStatus.Online:
            return colors.player_online;
        default:
            return "transparent";
    }
};
