import { createSlice } from "@reduxjs/toolkit";

export interface SidebarState {
    isSidebarOpen: boolean;
}

const initialState = {
    isSidebarOpen: false,
};

export const sidebarSlice = createSlice({
    name: "sidebar",
    initialState,
    reducers: {
        openSidebar(state) {
            state.isSidebarOpen = true;
        },
        closeSidebar(state) {
            state.isSidebarOpen = false;
        },
    },
});

export const openSidebar = () => {
    return sidebarSlice.actions.openSidebar();
};

export const closeSidebar = () => {
    return sidebarSlice.actions.closeSidebar();
};
