import { Box } from "@mui/material";
import { Switch, MbNumberField, GfLabelWrapper } from "@masterblaster/basics";
import { useTranslation } from "react-i18next";
export const AutoForfeitSettings = ({
    disabled,
    useAutoForfeit,
    forfeitTimeoutInSeconds,
    onChanged,
}: {
    disabled?: boolean;
    useAutoForfeit: boolean;
    forfeitTimeoutInSeconds: number;
    onChanged?(evt: { useAutoForfeit: boolean; forfeitTimeoutInSeconds: number }): void;
}) => {
    const { t } = useTranslation("translation", {
        keyPrefix: "components.tournament.games.cs_go.match_drawer.auto_forfeit_settings",
    });

    return (
        <GfLabelWrapper label={t("automatic_forfeit")} tooltip={t("forfeit_help_text")}>
            <Switch
                label={t("use_automatic_forfeit")}
                disabled={disabled}
                checked={useAutoForfeit}
                onChanged={(c) => onChanged?.({ useAutoForfeit: c, forfeitTimeoutInSeconds })}
            />
            <Box padding="0 1rem">
                <MbNumberField
                    fullWidth
                    disabled={disabled || !useAutoForfeit}
                    label={t("time_to_ready_up")}
                    value={forfeitTimeoutInSeconds ?? 300}
                    onNumberChanged={(v) => {
                        onChanged?.({ useAutoForfeit, forfeitTimeoutInSeconds: v ?? 0 });
                    }}
                />
            </Box>
        </GfLabelWrapper>
    );
};
