import { UnexpectedError } from "./UnexpectedError";
import * as Sentry from "@sentry/browser";
import { HttpError } from "@masterblaster/api";
import type { ErrorInfo } from "react";
import { Component } from "react";

export default class GlobalErrorBoundary extends Component<any, { hasError: boolean; eventId: string | undefined }> {
    state = {
        hasError: false,
        eventId: undefined,
    };

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    handleError = (event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) => {
        if (error instanceof HttpError) {
            return;
        }

        console.log(event);
        console.log(error);
        console.log(`source: ${source}, line: ${lineno}, col: ${colno}`);

        Sentry.withScope((scope) => {
            scope.setExtra("source", source);
            scope.setExtra("line", lineno);
            scope.setExtra("col", colno);
            scope.setExtra("event", JSON.stringify(event, null, 4));
            const eventId = Sentry.captureException(error);
            this.setState({ eventId });
        });

        // this.setState({ hasError: true });
    };

    componentDidMount = () => {
        window.onerror = this.handleError;
    };

    componentWillUnmount = () => {
        window.onerror = null;
    };

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.log(error);
        console.log(errorInfo);
        Sentry.withScope((scope) => {
            scope.setExtra("errorInfo", JSON.stringify(errorInfo, null, 4));
            const eventId = Sentry.captureException(error);
            this.setState({ eventId });
        });
    }

    render() {
        const { hasError, eventId } = this.state;

        if (hasError) {
            //render fallback UI
            return <UnexpectedError eventId={eventId} />;
        }

        if (!this.props.children) {
            return null;
        }

        return <>{this.props.children}</>;
    }
}
