export class HttpError extends Error {
    constructor(
        public method: string,
        public url: string,
        public statusCode: number,
        message: string
    ) {
        super(`HTTP Error: method=${method}, url=${url}, status=${statusCode} error=${message}`);
    }
}
