import type { LinkProps } from "react-router-dom";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

interface DesktopCardLinkProps extends LinkProps {}

export const DesktopCardLink = (props: DesktopCardLinkProps) => {
    return (
        <MatchLinkButtonContainer {...props}>
            <ChevronRightRoundedIcon sx={{ fontSize: "1.25rem" }} />
        </MatchLinkButtonContainer>
    );
};

const MatchLinkButtonContainer = styled(Link)(({ theme }) => ({
    width: "2.5rem",
    backgroundColor: theme.colors.greys[11],
    color: theme.colors.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
}));
