import { customFormatDuration, millisecondsToDuration } from "@masterblaster/api";
import type { SxProps, Theme } from "@mui/material";
import { styled } from "@mui/material";
import type { Duration } from "date-fns";
import { differenceInMilliseconds, formatDuration, isAfter } from "date-fns";
import type { CSSProperties, FC, ReactElement } from "react";
import { useEffect, useState } from "react";
import useInterval from "../notifications/UseInterval";

export const ShortCountDown = ({ date, style, sx }: { date: Date; style?: CSSProperties; sx?: SxProps<Theme> }) => {
    return (
        <TimeCountDown date={date}>
            {({ duration }) => {
                const mins = duration.minutes?.toString().padStart(2, "0") ?? "00";
                const seconds = duration.seconds?.toString().padStart(2, "0") ?? "00";
                return (
                    <StyledSpan style={style} sx={sx}>
                        {mins}:{seconds}
                    </StyledSpan>
                );
            }}
        </TimeCountDown>
    );
};

const StyledSpan = styled("span")({});

export interface TimeCountDownChildProps {
    timeLeft: number;
    completed: boolean;
    ready: boolean;
    duration: Duration;
}

export const TimeCountDown: FC<{
    date: Date | undefined;
    style?: CSSProperties;
    children?: (args: TimeCountDownChildProps) => ReactElement | null;
    onCompleted?: () => void;
}> = (props) => {
    const { date } = props;

    const [initialTime, setInitialTime] = useState<number>();

    useEffect(() => {
        if (date) {
            const now = new Date();
            const after = isAfter(now, date);
            const seconds = after ? 0 : differenceInMilliseconds(date, now);
            setInitialTime(seconds);
        }
    }, [date]);

    const { timeLeft, ready, completed } = useTimer(initialTime ?? 0);
    const dur = millisecondsToDuration(timeLeft);

    if (completed) {
        props.onCompleted?.();
    }

    if (props.children) {
        return props.children({ timeLeft, duration: dur, completed, ready: ready || !date });
    }

    return <span style={props.style}>{formatDuration ? formatDuration(dur) : customFormatDuration(dur)}</span>;
};

export const useTimer = (initialTimeLeftInMs: number, incrementsInMs?: number) => {
    // initialize timeLeft with the seconds prop
    const increments = incrementsInMs ?? 1000;
    const [timeLeft, setTimeLeft] = useState(initialTimeLeftInMs);
    const [ready, setReady] = useState(false);
    const [delay, setDelay] = useState<number | null>(increments);

    useEffect(() => {
        setTimeLeft(initialTimeLeftInMs);
        setDelay(increments);
        setReady(initialTimeLeftInMs > 0);
        // console.log("Update initial time", initialTimeLeft);
    }, [initialTimeLeftInMs, increments]);

    useInterval(() => {
        setTimeLeft((x) => x - increments);
        // console.log("Time left interval", timeLeft);
        if (timeLeft <= 0) {
            // console.log("No time left");
            setDelay(null);
        }
    }, delay);

    // console.log("Time left", timeLeft);
    return { timeLeft: delay === null ? 0 : timeLeft, ready, completed: delay === null };
};
