import { Base64Image, Intro, MbDialog, MbTextField, getTeamGravatarUrl } from "@masterblaster/basics";
import { Box, DialogContent, DialogActions, Button, styled } from "@mui/material";
import { OrganizationImageType, createOrganization } from "@components/organizations/OrganizationApi";
import type { JSXElementConstructor } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useQuery } from "@masterblaster/api";
import { generateLink } from "@utils/generateLink";
import { getOrganizationAspectRatio } from "./organizationPage/OrganizationImageComponent";
import { DefaultTeamAvatar } from "@masterblaster/assets";
import { showModal } from "@store/core";
import { EditableImage } from "@src/images/EditableImage";

export const showCreateOrganizationDialog = () => {
    return showModal<string | undefined>((onClose) => <CreateOrganizationDialog onClose={onClose} />);
};

export const CreateOrganizationPage = () => {
    const history = useHistory();
    const { t } = useTranslation(["common"]);
    const { getQueryParam } = useQuery();

    const [form, setForm] = useState<CreateOrganizationComponentFormData>({
        name: "",
        image: undefined,
    });

    const handleCreate = async () => {
        if (!form.name) {
            return;
        }

        const { data } = await createOrganization(form.name, form.image);
        if (data) {
            const leagueId = getQueryParam("leagueId");
            if (leagueId) {
                history.push(generateLink("LEAGUE_ROUTES.JOIN", { leagueId }));
            } else {
                history.push(generateLink("ORG_ROUTES.PAGE", { id: data }));
            }
        }
    };

    return (
        <Box my="1rem">
            <Intro header="Create organization" subheader="..." />

            <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem", marginTop: "2rem", maxWidth: 450 }}>
                <CreateOrganizationComponent
                    form={form}
                    saveButton={() => (
                        <Button variant="contained" onClick={handleCreate}>
                            {t("common:shared.create")}
                        </Button>
                    )}
                    onChange={setForm}
                />
            </Box>
        </Box>
    );
};

const CreateOrganizationDialog = ({ onClose }: { onClose: (organizationId?: string) => void }) => {
    const { t } = useTranslation("common");

    const [form, setForm] = useState<CreateOrganizationComponentFormData>({
        name: "",
        image: undefined,
    });

    const handleCreate = async (form: CreateOrganizationComponentFormData) => {
        if (!form.name) {
            return;
        }

        const result = await createOrganization(form.name);
        if (result.success && result.data) {
            onClose(result.data);
        }
    };

    return (
        <MbDialog title="Create organization" onClose={() => onClose()}>
            <DialogContent>
                <CreateOrganizationComponent form={form} onChange={setForm} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={() => onClose()}>
                    {t("button_actions.close")}
                </Button>
                <Button variant="contained" onClick={() => handleCreate(form)}>
                    {t("shared.create")}
                </Button>
            </DialogActions>
        </MbDialog>
    );
};

export interface CreateOrganizationComponentFormData {
    name: string | undefined;
    image: Blob | undefined;
}
export const CreateOrganizationComponent = ({
    saveButton: SaveButton,
    form,
    onChange,
}: {
    form: CreateOrganizationComponentFormData;
    saveButton?: JSXElementConstructor<unknown>;
    onChange: (form: CreateOrganizationComponentFormData) => void;
}) => {
    const handleChange = (form: CreateOrganizationComponentFormData) => {
        onChange(form);
    };

    const dim = getOrganizationAspectRatio(OrganizationImageType.Avatar);

    return (
        <CreateOrgContainer>
            <Box sx={{ padding: "0 1rem 1rem 1rem" }}>
                <EditableImage
                    uploadImageCommand="UploadOrganizationImageCommand"
                    allowEdit={true}
                    instantUpload={false}
                    dimensions={dim}
                    onImageChanged={({ imageId, image }) => handleChange({ ...form, image })}
                >
                    <Base64Image
                        blob={form.image}
                        fallbackSrc={form.name ? getTeamGravatarUrl(form.name) : DefaultTeamAvatar}
                        sx={{ width: 128, height: 128 }}
                    />
                </EditableImage>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", padding: "0 1rem", width: 1, gap: "1rem" }}>
                <MbTextField
                    label="Name"
                    fullWidth
                    value={form.name ?? ""}
                    onChange={(e) => handleChange({ ...form, name: e.currentTarget.value })}
                />
                <Box>{SaveButton && <SaveButton />}</Box>
            </Box>
        </CreateOrgContainer>
    );
};

const CreateOrgContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
    },
}));
