import { Box } from "@mui/material";
import type { CSSProperties } from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";

import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { MbDatePicker } from "./MbDatePicker";
import { MbTimePicker } from "./MbTimePicker";
import { isValid, parseISO, set } from "date-fns";

// TODO: fix date input and popper close

const getDate = (date: string | Date | undefined, allowEmptyDate: boolean | undefined) => {
    if (date instanceof Date) {
        return date;
    }

    const currentDate = new Date();

    if (typeof date === "string") {
        try {
            return parseISO(date);
        } catch {
            return allowEmptyDate ? null : currentDate;
        }
    }

    return allowEmptyDate ? null : currentDate;
};

export const DateTimeControl = (props: {
    label?: string;
    date: string | Date | undefined;
    allowEmptyDate?: boolean;
    onDateChanged?: (date: Date) => void;
    minDate?: Date;
    maxDate?: Date | undefined;
    error?: boolean;
    helperText?: string;
    showErrorBeforeBlur?: boolean;
    disabled?: boolean;
    style?: CSSProperties;
}) => {
    const { label, error, helperText, showErrorBeforeBlur, allowEmptyDate, onDateChanged, minDate, maxDate, disabled } =
        props;

    const [blured, setBlured] = useState(false);

    const date = getDate(props.date, allowEmptyDate);

    const changeDate = (value: string | Date, isDate: boolean) => {
        const isNewDateValid = isValid(value);

        if (!isNewDateValid) return date;
        const newDate = new Date(value);

        if (!date) return newDate;

        // if (!date) {
        //     const time = new Date();

        //     return set(newDate, {
        //         hours: time.getHours() + 1,
        //     });
        // }

        if (isDate) {
            const dayOfMonth = newDate.getDate();
            const month = newDate.getMonth();
            const year = newDate.getFullYear();

            return set(date, {
                year,
                month,
                date: dayOfMonth,
            });
        }

        const hours = newDate.getHours();
        const minutes = newDate.getMinutes();
        return set(date, {
            hours,
            minutes,
        });
    };

    const showError = blured || showErrorBeforeBlur;

    return (
        <DateTimeControlContainer style={props.style}>
            <MbDatePicker
                components={{
                    OpenPickerIcon: CalendarTodayIcon,
                }}
                minDate={minDate}
                maxDate={maxDate}
                value={date}
                disabled={disabled}
                //https://github.com/mui/mui-x/issues/4487
                // disableMaskedInput
                onChange={(d) => {
                    if (!d) {
                        return;
                    }

                    const newDate = changeDate(d, true);
                    if (newDate && onDateChanged) {
                        onDateChanged(newDate);
                    }
                }}
                TextFieldProps={{
                    fullWidth: true,
                    error: showError ? error : undefined,
                    label: label,
                    helpText: showError ? helperText : undefined,
                    onBlur: () => setBlured(true),
                }}
            />
            <MbTimePicker
                disableIgnoringDatePartForTimeValidation
                components={{
                    OpenPickerIcon: TimerOutlinedIcon,
                }}
                minTime={minDate}
                maxTime={maxDate}
                value={date}
                disabled={disabled}
                onChange={(time) => {
                    if (!time) {
                        return;
                    }
                    const newDate = changeDate(time, false);
                    if (newDate && onDateChanged) {
                        onDateChanged(newDate);
                        setBlured(true);
                    }
                }}
                TextFieldProps={{
                    fullWidth: true,
                }}
            />
        </DateTimeControlContainer>
    );
};

const DateTimeControlContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "end",
    width: "100%",
    minWidth: "326px",

    "& > :not(:last-child)": {
        margin: "0 1rem 0 0",
    },

    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        minWidth: "unset",

        "& > :not(:last-child)": {
            margin: "0 0 1rem 0",
        },
    },
}));

export default DateTimeControl;
