import * as Sentry from "@sentry/browser";
import { getNick } from "@masterblaster/api";
import type { PlayerDto } from "@masterblaster/api";
import { isValidTopLevelDomain } from "./TopLevelDomain";

export const getPlayerNickname = (player: PlayerDto | undefined, gameId?: string, defaultValue?: string) => {
    const anonNick = defaultValue ?? "Anonymous";
    if (!player) {
        return anonNick;
    }

    if (gameId === undefined) {
        return player.nickName || anonNick;
    }

    const name = getNick(player, gameId);
    if (!name) {
        return player.nickName || anonNick;
    }

    try {
        const regex = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.([a-zA-Z0-9()]{1,6})\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
        const match = name.match(regex);
        const topLevel = match?.groups?.[1];
        if (topLevel !== undefined && isValidTopLevelDomain(topLevel)) {
            //https://regexr.com/3a84o
            return name.replace(regex, "");
        }

        return name;
    } catch (err) {
        Sentry.captureException(err);
        return name;
    }
};
