import type { FC } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import type { BoxProps } from "@mui/material/Box";
import Box from "@mui/material/Box";
import { TeamAvatar } from "@masterblaster/basics";
import type { MatchSeriesTeam } from "../api/MatchSeries";

interface MatchRescheduleTeamBoxProps extends BoxProps {
    teams: MatchSeriesTeam[];
}

export const MatchRescheduleTeamBox: FC<MatchRescheduleTeamBoxProps> = ({ teams, ...boxProps }) => {
    if (!teams.length) return null;

    return (
        <Box mb="1.375rem" {...boxProps}>
            <Typography variant="h6" lineHeight="1.3" fontWeight="bold" mb="0.75rem" textAlign="center">
                {teams[0]?.team?.name} - {teams[1]?.team?.name}
            </Typography>
            <TeamAvatarContainer>
                <TeamAvatar team={teams[0]?.team} size="50" />
                <Typography variant="body1" lineHeight="1.3" fontWeight="bold">
                    vs
                </Typography>
                <TeamAvatar team={teams[1]?.team} size="50" />
            </TeamAvatarContainer>
        </Box>
    );
};

const TeamAvatarContainer = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
});
