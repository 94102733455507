import type { MbSelectProps } from "@masterblaster/basics";
import { MbSelect } from "@masterblaster/basics";
import { Box, MenuItem } from "@mui/material";
import { useGames } from "@src/config";
import { useTranslation } from "react-i18next";
import { GameIcon } from ".";

interface GameSelectorProps extends MbSelectProps<string> {
    gameId: string | undefined;
    onChanged(gameId: string | undefined): void;
}

export const GameSelector = (props: GameSelectorProps) => {
    const { games } = useGames();
    const { t } = useTranslation("translation", {
        keyPrefix: "components.game_accounts.game_selector",
    });

    const { gameId, onChanged, ...rest } = props;

    return (
        <MbSelect
            displayEmpty
            onChange={(e) => {
                const value = e.target.value;
                if (!value) {
                    onChanged(undefined);
                } else {
                    onChanged(value);
                }
            }}
            value={gameId ?? ""}
            placeholder={{ label: t("select_game"), value: "" }}
            {...rest}
        >
            {games.map((x, idx) => {
                return (
                    <MenuItem key={idx} value={x.settings.id}>
                        <Box display="flex" alignItems="center">
                            <GameIcon gameSettings={x.settings} size="small" />
                            <span style={{ marginLeft: "0.5rem" }}>{x.settings.name}</span>
                        </Box>
                    </MenuItem>
                );
            })}
        </MbSelect>
    );
};
