import { GfLink, LinkBehaviour, PlayerAvatar } from "@masterblaster/basics";
import { getPlayerNickname } from "@components/user/utils/DisplayNames";
import type { ListItemIconProps, ListItemProps, GridProps, ListItemSecondaryActionProps } from "@mui/material";
import { Grid, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, styled } from "@mui/material";
import type { PlayerDto } from "@masterblaster/api";
import { generateLink } from "@utils/generateLink";
import { useMemo, useCallback } from "react";
import type { JSXElementConstructor, ReactNode, ReactElement, PropsWithChildren, FC } from "react";
import type { MatchSeriesTeamPlayerStatus } from "@components/matches/api/MatchSeries";
import { mapStatus } from "./PlayerWidget";

export const PlayerListIemGridContainer = styled((props: GridProps) => (
    <Grid container sx={{ listStyle: "none" }} spacing={2} {...props} />
))({});
export const PlayerListItemGrid = styled((props: GridProps) => <Grid item xs={12} sm={6} md={4} lg={3} {...props} />)(
    {}
);

interface PlayerListItemProps extends ListItemProps {
    button?: boolean;
}

export const PlayerListItem = <TPlayer extends PlayerDto & { status?: MatchSeriesTeamPlayerStatus }>({
    player,
    gameId,
    wrapText,
    listItemIcon: CustomListItemIcon,
    listItemSecondaryAction,
    listItemSecondaryActionProps,
    listItemProps,
    secondaryText,
    playerExpansion: PlayerExpansion,
    disablePlayerLink,
    formatPlayerDisplayName,
}: {
    player: TPlayer;
    gameId?: string;
    wrapText?: boolean;
    listItemIcon?: JSXElementConstructor<ListItemIconProps>;
    listItemSecondaryAction?: ReactNode;
    listItemSecondaryActionProps?: ListItemSecondaryActionProps;
    listItemProps?: PlayerListItemProps;
    secondaryText?: ReactNode;
    playerExpansion?: ReactElement | null;
    formatPlayerDisplayName?: (player: TPlayer, nick: string) => ReactElement | string | undefined;
    disablePlayerLink?: boolean;
}) => {
    const { button, sx, ...restListItemProps } = listItemProps ?? { button: false, sx: undefined };
    const nickName = getPlayerNickname(player, gameId);
    const displayName = formatPlayerDisplayName?.(player, nickName) ?? nickName;

    return (
        <>
            <ListItem
                {...restListItemProps}
                sx={{
                    background: (theme) => theme.colors.greys[16],
                    borderLeft: (theme) => `2px solid ${theme.colors.greys[10]}`,
                    margin: "0.25rem 0",
                    ...sx,
                }}
                button={button as false | undefined}
            >
                <PlayerLink disablePlayerLink={disablePlayerLink} playerId={player.id} wrapText={wrapText}>
                    {CustomListItemIcon && <CustomListItemIcon />}
                    <ListItemAvatar>
                        <PlayerAvatar
                            player={{ name: nickName, avatarUrl: player.avatarUrl }}
                            status={mapStatus(player.status)}
                            sx={{ width: 40, height: 40 }}
                        />
                    </ListItemAvatar>
                    <ListItemText
                        primary={displayName}
                        primaryTypographyProps={{
                            sx: wrapText ? {} : { textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" },
                        }}
                        secondary={secondaryText}
                        secondaryTypographyProps={{ component: "div" }}
                    />
                </PlayerLink>
                {listItemSecondaryAction && (
                    <ListItemSecondaryAction {...listItemSecondaryActionProps}>
                        {listItemSecondaryAction}
                    </ListItemSecondaryAction>
                )}
            </ListItem>
            {PlayerExpansion}
        </>
    );
};

const PlayerLink: FC<PropsWithChildren<{ playerId: string; wrapText?: boolean; disablePlayerLink?: boolean }>> = ({
    disablePlayerLink,
    playerId,
    wrapText,
    children,
}) => {
    const profileLink = useMemo(
        () => (playerId ? generateLink("PLAYER_PROFILE_ROUTE", { id: playerId }) : undefined),
        [playerId]
    );
    if (disablePlayerLink || !profileLink) {
        return <>{children}</>;
    }

    return (
        <GfLink
            to={profileLink}
            component={LinkBehaviour}
            style={{ whiteSpace: wrapText ? "normal" : "nowrap" }}
            sx={{
                display: "flex",
                alignItems: "center",
                ...(wrapText ? {} : { textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }),
            }}
        >
            {children}
        </GfLink>
    );
};
