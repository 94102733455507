import { styled, ButtonGroup, Button, type Theme } from "@mui/material";

const createFn = (theme: Theme, selected: boolean | undefined, disabled: boolean | undefined) => {
    return (selectedColor: string, unSelectedColor: string) => {
        if (disabled) {
            return theme.colors.disabled_text;
        }

        return selected ? selectedColor : unSelectedColor;
    };
};

const borderSize = 1;
const selectedBorderSize = 2;
export const GfButtonGroup = styled(ButtonGroup)(() => ({}));

export const GroupButton = styled(Button)<{ selected?: boolean; disabled?: boolean }>(({
    theme,
    selected,
    disabled,
}) => {
    const fn = createFn(theme, selected, disabled);
    return {
        transition: "none",
        borderWidth: selected ? selectedBorderSize : borderSize,
        zIndex: selected ? 2 : 0,
        color: theme.colors.primary_text,
        background: theme.colors.input_background_color,
        borderColor: fn(theme.colors.main_color, theme.colors.input_border_color),
        "&.MuiButtonGroup-grouped:not(:last-of-type)": {
            borderRightColor: fn(theme.colors.main_color, "unset"),
        },
        "&.MuiButton-root": {
            "&:hover": {
                zIndex: 1,
                borderWidth: selected ? selectedBorderSize : borderSize,
                background: theme.palette.action.hover,
                borderColor: fn(theme.colors.main_color, theme.colors.primary_text),
            },
        },
    };
});
