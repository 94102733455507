import type { MapVeto } from "@services/VetoApi";
import { addSeconds, parseISO } from "date-fns";

import type { VetoConfiguration } from "@masterblaster/api";
import type { SxProps } from "@mui/material";
import { Box } from "@mui/material";
import { ShortCountDown } from "../shared/CountDown";

export const VetoCountDown = ({
    veto,
    vetoConfiguration,
    sx,
}: {
    veto: MapVeto;
    vetoConfiguration: VetoConfiguration;
    sx?: SxProps;
}) => {
    if (veto.completed) {
        return null;
    }

    if (!vetoConfiguration.useVeto || !vetoConfiguration.useAutoVeto) {
        return null;
    }

    const firstMap = veto.maps.filter((x) => x.chosenByTeamId === veto.nextTeamToChoose).length === 0;
    const timeout = firstMap ? vetoConfiguration.timeToVetoFirstMapInSeconds : vetoConfiguration.timeToVetoInSeconds;

    if (timeout === 0) {
        return <span></span>;
    }

    const deadline = addSeconds(parseISO(veto.lastMapSelectedAt), timeout);
    return (
        <Box sx={{ display: "flex", justifyContent: "center", ...sx }}>
            <ShortCountDown date={deadline} />
        </Box>
    );
};
