import { Trans } from "react-i18next";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import { Notification } from "../Notification";
import { NotificationTitle } from "../shared";
import type { NotificationElementProps } from "../NotificationElementProps";
import { generateLink } from "@utils/generateLink";
import type { NotificationPayload } from "../NotificationService";
import { formatDateTime } from "@masterblaster/api";

interface MatchSeriesRescheduleProposed extends NotificationPayload {
    matchSeriesId: string;
    matchSeriesName: string;
    competitionId: string;
    competitionName: string;
    teamId: string;
    scheduledAt: string;
    proposingTeamId: string;
    proposingTeamName: string;
    recipientTeamId: string;
    recipientTeamName: string;
}

export const NotificationMatchSeriesRescheduled = ({ notification, context, navigateTo }: NotificationElementProps) => {
    const data = notification.payload as MatchSeriesRescheduleProposed;

    const handleClick = async () => {
        await context.markAsRead(notification.id);
        const url = generateLink("MATCH_ROUTES.LOBBY", {
            id: data.matchSeriesId,
        });
        navigateTo(url);
    };

    const i18key = data.matchSeriesName
        ? "components.notifications.notification_type.match_series_rescheduled_v2"
        : "components.notifications.notification_type.match_series_rescheduled";

    return (
        <Notification
            createdAt={notification.createdAt}
            isRead={!!notification.readAt}
            image={<TimerOutlinedIcon />}
            title={
                <NotificationTitle>
                    <Trans
                        ns="translation"
                        i18nKey={`${i18key}.was_proposed_new_time`}
                        components={[<strong key={0} />]}
                        values={{
                            proposingTeamName: data.proposingTeamName,
                            matchName: `${data.matchSeriesName} - ${data.competitionName}`,
                            newTime: formatDateTime(data.scheduledAt),
                            competitionName: data.competitionName,
                        }}
                    />
                </NotificationTitle>
            }
            onClick={handleClick}
        />
    );
};

export const NotificationMatchSeriesRescheduleAccepted = ({
    notification,
    context,
    navigateTo,
}: NotificationElementProps) => {
    const data = notification.payload as MatchSeriesRescheduleProposed;

    const handleClick = async () => {
        await context.markAsRead(notification.id);
        const url = generateLink("MATCH_ROUTES.LOBBY", {
            id: data.matchSeriesId,
        });
        navigateTo(url);
    };

    const i18key = data.matchSeriesName
        ? "components.notifications.notification_type.match_series_rescheduled_v2"
        : "components.notifications.notification_type.match_series_rescheduled";

    return (
        <Notification
            createdAt={notification.createdAt}
            isRead={!!notification.readAt}
            image={<TimerOutlinedIcon />}
            title={
                <NotificationTitle>
                    <Trans
                        ns="translation"
                        i18nKey={`${i18key}.accepeted_proposed_new_time`}
                        components={[<strong key={0} />]}
                        values={{
                            recipientTeamName: data.recipientTeamName,
                            matchName: `${data.matchSeriesName} - ${data.competitionName}`,
                            newTime: formatDateTime(data.scheduledAt),

                            competitionName: data.competitionName,
                        }}
                    />
                </NotificationTitle>
            }
            onClick={handleClick}
        />
    );
};

export const NotificationMatchSeriesRescheduleRejected = ({
    notification,
    context,
    navigateTo,
}: NotificationElementProps) => {
    const data = notification.payload as MatchSeriesRescheduleProposed;

    const handleClick = async () => {
        await context.markAsRead(notification.id);
        const url = generateLink("MATCH_ROUTES.LOBBY", {
            id: data.matchSeriesId,
        });
        navigateTo(url);
    };

    const i18key = data.matchSeriesName
        ? "components.notifications.notification_type.match_series_rescheduled_v2"
        : "components.notifications.notification_type.match_series_rescheduled";

    return (
        <Notification
            createdAt={notification.createdAt}
            isRead={!!notification.readAt}
            image={<TimerOutlinedIcon />}
            title={
                <NotificationTitle>
                    <Trans
                        ns="translation"
                        i18nKey={`${i18key}.rejected_proposed_new_time`}
                        components={[<strong key={0} />]}
                        values={{
                            recipientTeamName: data.recipientTeamName,
                            matchName: `${data.matchSeriesName} - ${data.competitionName}`,
                            newTime: formatDateTime(data.scheduledAt),

                            competitionName: data.competitionName,
                        }}
                    />
                </NotificationTitle>
            }
            onClick={handleClick}
        />
    );
};
