import { Box, styled } from "@mui/material";
import { type CSSProperties, Children, type FC, type PropsWithChildren } from "react";

export const DetailsContainer: FC<PropsWithChildren<{ style?: CSSProperties }>> = (props) => {
    if (!props.children) {
        return null;
    }

    const childCount = Children.count(props.children);
    if (childCount === 0) {
        return null;
    }

    const validChildren = Children.map(props.children, (x) => x)?.filter((x) => !!x) ?? [];
    if (validChildren.length === 0) {
        return null;
    }

    return <Container style={props.style}>{props.children}</Container>;
};

const Container = styled(Box)(({ theme }) => ({
    padding: "1rem",
    border: `2px solid ${theme.colors.border}`,
    borderTop: "none",
    "& > *": {
        "&:not(:first-of-type)": {
            marginTop: "0.5rem",
        },
    },
}));
