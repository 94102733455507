import { useDrawerContext } from "@masterblaster/basics";
import { BestOfXSelector } from "@components/competitions/tournament/games/BestOfXSelector";
import { Box } from "@mui/material";
import { sendCommand } from "@masterblaster/api";
import { useState } from "react";
import type { MatchSeries } from "..";

export const MatchBoXInput = ({ disabled, matchSeries }: { disabled?: boolean; matchSeries: MatchSeries }) => {
    const [numberOfMaps, setNumberOfMaps] = useState(matchSeries.settings.numberOfMaps);
    const { keyChanged, SaveButton } = useDrawerContext("MatchBoXInput");

    const handleSave = async () => {
        await sendCommand("UpdateMatchSeriesBoXCommand", {
            matchSeriesId: matchSeries.id,
            numberOfMaps,
        });
    };

    return (
        <Box>
            <BestOfXSelector
                numberOfMaps={numberOfMaps}
                disabled={disabled}
                onChanged={(v) => {
                    setNumberOfMaps(v);
                    keyChanged();
                }}
            />

            <SaveButton onClick={handleSave} sx={{ marginTop: "1rem" }} />
        </Box>
    );
};
