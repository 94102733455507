import type { PaginationResult } from "@masterblaster/api";
import { get, getSwr, sendCommand } from "@masterblaster/api";
import { createContext, useCallback, useEffect, useState } from "react";
import type { ApplicationState } from "@store/core";
import { useSelector } from "react-redux";
import type {
    ChatUnreadMessagesUpdated,
    NotificationAdded,
    NotificationCountChanged,
    NotificationMarkedAsRead,
    NotificationRemoved,
} from "@messaging/core";
import { useUserSubscription } from "@messaging/core";
import _ from "lodash";
import type { ChatUnreadMessagesPayload } from "./types/NotificationChatUnreadMessages";

export type NotificationType =
    | "TeamMemberInvited"
    | "TeamJoinRequested"
    | "TeamJoinRequestAccepted"
    | "TeamJoinRequestRejected"
    | "CompetitionCheckinOpen"
    | "MatchSeriesResheduleProposal"
    | "TournamentCheckinOpen"
    | "MatchSeriesResheduleProposalAccepted"
    | "MatchSeriesResheduleProposalRejected"
    | "ChallengeStarted"
    | "OrganizationPlayerInvited"
    | "ChallengeOpponentInvited"
    | "CompetitionCheckinOpenPayload"
    | "ChatUnreadMessagesPayload";

export interface NotificationPayload {}

export interface PlayerData {
    notificationData: {
        count: number;
    };
}

export interface Notification<T = NotificationPayload> {
    id: string;
    createdAt: string;
    playerId: string;
    payload: T;
    payloadType: NotificationType;
    readAt: string | undefined;
}

export const getUnreadChatMessages = (playerId: string | undefined) =>
    getSwr<Notification<ChatUnreadMessagesPayload>[]>(playerId ? `api/chat/unread/${playerId}` : null);
export const getPlayerNotifications = () => get<PaginationResult<Notification>>("api/Notifications/range/0/20");
export const getUserData = () => get<PlayerData>("api/user/data");

const markNotificationAsRead = (notificationId: string) => sendCommand("MarkNotificationAsRead", { notificationId });
const resetNotificationCount = () => sendCommand("ResetNotificationCountCommand", {});

export interface NotificationsContextProps {
    notifications: Notification[];
    unreadChats: Notification<ChatUnreadMessagesPayload>[];
    markAsRead: (notificationId: string) => Promise<void>;
    resetNotificationCount: () => Promise<void>;
    count: number;
}

export const NotificationsContext = createContext<NotificationsContextProps>(undefined!);

const sortNotification = (notifications: Notification[]) =>
    _.chain(notifications)
        .filter((x) => !!x)
        .orderBy((x) => new Date(x.createdAt), "desc")
        .value();

export const useNotifications = () => {
    const [notificationCount, setNotificationCount] = useState<number>(0);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const user = useSelector((state: ApplicationState) => state.session.user);

    const { data: unreadChats, mutate: mutateUnreadChats } = getUnreadChatMessages(user?.id);

    const fetchNotifications = useCallback(() => {
        if (user?.id) {
            getPlayerNotifications().then((x) => {
                setNotifications(x.data);
            });
        }
    }, [user?.id]);

    const fetchUserData = useCallback(() => {
        if (user?.id) {
            getUserData().then((x) => {
                setNotificationCount(x.notificationData.count);
            });
        }
    }, [user?.id]);

    useEffect(() => {
        fetchNotifications();
        fetchUserData();
    }, [fetchNotifications, fetchUserData]);

    const setNotificationAsRead = (notificationId: string, readAt: string) => {
        const item = notifications.find((x) => x.id === notificationId);

        if (!item) {
            return;
        }

        const rest = notifications.filter((x) => x.id !== notificationId);
        const updated = [...rest, { ...item, readAt }];
        setNotifications(sortNotification(updated));
    };

    const markAsRead = async (notificationId: string) => {
        const notification = notifications.find((x) => x.id === notificationId);
        if (!notification || notification.readAt) {
            return;
        }

        const result = await markNotificationAsRead(notificationId);

        if (result.success) {
            setNotificationAsRead(notificationId, new Date().toDateString());
        }
    };

    const resetCount = async () => {
        if (notificationCount === 0) {
            return;
        }

        const result = await resetNotificationCount();
        if (result.success) {
            setNotificationCount(0);
        }
    };

    useUserSubscription<NotificationCountChanged>("NotificationCountChanged", (evt) => {
        setNotificationCount(evt.count);
    });

    useUserSubscription<NotificationAdded>(
        "NotificationAdded",
        (evt) => {
            setNotifications((x) => sortNotification([...x, { ...evt, readAt: undefined }]));
            setNotificationCount(evt.count);
        },
        [notifications, notificationCount]
    );

    useUserSubscription<NotificationRemoved>(
        "NotificationRemoved",
        (evt) => {
            setNotifications((x) => sortNotification([...x.filter((y) => y.id !== evt.notificationId)]));
            setNotificationCount(evt.count);
        },
        [notifications, notificationCount]
    );

    useUserSubscription<NotificationMarkedAsRead>(
        "NotificationMarkedAsRead",
        (evt) => {
            setNotificationAsRead(evt.notificationId, evt.readAt);
        },
        [notifications]
    );

    useUserSubscription<ChatUnreadMessagesUpdated>(
        "ChatUnreadMessagesUpdated",
        (evt) => {
            mutateUnreadChats(evt.notifications, { revalidate: false });
        },
        [mutateUnreadChats]
    );

    return {
        notifications,
        unreadChats: unreadChats ?? [],
        count: notificationCount,
        markAsRead,
        resetNotificationCount: resetCount,
    } as NotificationsContextProps;
};
