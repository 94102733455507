import { getSwr } from "@masterblaster/api";
import type { PlayerDto } from "../players";
import { useCallback } from "react";
import type { GameSetup, GameType, MapPool } from "./GameTypes";

export const useGames = (allGames: GameSetup[], isAdmin: boolean, getUnpublished?: boolean) => {
    const games = isAdmin || getUnpublished ? allGames : allGames.filter((x) => x.settings.published);
    const getGame = useCallback((game: GameType) => games.find((x) => x.settings.game === game), [games]);
    const getGameById = useCallback((gameId: string) => games.find((x) => x.settings.id === gameId), [games]);

    const getPlayerGames = useCallback(
        (player: PlayerDto | undefined) => {
            if (!player) {
                return [];
            }

            return games.filter((x) => {
                return player.gameAccounts.find((y) => y.gameId === x.settings.id) !== undefined;
            });
        },
        [games]
    );
    return { games, getGame, getGameById, getPlayerGames };
};

export const useMapPools = (gameId: string | undefined) => {
    const { data: mapPools, isLoading } = getSwr<MapPool[]>(gameId ? `api/games/map_pools/${gameId}` : null);

    return {
        mapPools: mapPools ?? [],
        isLoading,
    };
};

export interface GamePlayerFormat {
    text: string;
    selectorText: string;
    mode: PlayerFormatType;
}
export type PlayerFormatType = "Circle" | "Rect";
export const getPlayerFormat = (gameSetup: GameSetup, numberOfPlayers: number, solo?: boolean): GamePlayerFormat => {
    const teamSize = gameSetup.settings.teamSizes.find((x) => x.numberOfPlayers === numberOfPlayers);
    const name = teamSize?.name;
    const text = solo ? `${numberOfPlayers}` : `${numberOfPlayers}v${numberOfPlayers}`;
    const displayText = name ? name : text;
    const selectorText = name ? name : `${numberOfPlayers} player(s)`;

    const mode = displayText.length > 3 ? "Rect" : "Circle";
    return {
        text: displayText,
        selectorText,
        mode: mode,
    };
};
