import type { MutableRefObject } from "react";
import { useEffect } from "react";

const useClickOutside = (
    enable: boolean,
    container: MutableRefObject<HTMLDivElement | null>,
    onClickOutside: () => void
) => {
    useEffect(() => {
        if (!enable) return;

        const handleClick = (event: MouseEvent) => {
            if (!container.current) return;

            const { target } = event;

            if (target instanceof Node && !container.current.contains(target)) {
                onClickOutside?.();
            }
        };

        document.addEventListener("click", handleClick, true);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, [container, enable, onClickOutside]);
};

export { useClickOutside };
