import * as i18n from "i18next";
import { initReactI18next } from "react-i18next";
import league from "../components/competitions/leagues/translations/league-en-US.json";
import enUSCommon from "./en-US/common.json";
import enUSTranslation from "./en-US/translation.json";
import enUSValidation from "./en-US/validation.json";

export const resources = {
    en: {
        common: enUSCommon,
        validation: enUSValidation,
        translation: enUSTranslation,
        league,
    },
} as const;

export const defaultNS = "translation";

i18n.use(initReactI18next).init({
    lng: "en-US",
    fallbackLng: "en",
    ns: ["common", "validation", "translation", "league"],
    resources,
    defaultNS,
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
});

export default i18n;
