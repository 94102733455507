import { LinkBehaviour } from "../GfLink";
import { Button, styled } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export const BackButtonContainer = ({ href, text }: { href: string; text?: string }) => {
    return (
        <StyledBackButtonContainer>
            <Button variant="text" LinkComponent={LinkBehaviour} href={href} startIcon={<ChevronLeftIcon />}>
                {text ?? "Back"}
            </Button>
        </StyledBackButtonContainer>
    );
};

const StyledBackButtonContainer = styled("div")(({ theme }) => ({
    border: "1px solid",
    borderRadius: "5px",
    marginBottom: "1.5rem",
    borderColor: theme.colors.greys[12],
    backgroundColor: theme.colors.greys[15],
}));
