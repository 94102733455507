import { DefaultPlayerAvatar, DefaultTeamAvatar } from "@masterblaster/assets";
import type { AvatarProps } from "@mui/material";
import { Avatar } from "@mui/material";
import type { FC } from "react";

type AvatarMode = "Player" | "Team" | "Organization";
export interface GfAvatarProps extends Omit<AvatarProps, "src"> {
    url: string | undefined;
    mode: AvatarMode;
    noMargin?: boolean;
    size?: number;
}
const getDefaultAvatar = (mode: AvatarMode) => {
    switch (mode) {
        case "Player":
            return DefaultPlayerAvatar;
        case "Team":
            return DefaultTeamAvatar;
        case "Organization":
            return DefaultTeamAvatar;
        default:
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            throw new Error(`Unknown avatar mode: '${mode}'`);
    }
};

export const GfAvatar: FC<GfAvatarProps> = ({ sx, url, mode, noMargin, size, ...avatarProps }) => {
    const defaultAvatar = getDefaultAvatar(mode);
    return (
        <Avatar
            src={url || defaultAvatar}
            sx={{ width: size ?? 30, height: size ?? 30, ...sx }}
            {...avatarProps}
        ></Avatar>
    );
};
