import type { IResponse } from "@masterblaster/api";
import { ErrorText, InlineNotification } from "@masterblaster/basics";

import { Box } from "@mui/material";
import * as Sentry from "@sentry/browser";
import { showSnackbar } from "@utils/DialogHelpers";

export const showCommandError = async (commandName: string, error: any) => {
    Sentry.withScope((scope) => {
        scope.setExtra("command", commandName);
        Sentry.captureException(error);
    });

    await showSnackbar({
        variant: "error",
        message: `Operation '${commandName}' failed, please try again`,
    });
};

let isGetErrorShown = false;
export const showGetError = async (url: string, error: any) => {
    Sentry.withScope((scope) => {
        scope.setExtra("url", url);
        Sentry.captureException(error);
    });

    const status = error?.statusCode;
    const accessDenied = status === 401 || status === 403 || status === 404;
    const message = accessDenied
        ? "Access denied"
        : "Oops... Something went wrong while fetching data. Please try again.";
    const variant = accessDenied ? "error" : "warning";

    if (!isGetErrorShown) {
        isGetErrorShown = true;

        await showSnackbar({
            message,
            variant,
            autoHideDuration: 2000,
        });

        isGetErrorShown = false;
    }
};

export const showError = async (error: string) => {
    Sentry.withScope((scope) => {
        Sentry.captureException(error, scope);
    });

    await showSnackbar({
        variant: "error",
        message: error ?? "Unknown error",
    });
};

export const showResponseError = async (response?: IResponse) => {
    await showSnackbar({
        variant: "error",
        message: response?.errors ? response.errors.join("\n") : "Unknown error",
    });
};

interface IErrorMessageProps {
    errors?: string[];
    children?: any;
}

export const ErrorMessage = ({ errors }: IErrorMessageProps) => {
    return errors ? (
        <div>
            {errors.map((e, index) => (
                <ErrorText key={index} size="sm">
                    {e}
                </ErrorText>
            ))}
        </div>
    ) : null;
};

export const InlineErrorMessages = ({ errors }: { errors: string[] }) => {
    return (
        <Box display="flex" flexDirection="column" gap="0.5rem">
            {errors.map((x, idx) => (
                <InlineNotification key={idx} color="highEmphasis" variant="error" label={x} />
            ))}
        </Box>
    );
};
