import { useMatchViewMode } from "@components/matches/api/MatchApi";
import { type MatchSeries } from "@components/matches/api/MatchSeries";
import type { GameMode, IUser } from "@masterblaster/api";
import { Box } from "@mui/material";
import { useGame } from "@src/config";
import type { FC, JSXElementConstructor } from "react";
import { MatchTeams } from "./MatchTeams";
import { MatchScoreBox } from "./MatchScoreBox";

interface MatchCardCenterPieceProps {
    matchSeries: MatchSeries;
    user?: IUser;
    header?: JSXElementConstructor<{ matchSeries: MatchSeries }>;
    divider?: JSXElementConstructor<{ matchSeries: MatchSeries; gameMode: GameMode }>;
}

export const MatchCardCenterPiece: FC<MatchCardCenterPieceProps> = ({ matchSeries, user, header: Header }) => {
    const { settings } = useGame(matchSeries.gameId);
    const { viewMode } = useMatchViewMode(matchSeries);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                overflow: "hidden",
                gap: "0.5rem",
            }}
        >
            {Header && <Header matchSeries={matchSeries} />}
            <Box
                sx={(theme) => ({
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    overflow: "hidden",
                    gap: "0.75rem",
                })}
            >
                <MatchTeams
                    gameMode={settings.mode}
                    teams={matchSeries.teams}
                    user={user}
                    matchType={matchSeries.matchType}
                    viewMode={viewMode}
                    centerPiece={<MatchScoreBox matchSeries={matchSeries} gameMode={settings.mode} />}
                />
            </Box>
        </Box>
    );
};
