import { AccentContainer } from "@masterblaster/basics";
import { Box, useTheme } from "@mui/material";
import type { ReactNode } from "react";
import { DesktopCardLink } from "../../shared/DesktopCardLink";
import { MenuContainer } from "../../shared/MenuContainer";

export interface DesktopMatchCardProps {
    isActive: boolean;
    href: string;
    centerSection: ReactNode;
    rightSection: ReactNode;
    menuSection?: ReactNode;
    onClick?: () => void;
}

export const DesktopMatchCard = ({
    isActive,
    href,
    rightSection,
    menuSection,
    centerSection,
    onClick,
}: DesktopMatchCardProps) => {
    return (
        <AccentContainer
            enableBoxShadow={isActive}
            active={isActive}
            sx={{
                display: "flex",
                boxSizing: "border-box",
                backgroundColor: (theme) => theme.colors.secondary_background,
            }}
        >
            <Box
                sx={{
                    flex: "auto",
                    alignSelf: "center",
                    overflow: "hidden",
                    padding: "0.5rem 0",
                }}
            >
                {centerSection}
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flex: "0 0 11rem",
                    position: "relative",
                    alignItems: "center",
                    justifyContent: "end",
                }}
            >
                <Box sx={{ marginRight: "0.5rem" }}>{rightSection}</Box>
                {menuSection && <MenuContainer active={isActive}>{menuSection}</MenuContainer>}
                <DesktopCardLink to={href} onClick={onClick} />
            </Box>
        </AccentContainer>
    );
};
