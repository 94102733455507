import { type FC } from "react";
import { Notification } from "../Notification";
import { NotificationTitle } from "../shared";
import { type NotificationElementProps } from "../NotificationElementProps";
import type { NotificationPayload } from "../NotificationService";
import { acceptOrganizationInvite, rejectOrganizationInvite } from "@components/organizations";

interface OrganizationPlayerInvitePayload extends NotificationPayload {
    organizationId: string;
    organizationName: string;
    invitedByPlayerName: string;
    invitedByPlayerId: string;
}

export const NotificationOrganizationPlayerInvite: FC<NotificationElementProps> = ({
    notification,
    context,
    navigateTo,
    t,
}) => {
    const invite = notification.payload as OrganizationPlayerInvitePayload;

    const handleClick = async () => {
        await context.markAsRead(notification.id);
        const url = `/organization/${invite.organizationId}/join`;
        navigateTo(url);
    };

    const handleActionClick = async (accept: boolean) => {
        if (accept) {
            await acceptOrganizationInvite(invite.organizationId);
        } else {
            await rejectOrganizationInvite(invite.organizationId);
        }

        await context.markAsRead(notification.id);
    };

    return (
        <Notification
            createdAt={notification.createdAt}
            isRead={!!notification.readAt}
            title={
                <NotificationTitle>
                    <span>
                        You were invited to the organization {invite.organizationName} by {invite.invitedByPlayerName}
                    </span>
                </NotificationTitle>
            }
            buttonActions={
                !notification.readAt
                    ? [
                          {
                              variant: "contained",
                              children: t("common:button_actions.accept"),
                              onClick: () => handleActionClick(true),
                          },
                          {
                              variant: "contained",
                              color: "secondary",
                              children: t("common:button_actions.decline", {
                                  defaultValue: "Decline",
                              }),
                              onClick: () => handleActionClick(false),
                          },
                      ]
                    : undefined
            }
            onClick={handleClick}
        />
    );
};
