import * as Sentry from "@sentry/browser";
import { Box, Grid, Button } from "@mui/material";
import { getEmail, getFullName } from "@mb/auth";
import { useTranslation } from "react-i18next";
import { type FC } from "react";
import { styled } from "@mui/material/styles";

export const UnexpectedError: FC<{ eventId?: string }> = (props) => {
    const { t } = useTranslation("translation", {
        keyPrefix: "errors.unexpected_error",
    });

    const goBack = () => {
        window.location.href = "/";
    };

    const report = () => {
        try {
            const name = getFullName();
            const email = getEmail();
            Sentry.showReportDialog({
                eventId: props.eventId,
                user: {
                    name: name,
                    email: email,
                },
            });
        } catch {}
    };

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "90vh" }}
        >
            <Grid item xs={12}>
                <Grid container direction="column">
                    <Grid container direction="column">
                        <PageHeader>{t("header")}</PageHeader>
                        <PageSubheader as="h2">{t("subheader")}</PageSubheader>
                    </Grid>
                </Grid>
                <Box display="flex" style={{ marginTop: "1rem" }}>
                    <Button variant="contained" color="secondary" onClick={goBack}>
                        {t("go_back")}
                    </Button>

                    <Button variant="contained" color="primary" style={{ marginLeft: "1rem" }} onClick={report}>
                        {t("report_feedback")}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

const PageHeader = styled("h1")({
    margin: 0,
    fontSize: "3.5625rem",
    lineHeight: "3.375rem",
    letterSpacing: "-1px",
});

const PageSubheader = styled(PageHeader)({
    fontWeight: 300,
});
