import type { LeagueDto } from "@components/competitions/leagues";
import type { CompetitionTeamDto, SelectedTournamentTeamPlayer } from "@components/team";
import { TeamJoinStatus } from "@components/team";
import type {
    CompetitionAccessMode,
    CompetitionBuyInMode,
    CompetitionDto,
    CompetitionFormat,
    PaginationResult,
    SwissConfiguration,
    TournamentDto,
} from "@masterblaster/api";
import { CompetitionStatus, get, getSwr, sendCommand } from "@masterblaster/api";
import type {
    CompetitionFinished,
    CompetitionTeamAdded,
    CompetitionTeamRemoved,
    CompetitionTeamUpdated,
    TournamentUpdated,
} from "@messaging/core";
import { useTopicIdSubscription } from "@messaging/core";
import { showConfirmDialog } from "@utils/DialogHelpers";
import { generateLink } from "@utils/generateLink";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface TournamentHistory {
    id: string;
    name: string;
    gameId: string;
    date: string;
    status: CompetitionStatus;
    rank: number;
    winnings: string;
    teamId: string;
    teamName: string;
}

export interface CreateChallengeRequest {
    teamSize: number;
    mapPoolId: string | undefined;
    numberOfSubstitutes: number;
    numberOfMaps: number;
    locationId: string;
}

export interface CreateCompetitionRequest {
    communityId: string | undefined;
    leagueId: string | undefined;
    groupId: string | undefined;
    name: string;
    buyInMode: CompetitionBuyInMode;
    featured: boolean;
    frontPage: boolean;
    gameId: string | undefined;
    maxTeams: number | undefined;
    teamSize: number;
    supportLink: string;
    numberOfSubstitutes: number;
    publicTournament: boolean;
    platforms: string[];
    format: CompetitionFormat;
    mapPoolId: string | undefined;
    grandFinalAdvantage: number | undefined;
    swiss: SwissConfiguration;
}

export interface TournamentCardViewModel {
    id: string;
    name: string;
    type: string;
    status: CompetitionStatus;
    gameId: string;
    platforms: string[];
    coverImageUrl: string;

    buyInMode: CompetitionBuyInMode;

    isRegistered: boolean;
    isCheckedIn: boolean;

    scheduledTime?: string;
    registrationCloses?: string;

    maxTeams: number;
    teamSize: number;
    prizePool: string;
    teamJoinProgress: number;
    joinedTeams: number;

    communityId: string | undefined;
    communityName: string | undefined;
    communityAvatarUrl: string | undefined;

    accessMode: CompetitionAccessMode;
    tenantHost: string | undefined;
}

/*
    HOOKS
*/
export const useMatchCompetitionTeams = (competitionId: string | undefined, matchSeriesId: string | undefined) => {
    const { data: competitionTeams, mutate } = getMatchCompetitionTeams(matchSeriesId);

    useTopicIdSubscription<CompetitionTeamUpdated>(
        competitionId,
        "CompetitionTeamUpdated",
        (evt) => {
            if (!competitionTeams?.find((x) => x.id === evt.competitionTeam.id)) {
                return;
            }

            mutate(
                (prev) => (prev ? [...prev.filter((x) => x.id !== evt.competitionTeam.id), evt.competitionTeam] : prev),
                {
                    revalidate: false,
                }
            );
        },
        [mutate, competitionTeams]
    );

    return {
        competitionTeams,
    };
};

export const useCompetitionTeam = (competitionId: string | undefined, teamId: string | undefined) => {
    const { data: competitionTeam, mutate } = getCompetitionTeam(competitionId, teamId);

    useTopicIdSubscription<CompetitionTeamUpdated>(
        competitionId,
        "CompetitionTeamUpdated",
        (evt) => {
            if (evt.competitionTeam.teamId === teamId) {
                mutate(evt.competitionTeam, { revalidate: false });
            }
        },
        [mutate, teamId]
    );

    return {
        competitionTeam,
    };
};

export const useLeagueCompetitionTeams = (league: LeagueDto | undefined) => {
    const competitionIds = useMemo(() => league?.competitions.map((x) => x.competitionId), [league?.competitions]);

    return useLoadAndSubscribe(league?.id, [league?.id, ...(competitionIds ?? [])]);
};

export const useCompetitionTeams = (competitionId: string | undefined) => {
    return useLoadAndSubscribe(competitionId, [competitionId]);
};

const useLoadAndSubscribe = (
    competitionId: string | undefined,
    subscriptionIds: (string | undefined)[] | undefined
) => {
    const { data: competitionTeams, mutate, isLoading } = getCachedCompetitionTeams(competitionId);

    const ids = (subscriptionIds ?? []).filter((x) => x) as string[];

    const remove = useCallback(
        (competitionTeamId: string) =>
            mutate((prev) => [...(prev ?? []).filter((x) => x.id !== competitionTeamId)], { revalidate: false }),
        [mutate]
    );

    const updateTeam = (teams: CompetitionTeamDto[] | undefined, team: CompetitionTeamDto) => {
        return [...(teams ?? []).filter((x) => x.id !== team.id), team];
    };

    useTopicIdSubscription<CompetitionTeamAdded>(
        ids,
        "CompetitionTeamAdded",
        (evt) => {
            mutate((prev) => updateTeam(prev, evt.competitionTeam), { revalidate: false });
        },
        [mutate]
    );

    useTopicIdSubscription<CompetitionTeamRemoved>(
        ids,
        "CompetitionTeamRemoved",
        (evt) => {
            remove(evt.competitionTeamId);
        },
        [remove]
    );

    useTopicIdSubscription<CompetitionTeamUpdated>(
        ids,
        "CompetitionTeamUpdated",
        (evt) => {
            mutate((prev) => updateTeam(prev, evt.competitionTeam), {
                revalidate: false,
            });
        },
        [competitionTeams, mutate]
    );

    return {
        competitionTeams,
        isLoading,
        removeCompetitionTeam: (competitionTeamId: string) => remove(competitionTeamId),
    };
};

export const useCompetition = (competitionId: string | undefined) => {
    const { data: competition, mutate, isLoading } = getCachedCompetition(competitionId);

    useTopicIdSubscription<CompetitionFinished>(
        competitionId,
        "CompetitionFinished",
        (evt) => {
            if (!competition) {
                return;
            }
            mutate({ ...competition, status: CompetitionStatus.Completed }, { revalidate: false });
        },
        [mutate, competition]
    );

    useTopicIdSubscription<TournamentUpdated>(
        competitionId,
        "TournamentUpdated",
        (evt) => {
            mutate(evt.tournament, { revalidate: false });
        },
        [mutate]
    );

    const update = useCallback((competition: TournamentDto) => mutate(competition, { revalidate: false }), [mutate]);

    return {
        competition,
        isLoading,
        updateCompetition: update,
    };
};

export const useDisqualifyTeam = () => {
    const { t } = useTranslation("translation", {
        keyPrefix: "components.tournament.match.disqualify_button",
    });

    const showDisqualifyConfirmation = useCallback(
        (teamName: string) =>
            showConfirmDialog(
                t("disqualify"),
                t("disqualfy_team", {
                    teamName,
                })
            ),
        [t]
    );

    const showRevertDisqualifyConfirmation = useCallback(
        (teamName: string) =>
            showConfirmDialog(
                t("undisqualify"),
                t("undisqualfy_team", {
                    teamName,
                })
            ),
        [t]
    );

    const disqualify = useCallback(
        async (competitionId: string, teamId: string, teamName: string) => {
            const result = await showDisqualifyConfirmation(teamName);
            if (result) {
                await disqualifyTeam(competitionId, teamId);
            }
        },
        [showDisqualifyConfirmation]
    );

    const revertDisqualify = useCallback(
        async (competitionId: string, teamId: string, teamName: string) => {
            const result = await showRevertDisqualifyConfirmation(teamName);
            if (result) {
                await undisqualifyTeam(competitionId, teamId);
            }
        },
        [showRevertDisqualifyConfirmation]
    );

    return {
        disqualifyTeam: disqualify,
        revertDisqualify,
        showDisqualifyConfirmation,
        showRevertDisqualifyConfirmation,
    };
};

export const useCompetitionStatus = () => {
    const { t } = useTranslation("translation", {
        keyPrefix: "competition.status",
    });

    const translate = useCallback(
        (status: CompetitionStatus) => {
            switch (status) {
                case CompetitionStatus.None:
                case CompetitionStatus.Created:
                    return t("none");
                case CompetitionStatus.Started:
                    return t("started");
                case CompetitionStatus.Completed:
                    return t("finished");
                case CompetitionStatus.Stopped:
                    return t("stopped");
            }
        },
        [t]
    );

    return {
        translate,
    };
};

/*
    GET
*/

export const getFeaturedTournament = () => get<TournamentCardViewModel[]>(`/api/tournament/featured`);

export const getCachedCompetition = (competitionId: string | undefined) =>
    getSwr<TournamentDto>(competitionId ? `api/Tournament/cached/${competitionId}` : null);

export const getCachedCompetitionTeams = (competitionId: string | undefined) =>
    getSwr<CompetitionTeamDto[]>(competitionId ? `api/Tournament/cached/${competitionId}/teams` : null);

export const getPlayerCompetitionTeams = (competitionId: string | undefined) =>
    getSwr<CompetitionTeamDto[]>(competitionId ? `api/Tournament/${competitionId}/teams` : null);

export const getCompetitionTeam = (competitionId: string | undefined, teamId: string | undefined) =>
    getSwr<CompetitionTeamDto>(competitionId && teamId ? `api/Tournament/${competitionId}/team/${teamId}` : null);

export const getMatchCompetitionTeams = (matchSeriesId: string | undefined) =>
    getSwr<CompetitionTeamDto[]>(matchSeriesId ? `api/Tournament/match_teams/${matchSeriesId}` : null);

export const getTournamentHistoryForTeam = (teamId: string) =>
    getSwr<TournamentHistory[]>(`api/Tournament/team_history/${teamId}`);

export const getTournamentHistoryForPlayer = (playerId: string) =>
    getSwr<TournamentHistory[]>(`api/Tournament/player_history/${playerId}`);

export const getPagedTournaments = (options: {
    page?: number;
    pageSize?: number;
    status?: CompetitionStatus;
    search?: string;
}) => {
    return getSwr<PaginationResult<TournamentDto>>(
        `api/tournament/backoffice/filtered?page=${options.page}&pageSize=${options.pageSize}&status=${options.status}&search=${options.search}`
    );
};

export type CompetitionFilters = "Show All" | "Upcoming" | "Live" | "Joined" | "Completed";

export const getCompetitions = ({
    skip,
    take,
    communityId,
    leagueId,
    filter,
    gameId,
}: {
    filter: CompetitionFilters;
    skip?: number;
    take?: number;
    gameId?: string;
    communityId?: string;
    leagueId?: string;
}) => {
    const entries = Object.entries({
        filter,
        skip,
        take,
        gameId,
        communityId,
        leagueId,
    }).filter(([key, value]) => value !== undefined) as string[][];
    const params = new URLSearchParams(entries);

    return get<PaginationResult<TournamentCardViewModel>>(`/api/tournament/overview?${params.toString()}`);
};

export const getUnpublishedCompetitions = (communityId: string | undefined) =>
    getSwr<TournamentCardViewModel[]>(communityId ? `api/tournament/community/${communityId}/unpublished` : null);

export interface BackofficeTournamentUpdateRequest {
    tournamentId: string;
    name: string;
    communityOwnerId: string | undefined;
    status: CompetitionStatus;
    information: string;
}

/*
    Commands
*/

export const updateTournamentBackOffice = (request: BackofficeTournamentUpdateRequest) =>
    sendCommand("UpdateCompetitionCommand", request);

export const createCompetition = (req: CreateCompetitionRequest) =>
    sendCommand<{ competitionId: string }>("CreateCompetitionCommand", req);

export const createChallenge = (req: CreateChallengeRequest) =>
    sendCommand<{ competitionId: string }>("CreateChallengeCommand", req);

export const updateCompetitionInformation = (competitionId: string, information: string) =>
    sendCommand("UpdateCompetitionInformationCommand", { competitionId, information });

export const deleteCompetition = (competitionId: string) => sendCommand("DeleteCompetitionCommand", { competitionId });
export const copyCompetition = (competitionId: string, leagueId?: string, groupId?: string) =>
    sendCommand<{ competitionId: string }>("CopyCompetitionCommand", { competitionId, leagueId, groupId });

export const cancelCompetition = (competitionId: string) => sendCommand("CancelCompetition", { competitionId });

export const updateReadyUpSettings = (
    competitionId: string,
    enableForfeitTimer: boolean,
    timeToReadyUpBeforeForfeitInSeconds: number | undefined
) =>
    sendCommand("UpdateReadyUpCommand", {
        competitionId,
        enableForfeitTimer,
        timeToReadyUpBeforeForfeitInSeconds,
    });

export const updateCompetitionScoreConfig = async (
    competitionId: string,
    scoreConfigId: string | undefined,
    variables?: { name: string; value: string | undefined }[]
) => {
    const vars = variables?.reduce((p, c) => ({ ...p, [c.name]: c.value }), {});
    const result = await sendCommand<{ scoreConfigId: string }>("UpdateCompetitionScoreConfigCommand", {
        competitionId,
        scoreConfigId,
        variables: vars,
    });

    return result.data;
};

export const forfeitTeam = (matchSeriesId: string, teamId: string) =>
    sendCommand("ForfeitTeamCommand", { matchSeriesId, teamId });

export const disqualifyTeam = (competitionId: string, teamId: string) =>
    sendCommand("DisqualifyTeamCommand", { competitionId, teamId });

export const undisqualifyTeam = (competitionId: string, teamId: string) =>
    sendCommand("UndisqualifyTeamCommand", { competitionId, teamId });

export const updateTournamentTeamPlayers = (
    competitionId: string,
    teamId: string,
    players: SelectedTournamentTeamPlayer[]
) =>
    sendCommand("UpdateSelectedPlayersCommand", {
        competitionId,
        teamId,
        players,
    });

export const completeCompetition = (competitionId: string) => sendCommand("CompleteCompetition", { competitionId });

export const forceStartCompetition = (competitionId: string) => sendCommand("ForceStartCompetition", { competitionId });
export const startChallenge = (competitionId: string) => sendCommand("StartChallenge", { competitionId });
export const stopCompetition = (competitionId: string) => sendCommand("StopCompetition", { competitionId });

export const joinCompetition = (options: {
    competitionId: string;
    teamId: string;
    players: SelectedTournamentTeamPlayer[];
    inviteCode?: string | undefined;
}) => {
    return sendCommand("JoinCompetition", options);
};
export const withdrawCompetitionTeam = (competitionId: string, competitionTeamId: string) =>
    sendCommand("WithdrawCompetitionTeam", { competitionId, competitionTeamId });

export const checkin = (competitionId: string) => sendCommand<TournamentDto>("Checkin", { competitionId });

/*
 * Urls
 */
export const generateCompetitionLink = (competition: CompetitionDto) =>
    competition.parentLeagueId
        ? generateLink("LEAGUE_ROUTES.LEAGUE_PAGE", { leagueId: competition.parentLeagueId })
        : generateLink("TOURNAMENT_ROUTES.LOBBY", { id: competition.id });

export const generateCompetitionLinkByIds = (competitionId: string, parentLeagueId?: string) =>
    parentLeagueId
        ? generateLink("LEAGUE_ROUTES.LEAGUE_PAGE", { leagueId: parentLeagueId })
        : generateLink("TOURNAMENT_ROUTES.LOBBY", { id: competitionId });

/*
 *  Backoffice commands
 */

export const enlistCompetitionTeam = (competitionId: string, teamId: string) =>
    sendCommand<TournamentDto>("EnlistCompetitionTeamCommand", {
        competitionId,
        teamId,
    });

export const selectCompetitionTeamPlayer = (competitionId: string, teamId: string, playerId: string) =>
    sendCommand("SelectCompetitionTeamPlayer", {
        competitionId,
        teamId,
        playerId,
    });
export const unselectCompetitionTeamPlayer = (competitionId: string, teamId: string, playerId: string) =>
    sendCommand("UnselectCompetitionTeamPlayer", {
        competitionId,
        teamId,
        playerId,
    });
export const checkInCompetitionTeamPlayer = (competitionId: string, teamId: string, playerId: string) =>
    sendCommand("CheckInCompetitionTeamPlayer", {
        competitionId,
        teamId,
        playerId,
    });
export const checkOutCompetitionTeamPlayer = (competitionId: string, teamId: string, playerId: string) =>
    sendCommand("CheckOutCompetitionTeamPlayer", {
        competitionId,
        teamId,
        playerId,
    });

/*
 * Helpers
 */

export const getStringFromTeamJoinStatusCode = (statusCode: TeamJoinStatus | undefined): string => {
    switch (statusCode) {
        case TeamJoinStatus.Invited:
            return "Invited";
        case TeamJoinStatus.Withdrawn:
            return "Withdrawn";
        case TeamJoinStatus.Joined:
            return "Joined";
        default:
            return "Team ready";
    }
};
