import {
    BlockContainer,
    DateTimeControl,
    DetailsText,
    ErrorText,
    SpacedContainer,
    SpinnerButton,
    Switch,
} from "@masterblaster/basics";
import { Box } from "@mui/material";
import { formatDateTime } from "@masterblaster/api";
import { addHours, isAfter, parseISO } from "date-fns";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const StartingAtInput = ({
    startingAt,
    competitionScheduledTime,
    disabled,
    onSaved,
}: {
    startingAt: string | undefined;
    competitionScheduledTime: string | undefined;
    disabled?: boolean;
    onSaved?(startingAt: string | undefined): boolean | Promise<boolean>;
}) => {
    const { t } = useTranslation(["common", "validation"]);

    const [start, setStart] = useState(startingAt);
    const [changed, setChanged] = useState(false);

    const now = new Date();
    const startDate = start ? parseISO(start) : undefined;
    const scheduleTime = competitionScheduledTime ? parseISO(competitionScheduledTime) : undefined;
    const isInFuture = !startDate || isAfter(startDate, now);
    const afterTournamentStart = !startDate || !scheduleTime || isAfter(startDate, scheduleTime);

    return (
        <SpacedContainer>
            <SpacedContainer>
                <StartingAt
                    startingAt={start}
                    minDate={now}
                    disabled={disabled}
                    onChanged={(d) => {
                        setStart(d);
                        setChanged(true);
                    }}
                />
                <Box display="flex" flexDirection="column">
                    {!isInFuture && changed && (
                        <ErrorText size="xs">{t("validation:components.tournament.date_must_be_in_future")}</ErrorText>
                    )}
                    {!afterTournamentStart && changed && (
                        <ErrorText size="xs">
                            {t("validation:components.tournament.date_must_be_after_tournament_start", {
                                startAt: formatDateTime(scheduleTime),
                            })}
                        </ErrorText>
                    )}
                </Box>
            </SpacedContainer>
            {changed && (
                <SpinnerButton
                    variant="contained"
                    color="primary"
                    disabled={disabled || !isInFuture || !afterTournamentStart}
                    size="small"
                    onClick={async () => {
                        const saved = await onSaved?.(start);
                        if (saved ?? true) {
                            setChanged(false);
                        }
                    }}
                >
                    {t("common:button_actions.save")}
                </SpinnerButton>
            )}
        </SpacedContainer>
    );
};

export const StartingAt = ({
    startingAt,
    disabled,
    minDate,
    onChanged,
}: {
    startingAt: string | undefined;
    minDate?: Date;
    disabled?: boolean;
    onChanged?(startingAt: string | undefined): void;
}) => {
    const { t } = useTranslation("translation", {
        keyPrefix: "components.tournament.match.drawer.starting_at_input",
    });

    const scheduledStart = !!startingAt;
    return (
        <BlockContainer>
            <Switch
                label={t("scheduled_start")}
                disabled={disabled}
                checked={scheduledStart}
                onChanged={(checked) => {
                    if (checked) {
                        const defaultStart = addHours(new Date(), 1).toISOString();
                        onChanged?.(startingAt ?? defaultStart);
                    } else {
                        onChanged?.(undefined);
                    }
                }}
            />
            {!scheduledStart && (
                <Box>
                    <DetailsText size="xxs">{t("match_start_automatically")}</DetailsText>
                </Box>
            )}
            {scheduledStart && (
                <DateTimeControl
                    date={startingAt ?? ""}
                    minDate={minDate}
                    disabled={!scheduledStart || disabled}
                    style={{ margin: 0 }}
                    onDateChanged={(d) => {
                        onChanged?.(d.toISOString());
                    }}
                />
            )}
        </BlockContainer>
    );
};
