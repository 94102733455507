import { styled } from "@mui/material/styles";

export const NotificationImage = styled("img")({
    width: "100%",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "cover",
    objectPosition: "center",

    "&[src$='.svg']": {
        maxWidth: "1.5rem",
        maxHeight: "1.5rem",
    },

    "&:not([src$='.svg'])": {
        borderRadius: "50%",
    },
});

export const NotificationTitle = styled("span")(({ theme }) => ({
    margin: 0,
    color: theme.colors.white,
    fontSize: "0.875rem",
    lineHeight: "1.375rem",
}));

export const NotificationActionResult = styled("p")(({ theme }) => ({
    margin: 0,
    fontSize: "0.75rem",
    lineHeight: "1.375rem",
    color: theme.colors.main_color,
}));
