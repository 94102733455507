import { MbDialog, RichTextEditor } from "@masterblaster/basics";
import { HelpOutline } from "@mui/icons-material";
import { IconButton, Button, DialogActions, DialogContent, Tooltip } from "@mui/material";
import type { GameSettings } from "@masterblaster/api";
import { showModal } from "@store/core";
import { useTranslation } from "react-i18next";

export const showConnectionGuide = (guide: string | undefined) => {
    if (!guide) {
        return;
    }

    showModal((onClose) => <ConnectionGuideDialog onClose={onClose} guide={guide} />);
};

export const ConnectionGuideDialog = ({ guide, onClose }: { guide: string | undefined; onClose: () => void }) => {
    const { t } = useTranslation("translation", { keyPrefix: "match_lobby" });
    const { t: common } = useTranslation("common");
    return (
        <MbDialog title={t("connection_guide")} onClose={() => onClose()}>
            <DialogContent>
                <RichTextEditor content={guide} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" sx={{ width: 1 }} onClick={onClose}>
                    {common("button_actions.close")}
                </Button>
            </DialogActions>
        </MbDialog>
    );
};

export const ConnectionGuideButton = ({
    gameSettings,
    small,
    fullWidth,
}: {
    gameSettings: GameSettings;
    small?: boolean;
    fullWidth?: boolean;
}) => {
    const { t } = useTranslation("translation", { keyPrefix: "match_lobby" });

    if (!gameSettings.connectionGuide) {
        return null;
    }

    const handleClick = () => {
        showConnectionGuide(gameSettings.connectionGuide);
    };

    if (small) {
        return (
            <Tooltip title={t("connection_guide")}>
                <IconButton onClick={handleClick}>
                    <HelpOutline />
                </IconButton>
            </Tooltip>
        );
    }

    return (
        <Button variant="contained" color="secondary" fullWidth={fullWidth} onClick={handleClick}>
            <HelpOutline fontSize="small" />
            <span style={{ marginLeft: "0.25rem" }}>{t("connection_guide")}</span>
        </Button>
    );
};
