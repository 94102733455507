import { Box, styled } from "@mui/material";
import { type FC, type PropsWithChildren } from "react";

export const AspectRatio: FC<PropsWithChildren<{ ratio: number }>> = ({ ratio, children, ...rest }) => {
    return (
        <OuterWrapper ratio={ratio} {...rest}>
            <InnerWrapper>{children}</InnerWrapper>
        </OuterWrapper>
    );
};

const InnerWrapper = styled(Box)({
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
});

export const OuterWrapper = styled(Box)<{ ratio: number }>(({ ratio }) => ({
    position: "relative",
    width: "100%",
    height: 0,
    /**
     * For human readability, the ratio is expressed as
     * width / height, so we need to invert it.
     */
    paddingBottom: `${(1 / ratio) * 100}%`,
}));
