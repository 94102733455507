import type { MatchSeries } from "@components/matches/api/MatchSeries";
import {
    ButtonContainer,
    DrawerSection,
    MbRadio,
    MbSelect,
    MbTextField,
    PropertyList,
    SpacedContainer,
    SpinnerButton,
} from "@masterblaster/basics";
import { Box, MenuItem, RadioGroup } from "@mui/material";
import { showError } from "@services/Error";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchPubgMatchStats, stopPubgStatsPoll, usePubgMatchStats } from "./PubgApi";

export const PubgMatchStatsWidget = ({ matchSeries }: { matchSeries: MatchSeries }) => {
    const [pubgMatchId, setPubgMatchId] = useState("");
    const [matchId, setMatchId] = useState("none");
    const [mode, setMode] = useState("automatic");
    const { t } = useTranslation(["common", "translation"]);

    const { matchStats } = usePubgMatchStats(matchSeries.id);

    const fetchStats = async () => {
        if (!matchId) {
            await showError("Please select a match");
        }

        await fetchPubgMatchStats(matchSeries.id, matchId, pubgMatchId);
    };

    const properties = [
        {
            label: t("translation:components.tournament.games.pubg.match_drawer.match_stats_widget.pubg_match"),
            value: (
                <a
                    href={`https://pubglookup.com/players/steam/illedan/matches/${matchStats?.pubgMatchId}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    {matchStats?.pubgMatchId}
                </a>
            ),
            visible: !!matchStats?.pubgMatchId,
        },
        {
            label: t("translation:components.tournament.games.pubg.match_drawer.match_stats_widget.score_fetched"),
            value: matchStats?.fetchedAt,
        },
        {
            label: t("translation:components.tournament.games.pubg.match_drawer.match_stats_widget.next_pool"),
            value: matchStats?.nextPollAt,
        },
        { label: t("common:shared.failed"), value: matchStats?.failedAt },
        { label: t("common:shared.status"), value: matchStats?.reason },
        {
            label: t("translation:components.tournament.games.pubg.match_drawer.match_stats_widget.starting_search"),
            value: matchStats?.startPollAt,
        },
        {
            label: t("translation:components.tournament.games.pubg.match_drawer.match_stats_widget.ending_search"),
            value: matchStats?.endPollAt,
        },
        {
            label: t("translation:components.tournament.games.pubg.match_drawer.match_stats_widget.pool_interval"),
            value: matchStats?.pollInterval,
        },
    ];

    return (
        <SpacedContainer>
            <DrawerSection>
                {t("translation:components.tournament.games.pubg.match_drawer.match_stats_widget.pubg_scores")}
            </DrawerSection>

            <PropertyList properties={properties} />

            <RadioGroup row value={mode} onChange={(e) => setMode(e.currentTarget.value)}>
                <MbRadio
                    disabled={matchStats?.nextPollAt !== null}
                    value="automatic"
                    label={t("translation:components.tournament.games.pubg.match_drawer.match_stats_widget.automatic")}
                />
                <MbRadio disabled={matchStats?.nextPollAt !== null} value="manual" label={t("common:shared.manual")} />
            </RadioGroup>
            <MbSelect
                value={matchId}
                fullWidth
                label="Match"
                placeholder={{ label: "Select match", value: "" }}
                onChange={(e) => setMatchId(e.target.value)}
            >
                {matchSeries.series.map((match) => {
                    return (
                        <MenuItem key={match.id} value={match.id}>
                            {match.index} - {match.displayName}
                        </MenuItem>
                    );
                })}
            </MbSelect>
            {mode === "manual" && (
                <Box width={1} sx={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginTop: "-1rem" }}>
                    <MbTextField
                        label={t(
                            "translation:components.tournament.games.pubg.match_drawer.match_stats_widget.pubg_match_id"
                        )}
                        fullWidth
                        value={pubgMatchId}
                        onChange={(e) => setPubgMatchId(e.currentTarget.value)}
                    />
                </Box>
            )}

            <ButtonContainer>
                {(mode === "manual" || !matchStats?.nextPollAt) && (
                    <SpinnerButton variant="contained" color="primary" onClick={fetchStats}>
                        {t(
                            "translation:components.tournament.games.pubg.match_drawer.match_stats_widget.fetch_match_stats"
                        )}
                    </SpinnerButton>
                )}
                {matchStats?.nextPollAt && (
                    <SpinnerButton variant="contained" color="error" onClick={() => stopPubgStatsPoll(matchSeries.id)}>
                        {t("translation:components.tournament.games.pubg.match_drawer.match_stats_widget.stop_poll")}
                    </SpinnerButton>
                )}
            </ButtonContainer>
        </SpacedContainer>
    );
};
