import type { ReactNode, FocusEventHandler, ChangeEventHandler } from "react";
import { forwardRef, useState, useCallback, useMemo } from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { type InputProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";

import { MbInput, type MbInputProps } from "./MbInput";
import { TooltipIcon } from "../tooltips";

const initialInputState = {
    error: false,
    success: false,
};

export interface MbTextFieldProps extends MbInputProps {
    label?: ReactNode;
    subLabel?: string;
    subLabelIcon?: ReactNode;
    helpText?: string;
    tooltip?: string;
    InputProps?: InputProps;
}

export const MbTextField = forwardRef<any, MbTextFieldProps>(function MbTextField(
    {
        id,
        disabled,
        error,
        success,
        label,
        subLabel,
        subLabelIcon,
        helpText,
        tooltip,
        fullWidth,
        InputProps,
        className,
        style,
        onChange,
        onBlur,
        sx,
        ...inputProps
    },
    forwardedRef
) {
    const [isDirty, setIsDirty] = useState(false);

    const inputState = {
        error,
        success,
    };

    const showHelpText = isDirty ? (error || success) && !!helpText : false;

    const componentId = useMemo(() => id ?? `input-${uuidv4()}`, [id]);
    const componentDescId = useMemo(() => `${componentId}-desc`, [componentId]);

    const getHelperTextIcon = () => {
        if (!showHelpText && !subLabel) return;

        const iconStyle = { fontSize: "1rem", marginRight: "0.25rem", verticalAlign: "text-bottom" };

        if (!showHelpText && subLabel) return subLabelIcon;

        switch (true) {
            case error:
                return <WarningRoundedIcon sx={iconStyle} />;
            case success:
                return <CheckCircleRoundedIcon sx={iconStyle} />;
            default:
                return subLabelIcon;
        }
    };

    const handleBlur = useCallback<FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>>(
        (e) => {
            setIsDirty(true);

            onBlur && onBlur(e);
        },
        [onBlur]
    );

    const handleChange = useCallback<ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>>(
        (e) => {
            setIsDirty(true);

            onChange && onChange(e);
        },
        [onChange]
    );

    return (
        <FormControl fullWidth={fullWidth} className={className} style={style} sx={sx}>
            <InputInfoContainer>
                {label && (
                    <StyledFormLabel disabled={disabled} htmlFor={componentId}>
                        {label}
                    </StyledFormLabel>
                )}
                {(helpText || subLabel) && (
                    <StyledFormHelperText
                        disabled={disabled}
                        id={componentDescId}
                        {...(isDirty ? inputState : initialInputState)}
                    >
                        {getHelperTextIcon()}
                        {showHelpText ? helpText : subLabel}
                    </StyledFormHelperText>
                )}
                {tooltip && <TooltipIcon title={tooltip} />}
            </InputInfoContainer>
            <MbInput
                ref={forwardedRef}
                id={componentId}
                aria-describedby={componentDescId}
                fullWidth={fullWidth}
                disabled={disabled}
                {...(isDirty ? inputState : initialInputState)}
                onBlur={handleBlur}
                onChange={handleChange}
                {...inputProps}
                {...InputProps}
            />
        </FormControl>
    );
});

const InputInfoContainer = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",

    "& > :not(:last-child)": {
        marginRight: "0.25rem",
    },
});

const StyledFormHelperText = styled(FormHelperText, {
    shouldForwardProp: (prop) => prop !== "success",
})<{ success?: boolean }>(({ disabled, error, success, theme }) => ({
    margin: 0,
    fontSize: "0.75rem",
    lineHeight: "1.375rem",
    color: theme.colors.greys[6],

    ...(disabled && {
        color: theme.colors.greys[12],
    }),

    ...(success && {
        color: theme.colors.greens[4],
    }),

    ...(error && {
        color: theme.colors.reds[4],
    }),
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
    fontSize: "0.875rem",
    lineHeight: "1.375rem",
    color: theme.colors.greys[2],

    "&.Mui-disabled": {
        color: theme.colors.greys[10],
    },
}));
