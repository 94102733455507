import Box from "@mui/material/Box";

import { NotificationTeamMemberInvite } from "./types/NotificationTeamMemberInvite";
import {
    NotificationMatchSeriesRescheduleAccepted,
    NotificationMatchSeriesRescheduled,
    NotificationMatchSeriesRescheduleRejected,
} from "./types/NotificationMatchSeriesRescheduled";
import { NotificationTournamentCheckinOpen } from "./types/NotificationTournamentCheckinOpen";
import { NotificationChallengeStarted } from "./types/NotificationChallengeStarted";
import { NotificationChallengeOpponentInvite } from "./types/NotificationChallengeOpponentInvite";
import { NotificationOrganizationPlayerInvite } from "./types/NotificationOrganizationInvite";
import { NotificationTeamJoinRequest } from "./types/NotificationTeamJoinRequest";
import { NotificationTeamJoinRequestAccepted } from "./types/NotificationTeamJoinRequestAccepted";
import { NotificationTeamJoinRequestRejected } from "./types/NotificationTeamJoinRequestRejected";
import type { NotificationElementProps } from "./NotificationElementProps";
import { isProduction } from "@src/config/AppConfigKeeper";
import { DetailsText, ErrorText } from "@masterblaster/basics";
import { NotificationChatUnreadMessages } from "./types/NotificationChatUnreadMessages";

export const NotificationElementDispatcher = (props: NotificationElementProps) => {
    const { t } = props;
    switch (props.notification.payloadType) {
        case "TeamMemberInvited":
            return <NotificationTeamMemberInvite {...props} />;
        case "TeamJoinRequested":
            return <NotificationTeamJoinRequest {...props} />;
        case "TeamJoinRequestAccepted":
            return <NotificationTeamJoinRequestAccepted {...props} />;
        case "TeamJoinRequestRejected":
            return <NotificationTeamJoinRequestRejected {...props} />;
        case "TournamentCheckinOpen":
            return <NotificationTournamentCheckinOpen {...props} />;
        case "MatchSeriesResheduleProposal":
            return <NotificationMatchSeriesRescheduled {...props} />;
        case "MatchSeriesResheduleProposalAccepted":
            return <NotificationMatchSeriesRescheduleAccepted {...props} />;
        case "MatchSeriesResheduleProposalRejected":
            return <NotificationMatchSeriesRescheduleRejected {...props} />;
        case "ChallengeStarted":
            return <NotificationChallengeStarted {...props} />;
        case "ChallengeOpponentInvited":
            return <NotificationChallengeOpponentInvite {...props} />;
        case "OrganizationPlayerInvited":
            return <NotificationOrganizationPlayerInvite {...props} />;
        case "CompetitionCheckinOpenPayload":
            return <NotificationTournamentCheckinOpen {...props} />;
        case "ChatUnreadMessagesPayload":
            return <NotificationChatUnreadMessages {...props} />;
        default:
            if (isProduction()) {
                return null;
            } else {
                return (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <ErrorText>Visible only in dev mode</ErrorText>
                        <DetailsText>
                            {t("translation:components.notifications.notification_type.notification_not_implemented", {
                                type: props.notification.payloadType,
                            })}
                        </DetailsText>
                    </Box>
                );
            }
    }
};
