import { GfLabelWrapper, Switch } from "@masterblaster/basics";
import { type CsPlugin } from "@components/matches/api/MatchSeries";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const CsPluginsInput = ({
    disabled,
    csPlugins,
    onChanged,
}: {
    disabled?: boolean;
    csPlugins: CsPlugin[];
    onChanged?(csGoEnabledPlugin: CsPlugin): void;
}) => {
    const { t } = useTranslation("translation", {
        keyPrefix: "components.tournament.games.cs_go.match_drawer.plugin_settings",
    });
    return (
        <GfLabelWrapper label={t("available_plugins")} tooltip={t("available_plugins_help_text")}>
            <EnableAffixPlugin csPlugins={csPlugins} disabled={disabled} onChanged={(c) => onChanged?.(c)} />
        </GfLabelWrapper>
    );
};

export const EnableAffixPlugin = ({
    disabled,
    onChanged,
    csPlugins,
}: {
    disabled?: boolean;
    onChanged(csGoEnabledPlugin: CsPlugin): void;
    csPlugins?: CsPlugin[];
}) => {
    const { t } = useTranslation("translation", {
        keyPrefix: "components.tournament.games.cs_go.match_drawer.plugin_settings",
    });

    const [affixEnabled, setAffixEnabled] = useState(csPlugins?.some((p) => p.name === "affix" && p.enabled) ?? false);

    const onAffixChanged = (enabled: boolean) => {
        setAffixEnabled(enabled);
        onChanged?.({ name: "affix", enabled });
    };

    return <Switch disabled={disabled} label={t("affix_plugin")} checked={affixEnabled} onChanged={onAffixChanged} />;
};
