import type { ChangeEvent, FC } from "react";
import { useState, useEffect, useMemo } from "react";
import { styled } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import LinkIcon from "@mui/icons-material/Link";

import { MbTextField } from "@masterblaster/basics";

import type { ToolbapItemBaseProps } from ".";

const ButtonContainer = styled("div")({
    display: "flex",
    justifyContent: "space-between",

    "& :not(:last-child)": {
        marginRight: "0.5rem",
    },
});

export const LinkEditor: FC<ToolbapItemBaseProps> = ({ editor }) => {
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [href, setHref] = useState<string>("");
    const [prevHref, setPrevHref] = useState<string>("");

    const isTextSelected = useMemo(() => {
        const { view, state } = editor;
        const { from, to } = view.state.selection;
        const text = state.doc.textBetween(from, to, " ");

        return Boolean(text);
    }, [editor]);

    const handleClose = () => setAnchorEl(null);

    const handleUrlChange = (event: ChangeEvent<HTMLTextAreaElement>) => setHref(event.target.value);

    const clearState = () => {
        setHref("");
        setPrevHref("");
        setAnchorEl(null);
    };

    const handleSubmitClick = () => {
        editor.chain().focus().extendMarkRange("link").setLink({ href }).run();

        clearState();
    };

    const handleDeleteClick = () => {
        editor.chain().focus().extendMarkRange("link").unsetLink().run();

        clearState();
    };

    const open = Boolean(anchorEl);
    const hasPrevHref = Boolean(prevHref);

    useEffect(() => {
        if (!open) return;

        const previousHref = editor.getAttributes("link").href;
        setHref(previousHref);
        setPrevHref(previousHref);
    }, [editor, open]);

    return (
        <>
            <ToggleButton
                value="link"
                title="Link"
                disabled={!isTextSelected && !editor.isActive("link")}
                selected={editor.isActive("link")}
                onClick={(e) => setAnchorEl(e.currentTarget)}
            >
                <LinkIcon />
            </ToggleButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                PaperProps={{
                    sx: {
                        padding: "0.5rem",
                        "& > :not(:last-child)": {
                            marginBottom: "0.5rem",
                        },
                    },
                }}
            >
                <MbTextField
                    label="Link url"
                    placeholder="Link url"
                    name="link"
                    value={href}
                    onChange={handleUrlChange}
                />
                <ButtonContainer>
                    <Button variant="contained" disabled={!href || href === prevHref} onClick={handleSubmitClick}>
                        {hasPrevHref ? "Update link" : "Add link"}
                    </Button>
                    {hasPrevHref && (
                        <Button variant="contained" onClick={handleDeleteClick}>
                            Delete link
                        </Button>
                    )}
                </ButtonContainer>
            </Popover>
        </>
    );
};
