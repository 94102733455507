import { useEffect, useState } from "react";
import type { GameServerLocation } from "./backoffice/GameServerApi";
import { getCsServerLocations } from "./backoffice/GameServerApi";
import type { MbSelectProps } from "@masterblaster/basics";
import { MbSelect } from "@masterblaster/basics";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";

interface CsGameServerLocationsProps extends MbSelectProps<string> {
    location?: string;
    disabled?: boolean;
    hideLabel?: boolean;
    onLocationChanged?(location: string): void;
}

export const CsGameServerLocationsSelector = (props: CsGameServerLocationsProps) => {
    const { onLocationChanged, location, disabled, hideLabel, ...rest } = props;
    const { t } = useTranslation(["common", "translation"]);

    const { data: locations = [] } = getCsServerLocations();

    return (
        <MbSelect
            fullWidth
            displayEmpty
            label={hideLabel ? undefined : t("common:shared.server_location")}
            tooltip={
                hideLabel ? undefined : t("translation:components.tournament.games.cs_go.game_server_location.tooltip")
            }
            value={location || "select"}
            disabled={disabled}
            placeholder={{
                label: t("translation:components.tournament.games.cs_go.game_server_location.select_server_location"),
                value: "select",
            }}
            onChange={(e) => {
                const val = e.target.value;
                onLocationChanged?.(val);
            }}
            {...rest}
        >
            {locations.map((loc, idx) => (
                <MenuItem key={idx} value={loc.name}>
                    {loc.description}
                </MenuItem>
            ))}
        </MbSelect>
    );
};
