import { DateTimeControl } from "@masterblaster/basics";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { formatDateTime } from "@masterblaster/api";
import { showModal } from "@store/core";
import { isAfter } from "date-fns";
import type { FC } from "react";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { MatchRescheduleDateInfo } from "./MatchRescheduleDateInfo";
import { MatchRescheduleDialogTitle } from "./MatchRescheduleDialogTitle";
import { MatchRescheduleTeamBox } from "./MatchRescheduleTeamBox";

import type { MatchRescheduleProposal, MatchSeries } from "../api/MatchSeries";
import { getMatchSeriesTeamPlayer } from "../api/MatchSeries";
import { proposeMatchReschedule, updateMatchRescheduleProposal } from "./MatchRescheduleApi";
import { GradientBorder, StyledDialog, StyledDialogActions, StyledDialogContent } from "./shared";

export const showMatchRescheduleProposalDialog = async (
    matchSeries: MatchSeries,
    playerId: string | undefined,
    proposal?: MatchRescheduleProposal
) => {
    if (!playerId) {
        return;
    }

    const teamPlayer = getMatchSeriesTeamPlayer(matchSeries.teams, playerId);

    const lastPossibleMatchRescheduleDate = matchSeries.lastPossibleMatchRescheduleDate;
    if (!lastPossibleMatchRescheduleDate) {
        return;
    }

    const result = await showModal<MatchRescheduleProposeDialogResult>((onClose) => (
        <MatchRescheduleProposeDialog
            matchSeries={matchSeries}
            proposalScheduledAt={proposal?.scheduledAt}
            lastPossibleMatchRescheduleDate={lastPossibleMatchRescheduleDate}
            onClose={onClose}
        />
    ));

    if (!result) {
        return;
    }

    if (proposal) {
        await updateMatchRescheduleProposal(proposal.matchSeriesId, proposal.id, result.proposalScheduledAt);
    } else {
        if (!teamPlayer) {
            return;
        }

        await proposeMatchReschedule(matchSeries.id, teamPlayer.teamId, result.proposalScheduledAt);
    }
};

interface MatchRescheduleProposeDialogResult {
    proposalScheduledAt: string;
}

export interface MatchRescheduleProposeDialogProps {
    matchSeries: MatchSeries;
    proposalScheduledAt: string | undefined;
    lastPossibleMatchRescheduleDate: string;
    onClose: (result?: MatchRescheduleProposeDialogResult) => void;
}

export const MatchRescheduleProposeDialog: FC<MatchRescheduleProposeDialogProps> = (props) => {
    const { matchSeries, lastPossibleMatchRescheduleDate, onClose } = props;
    const [proposalScheduledAt, setProposalScheduledAt] = useState(props.proposalScheduledAt);
    const [isSuggested, setIsSuggested] = useState<boolean>(false);
    const { t } = useTranslation(["common", "translation"]);

    const currentDate = new Date();
    const deadlineDate = new Date(lastPossibleMatchRescheduleDate);

    const tooLate = isAfter(currentDate, deadlineDate);

    const handleCancelDialog = () => {
        onClose();
    };

    const handleConfirmDialog = () => {
        if (proposalScheduledAt) {
            onClose({ proposalScheduledAt });
        }
    };

    const handleSuggestDate = () => setIsSuggested(true);
    const handleBack = () => setIsSuggested(false);

    const currentScheduledDate = proposalScheduledAt ?? matchSeries.startingAt ?? currentDate;
    return (
        <StyledDialog open={true} fullWidth={true} onClose={handleCancelDialog}>
            <MatchRescheduleDialogTitle onClose={handleCancelDialog}>
                {t("translation:components.tournament.match.reschedule.proposal_dialog.title", {
                    matchName: matchSeries.name,
                })}
            </MatchRescheduleDialogTitle>
            <StyledDialogContent>
                <MatchRescheduleTeamBox teams={matchSeries.teams} mb="1.375rem" />
                {deadlineDate && (
                    <Typography variant="body1" mb="0.625rem" textAlign="center">
                        {
                            <Trans
                                ns="translation"
                                i18nKey="components.tournament.match.reschedule.proposal_dialog.last_possible_date"
                                components={[<strong key={0} />]}
                                values={{
                                    date: formatDateTime(deadlineDate),
                                }}
                            />
                        }
                    </Typography>
                )}
                <MatchRescheduleDateInfo isActive={isSuggested} mb="0.625rem">
                    {isSuggested
                        ? proposalScheduledAt &&
                          t("translation:components.tournament.match.reschedule.suggested_new_date", {
                              date: formatDateTime(proposalScheduledAt),
                          })
                        : t("translation:components.tournament.match.reschedule.current_date", {
                              date: formatDateTime(matchSeries.startingAt),
                          })}
                </MatchRescheduleDateInfo>
                <GradientBorder active={!isSuggested}>
                    <DateTimeControlContainer active={!isSuggested}>
                        <Typography variant="body1" pb="1rem">
                            {t("translation:components.tournament.match.reschedule.proposal_dialog.suggest_new_start")}
                        </Typography>

                        <DateTimeControl
                            helperText={tooLate ? "Too late" : ""}
                            error={tooLate}
                            disabled={isSuggested}
                            date={currentScheduledDate}
                            minDate={currentDate}
                            maxDate={deadlineDate}
                            onDateChanged={(date) => {
                                setProposalScheduledAt(date.toISOString());
                            }}
                        />
                    </DateTimeControlContainer>
                </GradientBorder>

                <StyledDialogActions>
                    <CancelButton variant="text" onClick={isSuggested ? handleBack : handleCancelDialog}>
                        {isSuggested ? t("common:button_actions.back") : t("common:button_actions.cancel")}
                    </CancelButton>
                    {isSuggested ? (
                        <AcceptButton variant="contained" disabled={tooLate} onClick={handleConfirmDialog}>
                            {t("common:button_actions.confirm")}
                        </AcceptButton>
                    ) : (
                        <ConfirmButton
                            variant="contained"
                            disabled={tooLate || !proposalScheduledAt}
                            onClick={handleSuggestDate}
                        >
                            {t("common:button_actions.suggest")}
                        </ConfirmButton>
                    )}
                </StyledDialogActions>
            </StyledDialogContent>
        </StyledDialog>
    );
};

const DateTimeControlContainer = styled("div", {
    shouldForwardProp: (prop) => prop !== "active",
})<{
    active: boolean;
}>(({ theme, active }) => ({
    background: `linear-gradient(180deg, #282A36 0%, ${theme.colors.contrast_background} 100%)`,
    padding: "1.25rem clamp(1rem, 8vw, 3rem)",
    boxSizing: "border-box",
    borderRadius: "4px",
    opacity: active ? "1" : "0.33",
}));

const CancelButton = styled(Button)({
    color: "#FFF",
    fontSize: "1rem",
    lineHeight: "1.25",
    fontWeight: "bold",
    padding: "0.75rem 1rem",
    transition: "ease-in-out 200ms",
    borderRadius: "3px",

    "&:hover": {
        background: "#383B4C",
        boxShadow: "0px 0px 2px rgba(255, 255, 255, 0.5)",
    },
});

const ConfirmButton = styled(CancelButton)({
    color: "#282A36",
    background: "#EEEEF0",

    "&:hover": {
        background: "#EEEEF0",
    },
});

const AcceptButton = styled(CancelButton)({
    color: "#FFF",
    background: "#5CA522",

    "&:hover": {
        background: "#ABE27F",
    },
});
