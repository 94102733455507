import { FileCopyOutlined, VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import type { IconButtonProps, OutlinedTextFieldProps, SxProps, Theme } from "@mui/material";
import { Divider, IconButton, InputAdornment } from "@mui/material";
import copy from "copy-to-clipboard";
import type { CSSProperties, FC, PropsWithChildren } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MbTextField } from "./MbTextField";

interface ConnectionFieldProps extends Pick<OutlinedTextFieldProps, "size"> {
    label?: string;
    value: string | number | undefined;
    isPassword?: boolean;
    sx?: SxProps<Theme>;
}

export const ConnectionField = (props: ConnectionFieldProps) => {
    const { label, value, isPassword, sx } = props;
    const [password, showPassword] = useState(false);

    return (
        <MbTextField
            fullWidth
            label={label}
            value={value ?? ""}
            sx={sx}
            type={isPassword && !password ? "password" : undefined}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        {isPassword && (
                            <>
                                <Divider sx={{ width: "1px", height: 29, color: (theme) => theme.colors.border }} />
                                <TogglePasswordVisibilityButton
                                    visible={password}
                                    onToggle={showPassword}
                                    style={{ borderRadius: 0, margin: "0 0.5rem" }}
                                />
                            </>
                        )}
                        <Divider sx={{ width: "1px", height: 29, color: (theme) => theme.colors.border }} />
                        <CopyToClipboardButton
                            value={(value ?? "").toString()}
                            style={{ borderRadius: 0, marginLeft: "0.5rem" }}
                        />
                    </InputAdornment>
                ),
            }}
        />
    );
};

export const TogglePasswordVisibilityButton = ({
    visible,
    onToggle,
    style,
}: {
    visible: boolean;
    onToggle(visible: boolean): void;
    style?: CSSProperties;
}) => {
    const { t } = useTranslation("translation", {
        keyPrefix: "basics.inputs.connection_field",
    });

    return (
        <IconButton
            aria-label={t("toggle_pass_visibility")}
            style={style}
            size="small"
            onClick={() => onToggle(!visible)}
        >
            {visible ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
        </IconButton>
    );
};

interface CopyToClipboardButtonProps extends Omit<IconButtonProps, "value"> {
    value: string | number | boolean | undefined;
}
export const CopyToClipboardButton: FC<PropsWithChildren<CopyToClipboardButtonProps>> = (props) => {
    const { value, children, onClick, ...rest } = props;
    const { t } = useTranslation("translation", {
        keyPrefix: "basics.inputs.connection_field",
    });

    return (
        <IconButton
            aria-label={t("copy_to_clipboard")}
            size="small"
            onClick={(e) => {
                if (value) {
                    copy(value.toString());
                }

                onClick?.(e);
            }}
            {...rest}
        >
            {children || <FileCopyOutlined fontSize="small" />}
        </IconButton>
    );
};
