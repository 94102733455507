import type { SxProps, Theme } from "@mui/material";
import { Avatar, Box } from "@mui/material";
import type { CSSProperties } from "react";
import { DefaultTeamAvatar } from "@masterblaster/assets";
import md5 from "md5";
import { getImageUrl } from "@masterblaster/api";

export const getTeamGravatarUrl = (name: string) => `https://www.gravatar.com/avatar/${md5(name)}?d=retro`;

interface TeamAvatarProps {
    team?: { avatarImageId: string | undefined; name: string } | null;
    showBorder?: boolean;
    size?: number | string;
    className?: string;
    style?: CSSProperties;
    sx?: SxProps<Theme>;
}
export const TeamAvatar = ({ team, showBorder, size, className, style, sx }: TeamAvatarProps) => {
    const getSrc = () => {
        if (!team) {
            return DefaultTeamAvatar;
        }

        if (team.avatarImageId) {
            return getImageUrl(team.avatarImageId);
        }

        if (team.name) {
            return getTeamGravatarUrl(team.name);
        }

        return DefaultTeamAvatar;
    };

    const src = getSrc();

    return (
        <Avatar
            className={className}
            src={src}
            style={{ width: size ?? 30, height: size ?? 30, ...style }}
            sx={{
                border: (theme) => (showBorder ? `2px solid ${theme.colors.greys[10]}` : undefined),
                ...sx,
            }}
        ></Avatar>
    );
};

export const TeamAvatarWithName = (props: TeamAvatarProps & { reverse?: boolean }) => {
    if (!props.team) {
        return null;
    }

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                flexDirection: props.reverse ? "row-reverse" : "row",
            }}
        >
            <TeamAvatar {...props} />
            <span>{props.team?.name}</span>
        </Box>
    );
};
