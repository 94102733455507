import { MatchStatus, type MatchSeries } from "@components/matches";
import { LinkBehaviour } from "@masterblaster/basics";
import { Box, Link, styled } from "@mui/material";
import { generateCompetitionLinkByIds } from "@services/Tournament";
import { useMemo, type FC } from "react";
import { MatchSeriesStartingAt } from "./MatchSeriesStartingAt";

export const MatchNameAndStatus = ({ matchSeries }: { matchSeries: MatchSeries }) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem", fontSize: "0.75rem", lineHeight: "1rem" }}>
            <MatchSeriesStartingAt matchSeries={matchSeries} />
            <MatchName matchSeries={matchSeries} />
        </Box>
    );
};

export const MatchName = ({ matchSeries }: { matchSeries: MatchSeries }) => {
    const matchName = useMemo(() => `${matchSeries.name} (BO${matchSeries.settings.numberOfMaps})`, [matchSeries]);

    return <Box>{matchName}</Box>;
};

export const MatchCompetitionName = ({ matchSeries }: { matchSeries: MatchSeries }) => {
    const cardName = matchSeries.parentLeagueName
        ? `${matchSeries.parentLeagueName} - ${matchSeries.competitionName}`
        : matchSeries.competitionName;
    const cardHref = generateCompetitionLinkByIds(matchSeries.competitionId, matchSeries.parentLeagueId);

    return (
        <Link
            component={LinkBehaviour}
            title={cardName}
            href={cardHref}
            sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                color: (theme) => theme.colors.secondary_text,
            }}
        >
            {cardName}
        </Link>
    );
};
