import type { PaginationResult } from "../http";
import { get, getSwr } from "../http";
import type { SearchV2Result, SearchV2ResultObjectType } from "./SearchTypes";

interface GlobalSearchOptions {
    objectType?: SearchV2ResultObjectType;
    // keyQuery?: string;
    exactObjectNameMatch?: boolean;
    keyQueries?: string[];
    page?: number;
    pageSize?: number;
}
const buildGlobalSearchQueryParams = (query: string, options?: GlobalSearchOptions) => {
    let params = `query=${query}`;
    if (options?.objectType) {
        params += `&objectType=${options.objectType}`;
    }
    // if (options?.keyQuery) {
    //     params += `&keyQuery=${options.keyQuery}`;
    // }
    if (options?.exactObjectNameMatch) {
        params += `&exactObjectNameMatch=${options.exactObjectNameMatch}`;
    }
    if (options?.keyQueries) {
        params += `&keyQuery=${options.keyQueries.join(",")}`;
    }
    if (options?.page) {
        params += `&page=${options.page}`;
    }
    if (options?.pageSize) {
        params += `&pageSize=${options.pageSize}`;
    }

    return params;
};

// new V2 search
export const globalSearch = (input: string, options?: GlobalSearchOptions) => {
    const params = buildGlobalSearchQueryParams(input, options);
    return getSwr<PaginationResult<SearchV2Result>>(`/api/search2/search?${params}`);
};

export const globalSearchAsync = (input: string, options?: GlobalSearchOptions) => {
    const params = buildGlobalSearchQueryParams(input, options);
    return get<PaginationResult<SearchV2Result>>(`/api/search2/search?${params}`);
};

export const adminBuildIndex = () => get(`/api/search2/build-index`);
