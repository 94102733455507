import { styled } from "@mui/material/styles";

const StreamsLoadingContainer = styled("div")(({ theme }) => ({
    height: "27.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("xs")]: {
        height: "35rem",
    },
}));

const StreamsContainer = styled("div")({
    "& > :not(:last-child)": {
        marginBottom: "0.5rem",
    },
});

export { StreamsLoadingContainer, StreamsContainer };
