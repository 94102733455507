import type { HubConnection, IRetryPolicy } from "@microsoft/signalr";
import { HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { getDispatch, bannerSlice } from "@store/core";
import { TokenStore } from "@mb/auth";

let connection: HubConnection;

let resolveConnection: (connection: HubConnection) => void | undefined;
let connectPromise = new Promise<HubConnection>((resolve) => (resolveConnection = resolve));

export const getConnection = async (retryAttempt = 0): Promise<HubConnection> => {
    const connection = await connectPromise;

    if (connection.state !== HubConnectionState.Connected) {
        if (retryAttempt <= 3) {
            return await new Promise<HubConnection>((resolve) => {
                setTimeout(async () => {
                    const connection = await getConnection(retryAttempt + 1);
                    resolve(connection);
                }, 500);
            });
        } else {
            console.log(`Retried getting SignalR connection ${retryAttempt} times, giving up`);
            return connection;
        }
    } else {
        return connection;
    }
};

const openWebSocketConnectionNotice = () => {
    const dispatch = getDispatch();
    if (dispatch) {
        dispatch(bannerSlice.actions.openWebSocketNotice());
    }
};

const closeWebSocketConnectionNotice = () => {
    const dispatch = getDispatch();
    if (dispatch) {
        dispatch(bannerSlice.actions.closeWebSocketNotice());
    }
};

const retryNotice = (retry: number) => {
    const dispatch = getDispatch();
    if (dispatch) {
        dispatch(bannerSlice.actions.reconnectionWebScoket(retry));
    }
};

const suspendWebSocketNotice = () => {
    const dispatch = getDispatch();
    if (dispatch) {
        dispatch(bannerSlice.actions.suspendWebSocketNotice());
    }
};

const resumeWebSocketNotice = () => {
    const dispatch = getDispatch();
    if (dispatch) {
        dispatch(bannerSlice.actions.resumeWebSocketNotice());
    }
};

class Retry implements IRetryPolicy {
    nextRetryDelayInMilliseconds(): number | null {
        const retry = Math.random() * 5000;
        console.log(`SignalR reconnect in: ${retry}ms`);
        retryNotice(retry);
        return retry;
    }
}

export const delayAsync = async (timeout: number) => {
    return new Promise((resolve) => setTimeout(resolve, timeout));
};

export const restartSignalrConnection = async () => {
    if (!connection || connection.state !== HubConnectionState.Disconnected) {
        return;
    }

    console.log("Restarting signalR");
    suspendWebSocketNotice();
    console.log("Stopping signalR");
    await connection.stop();
    console.log("SignalR stop called");
    let counter = 0;
    while (connection.state !== HubConnectionState.Disconnected && counter < 3) {
        console.log("Waiting for SignalR to stop");
        await delayAsync(1000);
        counter = counter + 1;
    }

    console.log("SignalR connecting...");
    await connect();
    closeWebSocketConnectionNotice();
    resumeWebSocketNotice();
};

const connect = async () => {
    await connection.start();
    console.log(`SignalR connection started`);
    console.log(connection);

    closeWebSocketConnectionNotice();
    resolveConnection(connection);
};

export const initializeSignalR = async (): Promise<HubConnection> => {
    connection = new HubConnectionBuilder()
        .withUrl("/hubs/events", { accessTokenFactory: () => TokenStore.getToken() || "" })
        .withAutomaticReconnect(new Retry())
        .configureLogging(LogLevel.Information)
        .build();

    connection.serverTimeoutInMilliseconds = 60000;

    connection.onreconnected((_connectionId) => {
        console.log("SignalR reconnected!");

        closeWebSocketConnectionNotice();
        resolveConnection(connection);
    });

    connection.onclose(() => {
        openWebSocketConnectionNotice();
        connectPromise = new Promise<HubConnection>((resolve) => (resolveConnection = resolve));
    });

    await connect();

    return connectPromise;
};
