import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

export const MessageDialog = (props: { onClose?: () => void; title: string; description: string | ReactNode }) => {
    const { title, description, onClose } = props;
    const { t } = useTranslation("common", {
        keyPrefix: "button_actions",
    });

    const content =
        typeof description === "string" ? <DialogContentText>{description}</DialogContentText> : description;

    return (
        <Dialog
            open={true}
            onClose={onClose}
            maxWidth="md"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => {
                        if (onClose) onClose();
                    }}
                    color="primary"
                    autoFocus
                >
                    {t("ok")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
