import type { CsMatchConnectionInfo } from "@components/competitions/tournament/games/csgo";
import {
    CsGameServerLocationsSelector,
    CsGameServerStatus,
    useCsTournamentSettings,
} from "@components/competitions/tournament/games/csgo";
import type { CsPlugin, MatchSeries, MatchSeriesCsGameSettings } from "@components/matches/api/MatchSeries";
import { GameType, sendCommand } from "@masterblaster/api";
import { BlockContainer, DrawerSection, ErrorText, MbTextField, SpinnerButton, Switch } from "@masterblaster/basics";
import { isAdministrator } from "@mb/auth";
import type { Theme } from "@mui/material";
import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CsVersionSelector } from "../CsVersionSelector";
import { AutoForfeitSettings } from "./AutoForfeitSettings";
import { CsPluginsInput } from "./CsPluginsInput";

export const CsGameSettingsInput = (props: {
    disabled?: boolean;
    connectionInfo: CsMatchConnectionInfo;
    matchSeries: MatchSeries;
    gameSettings: MatchSeriesCsGameSettings;
}) => {
    const { gameSettings, disabled, connectionInfo, matchSeries } = props;
    const { t } = useTranslation(["common", "translation"]);

    const isAdmin = isAdministrator();

    const { colors } = useTheme<Theme>();

    const [changed, setChanged] = useState(false);
    const [locationId, setLocationId] = useState(gameSettings.locationId);
    const [version, setVersion] = useState(gameSettings.version);
    const [useAutoForfeit, setUseAutoForfeit] = useState(gameSettings.useAutoForfeit);
    const [forfeitTimeoutInSeconds, setForfeitTimeoutInSeconds] = useState(gameSettings.forfeitTimeoutInSeconds);
    const [customConfigFile, setCustomConfigfile] = useState(gameSettings.customConfigFile);
    const [insecureServer, setInsecureServer] = useState(gameSettings.insecureServer);
    const { settings } = useCsTournamentSettings(matchSeries.competitionId, GameType.CSGO);
    const [plugins, setPlugins] = useState(settings?.plugins ?? ([] as CsPlugin[]));

    const onEnabledPluginChanged = (enabledPlugin: CsPlugin) => {
        const csPlugin = plugins.find((p) => p.name === enabledPlugin.name);
        if (csPlugin) {
            csPlugin.enabled = enabledPlugin.enabled;
        } else {
            plugins.push(enabledPlugin);
        }

        setPlugins(plugins);
        setChanged(true);
    };

    const onSaveGameSettings = async () => {
        const result = await sendCommand("UpdateMatchSeriesCsGameSettingsCommand", {
            matchSeriesId: matchSeries.id,
            locationId,
            useAutoForfeit,
            insecureServer,
            forfeitTimeoutInSeconds,
            customConfigFile,
            plugins,
            version,
        });

        if (result.success) {
            setChanged(false);
        }
    };

    return (
        <BlockContainer>
            <DrawerSection>{t("common:shared.game_settings")}</DrawerSection>
            {disabled && (
                <ErrorText size="xxs">
                    {t("translation:components.tournament.games.cs_go.match_drawer.game_settings_input.help_text")}
                </ErrorText>
            )}
            <Box>
                <CsGameServerLocationsSelector
                    location={locationId || settings?.locationId || ""}
                    disabled={disabled}
                    onLocationChanged={(v) => {
                        setLocationId(v);
                        setChanged(true);
                    }}
                />
            </Box>
            <CsVersionSelector
                label="CS Version"
                version={version}
                onChanged={(version) => {
                    setVersion(version);
                    setChanged(true);
                }}
            />

            <AutoForfeitSettings
                disabled={disabled}
                useAutoForfeit={useAutoForfeit}
                forfeitTimeoutInSeconds={forfeitTimeoutInSeconds}
                onChanged={(evt) => {
                    setUseAutoForfeit(evt.useAutoForfeit);
                    setForfeitTimeoutInSeconds(evt.forfeitTimeoutInSeconds);
                    setChanged(true);
                }}
            />
            {isAdmin && (
                <Box>
                    <MbTextField
                        label="Custom CS config file (platform admins only)"
                        value={customConfigFile ?? ""}
                        onChange={(e) => {
                            setCustomConfigfile(e.currentTarget.value);
                            setChanged(true);
                        }}
                    />
                </Box>
            )}

            <Switch
                label="Use insecure sever"
                checked={insecureServer}
                onChanged={(checked) => {
                    setInsecureServer(checked);
                    setChanged(true);
                }}
            />

            <CsPluginsInput disabled={disabled} csPlugins={gameSettings.plugins} onChanged={onEnabledPluginChanged} />

            {connectionInfo?.status === CsGameServerStatus.Started &&
                connectionInfo?.location !== gameSettings.locationId && (
                    <span style={{ padding: "0.5rem", color: colors.error }}>
                        {t(
                            "translation:components.tournament.games.cs_go.match_drawer.game_settings_input.connection_info"
                        )}
                    </span>
                )}

            {changed && (
                <SpinnerButton variant="contained" color="primary" size="small" onClick={onSaveGameSettings}>
                    {t("common:button_actions.save")}
                </SpinnerButton>
            )}
        </BlockContainer>
    );
};
