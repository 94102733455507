import type { AResponse, IResponse, IUser } from "@masterblaster/api";

export interface RegisterResponse extends AResponse<{ token: string; playerId: string }> {}

export interface GetTokenResponse extends AResponse<{ token: string }> {}
export type LoginResponse = AResponse<LoginResponseData>;
export type ResetPasswordResponse = IResponse;
export type ForgotPasswordResponse = IResponse;
export type ChangePasswordResponse = IResponse;

export type ChangeEmailResponse = AResponse<{ token: string }>;

export type VerifyCodeResponse = AResponse<LoginResponseData>;

export enum AccessLevels {
    GameAdmin = 100,
    TournamentAdmin = 200,
    CreateTournament = 300,
    CommunityAdmin = 400,
}

export interface UserLoginInfo {
    loginProvider: string;
    providerKey: string;
    providerDisplayName: string | undefined;
}

export interface LoginResponseData {
    token: string;
    user: IUser;
    acls: UserResourceAclEntry[];
    isPendingVerification: boolean;
}

export interface UserData {
    user: IUser;
    pendingTeamInvites?: string[];
    acl: UserResourceAclEntry[];
}

export interface UserResourceAclEntry {
    resourceName: string;
    resourceId: string;
    access: AccessLevels;
}
