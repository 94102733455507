import type { CsMatchConnectionInfo } from "@components/competitions/tournament/games/csgo";
import { CsGameServerStatus } from "@components/competitions/tournament/games/csgo";
import type { MatchSeries, MatchSeriesCsGameSettings } from "@components/matches/api/MatchSeries";
import { MatchStatus } from "@components/matches/api/MatchSeries";
import { formatDateTime, sendCommand } from "@masterblaster/api";
import { ButtonContainer, DrawerSection, FileUploadButton, PropertyList, SpinnerButton } from "@masterblaster/basics";
import { TokenStore, isAdministrator, isTournamentAdministrator } from "@mb/auth";
import { Box, List, ListItemButton, ListItemText } from "@mui/material";
import { showModal } from "@store/core";
import { showConfirmDialog } from "@utils/DialogHelpers";
import { generateLink } from "@utils/generateLink";
import { useTranslation } from "react-i18next";
import { CsGameSettingsInput } from "./CsGameSettingsInput";
import type { CsMatchRestoreDialogResult } from "./CsMatchRestoreDialog";
import { CsMatchRestoreDialog } from "./CsMatchRestoreDialog";
import { TimeToJoin } from "./TimeToJoin";

export const CsMatchDrawer = ({ matchSeries }: { matchSeries: MatchSeries }) => {
    const connectionInfo = matchSeries.connectionInfo.fields as unknown as CsMatchConnectionInfo;
    const gameSettings = matchSeries.gameSettings as MatchSeriesCsGameSettings;
    const isAdmin = isAdministrator() || isTournamentAdministrator();
    const { t } = useTranslation(["common", "translation"]);

    const uri = connectionInfo?.host ? `${connectionInfo?.host}:${connectionInfo?.port}` : "";
    const delay =
        connectionInfo?.status === CsGameServerStatus.Stopping && (connectionInfo?.shutdownDelay ?? 0) > 0
            ? t("translation:components.tournament.games.cs_go.match_drawer.cs_match_drawer.waiting_gotv", {
                  shutdownDelay: connectionInfo?.shutdownDelay,
              })
            : "";
    const statusText = `${CsGameServerStatus[connectionInfo?.status]} ${delay}`;

    const hasServerInfo = connectionInfo?.status !== CsGameServerStatus.NotSet;

    const data = [
        {
            label: t("translation:components.tournament.games.cs_go.match_drawer.cs_match_drawer.server"),
            value: uri,
            visible: hasServerInfo,
        },
        {
            label: t("common:input.password"),
            value: connectionInfo?.password,
            visible: !!(hasServerInfo && connectionInfo?.password),
        },
        {
            label: t("translation:components.tournament.games.cs_go.match_drawer.cs_match_drawer.location"),
            value: connectionInfo?.location,
            visible: hasServerInfo,
        },
        { label: t("common:shared.status"), value: statusText, visible: !!connectionInfo?.status },
        {
            label: t("translation:components.tournament.games.cs_go.match_drawer.cs_match_drawer.failed_reason"),
            value: connectionInfo?.failedReason,
            visible: !!connectionInfo?.failedReason,
        },
        {
            label: t("translation:components.tournament.games.cs_go.match_drawer.cs_match_drawer.ready_at"),
            value: formatDateTime(connectionInfo?.readyAt),
        },
        {
            label: t("translation:components.tournament.games.cs_go.match_drawer.cs_match_drawer.server_match_config"),
            href: `/api/cs/gameserver/config/${connectionInfo?.serverId}`,
            visible: !!connectionInfo?.serverId,
        },
        {
            label: t("translation:components.tournament.games.cs_go.match_drawer.cs_match_drawer.gf_match_config"),
            href: `/api/get5/matchconfig/${matchSeries.id}`,
            // visible: isStarted
        },
        {
            label: t("translation:components.tournament.games.cs_go.match_drawer.cs_match_drawer.server_console"),
            href: generateLink("CS_SERVER_ROUTES.CONSOLE", {
                matchId: matchSeries.id,
            }),
        },
        {
            label: t("translation:components.tournament.games.cs_go.match_drawer.cs_match_drawer.server_events"),
            href: generateLink("CS_SERVER_ROUTES.EVENTS", {
                matchId: matchSeries.id,
            }),
        },
        {
            label: t(
                "translation:components.tournament.games.cs_go.match_drawer.cs_match_drawer.dathost_control_panel"
            ),
            href: `https://dathost.net/control-panel/game-server/console/${connectionInfo?.serverId}`,
            visible: isAdmin && hasServerInfo,
        },
        {
            label: t("translation:components.tournament.games.cs_go.match_drawer.cs_match_drawer.used_servers"),
            value: (
                <Box sx={{ width: 1 }}>
                    <List dense disablePadding sx={{ width: 1 }}>
                        {connectionInfo?.usedGameServers?.map((x, idx) => {
                            return (
                                <ListItemButton
                                    key={idx}
                                    dense
                                    disableGutters
                                    component="a"
                                    href={`https://dathost.net/control-panel/game-server/console/${x.serverId}`}
                                    target="_blank"
                                >
                                    <ListItemText
                                        sx={{ margin: 0 }}
                                        primary={`${x.host}:${x.port}`}
                                        secondary={formatDateTime(x.usedAt)}
                                    />
                                </ListItemButton>
                            );
                        })}
                    </List>
                </Box>
            ),
            visible: isAdmin,
        },
    ];

    const onKillServer = async (matchSeriesId: string) => {
        const confirmed = await showConfirmDialog(
            t("translation:components.tournament.games.cs_go.match_drawer.cs_match_drawer.kill_server"),
            t("common:shared.are_you_sure")
        );
        if (confirmed) {
            await sendCommand("KillGameServer", { matchSeriesId });
        }
    };

    const confirmImport = async () => {
        const confirmed = await showConfirmDialog(
            t("translation:components.tournament.games.cs_go.match_drawer.cs_match_drawer.import_match"),
            t("translation:components.tournament.games.cs_go.match_drawer.cs_match_drawer.import_match_desc")
        );
        return confirmed ?? false;
    };

    const onBackup = async (matchSeriesId: string) => {
        const confirmed = await showConfirmDialog(
            t("translation:components.tournament.games.cs_go.match_drawer.cs_match_drawer.backup_server"),
            t("translation:components.tournament.games.cs_go.match_drawer.cs_match_drawer.backup_server_desc")
        );
        if (confirmed) {
            await sendCommand("BackupGameServer", { matchSeriesId });
        }
    };

    const onRestore = async (matchSeriesId: string) => {
        const modalResult = await showModal<CsMatchRestoreDialogResult>((onClose) => (
            <CsMatchRestoreDialog matchSeriesId={matchSeriesId} onClose={onClose} />
        ));

        if (modalResult?.backupId) {
            await sendCommand("RestoreGameServer", {
                matchSeriesId,
                backupId: modalResult.backupId,
                restart: modalResult.restart,
            });
        }
    };

    const s = connectionInfo?.status;
    const showKill = s === CsGameServerStatus.Stopping;
    const disabled = matchSeries.status > MatchStatus.NotStarted;
    return (
        <Box>
            <CsGameSettingsInput
                disabled={disabled}
                matchSeries={matchSeries}
                gameSettings={gameSettings}
                connectionInfo={connectionInfo}
            />
            <DrawerSection>
                {t("translation:components.tournament.games.cs_go.match_drawer.cs_match_drawer.game_server")}
            </DrawerSection>
            <TimeToJoin matchSeries={matchSeries} />
            <PropertyList properties={data} />
            <ButtonContainer sx={{ margin: "1rem 0" }}>
                {isAdmin && (
                    <FileUploadButton
                        onUploadClick={confirmImport}
                        size="small"
                        fileUploadProps={{
                            token: TokenStore.getToken(),
                            uploadEndpoint: `api/cs/backoffice/import_get5?matchSeriesId=${matchSeries.id}`,
                        }}
                    >
                        {t(
                            "translation:components.tournament.games.cs_go.match_drawer.cs_match_drawer.import_match_stats"
                        )}
                    </FileUploadButton>
                )}
            </ButtonContainer>
            <PauseResumeControl matchSeries={matchSeries} />
            <ButtonContainer>
                {showKill && (
                    <SpinnerButton variant="contained" size="small" onClick={() => onKillServer(matchSeries.id)}>
                        {t("translation:components.tournament.games.cs_go.match_drawer.cs_match_drawer.kill_server")}
                    </SpinnerButton>
                )}
            </ButtonContainer>
            {isAdmin && (
                <>
                    <DrawerSection>Backup &amp; Restore</DrawerSection>
                    <ButtonContainer>
                        <SpinnerButton variant="contained" size="small" onClick={() => onBackup(matchSeries.id)}>
                            {t("common:shared.backup")}
                        </SpinnerButton>
                        <SpinnerButton variant="contained" size="small" onClick={() => onRestore(matchSeries.id)}>
                            {t("translation:components.tournament.games.cs_go.match_drawer.cs_match_drawer.restore")}
                        </SpinnerButton>
                    </ButtonContainer>
                </>
            )}
        </Box>
    );
};

const PauseResumeControl = ({ matchSeries }: { matchSeries: MatchSeries }) => {
    const connectionInfo = matchSeries.connectionInfo.fields as unknown as CsMatchConnectionInfo;
    const disabled = connectionInfo?.status !== CsGameServerStatus.Started;

    const handlePause = async () => {
        await sendCommand("PauseCsGoMatch", {
            matchSeriesId: matchSeries.id,
        });
    };

    const handleResume = async () => {
        await sendCommand("ResumeCsGoMatch", {
            matchSeriesId: matchSeries.id,
        });
    };

    return (
        <>
            <DrawerSection>In game controls</DrawerSection>
            <ButtonContainer>
                {!connectionInfo?.paused && (
                    <SpinnerButton disabled={disabled} variant="contained" size="small" onClick={() => handlePause()}>
                        Pause match
                    </SpinnerButton>
                )}
                {connectionInfo?.paused && (
                    <SpinnerButton disabled={disabled} variant="contained" size="small" onClick={() => handleResume()}>
                        Resume match
                    </SpinnerButton>
                )}
            </ButtonContainer>
        </>
    );
};
