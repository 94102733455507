import { convertToMatchSeries } from "@components/matches/api/Converters";
import type { MatchSeries } from "@components/matches/api/MatchSeries";
import type { PaginationResult } from "@masterblaster/api";
import { get, getSwr, sendCommand } from "@masterblaster/api";
import type { SocialLink } from "@masterblaster/basics";
import { useCallback, useEffect, useState } from "react";

export interface CreateCommunityRequest {
    id: string | undefined;
    name: string | undefined;
}

export enum CommunityImageType {
    Avatar = 1,
    Cover = 2,
}

export interface CommunityImageModel {
    imageType: CommunityImageType;
    imageId: string;
    originalImageId?: string;
}
export interface CommunityModel {
    id: string;
    name: string;
    about: string;
    supportLink: string;
    ownerId: string;
    isPublished: boolean;
    socialLinks: SocialLink[];
    images: CommunityImageModel[];
}

export interface CommunityOverviewModel {
    id: string;
    name: string;
    images: CommunityImageModel[];
}

export enum CommunityAccessLevel {
    GameAdmin = 100,
    TournamentAdmin = 200,
    CreateTournament = 300,
    CommunityAdmin = 400,
}

/* Back office */
export const createCommunity = (community: CreateCommunityRequest) =>
    sendCommand<CommunityModel>("CreateCommunity", community);

export const getUserCommunities = (accessLevel?: CommunityAccessLevel) =>
    get<CommunityModel[]>(`/api/communities/my?accessLevel=${accessLevel}`);

/* Public acccess */
export const getCommunity = (id: string | undefined) => getSwr<CommunityModel>(id ? `/api/communities/${id}` : null);

export const getCommunities = (page: number, pageSize?: number) =>
    get<{ myCommunities: CommunityOverviewModel[]; communities: PaginationResult<CommunityOverviewModel> }>(
        `/api/communities?page=${page}&pagesize=${pageSize}`
    );

export const getStreamCommunityMatches = (communityId: string) =>
    get<MatchSeries[]>(`/api/matches/community/${communityId}/streamed`).then((result) => {
        return result.map((x) => convertToMatchSeries(x));
    });

export const useCommunity = (communityId: string | undefined) => {
    const { data: community, mutate: fetchData } = getCommunity(communityId);

    return { community, refresh: fetchData };
};
