import type { CommunityModel } from "@components/communities";
import type { MatchSeries } from "@components/matches";
import type { CompetitionTeamDto } from "@components/team";
import type { TournamentDto } from "@masterblaster/api";
import { createContext, useContext } from "react";

interface TournamentUi {
    isCalculatingBrackets: boolean;
    setIsCalculatingBrackets: (state: boolean) => void;
}

interface TournamentContextProps {
    competition: TournamentDto;
    competitionTeams: CompetitionTeamDto[];
    community: CommunityModel | undefined;
    matches: MatchSeries[];
    streamedMatches: MatchSeries[];
    updateCompetition: (tournament: TournamentDto) => Promise<TournamentDto | undefined>;
    ui: TournamentUi;
}

export const TournamentContext = createContext<TournamentContextProps>(undefined!);

export const defaultCompetitionContextValues = {
    competition: undefined!,
    competitionTeams: [],
    community: undefined,
    matches: [],
    streamedMatches: [],
    updateCompetition: (t) => Promise.resolve(),
    ui: {
        isCalculatingBrackets: false,
        setIsCalculatingBrackets: () => void 0,
    },
} as TournamentContextProps;

export const useTournamentContext = () => {
    return useContext(TournamentContext);
};
