import { type Subscription } from "@components/subscriptions";
import { Box, Button, styled } from "@mui/material";
import { assignSubscriptionSeat, unassignSubscriptionSeat } from "../SubscriptionApi";

export const AddRemovePassButtonsComponent = ({
    subscription,
    playerId,
}: {
    subscription: Subscription;
    playerId: string;
}) => {
    const hasSeat = subscription.seatAssignments.find((x) => x.playerId === playerId);
    if (hasSeat) {
        return (
            <PassButtonContainer>
                <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => unassignSubscriptionSeat(subscription.id, playerId)}
                >
                    Remove pass
                </Button>
            </PassButtonContainer>
        );
    }

    const disabled = subscription.seatAssignments.length >= subscription.numberOfSeats;

    return (
        <PassButtonContainer>
            <Button
                disabled={disabled}
                variant="contained"
                fullWidth
                onClick={() => assignSubscriptionSeat(subscription.id, playerId)}
            >
                Add pass
            </Button>
        </PassButtonContainer>
    );
};

const PassButtonContainer = styled(Box)({ width: "120px" });
