import { CommunityImageType, useCommunity } from "@components/communities";
import type { SubscriptionMember } from "@components/subscriptions";
import { showEditSubscriptionNumberOfSeatsDialog } from "@components/subscriptions";
import { Add as AddIcon, Settings as SettingsIcon } from "@mui/icons-material";
import type { SxProps, Theme } from "@mui/material";
import { Box, Button, IconButton, Link, styled } from "@mui/material";
import { isAdministrator } from "@mb/auth";
import { DefaultCommunityHeader } from "@masterblaster/assets";
import { updateSubscription } from "../SubscriptionApi";
import type { SubscriptionType } from "../SubscriptionEnums";
import { getImageUrl } from "@masterblaster/api";

interface SubscriptionInfo {
    id?: string;
    name: string;
    numberOfSeats?: number;
    subscribeToId: string;
    subscriptionType: SubscriptionType;
    seatAssignments?: SubscriptionMember[];
}

export const SubscriptionAccessPassComponent = ({
    subscription,
    onCreatePass,
}: {
    subscription: SubscriptionInfo;
    onCreatePass?: () => Promise<void>;
}) => {
    const isAdmin = isAdministrator();
    const handleUpdateNumberOfSeats = async () => {
        if (subscription?.id) {
            const seats = await showEditSubscriptionNumberOfSeatsDialog(subscription.numberOfSeats);
            if (seats !== undefined) {
                await updateSubscription(subscription.id, seats);
            }
        } else {
            await onCreatePass?.();
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
                background: (theme) => theme.colors.greys[16],
                border: (theme) => `1px solid ${theme.colors.greys[7]}`,
                boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.4)",
                borderRadius: "8px",
            }}
        >
            <AccessPassBannerComponent
                subscribeToId={subscription.subscribeToId}
                subscriptionType={subscription.subscriptionType}
            />
            <Box
                sx={{
                    display: "flex",
                    position: "relative",
                    flexDirection: "column",
                    alignItem: "center",
                    gap: "1.5rem",
                    width: 1,
                }}
            >
                {isAdmin && (
                    <Box sx={{ position: "absolute", top: "-1rem", right: 0 }}>
                        <IconButton onClick={handleUpdateNumberOfSeats}>
                            {subscription.id ? <SettingsIcon /> : <AddIcon />}
                        </IconButton>
                    </Box>
                )}

                <AccessPassName name={subscription.name} sx={{ padding: subscription.id ? "auto" : "3.5rem 0" }} />
                <AccessPassAssignCounter
                    total={subscription.numberOfSeats}
                    assigned={subscription.seatAssignments?.length}
                />

                <Box sx={{ padding: "0 1rem 1rem 1rem" }}>
                    <Button
                        variant="contained"
                        fullWidth
                        component={Link}
                        target="_blank"
                        href="https://skagerrak.tech/priser"
                    >
                        {subscription.id ? "Buy more" : "Buy pass"}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

const AccessPassName = ({ name, sx }: { name: string; sx?: SxProps<Theme> }) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", ...sx }}>
            <AccessPassText style={{ fontWeight: 300 }}>Access pass</AccessPassText>
            <AccessPassText style={{ fontWeight: 700 }}>{name}</AccessPassText>
        </Box>
    );
};

const AccessPassAssignCounter = ({ total, assigned }: { total: number | undefined; assigned: number | undefined }) => {
    if (total === undefined || assigned === undefined) {
        return null;
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: "0.5rem" }}>
            <AccessPassNumber label="Total" count={total} sx={{ color: (theme) => theme.colors.greys[5] }} />
            <AccessPassNumber label="In use" count={assigned} />
            <AccessPassNumber label="Open" count={total - assigned} />
        </Box>
    );
};

const AccessPassBannerComponent = ({
    subscribeToId,
}: {
    subscribeToId: string;
    subscriptionType: SubscriptionType;
}) => {
    const { community } = useCommunity(subscribeToId);

    if (!community) {
        return null;
    }

    const headerImage = community.images.find((x) => x.imageType === CommunityImageType.Cover);
    return (
        <AccessPassBannerImage src={getImageUrl(headerImage?.imageId) || DefaultCommunityHeader} alt="Access pass" />
    );
};

const AccessPassBannerImage = styled("img")({
    width: "100%",
    minHeight: "150px",
    maxHeight: "150px",
    maxWidth: "325px",
    objectFit: "cover",
    objectPosition: "center",
});

const AccessPassNumber = ({ label, count, sx }: { label: string; count: number; sx?: SxProps<Theme> }) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "0.5rem", ...sx }}>
            <span style={{ fontSize: "10px", lineHeight: "16px", fontWeight: 700 }}>{label}</span>
            <span style={{ fontSize: "40px", lineHeight: "48px", fontWeight: 700 }}>{count}</span>
        </Box>
    );
};

const AccessPassText = styled("span")({
    fontSize: "32px",
    lineHeight: "38px",
});
