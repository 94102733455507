import type { CSSProperties, FC } from "react";
import { forwardRef } from "react";
import type { LinkProps } from "react-router-dom";
import { Link } from "react-router-dom";
import { Link as LinkV6 } from "react-router-dom-v5-compat";

import type { SxProps, Theme } from "@mui/material";
import { MbAnchor, MbRouterLink } from "./buttons";

export const urlRegExp =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export const LinkBehaviour = forwardRef<any, Omit<LinkProps, "to"> & { href: LinkProps["to"] }>(
    function LinkBehavior(props, ref) {
        const { href, target, ...other } = props;
        // Map href (MUI) -> to (react-router)
        return (
            <Link
                data-testid="custom-link"
                ref={ref}
                target={target}
                to={href}
                style={{ whiteSpace: "nowrap" }}
                {...other}
            />
        );
    }
);

export const LinkBehaviourV6 = forwardRef<any, Omit<LinkProps, "to"> & { href: LinkProps["to"] }>(
    function LinkBehavior(props, ref) {
        const { href, target, ...other } = props;
        // Map href (MUI) -> to (react-router)
        return (
            <LinkV6
                data-testid="custom-link"
                ref={ref}
                target={target}
                to={href}
                style={{ whiteSpace: "nowrap" }}
                {...other}
            />
        );
    }
);

interface GfLinkProps extends LinkProps {
    color?: string;
    style?: CSSProperties;
    underline?: boolean;
    sx?: SxProps<Theme>;
    external?: boolean;
}
export const GfLink: FC<GfLinkProps> = (props) => {
    const { color, underline, to, external, ...rest } = props;
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    const href = to.toString() ?? "";

    urlRegExp.lastIndex = 0;
    const isExternal = external ?? urlRegExp.test(href);

    const hasProtocol = href.startsWith("http") || href.startsWith("//");

    if (isExternal) {
        return (
            <MbAnchor
                href={hasProtocol ? href : `https://${href}`}
                target="_blank"
                rel="noopener noreferrer"
                style={props.style}
                sx={props.sx}
            >
                {props.children}
            </MbAnchor>
        );
    }

    return (
        <MbRouterLink color={color} underline={underline} to={to} {...rest}>
            {props.children}
        </MbRouterLink>
    );
};
