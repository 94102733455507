import { MbTextField } from "@masterblaster/basics";
import { searchOrganization } from "@services/Search";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import type { CSSProperties } from "react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const typingInterval = 250;

const validateName = (name: string) => searchOrganization(name, true);
export const validateNameDebounced = AwesomeDebouncePromise(validateName, typingInterval);

export const OrganizationNameInput = (props: {
    name: string | undefined;
    disabled?: boolean;
    style?: CSSProperties;
    onChanged(name: string, isValid: boolean): void;
}) => {
    const { disabled, style, onChanged } = props;
    const { t } = useTranslation(["common", "translation", "validation"]);

    const [name, setName] = useState(props.name);
    const [isOrganizationNameValid, setIsOrganizationNameValid] = useState<boolean>();
    const [isSearching, setIsSearching] = useState(false);
    const isNameExist = !!name;
    const isNameChanged = true;

    const validateName = useCallback((name: string) => {
        setIsSearching(true);
        return validateNameDebounced(name).then((response) => {
            const isValid = response && response.data && response.data.length === 0;
            setIsSearching(false);

            return isValid;
        });
    }, []);

    useEffect(() => {
        if (!name || name?.toLowerCase() === props.name?.toLowerCase()) {
            return;
        }

        validateName(name).then((isValid) => {
            setIsOrganizationNameValid(isValid);
            onChanged(name, isValid);
        });
    }, [name, props.name, validateName, onChanged]);

    const getOrganizationNameHelpText = () => {
        if (isNameExist && isNameChanged && !isSearching) {
            return isOrganizationNameValid
                ? t("validation:components.organization.organization_name_available")
                : t("validation:components.organization.organization_name_exist");
        }
    };

    const hasError = isNameExist && isNameChanged && isOrganizationNameValid === false;
    const isSuccess = isNameExist && isNameChanged && isOrganizationNameValid;

    return (
        <MbTextField
            id="new-organization-name"
            fullWidth
            autoComplete="organizationname"
            placeholder={t("translation:components.organizations.organization_name_input.placeholder")}
            error={hasError}
            success={isSuccess}
            helpText={getOrganizationNameHelpText()}
            value={name}
            disabled={disabled}
            label={t("common:shared.organization_name")}
            style={style}
            onChange={(e) => setName(e.currentTarget.value)}
            autoFocus
        />
    );
};
