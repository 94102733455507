import type { CsVersion } from "@components/competitions/tournament/games/csgo";
import type { TeamDto } from "@components/team/TeamService";
import { CompetitionTeamPlayerRole } from "@components/team/TeamService";
import type { CompetitionFormat, CompetitionStatus, CustomFieldValues, GameType, PlayerDto } from "@masterblaster/api";
import { getEnumList } from "@utils/enums";
import { getHead2HeadTeams } from "./MatchApi";

export const getMatchStatusEnums = <T extends object>(enumType: T, toText?: (value: T[keyof T]) => string) => {
    return getEnumList(enumType, toText).filter((x) => x.value !== 1);
};

export interface IMatch {
    id: string;
    index: number;
    displayName: string;
    name: string;
    status: MatchStatus;
    statusText: string;
    enteredPostgameAt: string | undefined;
    createdAt: string | undefined;
}

export interface MatchModel extends IMatch {
    teamScores: MatchTeamScore[];
}

export enum MatchRescheduleProposalStatus {
    Pending = 10,
    Accepted = 20,
    Rejected = 30,
}
export interface MatchRescheduleProposal {
    id: string;
    matchSeriesId: string;
    teamId: string;
    userId: string;
    scheduledAt: string;
    status: MatchRescheduleProposalStatus;
}

export interface IMatchSeriesTeamScore {
    teamId: string;
    score: { value: number | undefined; customFields: CustomFieldValues };
    // value: number | undefined;
}

export interface ReportedScoreImage {
    teamId: string;
    imageId: string;
}

export type CsPluginType = "affix" | "other";

export interface CsPlugin {
    name: CsPluginType;
    enabled: boolean;
}

export interface MatchSeriesGameSettings {}

export interface MatchSeriesCsGameSettings extends MatchSeriesGameSettings {
    locationId: string;
    useAutoForfeit: boolean;
    forfeitTimeoutInSeconds: number;
    customConfigFile: string | undefined;
    plugins: CsPlugin[];
    version: CsVersion;
    insecureServer: boolean;
}

export interface MatchConnectionInfo {
    fields: CustomFieldValues;
}

export enum MatchStatus {
    NotStarted = 0,
    Starting = 10,
    Started = 20,
    Postgame = 25,
    Paused = 27,
    Failed = 28,
    Finished = 30,
}

export enum MatchSeriesType {
    Normal = 0,
    Upper = 1,
    Lower = 2,
}

export enum MatchSeriesFormat {
    RoundRobin = 0,
    SingleElimination = 1,
    DoubleElimination = 2,
    BattleRoyale = 3,
    Challenge = 4,
    Swiss = 5,
}

export enum MatchType {
    Normal,
    Bye,
    BronzeFinal,
    Final,
    LowerFinal,
    GrandFinal,
}

export interface MatchTeamScore {
    competitionId: string;
    matchSeriesId: string | undefined;
    matchId: string;
    teamId: string;
    gameId: string;
    value?: number;
    customFields: CustomFieldValues;

    reportedByTeamId: string | undefined;
    reportedAt: string | undefined;

    reportedScoreConfirmedByTeamId: string | undefined;
    reportedScoreConfirmedByPlayerId: string | undefined;
    reportedScoreConfirmedAt: string | undefined;
    imageIds: string[];
}

export interface MatchTeamPlayerScore {
    playerId: string;
}

export interface MatchSeriesSettings {
    useVeto: boolean;
    useVetoSide: boolean;
    useBestOfX: boolean;
    mapVetoId: string | undefined;
    numberOfMaps: number;
    isVetoCompleted: boolean;
    useDemoUpload: boolean;
    matchSummaryTimeout: string;

    isTwitchStreamingEnabled?: boolean;
    twitchStreamingUrl?: string;

    enableForfeitTimer: boolean;
    timeToReadyUpBeforeForfeitInSeconds: number | undefined;
}

// As defined on the backend MatchSeries.cs
export interface IMatchSeries {
    id: string;
    startId: string | undefined;
    competitionId: string;
    roundIndex: number;
    matchIndex: number;
    name: string;
    game: GameType;
    gameId: string;
    status: MatchStatus;
    statusChangedAt: string | undefined;
    matchType: MatchType;
    type: MatchSeriesType;
    format: MatchSeriesFormat;
    settings: MatchSeriesSettings;

    enteredPostgameAt: string | undefined;
    completedAt: string | undefined;

    failedReason: string | undefined;

    pausedBy: string | undefined;
    pausedAt: string | undefined;
    pauseReason: string | undefined;

    lastPossibleMatchRescheduleDate: string | undefined;
    isMatchReschedulingEnabled: boolean;
    matchScheduleProposals: MatchRescheduleProposal[];

    startingAt: string | undefined;
    startedAt: string | undefined;
    createdAt: string;

    teamScores: IMatchSeriesTeamScore[];
    forfeitedOrDisqualified: boolean;
    hasForfeited: boolean;
    hasDisqualified: boolean;

    teams: IMatchSeriesTeam[];
    series: IMatch[];

    connectionInfo: MatchConnectionInfo;
    gameSettings: MatchSeriesGameSettings | undefined;
}

export interface MatchSeries extends Omit<IMatchSeries, "teams"> {
    teams: MatchSeriesTeam[];

    series: MatchModel[];

    competitionFormat: CompetitionFormat;
    competitionStatus: CompetitionStatus;
    competitionName: string;

    // Mapped from Leagues competitions
    parentLeagueId: string | undefined;
    parentLeagueName: string | undefined;
    parentLeagueGroupId: string | undefined;
    parentLeagueSortOrder: number | undefined;
}

export enum MatchSeriesTeamPlayerStatus {
    Offline = 0,
    Online = 1,
    Ready = 2,
}

export interface IMatchSeriesTeamPlayer {
    playerId: string;
    role: CompetitionTeamPlayerRole;
    status: MatchSeriesTeamPlayerStatus;
}

export interface MatchSeriesTeamPlayer {
    playerId: string;
    player: PlayerDto;
    role: CompetitionTeamPlayerRole;
    status: MatchSeriesTeamPlayerStatus;
}

export interface IMatchSeriesTeam {
    teamId: string | undefined;
    slot: number;
    source: string | undefined;
    sourceId: string | undefined;
    useLoser: boolean;
    advantage: number;
    players: IMatchSeriesTeamPlayer[];

    forfeited: boolean;
    forfeitReason: string | undefined;
    disqualified: boolean;
    disqualifiedReason: string | undefined;
    withdrawn: boolean;
}

export interface MatchSeriesTeam extends IMatchSeriesTeam {
    team: TeamDto | undefined;
    players: MatchSeriesTeamPlayer[];
}

export interface MatchTeamScoreStats {
    score: number | string | undefined;
    disqualified: boolean;
    forfeited: boolean;
    leading: boolean;
    winner: boolean;
}

export interface MatchSeriesStats {
    disqualified: boolean;
    forfeited: boolean;
    teamA: MatchTeamScoreStats;
    teamB: MatchTeamScoreStats;
}

export interface MatchDemo {
    id: string;
    matchSeriesId: string;
    matchId: string;
    matchIndex: number | undefined;
    map: string;
    demo: string;
}

export const getMatchScore = (matchSeries: MatchSeries, match: MatchModel | undefined) => {
    return computeMatchSeriesScore(matchSeries.teams, match?.status, (teamId) => {
        return match?.teamScores.find((x) => x.teamId === teamId)?.value;
    });
};

export const getMatchSeriesScore = (matchSeries: MatchSeries) => {
    return computeMatchSeriesScore(matchSeries.teams, matchSeries.status, (teamId) => {
        return matchSeries.teamScores.find((x) => x.teamId === teamId)?.score?.value;
    });
};

const computeMatchSeriesScore = (
    teams: MatchSeriesTeam[],
    status: MatchStatus | undefined,
    scoreProvider: (teamId: string | undefined) => number | undefined
) => {
    if (status === undefined) {
        return {
            forfeited: false,
            disqualified: false,
            teamA: {
                score: 0,
                disqualified: false,
                winner: false,
                leading: false,
            } as MatchTeamScoreStats,
            teamB: {
                score: 0,
                disqualified: false,
                winner: false,
                leading: false,
            } as MatchTeamScoreStats,
        } as MatchSeriesStats;
    }

    const getTeamScore = (matchTeam: MatchSeriesTeam | undefined) => {
        if (!matchTeam) {
            return "-";
        }

        if (matchTeam.disqualified) {
            return "DQ";
        }

        const score = scoreProvider(matchTeam.team?.id);
        return score ?? 0;
    };

    const [teamA, teamB] = getHead2HeadTeams(teams);
    const finished = status >= MatchStatus.Finished;
    const teamAScore = teamB?.forfeited ? "W" : getTeamScore(teamA);
    const teamBScore = teamA?.forfeited ? "W" : getTeamScore(teamB);

    return {
        forfeited: teamA?.forfeited || teamB?.forfeited,
        disqualified: teamA?.disqualified || teamB?.disqualified,
        teamA: {
            score: teamAScore,
            forfeited: teamA?.forfeited,

            disqualified: teamA?.disqualified,
            winner: teamB?.withdrawn || (finished && teamAScore > teamBScore),
            leading: !finished && teamAScore > teamBScore,
        } as MatchTeamScoreStats,
        teamB: {
            score: teamBScore,
            forfeited: teamB?.forfeited,
            disqualified: teamB?.disqualified,
            winner: teamA?.withdrawn || (finished && teamAScore < teamBScore),
            leading: !finished && teamAScore < teamBScore,
        } as MatchTeamScoreStats,
    } as MatchSeriesStats;
};

export const getMatchSeriesPlayersAndRoles = (
    teams: { teamId: string | undefined; players: IMatchSeriesTeamPlayer[] }[]
) => {
    return teams.flatMap((x) => x.players.map((y) => ({ teamId: x.teamId, player: y })));
};

export const isPlayerInMatch = (matchSeries: MatchSeries, playerId?: string) => {
    if (!playerId) {
        return false;
    }

    const players = matchSeries.teams.flatMap((x) => x.players.flatMap((y) => y.player) ?? []);
    return players.some((x) => x.id === playerId);
};

export const getMatchSeriesTeamPlayer = (
    teams: { teamId: string | undefined; players: IMatchSeriesTeamPlayer[] }[],
    playerId: string | undefined,
    role?: CompetitionTeamPlayerRole
) => {
    if (!playerId) {
        return undefined;
    }

    const entry = getMatchSeriesPlayersAndRoles(teams).find(
        (x) => x.player.playerId === playerId && (!role || role === x.player.role)
    );

    if (!entry || !entry?.teamId) {
        return undefined;
    }

    return {
        teamId: entry.teamId,
        playerId: entry.player?.playerId,
        player: entry.player,
        role: entry.player.role,
    };
};

export const getMatchSeriesTeamAdmin = (matchSeriesTeams: MatchSeriesTeam[], playerId?: string) => {
    if (!playerId) {
        return undefined;
    }

    const teamAdmin = matchSeriesTeams
        .map((x) => {
            const player = x.team?.players.find((p) => p.playerId === playerId);
            return {
                team: x.team,
                player,
                matchSeriesTeam: x,
                role: x.players.find((p) => p.playerId === playerId)?.role,
            };
        })
        .filter((x) => x.matchSeriesTeam && x.player?.isAdministrator)
        .map((x) => ({
            teamId: x.team?.id,
            playerId: x.player?.playerId,
            player: x.player,
            role: x.role,
        }))[0];

    return teamAdmin;
};

export const getTeamAndTeamLeader = (matchSeriesTeams: (MatchSeriesTeam | undefined)[], teamId: string | undefined) => {
    const team = matchSeriesTeams.find((x) => x?.team?.id === teamId);
    const teamLeader = team?.players.find((x) => x.role === CompetitionTeamPlayerRole.TeamLeader);

    return { team, teamLeader };
};

export const getPlayerInMatchSeriesTeam = (
    matchSeriesTeam: MatchSeriesTeam | undefined,
    playerId: string | undefined
) => {
    return matchSeriesTeam?.players.find((x) => x.playerId === playerId);
};

export const getMatchSeriesTeamAndPlayer = (matchSeries: MatchSeries | undefined, userId: string | undefined) => {
    if (!matchSeries) {
        return {
            matchSeriesTeam: undefined,
            matchSeriesTeamPlayer: undefined,
        };
    }

    const isPlayer = (team: MatchSeriesTeam) => team.players.some((x) => x.playerId === userId);
    const matchSeriesTeam = matchSeries.teams.find((x) => isPlayer(x));
    const matchSeriesTeamPlayer = matchSeriesTeam?.players.find((x) => x.playerId === userId);

    return {
        matchSeriesTeam,
        matchSeriesTeamPlayer,
    };
};

export const getMatchTeamPlayerStatus = (matchTeam: MatchSeriesTeam) => {
    const players = matchTeam?.players.filter((x) => x.role !== CompetitionTeamPlayerRole.None) ?? [];

    const readyPlayers = players.filter((x) => x.status === MatchSeriesTeamPlayerStatus.Ready);
    const notReadyPlayers = players.filter((x) => x.status !== MatchSeriesTeamPlayerStatus.Ready);

    const teamSize = players.filter((x) => x.role !== CompetitionTeamPlayerRole.Substitute).length;
    const allReady = readyPlayers.length === teamSize;

    return { players, readyPlayers, notReadyPlayers, teamSize, allReady };
};
