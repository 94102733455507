import { MatchSeriesCard } from "@components/cards/match/MatchSeriesCard";
import type { MatchSeries } from "@components/matches";
import { TwitchTagState, useQuery } from "@masterblaster/api";
import { GfLoader, LinkBehaviour } from "@masterblaster/basics";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { StreamStatusSelector } from "./StreamStatusSelector";
import { StreamsEmptyView } from "./StreamsEmptyView";
import { decomposeStreamedMatchByStatus } from "./helpers";
import { StreamsContainer, StreamsLoadingContainer } from "./shared";

interface StreamsPageProps {
    competitionId: string;
    streamedMatches: MatchSeries[];
    isLoading: boolean;
    backToLink: string;
}

export const AllStreamsOverview: React.FC<StreamsPageProps> = (props: StreamsPageProps) => {
    const history = useHistory();
    const { getQueryParam } = useQuery();
    const { t } = useTranslation("translation", {
        keyPrefix: "components.stream",
    });
    const id = props.competitionId;
    const defaultFilter = "SCHEDULED";
    const status =
        TwitchTagState[(getQueryParam("status")?.toUpperCase() ?? defaultFilter) as keyof typeof TwitchTagState];
    const urlStatus =
        (TwitchTagState[status]?.toLowerCase() as Lowercase<keyof typeof TwitchTagState>) ?? defaultFilter;

    const streamedMatchesByStatus = useMemo(
        () => decomposeStreamedMatchByStatus(props.streamedMatches),
        [props.streamedMatches]
    );

    const filteredStreamedMatches = streamedMatchesByStatus[urlStatus];

    const onStatusChanged = (value: TwitchTagState) => {
        history.push({
            search: `status=${TwitchTagState[value]?.toLowerCase() ?? defaultFilter}`,
        });
    };

    return (
        <div>
            <StreamsTopSection>
                <Button
                    variant="contained"
                    LinkComponent={LinkBehaviour}
                    href={props.backToLink}
                    startIcon={<ChevronLeftIcon />}
                >
                    {t("back_to_overview")}
                </Button>
            </StreamsTopSection>
            <StreamsHeader>
                <StreamsHeading>{t("streamed_matches")}</StreamsHeading>
                <StreamStatusSelector value={status} onChanged={onStatusChanged} />
            </StreamsHeader>
            {(props.isLoading || !props.streamedMatches) && (
                <StreamsLoadingContainer>
                    <GfLoader />
                </StreamsLoadingContainer>
            )}
            {!props.isLoading && props.streamedMatches && (
                <StreamsContainer>
                    {filteredStreamedMatches.length > 0 ? (
                        filteredStreamedMatches.map((matchSeries, index) => (
                            <MatchSeriesCard key={index} matchSeries={matchSeries} />
                        ))
                    ) : (
                        <StreamsEmptyView />
                    )}
                </StreamsContainer>
            )}
        </div>
    );
};

const StreamsTopSection = styled("div")({
    marginBottom: "2rem",
});

const StreamsHeader = styled("header")({
    marginBottom: "1.5rem",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
});

const StreamsHeading = styled("h3")(({ theme }) => ({
    margin: 0,
    fontSize: "1.125rem",
    lineHeight: "1.5625rem",
    color: theme.colors.greys[2],
}));
