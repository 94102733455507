import { ErrorText } from "@masterblaster/basics";
import { Box } from "@mui/material";
import { sendCommand } from "@masterblaster/api";

import { useTranslation } from "react-i18next";
import type { MatchSeries } from "../api/MatchSeries";
import { MatchStatus } from "../api/MatchSeries";
import { StartingAtInput } from "./StartingAtInput";
import type { TournamentDto } from "@masterblaster/api";

export const MatchStartingAtInput = ({
    tournament,
    matchSeries,
}: {
    tournament: TournamentDto;
    matchSeries: MatchSeries;
}) => {
    const { t } = useTranslation("validation");
    const onStartingAtUpdated = async (start: string | undefined) => {
        const result = await sendCommand("UpdateMatchSeriesStartingAt", {
            matchSeriesId: matchSeries.id,
            startingAt: start,
        });
        return result.success;
    };

    const disabled = matchSeries.status > MatchStatus.NotStarted;
    return (
        <Box>
            {disabled && <ErrorText size="xs">{t("components.tournament.cannot_change_start_time")}</ErrorText>}
            <StartingAtInput
                disabled={disabled}
                startingAt={matchSeries.startingAt}
                competitionScheduledTime={tournament.schedule.scheduledTime}
                onSaved={(start) => onStartingAtUpdated(start)}
            />
        </Box>
    );
};
