import type { GameSetup } from "@masterblaster/api";
import { createContext, useContext } from "react";
import type { TeamDto } from "@components/team";
import type { PlayerOrganizationModel } from "@components/organizations";
import type { AppConfig } from "./AppConfigKeeper";

export const AppConfigContext = createContext<{
    environment: string;
    version: string;
    isLoading: boolean;
    games: GameSetup[];
    playerTeams: TeamDto[];
    playerOrganizations: PlayerOrganizationModel[];
    appConfig: AppConfig;
}>(undefined!);

export const useAppConfigContext = () => {
    return useContext(AppConfigContext);
};

export const useTenant = () => {
    const { appConfig } = useAppConfigContext();

    return {
        tenantId: appConfig.tenantId,
        ui: appConfig.tenantUiConfig ?? {},
    };
};
