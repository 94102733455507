import { MbNumberField } from "@masterblaster/basics";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { useState } from "react";
import { useIsMobile } from "@masterblaster/theme";
import { useTranslation } from "react-i18next";
import { showModal } from "@store/core";

export const showEditSubscriptionNumberOfSeatsDialog = (numberOfSeats: number | undefined) => {
    return showModal<number | undefined>((onClose) => (
        <SubscriptionEditNumberOfSeatsDialog onClose={onClose} numberOfSeats={numberOfSeats} />
    ));
};

const SubscriptionEditNumberOfSeatsDialog = ({
    numberOfSeats,
    onClose,
}: {
    numberOfSeats: number | undefined;
    onClose: (numberOfSeats: number | undefined) => void;
}) => {
    const isMobile = useIsMobile();
    const { t } = useTranslation(["common"]);
    const [seats, setSeats] = useState<number | undefined>(numberOfSeats);
    return (
        <Dialog open={true} fullWidth={true} fullScreen={isMobile} maxWidth="xs">
            <DialogTitle>Edit number of seats</DialogTitle>
            <DialogContent>
                <MbNumberField
                    value={seats ?? 0}
                    onNumberChanged={(n) => {
                        setSeats(n);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="primary" onClick={() => onClose(undefined)}>
                    {t("common:button_actions.cancel")}
                </Button>
                <Button variant="contained" color="primary" onClick={() => onClose(seats)}>
                    {t("common:button_actions.ok")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
