import _ from "lodash";
import type { MatchSeries } from "@components/matches/api/MatchSeries";
import { TwitchTagState } from "@masterblaster/api";
import { getTwitchTagState } from "./twitch/helpers";

const decomposeStreamedMatchByStatus = (streamedMatches?: MatchSeries[]) => {
    if (!streamedMatches)
        return {
            all: [],
            live: [],
            scheduled: [],
            record: [],
        };

    const sortedStreamedMatches = _.orderBy(
        streamedMatches,
        [
            (matchSeries) => {
                switch (getTwitchTagState(matchSeries)) {
                    case TwitchTagState.LIVE:
                        return 0;
                    case TwitchTagState.SCHEDULED:
                        return 1;
                    case TwitchTagState.RECORD:
                        return 2;
                }
            },
            (matchSeries) => new Date(matchSeries.startingAt ?? 0).getTime(),
            (matchSeries) => matchSeries.roundIndex,
            (matchSeries) => matchSeries.matchIndex,
        ],
        ["asc", "desc", "desc", "asc"]
    );

    const streamedMatchesByStatus = streamedMatches.reduce<
        Record<Lowercase<keyof typeof TwitchTagState> | "all", MatchSeries[]>
    >(
        (acc, stream) => {
            const state = getTwitchTagState(stream);

            if (state === null) return acc;

            acc[TwitchTagState[state].toLowerCase() as Lowercase<keyof typeof TwitchTagState>].push(stream);

            return acc;
        },
        {
            all: [],
            live: [],
            scheduled: [],
            record: [],
        }
    );

    streamedMatchesByStatus.all = sortedStreamedMatches;

    return streamedMatchesByStatus;
};

export { decomposeStreamedMatchByStatus };
