import { CompetitionTeamPlayerRole } from "@components/team/TeamService";
import { useIsMobile } from "@masterblaster/theme";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import RestoreRoundedIcon from "@mui/icons-material/RestoreRounded";
import { Chip, Divider, IconButton, Typography, styled } from "@mui/material";
import { AccessLevels } from "@mb/auth";
import { useAuthorization } from "@services/AuthSerivce";
import { TimerGradientIcon } from "@masterblaster/assets";
import { formatDateTime } from "@masterblaster/api";
import type { ApplicationState } from "@store/core";
import { useMemo, type MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import type { MatchRescheduleProposal, MatchSeries } from "../api/MatchSeries";
import { MatchStatus, getMatchSeriesTeamAdmin, getMatchSeriesTeamPlayer } from "../api/MatchSeries";
import { getPendingProposal } from "./MatchRescheduleApi";
import { showMatchRescheduleInquiryDialog } from "./MatchRescheduleInquiryDialog";
import { showMatchRescheduleProposalDialog } from "./MatchRescheduleProposalDialog";
import { GradientBorder } from "./shared";

export interface MatchRescheduleBarProps {
    matchSeries: MatchSeries;
    variant?: "mini" | "normal";
}

export const MatchRescheduleBar = ({ matchSeries, variant }: MatchRescheduleBarProps) => {
    const user = useSelector((state: ApplicationState) => state.session.user);
    const isGameAdmin = useAuthorization("Tournament", matchSeries.competitionId, AccessLevels.GameAdmin);

    const proposal = useMemo(() => getPendingProposal(matchSeries.matchScheduleProposals), [matchSeries]);

    const teamPlayer = useMemo(
        () =>
            getMatchSeriesTeamPlayer(matchSeries.teams, user?.id, CompetitionTeamPlayerRole.TeamLeader) ??
            getMatchSeriesTeamAdmin(matchSeries.teams, user?.id),
        [matchSeries, user?.id]
    );

    const showRescheduleBar = proposal && teamPlayer?.teamId && matchSeries.status === MatchStatus.NotStarted;

    if (!showRescheduleBar || !matchSeries.lastPossibleMatchRescheduleDate) {
        return null;
    }

    return (
        <MatchRescheduleBarContent
            matchSeries={matchSeries}
            proposal={proposal}
            playerId={teamPlayer?.playerId}
            playerTeamId={teamPlayer?.teamId}
            isGameAdmin={isGameAdmin}
            variant={variant}
        />
    );
};

const MatchRescheduleBarContent = ({
    matchSeries,
    proposal,
    playerId,
    playerTeamId,
    isGameAdmin,
    variant,
}: {
    matchSeries: MatchSeries;
    proposal: MatchRescheduleProposal;
    isGameAdmin: boolean;
    playerId?: string;
    playerTeamId?: string;
    variant?: MatchRescheduleBarProps["variant"];
}) => {
    const isMobile = useIsMobile();
    const { t } = useTranslation("translation", {
        keyPrefix: "components.tournament.match.reschedule",
    });

    const handleAcceptOrDeclineSchedule = async () => {
        await showMatchRescheduleInquiryDialog(matchSeries, false, "accept");
    };

    const handleAcceptSchedule = async () => {
        await showMatchRescheduleInquiryDialog(matchSeries, true, "accept");
    };

    const handleRejectSchedule = async () => {
        await showMatchRescheduleInquiryDialog(matchSeries, true, "decline");
    };

    const isOtherTeamPlayer = playerTeamId !== proposal?.teamId;
    const canInteractWithBar = isOtherTeamPlayer || isGameAdmin;

    const handleOpenProposal = async () => {
        if (isOtherTeamPlayer) {
            return;
        }

        await showMatchRescheduleProposalDialog(matchSeries, playerId, proposal);
    };

    if (variant === "mini") {
        return (
            <Chip
                variant="high-emphasis"
                clickable={true}
                icon={<RestoreRoundedIcon />}
                onClick={canInteractWithBar ? handleAcceptOrDeclineSchedule : handleOpenProposal}
                sx={{
                    cursor: "pointer",
                    "& .MuiChip-icon": {
                        marginRight: 0,
                    },
                }}
            />
        );
    }

    return (
        <MatchRescheduleBarComponent
            proposal={proposal}
            viewOptions={canInteractWithBar}
            onAccept={handleAcceptSchedule}
            onReject={handleRejectSchedule}
            onClick={handleOpenProposal}
        />
    );
};

interface MatchRescheduleBarComponentProps {
    proposal: MatchRescheduleProposal;
    viewOptions?: boolean;
    onAccept: (proposal: MatchRescheduleProposal) => void;
    onReject: (proposal: MatchRescheduleProposal) => void;
    onClick: (proposal: MatchRescheduleProposal) => void;
}
const MatchRescheduleBarComponent = ({
    proposal,
    viewOptions,
    onAccept,
    onReject,
    onClick,
}: MatchRescheduleBarComponentProps) => {
    const { t } = useTranslation("translation", {
        keyPrefix: "components.tournament.match.reschedule",
    });

    const handleProposalClick = (e: MouseEvent) => {
        e.stopPropagation();
        onClick(proposal);
    };

    return (
        <RescheduleBarWrapper onClick={handleProposalClick}>
            <GradientBorder active>
                <RescheduleBarContainer>
                    <TimerImg src={TimerGradientIcon} alt="timer icon" />
                    <Typography mr={viewOptions ? "0.875rem" : "0"} fontWeight="bold">
                        {t("suggested_new_date", {
                            date: formatDateTime(proposal.scheduledAt),
                        })}
                    </Typography>
                    {viewOptions && (
                        <>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onAccept(proposal);
                                }}
                                sx={{ marginRight: "0.625rem" }}
                            >
                                <CheckIcon sx={{ color: "#339F00" }} />
                            </IconButton>
                            <StyledDivider orientation="vertical" />
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onReject(proposal);
                                }}
                            >
                                <CloseIcon sx={{ color: "#E82E2E" }} />
                            </IconButton>
                        </>
                    )}
                </RescheduleBarContainer>
            </GradientBorder>
        </RescheduleBarWrapper>
    );
};

const TimerImg = styled("img")({
    marginRight: "0.875rem",
});

const StyledDivider = styled(Divider)({
    height: "1.5rem",
    marginRight: "0.625rem",
    borderColor: "rgba(255, 255, 255, 0.25)",
});

const RescheduleBarContainer = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: "0.125rem 1.25rem",
    borderRadius: "4px",
    background: `linear-gradient(180deg, #282A36 0%, ${theme.colors.contrast_background} 100%)`,
}));

const RescheduleBarWrapper = styled("div")(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
        order: -1,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginBottom: "0.5rem",
    },
}));
