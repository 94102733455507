import { IconButton } from "@mui/material";
import type { Theme } from "@mui/material/styles";
import { styled, useTheme } from "@mui/material/styles";
import {
    LoginDiscord,
    LoginGoogle,
    LoginSteam,
    SocialDiscord,
    SocialDiscordInverted,
    SocialFacebook,
    SocialInstagram,
    SocialHome,
    SocialSteam,
    SocialSteamInverted,
    SocialTwitch,
    SocialTwitter,
    SocialVk,
    SocialGoogle,
    SocialGoogleInverted,
} from "@masterblaster/assets";
import type { FC, HTMLAttributes } from "react";
import { Icon } from "../customIcons/Icons";

export interface SocialLink {
    linkType: SocialLinkType;
    url: string;
}

export enum SocialLinkType {
    Twitter = 1,
    Discord = 2,
    Facebook = 3,
    Twitch = 4,
    Website = 5,
    Vk = 6,
    Steam = 7,
    Instagram = 8,
    Google = 9,
}

export const mapLoginProviderToSocialLinkType = (provider: string) => {
    switch (provider) {
        case "Steam":
            return SocialLinkType.Steam;
        case "Discord":
            return SocialLinkType.Discord;
        case "Google":
            return SocialLinkType.Google;
        default:
            return undefined;
    }
};

export const getSocialProperties = (variant: SocialLinkType, theme: Theme) => {
    const { colors } = theme;

    switch (variant) {
        case SocialLinkType.Discord:
            return {
                color: colors.discord,
                svg: SocialDiscord,
                invertedSvg: SocialDiscordInverted,
                loginIcon: LoginDiscord,
                icon: <Icon variant="discord" />,
            };
        case SocialLinkType.Facebook:
            return {
                color: colors.facebook,
                svg: SocialFacebook,
                icon: <Icon variant="facebook" />,
            };
        case SocialLinkType.Instagram:
            return {
                color: colors.instagram,
                svg: SocialInstagram,
                icon: <Icon variant="instagram" />,
            };
        case SocialLinkType.Twitter:
            return {
                color: colors.twitter,
                svg: SocialTwitter,
                icon: <Icon variant="twitter" />,
            };
        case SocialLinkType.Twitch:
            return {
                color: colors.twitch,
                svg: SocialTwitch,
                icon: <Icon variant="twitch" />,
            };
        case SocialLinkType.Website:
            return {
                color: colors.black,
                svg: SocialHome,
                icon: <Icon variant="home" />,
            };
        case SocialLinkType.Vk:
            return { color: colors.vk, svg: SocialVk, icon: <Icon variant="vk" /> };
        case SocialLinkType.Steam:
            return {
                color: colors.steam,
                svg: SocialSteam,
                invertedSvg: SocialSteamInverted,
                loginIcon: LoginSteam,
                icon: "",
            };
        case SocialLinkType.Google:
            return {
                color: colors.google,
                svg: SocialGoogle,
                invertedSvg: SocialGoogleInverted,
                icon: <Icon variant="google" />,
                loginIcon: LoginGoogle,
            };
        default:
            return { color: colors.black, svg: undefined as unknown, icon: "" };
    }
};

export const getSuffix = (variant: SocialLinkType) => {
    switch (variant) {
        case SocialLinkType.Website:
            return "";
        case SocialLinkType.Discord:
            return "discord.gg";
        case SocialLinkType.Twitch:
            return "twitch.tv";
        case SocialLinkType.Vk:
            return "vk.com";
        case SocialLinkType.Facebook:
            return "facebook.com";
        case SocialLinkType.Twitter:
            return "twitter.com";
        case SocialLinkType.Instagram:
            return "instagram.com";
        default:
            return "";
    }
};

const getSocialHref = (linkType: SocialLinkType, url: string) => {
    if (linkType === SocialLinkType.Website) return url.startsWith("http") ? url : `https://${url}`;

    const host = getSuffix(linkType);

    return `https://${host}/${url}`;
};

export interface SocialBadgeProps extends HTMLAttributes<HTMLDivElement> {
    links?: SocialLink[];
}

export const SocialBadges: FC<SocialBadgeProps> = ({ links, ...divProps }) => {
    if (!links) {
        return null;
    }

    return (
        <SocialBadgesContainer {...divProps}>
            {links
                .filter((x) => x.url)
                .map(({ url, linkType }) => (
                    <SocialBadge key={linkType} url={url} linkType={linkType} />
                ))}
        </SocialBadgesContainer>
    );
};

export const SocialBadge = ({ url, linkType }: { url: string; linkType: SocialLinkType }) => {
    const theme = useTheme();
    return (
        <IconButton size="small" key={linkType} href={getSocialHref(linkType, url)}>
            {getSocialProperties(linkType, theme).icon}
        </IconButton>
    );
};

const SocialBadgesContainer = styled("div")({
    display: "flex",
    alignItems: "center",

    "& > :not(:last-child)": {
        marginRight: "0.5rem",
    },
});
