import { Box, styled } from "@mui/material";

export const AccentContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== "active" && prop !== "enableBoxShadow",
})<{ active?: boolean; enableBoxShadow?: boolean }>(({ theme, active, enableBoxShadow }) => ({
    border: `0.0625rem solid ${theme.colors.input_border_color}`,
    borderRadius: theme.shape.borderRadius,

    ...(active && {
        borderColor: "transparent",
        backgroundImage: `linear-gradient(${theme.colors.black} 0 100%), linear-gradient(266.21deg, #EC008E 2.76%, #FFC700 96.55%)`,
        backgroundClip: "padding-box, border-box",
        backgroundOrigin: "padding-box, border-box",
    }),
    ...(enableBoxShadow && {
        boxShadow:
            "4px 4px 15px rgba(236, 0, 142, 0.35), -2px -2px 15px rgba(255, 199, 0, 0.35), 0px 0px 5px rgba(255, 107, 0, 0.5)",
    }),
}));
