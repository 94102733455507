export enum CompetitionFormat {
    None = 0,
    BattleRoyale = 1,
    SingleElimination = 2,
    DoubleElimination = 3,
    RoundRobin = 4,
    Challenge = 5,
    Swiss = 6,
}

export enum GameType {
    CSGO = 0,
    PUBG = 1,
    Generic = 10,
}

/**
 * Game accounts
 */
export interface BasicGameAccount {
    nick: string;
    avatarUrl?: string;
    gameId: string;
    isConnected: boolean;
    connectedAt: string;
}

export interface GameAccount extends BasicGameAccount {
    id: string;
    playerId: string;
    nick: string;
    gameId: string;
    isConnected: boolean;
    connectErrorMessage: string | undefined;
    alreadyConnectToPlayerId: string | undefined;

    steamId?: string;
    steamId64?: string;
    avatarUrl?: string;

    pubgAccountId?: string;
}

/**
 * Custom fields
 */
export enum CustomFieldIntent {
    Value = 0,
    Duration = 1,
    LinkButton = 2,
    List = 3,
}

export enum CustomFieldType {
    Number = 0,
    String = 1,
    Boolean = 2,
}

export enum PersistedCustomFieldGroup {
    PlayerStats = 0,
    TeamStats = 1,
    ConnectionInfo = 2,
    MapPool = 3,
    ScoreConfiguration = 4,
}

export enum CustomFieldAggregationOperator {
    None = 0,
    Sum = 1,
    Min = 2,
    Max = 3,
    Avg = 4,
}

export enum CustomFieldSortDirection {
    None = 0,
    Asc = 1,
    Desc = 2,
}

export enum CustomFieldVisibleFor {
    Everyone = 0,
    Authenticated = 1,
    Player = 2,
}

export interface CustomField {
    displayName: string | undefined;
    abbreviation: string | undefined;
    field: string;
    description: string;
    required: boolean;
    options: (string | number | boolean | undefined)[];
    primaryScoreField: boolean;
    editable: boolean;
    defaultValue: string | number | boolean | undefined;
    sortOrder: number;
    type: CustomFieldType;
    intent: CustomFieldIntent;
    visibleFor: CustomFieldVisibleFor;
    aggregateOperation: CustomFieldAggregationOperator;
    sortDirection: CustomFieldSortDirection;
    calculated: boolean;
    formula: string | undefined;
}

export interface PersistedCustomField extends CustomField {
    id: string;
    gameId: string;
    fieldGroup: PersistedCustomFieldGroup;
}

export interface CustomFieldValues {
    [key: string]: string | number | boolean | undefined;
}

/**
 * Score configuration
 */
export interface ScoreConfiguration {
    id: string;
    competitionId: string | undefined;
    name: string;
    default: boolean;
    gameId: string;
    variables: { name: string; value: string | undefined }[];
}

/**
 * Game settings
 */
export interface GameMap {
    id: string;
    gameId: string;
    displayName: string;
    name: string;
    description?: string;
    numberOfPlayers: number[];
    thumbnailImageId: string | undefined;
}

export interface MapPool {
    id: string;
    gameId: string;
    name: string;
    useVetoSide: boolean;
    teamSizes: number[];
    customFields: CustomFieldValues;
    maps: GameMap[];
}

export interface GameSetupImage {
    imageId: string | undefined;
    originalImageId?: string;
}

export interface TeamSize {
    numberOfPlayers: number;
    name?: string;
}

export enum GameMode {
    Head2Head = 1,
    FreeForAll = 2,
}

export interface GameSettings {
    id: string;
    name: string;
    description: string;
    game: GameType;
    mode: GameMode;
    published: boolean;
    useVeto: boolean;
    useManualScore: boolean;
    useManualReadyUp: boolean;
    useInGameReadyUp: boolean;
    useManualConnectionInfo: boolean;
    useBestOfX: boolean;
    useSlotInConnectionInfo: boolean;
    allowHomeTeamToEnterConnectionInfo: boolean;
    postGameTimerInSeconds: number | undefined;
    connectionGuide: string | undefined;

    defaultTournamentImage: GameSetupImage | undefined;
    iconImage: GameSetupImage | undefined;
    matchBoxBackgroundImage: GameSetupImage | undefined;

    backgroundColor: string | undefined;

    teamSizes: TeamSize[];
    validFormats: CompetitionFormat[];

    platforms: string[];
}

/**
 * Game setup
 */

export interface GameSetup {
    settings: GameSettings;
    mapPools: MapPool[];
    maps: GameMap[];
    connectionInfoFields: CustomField[];
    teamStatFields: CustomField[];
    playerStatFields: CustomField[];
    mapPoolFields: CustomField[];
    scoreConfigurationFields: CustomField[];
    scoreConfigurations: ScoreConfiguration[];
}
