import { useEffect, useRef, useState } from "react";
import type { TwitchPlayer, PlayerOptions } from "twitch-embed";

import { loadScript } from "@utils/loadScript";
import { TWITCH_LIBRARY_LINK } from "./helpers";

export interface TwitchPlayerHookProps {
    playerId: string;
    playerOptions: PlayerOptions;
}

export const useTwitchPlayer = ({ playerId, playerOptions }: TwitchPlayerHookProps) => {
    const [player, setPlayer] = useState<TwitchPlayer | null>(null);
    const loadStateRef = useRef<boolean>(false);

    const createPlayer = () => {
        const Twitch = window.Twitch;

        if (!Twitch) return null;

        const player = new Twitch.Player(playerId, playerOptions);

        const playerIframe = document.getElementById(playerId)?.children[0];

        playerIframe?.addEventListener(
            "load",
            () => {
                loadStateRef.current = true;
            },
            {
                once: true,
            }
        );

        player.addEventListener("ready", () => {
            setPlayer(player);
        });

        return player;
    };

    useEffect(() => {
        let player: TwitchPlayer | null;

        if (window.Twitch) {
            player = createPlayer();
        } else {
            const script = loadScript(TWITCH_LIBRARY_LINK);

            script?.addEventListener(
                "load",
                () => {
                    player = createPlayer();
                },
                {
                    once: true,
                }
            );
        }

        return () => {
            player && player.destroy();
            setPlayer(null);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        player,
        loadStateRef,
    };
};
