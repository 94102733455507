import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";

export const extensions = [
    StarterKit,
    TextAlign.configure({
        types: ["heading", "paragraph", "image"],
    }),
    Image.configure({
        inline: true,
    }),
    Link,
];
