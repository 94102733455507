import type { BoxProps, Theme } from "@mui/material";
import { Box, styled } from "@mui/material";

type ImageOverlayPositions = "top" | "bottom" | "cover";
interface ImageOverlayOptions {
    position: ImageOverlayPositions;
    overlaySize?: string;
    padding?: string;
}

const defaultOverlaySize = "1rem";
const defaultPadding = "1rem";

const getAnchors = (options: ImageOverlayOptions, theme: Theme) => {
    const gradientRotation = options.position === "top" ? "180deg" : "0deg";
    const gradient = `linear-gradient(${gradientRotation}, ${theme.colors.tournament_card_gradient_start}, ${theme.colors.tournament_card_gradient_end})`;
    if (options.position === "top") {
        return {
            top: 0,
            left: 0,
            gradient,
        };
    } else if (options.position === "bottom") {
        return {
            bottom: 0,
            left: 0,
            gradient,
        };
    } else {
        return {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            gradient: "rgba(0,0,0, 0.5)",
        };
    }
};

export interface ImageOverlayProps {
    options: ImageOverlayOptions;
}
export const ImageOverlay = styled(({ options, ...rest }: ImageOverlayProps & BoxProps) => (
    <Box {...rest} />
))<ImageOverlayProps>(({ theme, options }) => {
    const anchors = getAnchors(options, theme);
    const overlaySize = options?.overlaySize ?? defaultOverlaySize;

    return {
        position: "absolute",
        top: anchors.top,
        bottom: anchors.bottom,
        left: anchors.left,
        right: anchors.right,
        color: "white",
        width: "100%",
        padding: options.padding ?? defaultPadding,
        background: anchors.gradient,
        [theme.breakpoints.down("sm")]: {
            fontSize: "0.7em",
            padding: `calc(${overlaySize} / 2)`,
        },
    };
});
