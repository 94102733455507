import type { FC } from "react";
import type { RadioProps } from "@mui/material/Radio";
import RadioButton from "@mui/material/Radio";
import type { FormControlLabelProps } from "@mui/material/FormControlLabel";
import FormControlLabel from "@mui/material/FormControlLabel";

import { RadioButtonCheckedRoundedIcon } from "./MbGradientIcon";

export interface MbRadioProps extends RadioProps {
    label?: FormControlLabelProps["label"];
    labelPlacement?: FormControlLabelProps["labelPlacement"];
}

export const MbRadio: FC<MbRadioProps> = ({ label, labelPlacement, className, style, sx, ...radioProps }) => {
    if (label)
        return (
            <FormControlLabel
                control={<RadioButton checkedIcon={<RadioButtonCheckedRoundedIcon />} {...radioProps} />}
                label={label}
                labelPlacement={labelPlacement}
                className={className}
                style={style}
                sx={sx}
            />
        );

    return (
        <RadioButton
            checkedIcon={<RadioButtonCheckedRoundedIcon />}
            className={className}
            style={style}
            sx={sx}
            {...radioProps}
        />
    );
};
