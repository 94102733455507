import { ConfirmDialog, MessageDialog, SnackbarWrapper } from "@masterblaster/basics";
import type { SnackbarWrapperProps } from "@masterblaster/basics";
import type { DialogProps } from "@mui/material";
import { showModal } from "@store/ModalStore";
import type { ReactNode } from "react";

export const showSnackbar = (props: Omit<SnackbarWrapperProps, "onClose">) =>
    showModal((onClose) => <SnackbarWrapper onClose={onClose} {...props} />);

export const showConfirmDialog = (title: string, description: string, maxWidth?: DialogProps["maxWidth"]) => {
    const props = {
        maxWidth,
    };
    return showModal<boolean>((onClose) => (
        <ConfirmDialog onClose={(result) => onClose(result)} title={title} description={description} {...props} />
    ));
};

export const showMessageDialog = (title: string, description: string | ReactNode) => {
    return showModal((onClose) => (
        <MessageDialog
            onClose={() => {
                if (onClose) onClose();
            }}
            title={title}
            description={description}
        />
    ));
};
