import type { ColorsType } from "@masterblaster/theme";

export type TextSize = "xxs" | "xs" | "sm" | "md" | "normal" | "lg" | "xl";

export const getHeaderColor = (size: TextSize | undefined, colors: ColorsType) => {
    switch (size) {
        case "sm":
        case "md":
        default:
            return colors.primary_text;
    }
};
export const getHeaderWeight = (size: TextSize | undefined) => {
    switch (size) {
        case "sm":
        case "md":
        default:
            return "normal";
    }
};

export const getFontSize = (size: TextSize | undefined) => {
    switch (size) {
        case "xxs":
            return "0.875em";
        case "xs":
            return "1em"; // 16
        case "sm":
            return "1.125em"; // 18
        case "md":
            return "1.875em"; // 30
        case "lg":
            return "2.5em"; // 40
        case "xl":
            return "3.75em"; // 60
        default:
            return "1.125em"; // 18
    }
};
