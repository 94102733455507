import type { FC, ReactNode } from "react";
import { useState } from "react";
import { Box, Card, CardContent } from "@mui/material";
import { styled } from "@mui/material/styles";
import { borderRadius } from "@masterblaster/theme";

export interface StatItemProps {
    name: string;
    description?: string;
    value: string | number | ReactNode | undefined;
    valueColor?: string;
}

export const StatItem: FC<StatItemProps> = ({ name, description, value, valueColor }) => {
    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                width: "100%",
                minHeight: "8rem",
                borderRadius: 1,
                padding: "1rem",
                backgroundColor: (theme) => theme.colors.greys[16],
            }}
        >
            {description && (
                <Box
                    sx={{
                        position: "absolute",
                        top: "0.75rem",
                        right: "0.75rem",
                        display: "flex",
                        padding: "0.25rem 0.5rem",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "0.25rem",
                        borderRadius: "6.25rem",
                        fontSize: "0.75rem",
                        color: (theme) => theme.colors.secondary_text,
                        background: (theme) => theme.colors.greys[15],
                    }}
                >
                    {description}
                </Box>
            )}
            <StatHeader>{name}</StatHeader>
            <StatValue color={valueColor}>{value}</StatValue>
        </Box>
    );
};

const StatHeader = styled("h5")(({ theme }) => ({
    margin: "0 0 0.5rem",
    fontFamily: "Inter Variable",
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1rem",
    color: theme.colors.secondary_text,
}));

const StatValue = styled("p", {
    shouldForwardProp: (prop) => prop !== "color",
})<{ color?: string }>(({ color, theme }) => ({
    margin: 0,
    fontFamily: "Inter Variable",
    fontWeight: 500,
    fontSize: "1.5rem",
    lineHeight: "150%",
    color: color ?? theme.colors.white,
}));
