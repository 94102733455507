import type { FC } from "react";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import type { BoxProps } from "@mui/material/Box";
import Box from "@mui/material/Box";
import { GradientBorder } from "./shared";

interface MatchRescheduleDateInfoProps extends BoxProps {
    isActive: boolean;
}

export const MatchRescheduleDateInfo: FC<MatchRescheduleDateInfoProps> = ({ children, isActive, ...boxProps }) => {
    return (
        <Box mb="0.625rem" {...boxProps}>
            <GradientBorder active={isActive}>
                <DateContainer active={isActive}>
                    <Typography variant="h5" lineHeight="1.25" textAlign="center">
                        {children}
                    </Typography>
                </DateContainer>
            </GradientBorder>
        </Box>
    );
};

const DateContainer = styled("div", {
    shouldForwardProp: (prop) => prop !== "active",
})<{
    active: boolean;
}>(({ theme, active }) => ({
    background: active ? `linear-gradient(180deg, #282A36 0%, ${theme.colors.contrast_background} 100%)` : "none",
    padding: "0.5rem 1.375rem",
    boxSizing: "border-box",
    borderRadius: "4px",
}));
