import type { SxProps, Theme } from "@mui/material";
import { Avatar } from "@mui/material";
import { useEffect, useState } from "react";

export const Base64Image = ({
    blob,
    fallbackSrc,
    sx,
}: {
    blob: Blob | undefined;
    fallbackSrc?: string;
    sx?: SxProps<Theme>;
}) => {
    const [base64, setBase64] = useState<string>();

    useEffect(() => {
        if (blob) {
            blobToBase64(blob).then(setBase64);
        }
    }, [blob]);

    const avatarSrc = base64 ? `data:image/png;base64.${base64}` : fallbackSrc;
    return <Avatar src={avatarSrc} sx={sx} />;
};

const blobToBase64 = (blob: Blob) => {
    return new Promise<string>((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
    });
};
