import { Button, CircularProgress, Snackbar, type ButtonProps } from "@mui/material";
import { useEffect, useLayoutEffect, useRef, useState, type MouseEvent, type CSSProperties } from "react";
import { SnackbarContentWrapper } from "../snackbar/SnackbarContentWrapper";
export interface SpinnerButtonProps extends ButtonProps {
    isLoading?: boolean;
    p?: number;
    wrap?: boolean;
}

const getSize = (height: number) => {
    return height / 2;
};

const getMargin = (height: number) => {
    const size = getSize(height);
    return size / 2;
};

export const SpinnerButton = (props: SpinnerButtonProps) => {
    const { isLoading: loading, wrap, disabled, p, style, ...rest } = props;

    const buttonEl = useRef<HTMLButtonElement>(null);
    const [isLoading, setIsLoading] = useState(loading);
    const [spinnerSize, setSpinnerSize] = useState(getSize(24));
    const [spinnerMargin, setSpinnerMargin] = useState(getMargin(24));
    const [open, setOpen] = useState(false);
    const [error, setError] = useState("");
    const [unmounted, setUnmounted] = useState(false);

    useEffect(() => {
        setIsLoading(loading);

        return () => {
            setIsLoading(false);
            setUnmounted(true);
        };
    }, [loading]);

    const showError = (error: any) => {
        const msg = error ? error.toString() : "";
        setError(`Operation failed: ${msg}`);
        setOpen(true);
        setIsLoading(false);
        setTimeout(() => setOpen(false), 5000);
    };

    const onClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
        if (props.onClick) {
            setIsLoading(true);
            const result = props.onClick(e) as any;
            if (props.isLoading === undefined && result && result.then) {
                result
                    .then(() => {
                        if (!unmounted) {
                            setIsLoading(false);
                        }
                    })
                    .catch(showError);
            } else {
                setIsLoading(false);
            }
        }
    };

    useLayoutEffect(() => {
        const current = buttonEl.current;
        if (current) {
            const height = current.offsetHeight;
            setSpinnerSize(getSize(height));
            setSpinnerMargin(getMargin(height));
        }
    }, []);

    const margin = p ? `${p}rem` : "";
    const btnStyle = {
        margin,
        ...(wrap ? {} : { whiteSpace: "nowrap " }),
        ...(style ?? {}),
    } as CSSProperties;

    return (
        <>
            <Button ref={buttonEl} {...rest} style={btnStyle} disabled={disabled || isLoading} onClick={onClickHandler}>
                {props.children}
                {isLoading && (
                    <CircularProgress
                        size={spinnerSize}
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: -spinnerMargin,
                            marginLeft: -spinnerMargin,
                        }}
                    />
                )}
            </Button>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={open}
                autoHideDuration={6000}
                onClose={(_, reason) => {
                    if (reason === "clickaway") {
                        return;
                    }

                    setOpen(false);
                }}
            >
                <SnackbarContentWrapper onClose={() => setOpen(false)} variant="error" message={error} />
            </Snackbar>
        </>
    );
};
