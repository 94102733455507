import { CopyToClipboardButton } from "@masterblaster/basics";
import { FileCopyOutlined } from "@mui/icons-material";
import type { SxProps, Theme } from "@mui/material";
import { Box, Button, Link, styled } from "@mui/material";
import type { CustomField } from "@masterblaster/api";
import { CustomFieldIntent } from "@masterblaster/api";

interface CustomFieldValueOptions {
    showCopyToClipboard?: boolean;
    editable?: boolean;
}
export const CustomFieldValue = ({
    field,
    value,
    options,
    sx,
}: {
    field: Pick<CustomField, "field" | "displayName" | "intent" | "defaultValue">;
    value: string | number | boolean | undefined;
    options?: CustomFieldValueOptions;
    sx?: SxProps<Theme>;
}) => {
    const isString = typeof value === "string";
    if (isString && field.intent === CustomFieldIntent.LinkButton) {
        return (
            <Button variant="contained" component={Link} href={value} sx={sx}>
                {field.displayName ?? field.field}
            </Button>
        );
    }

    const displayValue = value?.toString() ?? field.defaultValue?.toString();
    return (
        <Box
            sx={{
                display: "flex",
                gap: "0.5rem",
                alignItems: "center",
                justifyContent: "space-between",
                width: 1,
                ...sx,
            }}
        >
            <FieldValueContainer>{displayValue}</FieldValueContainer>
            {options?.showCopyToClipboard && displayValue && (
                <CopyToClipboardButton value={displayValue}>
                    <FileCopyOutlined sx={{ width: "0.8rem", height: "0.8rem" }} />
                </CopyToClipboardButton>
            )}
        </Box>
    );
};

const FieldValueContainer = styled("div")(({ theme }) => ({
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
}));
