import type { SearchV2Result } from "@masterblaster/api";
import { SearchV2ResultObjectType, globalSearchAsync } from "@masterblaster/api";
import { generateLink } from "@utils/generateLink";

export const createSearchItemUrl = (value: SearchV2Result) => {
    if (!value || typeof value === "string" || Array.isArray(value) || !value.id) {
        return undefined;
    }

    switch (value.objectType) {
        case SearchV2ResultObjectType.Player:
            return generateLink("PLAYER_PROFILE_ROUTE", {
                id: value.id,
            });
        case SearchV2ResultObjectType.Team:
            return generateLink("TEAM_ROUTES.TEAM", {
                id: value.id,
            });

        case SearchV2ResultObjectType.League:
            return generateLink("LEAGUE_ROUTES.OVERVIEW", {
                leagueId: value.id,
            });
        case SearchV2ResultObjectType.Tournament:
            return generateLink("TOURNAMENT_ROUTES.LOBBY", {
                id: value.id,
            });

        case SearchV2ResultObjectType.Match:
            return generateLink("MATCH_ROUTES.LOBBY", {
                id: value.id,
            });

        case SearchV2ResultObjectType.Community:
            return generateLink("COMMUNITY_ROUTES.COMMUNITY", {
                id: value.id,
            });
        case SearchV2ResultObjectType.Organization:
            return generateLink("ORG_ROUTES.PAGE", {
                id: value.id,
            });
        case SearchV2ResultObjectType.FragTrial:
            return generateLink("TRIALS_ROUTES.TRIAL", {
                id: value.id,
            });
        default:
            return undefined;
    }
};

export const searchTeam = (input: string, exactSearch = false) =>
    globalSearchAsync(input, { objectType: SearchV2ResultObjectType.Team, exactObjectNameMatch: exactSearch });

export const searchForTeams = (search: string, page: number, pageSize: number, gameId?: string, onlyOrgs?: boolean) => {
    let keyQueries = [] as string[];
    if (gameId) keyQueries = [...keyQueries, `gameId:${gameId}`];
    if (onlyOrgs) keyQueries = [...keyQueries, `organizationOwnerId:`];

    return globalSearchAsync(search, { objectType: SearchV2ResultObjectType.Team, page, pageSize, keyQueries });
};

export const searchPlayer = (input: string) =>
    globalSearchAsync(input, { objectType: SearchV2ResultObjectType.Player });

export const searchOrganization = (input: string, exactSearch = false) =>
    globalSearchAsync(input, { objectType: SearchV2ResultObjectType.Organization, exactObjectNameMatch: exactSearch });
