import { MbRouterLink, CommunityAvatar } from "@masterblaster/basics";
import { Box, Card, CardActionArea, CardContent, CardMedia } from "@mui/material";
import { DefaultCommunityHeader } from "@masterblaster/assets";
import { generateLink } from "@utils/generateLink";

import type { CommunityOverviewModel } from "./CommunitiesApi";
import { CommunityImageType } from "./CommunitiesApi";
import { getImageUrl } from "@masterblaster/api";

export const CommunityCard = ({ community }: { community: CommunityOverviewModel }) => {
    const headerImage = community.images.find((x) => x.imageType === CommunityImageType.Cover);
    const avatarImage = community.images.find((x) => x.imageType === CommunityImageType.Avatar);
    return (
        <MbRouterLink
            to={generateLink("COMMUNITY_ROUTES.COMMUNITY", {
                id: community.id,
            })}
        >
            <Card
                elevation={0}
                sx={{
                    borderRadius: (theme) => `${theme.shape.borderRadius}px`,
                    border: (theme) => `2px solid ${theme.colors.border}`,
                }}
            >
                <CardActionArea>
                    <CardMedia
                        component="img"
                        style={{ height: 90, objectFit: "cover" }}
                        image={getImageUrl(headerImage?.imageId) || DefaultCommunityHeader}
                    />
                    <CardContent style={{ padding: 0 }}>
                        <Box
                            style={{
                                position: "absolute",
                                marginLeft: "1rem",
                                marginTop: -22,
                                backgroundColor: "transparent",
                            }}
                        >
                            <CommunityAvatar url={getImageUrl(avatarImage?.imageId)} />
                        </Box>
                        <Box display="flex" alignItems="center" paddingLeft="1rem" style={{ height: 90 }}>
                            <span>{community.name}</span>
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        </MbRouterLink>
    );
};
