import type { FC } from "react";
import type { SvgIconProps } from "@mui/material";

import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatStrikethroughIcon from "@mui/icons-material/FormatStrikethrough";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import CodeIcon from "@mui/icons-material/Code";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";

import type { EditorCommands, AlignmentVariants, HeadingLevels } from "../utils";

type EditorOption<T> = { key: T; icon?: FC<SvgIconProps>; label: string };

type DefaultOptionsKeys = Exclude<keyof EditorCommands, "setAlignment" | "setHeading">;

export const defaultOptions: Array<EditorOption<DefaultOptionsKeys>> = [
    {
        key: "bold",
        icon: FormatBoldIcon,
        label: "Bold",
    },
    {
        key: "italic",
        icon: FormatItalicIcon,
        label: "Italic",
    },
    {
        key: "strike",
        icon: FormatStrikethroughIcon,
        label: "Strike",
    },
    {
        key: "bulletList",
        icon: FormatListBulletedIcon,
        label: "Bullet list",
    },
    {
        key: "orderedList",
        icon: FormatListNumberedIcon,
        label: "Ordered list",
    },
    {
        key: "codeBlock",
        icon: CodeIcon,
        label: "Code",
    },
    {
        key: "blockquote",
        icon: FormatQuoteIcon,
        label: "Blockquote",
    },
];

export const alignOptions: Array<EditorOption<AlignmentVariants>> = [
    {
        key: "left",
        icon: FormatAlignLeftIcon,
        label: "Left",
    },
    {
        key: "center",
        icon: FormatAlignCenterIcon,
        label: "Center",
    },
    {
        key: "right",
        icon: FormatAlignRightIcon,
        label: "Right",
    },
    {
        key: "justify",
        icon: FormatAlignJustifyIcon,
        label: "Justify",
    },
];

export const headingOptions: Array<EditorOption<HeadingLevels>> = [
    {
        key: 1,
        label: "Heading 1",
    },
    {
        key: 2,
        label: "Heading 2",
    },
    {
        key: 3,
        label: "Heading 3",
    },
    {
        key: 4,
        label: "Heading 4",
    },
    {
        key: 5,
        label: "Heading 5",
    },
    {
        key: 6,
        label: "Heading 6",
    },
];
