import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { ReactElement } from "react";
import { cloneElement } from "react";
import { v4 as uuidv4 } from "uuid";
import { getDispatch } from "@store/core";

type ModalComponent = {
    key: string;
    component: ReactElement;
};

export interface ModalState {
    modalComponents: ModalComponent[];
}

const initialState: ModalState = {
    modalComponents: [],
};

type ShowActionType = PayloadAction<{
    key: string;
    modal: ReactElement;
}>;

type HideActionType = PayloadAction<string>;

export const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        show(state, action: ShowActionType) {
            state.modalComponents = [
                ...state.modalComponents,
                {
                    key: action.payload.key,
                    component: action.payload.modal,
                },
            ];
        },

        hide(state, action: HideActionType) {
            state.modalComponents = state.modalComponents.filter((modal) => modal.key !== action.payload);
        },
    },
});

interface ModalReturnFunction<RType> {
    (result?: RType): void;
}
interface ModalCreator<RType = undefined> {
    (onClose: ModalReturnFunction<RType>): ReactElement;
}

export const showModal = <RType>(creator: ModalCreator<RType>) => {
    const dispatch = getDispatch();

    if (!dispatch) {
        throw new Error("Dispatch not initialized");
    }

    return new Promise<RType>((resolve) => {
        const key = uuidv4();

        const onClose: ModalReturnFunction<RType> = (result?: RType) => {
            resolve(result as RType);
            dispatch(modalSlice.actions.hide(key));
        };

        const modalProps = {
            key: key,
            onClose: onClose,
        };

        const component = creator(onClose);
        const modal = cloneElement(component, modalProps);

        dispatch(modalSlice.actions.show({ key: key, modal: modal }));
    });
};
