import { useTranslation } from "react-i18next";

import { EmptyView } from "@masterblaster/basics";
import { StreamsEmptyIcon } from "@masterblaster/assets";

export const StreamsEmptyView = () => {
    const { t } = useTranslation("translation", {
        keyPrefix: "components.stream.empty_view",
    });

    return (
        <EmptyView
            alt="Empty streams"
            src={StreamsEmptyIcon}
            maxWidth={200}
            header={t("no_matches_streamed")}
            subheader={t("come_back_later")}
        />
    );
};
